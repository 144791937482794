import "./Register5.css";
import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Button,
  IconButton,
  registerUser,
  getCountries,
  jwt_decode,
  Typography,
  TextField,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from "./Register5Imports";

import { PreviousArrowIcon } from "Icons/Icons";
import useNotification from "Components/CommonComponents/AlertNotifications/AlertNotifications";
import { useSnackbar } from "notistack";
import { Error } from "Utils/Success&Errorfunctions";
import { login } from "Services/Login&Registrationservice";
import { CircularProgress } from "@mui/material";

export const Register5 = (props) => {
  const navigate = useNavigate();
  const [msg, sendNotification] = useNotification();
  const { enqueueSnackbar } = useSnackbar();

  const CountryTemp = [];

  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(CountryTemp[0]);
  // const [inputValue, setInputValue] = useState("");
  const [Countries, setCountries] = useState([]);
  const [country, setCountry] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [city, setCity] = useState("");
  const [postalCode, setPostalCode] = useState("");

  const [progress, setProgress] = useState(67);

  useEffect(() => {
    getCountry();
    getEmail();
  }, []);

  const getEmail = () => {
    if (JSON.parse(localStorage.getItem("JWT")) !== null) {
      const JWT = JSON.parse(localStorage.getItem("JWT"));
      const DecodedJWT = jwt_decode(JWT.access_token);
      const accountType = DecodedJWT.account_type;
      if (accountType === "contractor" || accountType === "employee") {
        navigate("/employeeregister");
      }

      const isUserProfileUpdated = DecodedJWT.user_profile_updated;
      // console.log(isUserProfileUpdated);
      if (isUserProfileUpdated == true) {
        navigate("/register/6");
      }
    }
  };

  const showLoading = () => {
    setLoading(true);
  };

  const hideLoading = () => {
    setLoading(false);
  };

  const getCountry = () => {
    getCountries().then(
      (res) => {
        setCountries(res.data);
      },
      (err) => {
        console.log(err.data.message);
      }
    );
  };

  const submit = (e) => {
    e.preventDefault();
    showLoading();
    const data = {
      address: {
        country_id: country,
        address1: addressLine1.trim(),
        address2: addressLine2.trim(),
        city: city.trim(),
        postal_code: postalCode.trim(),
      },
    };
    localStorage.setItem("Registrationdata3", JSON.stringify(data));
    const jwt = JSON.parse(localStorage.getItem("JWT"));
    const decodedjwt = jwt_decode(jwt.access_token);
    if (
      addressLine1.trim() !== "" &&
      city.trim() !== "" &&
      postalCode.trim() !== ""
    ) {
      const page1data = JSON.parse(localStorage.getItem("Registrationdata"));
      const page2data = JSON.parse(localStorage.getItem("Registrationdata1"));
      const page3data = JSON.parse(localStorage.getItem("Registrationdata2"));
      const page4data = JSON.parse(localStorage.getItem("Registrationdata3"));

      let Masterdata = {
        ...page1data,
        ...page2data,
        ...page3data,
        ...page4data,
      };
      registerUser(Masterdata, decodedjwt.row_id).then(
        (res) => {
          var variant = "success";
          enqueueSnackbar(res.data.message, { variant });
          localStorage.removeItem("Registrationdata");
          localStorage.removeItem("Registrationdata1");
          localStorage.removeItem("Registrationdata2");
          localStorage.removeItem("Registrationdata3");

          navigate("/register/6");
          hideLoading();
        },
        (err) => {
          var errors = Error(err.response.data.message);

          if (Array.isArray(errors)) {
            errors.forEach((element) => {
              sendNotification({
                msg: `Please enter valid ${element}`,
                variant: "error",
              });
            });
            hideLoading();
          } else {
            sendNotification({
              msg: err.response.data.message,
              variant: "error",
            });
            hideLoading();
          }
          hideLoading();
        }
      );
    } else {
      if (addressLine1.trim() == "") {
        sendNotification({
          msg: "Please enter valid Address Line 1!",
          variant: "error",
        });
      } else if (city.trim() == "") {
        sendNotification({
          msg: "Please enter valid City Name!",
          variant: "error",
        });
      } else if (postalCode.trim() == "") {
        sendNotification({
          msg: "Please enter valid Postal Code!",
          variant: "error",
        });
      }
    }
    // console.log(res);
  };
  const handleChange = (event) => {
    setCountry(event.target.value);
  };

  return (
    <>
      <form onSubmit={submit} className="register_form">
        <Grid xs={12} className="select_contry">
          <FormControl fullWidth className="register-select">
            <InputLabel id="demo-simple-select-label">
              Select country
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={country}
              label="select country"
              onChange={handleChange}
            >
              {Countries.map((country) => (
                <MenuItem key={country.row_id} value={country.row_id}>
                  {country.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid xs={12}>
          <TextField
            fullWidth
            required
            label="Address line 1"
            onChange={(e) => setAddressLine1(e.target.value)}
            id="fullWidth"
            variant="standard"
            inputProps={{ maxLength: 128, autoComplete: "off" }}
          />
        </Grid>
        <Grid xs={12}>
          <TextField
            fullWidth
            label="Address line 2 (optional)"
            id="fullWidth"
            variant="standard"
            onChange={(e) => setAddressLine2(e.target.value)}
            inputProps={{ maxLength: 128, autoComplete: "off" }}
          />
        </Grid>
        <Grid xs={12}>
          <TextField
            required
            fullWidth
            label="City"
            id="fullWidth"
            variant="standard"
            onChange={(e) => setCity(e.target.value)}
            inputProps={{ maxLength: 128, autoComplete: "off" }}
          />
        </Grid>
        <Grid xs={12}>
          <TextField
            required
            fullWidth
            label="Postal Code"
            id="fullWidth"
            variant="standard"
            onChange={(e) => setPostalCode(e.target.value)}
            inputProps={{ maxLength: 20, autoComplete: "off" }}
          />
        </Grid>
        <Grid xs={12} className="login_bttn_wrapper">
          <Button
            variant="contained"
            disabled={loading}
            type="submit"
            color="primary"
            className="theme_bttn fill"
            endIcon={
              loading ? <CircularProgress size={20} color="inherit" /> : null
            }
          >
            Continue
          </Button>
        </Grid>
      </form>
    </>
  );
};
