import "./Register6.css";
import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Registrationend,
  Typography,
  Grid,
  Button,
  IconButton,
} from "./Register6Imports";
import { PreviousArrowIcon, WelcomeInrole } from "Icons/Icons";
import { useSnackbar } from "notistack";
import { jwt_decode } from "../Register1/Register1Imports";
import { login } from "Services/Login&Registrationservice";

export const Register6 = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getEmail();
  }, []);

  const getEmail = () => {
    if (JSON.parse(localStorage.getItem("JWT")) !== null) {
      const JWT = JSON.parse(localStorage.getItem("JWT"));
      const DecodedJWT = jwt_decode(JWT.access_token);
      const accountType = DecodedJWT.account_type;
      const email = { email: DecodedJWT.email };
      console.log(email);
      login(email).then(
        (res) => {
          localStorage.setItem(
            "JWT",
            JSON.stringify({ access_token: res.data.access_token })
          );
        },
        (err) => {
          console.log(err);
        }
      );
    }
  };

  const Confirm = () => {
    var variant = "success";

    enqueueSnackbar(`Welcome to Inrole.`, { variant });

    navigate("/home");
  };

  return (
    <>
      <WelcomeInrole />
      <Typography variant="body2">
        Lets get started on journey to hire employees and contractors globally!
      </Typography>
      <Grid xs={12} className="login_bttn_wrapper">
        <Button
          variant="contained"
          type="button"
          color="primary"
          onClick={Confirm}
          className="theme_bttn fill"
        >
          Go to your home
        </Button>
      </Grid>
    </>
  );
};
