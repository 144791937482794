import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Grid from "@mui/material/Unstable_Grid2";

// import LoginPageSideBarDesign from "../../Assets/Svgs/LoginPageSideBarDesign.svg";

import { InRoleSidebarLogo } from "Icons/Icons";
import { Button } from "@mui/material";
import { LogoutIcon } from "Components/Dashboard/DashboardImports";

function AuthSidebar(props) {
  const isBackgroundImage = props.backgroundImage;
  const { logout } = useAuth0();
  // const backgroundImageStyle = {
  //   backgroundImage: "url(" + LoginPageSideBarDesign + ")",
  // };

  return (
    <Grid
      item
      md={4}
      className={"login-sidebar " + props.children}
      style={{ padding: 0 }}
    >
      <div className="login_sidebar_container" style={isBackgroundImage}>
        <InRoleSidebarLogo className="logo-img" />

        <div className="login_sidebar_content">{props.children}</div>

        {props.logoutBttn ? (
          <div className="login_sidebar_logout">
            <Button
              className="theme_bttn transparent"
              startIcon={<LogoutIcon />}
              onClick={() => {
                logout(
                  {
                    logoutParams: {
                      returnTo: window.location.origin,
                    },
                  },
                  localStorage.clear()
                );
              }}
            >
              Logout
            </Button>
          </div>
        ) : null}
      </div>
    </Grid>
  );
}

export default AuthSidebar;
