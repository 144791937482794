import "./Team.css";
import { React, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Avatar,
  IconButton,
  Typography,
  Grid,
  Button,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Switch,
  TextField,
  InputAdornment,
  SearchIcon,
} from "./TeamImpoerts";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import {
  getAllContractor,
  getAllEmployee,
} from "Services/AddEmployeeAndContractor";

import TablePagination from "@mui/material/TablePagination";
import { EmployeeAndContractorAddModal } from "Components/CommonComponents/Modals/Employee-Contractor Add Modal/EmployeeAndContractorAddModal";
import {
  Alert,
  CircularProgress,
  Collapse,
  Fade,
  Grow,
  Slide,
  Zoom,
} from "@mui/material";

import { HelpButton } from "Components/CommonComponents/Bottom_Help_Button/HelpButton";
import {
  DataNotFound,
  FilterIcon,
  TeamEditIcon,
  TeamNoEmployeeIcon,
} from "Icons/Icons";
import { TabPanel, a11yProps } from "Utils/TabPanel";
import { TransitionGroup } from "react-transition-group";

export const Teams = (props) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState(0);

  const [open, setOpen] = useState(false);

  const [totalEmployee, setTotalEmployee] = useState(0);
  const [allEmployeeArray, setAllEmployeeArray] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);

  const [Search, setSearch] = useState("");

  const [allEmployeeArrayTemp, setAllEmployeeArrayTemp] = useState([]);

  const [totalContractor, setTotalContractor] = useState(0);
  const [allContractorArray, setAllContractorArray] = useState([]);
  const [pageForContractor, setPageForContractor] = useState(0);
  const [rowsPerPageForContractor, setRowsPerPageForContractor] = useState(5);
  const [searchcontractor, setSearchcontractor] = useState("");
  const [allContractorArrayTemp, setAllContractorArrayTemp] = useState([]);

  const [editDialog, setEditDialog] = useState(false);
  const [filterDialog, setFilterDialog] = useState(false);

  const editDialogToggle = () => {
    setEditDialog(!editDialog);
  };

  const [rowName, setRowName] = useState([
    "Name",
    "Employment ID",
    "Employment Country",
    "Annual Salary",
    "Role",
    "Employee Status",
  ]);
  const [rowNameForContractor, setRowNameForContractor] = useState([
    "Name",
    "Employment ID",
    "Employment Country",
    "Annual Salary",
    "Role",
  ]);
  const [filterColumnForEmployee, setFilterColumnForEmployee] = useState("");
  const [checkedForEmployee, setCheckedForEmployee] = useState([
    "Name",
    "Employment ID",
    "Employment Country",
    "Annual Salary",
    "Role",
    "Employee Status",
  ]);
  const [checkedForContractor, setCheckedForContractor] = useState([
    "Name",
    "Employment ID",
    "Employment Country",
    "Annual Salary",
    "Role",
    "Contractor Status",
  ]);
  const [filterColumn, setfilterColumn] = useState("");

  const [filterStatus, setFilterStatus] = useState([
    "All",
    "Active",
    "Inactive",
  ]);
  const [checkedForFilterStatus, setCheckedForFilterStatus] = useState(["All"]);

  const [filterStatusForContractor, setFilterStatusForContractor] = useState([
    "All",
    "Active",
    "Inactive",
  ]);
  const [
    checkedForFilterStatusForContractor,
    setCheckedForFilterStatusForContractor,
  ] = useState(["All"]);

  const hideLoading = () => {
    setLoading(false);
  };
  const showLoading = () => {
    setLoading(true);
  };

  useEffect(() => {
    localStorage.removeItem("ContractorData1");
    localStorage.removeItem("ContractorData2");
    localStorage.removeItem("ContractorData3");
    localStorage.removeItem("ContractorRowData");

    localStorage.removeItem("summuaryData");
    localStorage.removeItem("Empdata1");
    localStorage.removeItem("Empdata2");
    localStorage.removeItem("Empdata3");
    localStorage.removeItem("empRowData");

    localStorage.removeItem("empidForDetailView");
    localStorage.removeItem("empdactivateIDForDetailView");
    localStorage.removeItem("employeeActivationID");
    localStorage.removeItem("contraidForDetailView");
    localStorage.removeItem("contradactivateIDForDetailView");
    localStorage.removeItem("contractorActivationID");
    localStorage.removeItem("employeeStatus");
    localStorage.removeItem("contractorStatus");
    if (window.location.search.includes("contractor")) {
      setValue(1);
    } else {
      setValue(0);
    }
    const getData = setTimeout(() => {
      employeeListing();
    }, 200);

    return () => clearTimeout(getData);
  }, [rowsPerPage, Search, checkedForFilterStatus]);

  useEffect(() => {
    const getData = setTimeout(() => {
      contractorListing();
    }, 200);

    return () => clearTimeout(getData);
  }, [
    rowsPerPageForContractor,
    searchcontractor,
    checkedForFilterStatusForContractor,
  ]);

  function handleToggle(value) {
    const currentIndex = checkedForEmployee.indexOf(value);
    const newChecked = [...checkedForEmployee];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheckedForEmployee(newChecked);
  }

  function handleToggleForContractor(value) {
    const currentIndex = checkedForContractor.indexOf(value);
    const newChecked = [...checkedForContractor];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheckedForContractor(newChecked);
  }

  function handleToggleForFilterStatus(value) {
    var currentIndex = checkedForFilterStatus.indexOf(value);
    var newChecked = [...checkedForFilterStatus];
    if (currentIndex === -1) {
      newChecked.pop();
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    if (newChecked.length <= 0) {
      setCheckedForFilterStatus(["All"]);
    } else {
      setCheckedForFilterStatus(newChecked);
    }
  }

  function handleToggleForFilterStatusForContractor(value) {
    var currentIndex = checkedForFilterStatusForContractor.indexOf(value);
    var newChecked = [...checkedForFilterStatusForContractor];
    if (currentIndex === -1) {
      newChecked.pop();
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    if (newChecked.length <= 0) {
      setCheckedForFilterStatusForContractor(["All"]);
    } else {
      setCheckedForFilterStatusForContractor(newChecked);
    }
  }

  const TeamEmployeeDetailsNavigate = (index, deactivationid, status) => {
    if (status === "active") {
      localStorage.setItem("empidForDetailView", index);
      localStorage.setItem("empdactivateIDForDetailView", deactivationid);
      navigate("/team/employeeid");
    } else {
      localStorage.setItem("empidForDetailView", index);
      localStorage.setItem("employeeActivationID", deactivationid);
      navigate("/team/employeeid");
    }
  };

  const TeamContractorDetails = (index, deactivationid, status) => {
    if (status === "active") {
      localStorage.setItem("contraidForDetailView", index);
      localStorage.setItem("contradactivateIDForDetailView", deactivationid);
      navigate("/team/contractorid");
    } else {
      localStorage.setItem("contraidForDetailView", index);
      localStorage.setItem("contractorActivationID", deactivationid);
      navigate("/team/contractorid");
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const employeeListing = (pageNo = 1) => {
    showLoading();
    getAllEmployee(
      pageNo,
      rowsPerPage,
      Search,
      checkedForFilterStatus[0].toLowerCase()
    ).then(
      (res) => {
        setTotalEmployee(res.data.total);
        res.data.data.map((data) => {
          var logo = data.emp_id.full_name.split(" ");
          var logo1 = logo[0].slice(0, 1).toUpperCase();
          if (logo[1] != null) {
            var logo2 = logo[1].slice(0, 1).toUpperCase();
            data.avtarLogo = logo1.concat(logo2);
          }
        });

        setAllEmployeeArray(res.data.data);
        setAllEmployeeArrayTemp(res.data.data);
        hideLoading();
      },
      (err) => {
        setTotalEmployee(0);
        setAllEmployeeArray([]);
        props.unAuthorized(err.response.status);
        hideLoading();
      }
    );
  };

  const onEmployeePageChange = (event, newPage) => {
    setPage(newPage);
    employeeListing(newPage + 1);
  };

  const contractorListing = (pageNo = 1) => {
    showLoading();
    getAllContractor(
      pageNo,
      rowsPerPageForContractor,
      searchcontractor,
      checkedForFilterStatusForContractor[0].toLowerCase()
    ).then(
      (res) => {
        setTotalContractor(res.data.total);
        res.data.data.map((data) => {
          var logo = data.contractor_id.full_name.split(" ");
          var logo1 = logo[0].slice(0, 1).toUpperCase();
          if (logo[1] != null) {
            var logo2 = logo[1].slice(0, 1).toUpperCase();
            data.avtarLogo = logo1.concat(logo2);
          }
        });

        setAllContractorArray(res.data.data);
        setAllContractorArrayTemp(res.data.data);
        hideLoading();
      },
      (err) => {
        setTotalContractor(0);
        setAllContractorArray([]);
        props.unAuthorized(err.response.status);
        hideLoading();
      }
    );
  };

  const onContractorPageChange = (event, newPage) => {
    setPageForContractor(newPage);

    contractorListing(newPage + 1);
  };

  return (
    <>
      <Slide in={true} direction="left">
        <Grid item className={props.className}>
          <Grid container className="team_top_heading">
            <Grid item>
              <Typography variant="h3" className="page_heading">
                Team
              </Typography>
            </Grid>

            {allEmployeeArrayTemp.length || allContractorArrayTemp.length ? (
              <>
                <Grid item>
                  <Button
                    onClick={() => setOpen(true)}
                    variant="contained"
                    startIcon={<PersonAddIcon />}
                    className="theme_bttn fill"
                  >
                    Add new hire
                  </Button>
                </Grid>
                <Grid xs={12} item>
                  <Typography variant="body2" className="page_head_para">
                    Want to learn more about the onboarding process at InRole?{" "}
                    <Link>Here is our onboarding guide.</Link>
                  </Typography>
                </Grid>
              </>
            ) : null}
          </Grid>

          {!allEmployeeArrayTemp.length && !allContractorArrayTemp.length ? (
            !loading ? (
              <>
                <div className="team_not_found">
                  <TeamNoEmployeeIcon />

                  <Typography variant="h4">
                    You don’t have any team members yet
                  </Typography>
                  <Typography variant="body2">
                    Your team members will appear here after they finish their
                    onboarding. You can track their progress on the{" "}
                    <Link to="">Onboarding</Link> page or add new hires below
                  </Typography>
                  <Button
                    startIcon={<PersonAddIcon />}
                    className="theme_bttn fill"
                    onClick={() => setOpen(true)}
                  >
                    Add first hire
                  </Button>
                </div>
                <HelpButton />
              </>
            ) : (
              <>
                <div className="team_not_found">
                  <CircularProgress size={100} />
                </div>
                <HelpButton />
              </>
            )
          ) : (
            <>
              <div className="dash_tabs">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs "
                >
                  <Tab
                    label={<Typography>Employees ({totalEmployee})</Typography>}
                    {...a11yProps(0)}
                    onClick={() => navigate("/team")}
                  />

                  <Tab
                    label={
                      <Typography>Contractors ({totalContractor})</Typography>
                    }
                    {...a11yProps(1)}
                  />
                </Tabs>
                <TabPanel value={value} index={0} className="tab-pannel">
                  <div
                    className={`data_table ${
                      !allEmployeeArray.length && "no_data_found"
                    }`}
                  >
                    <Grid container className="data_table_filters_grid">
                      <>
                        <TextField
                          spellCheck="false"
                          value={Search}
                          name="search"
                          key="ABC"
                          onChange={(e) => setSearch(e.target.value)}
                          className="outer_searchfilled"
                          placeholder="Search"
                          id="outlined-start-adornment"
                          InputProps={{
                            autoComplete: "off",
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </>
                      <div className="table_edit_controller">
                        <Grid container className="filter_group">
                          <IconButton
                            onClick={(e) => setFilterDialog(!filterDialog)}
                          >
                            <FilterIcon />
                          </IconButton>
                          <Typography>Filter</Typography>
                        </Grid>{" "}
                        {filterDialog ? (
                          <Zoom in={filterDialog}>
                            <div className="edit_filter_menu is_small">
                              <Button
                                className="edit_filter_menu_close"
                                onClick={() => setFilterDialog(!filterDialog)}
                                disableRipple
                              ></Button>
                              <div className="edit_filter_menu_bx">
                                <List
                                  subheader={
                                    <ListSubheader>Filter Data</ListSubheader>
                                  }
                                >
                                  {filterStatus.map((data, index) => (
                                    <ListItem key={index}>
                                      <Switch
                                        edge="end"
                                        onChange={() =>
                                          handleToggleForFilterStatus(data)
                                        }
                                        checked={
                                          checkedForFilterStatus.indexOf(
                                            data
                                          ) !== -1
                                        }
                                        className={
                                          checkedForFilterStatus.indexOf(
                                            data
                                          ) !== -1
                                            ? "active"
                                            : "inactive"
                                        }
                                        inputProps={{
                                          "aria-labelledby": data,
                                        }}
                                      />
                                      <ListItemText
                                        id={data}
                                        primary={data}
                                        disableTypography
                                      />
                                    </ListItem>
                                  ))}
                                </List>
                              </div>
                            </div>
                          </Zoom>
                        ) : null}
                      </div>

                      <div className="table_edit_controller">
                        <IconButton onClick={editDialogToggle}>
                          <TeamEditIcon />
                        </IconButton>

                        {editDialog ? (
                          <Zoom in={editDialog}>
                            <div className="edit_filter_menu">
                              <Button
                                className="edit_filter_menu_close"
                                onClick={editDialogToggle}
                                disableRipple
                              ></Button>
                              <div className="edit_filter_menu_bx">
                                <TextField
                                  placeholder="Search Columns"
                                  fullWidth
                                  value={filterColumnForEmployee}
                                  id="outlined-start-adornment"
                                  sx={{
                                    mt: "10px",
                                    ml: "10px",
                                    mr: "5px",
                                    width: "auto",
                                  }}
                                  onChange={(e) =>
                                    setFilterColumnForEmployee(e.target.value)
                                  }
                                  InputProps={{
                                    autoComplete: "off",
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <SearchIcon />
                                      </InputAdornment>
                                    ),
                                  }}
                                />{" "}
                                <List
                                  subheader={
                                    <ListSubheader>
                                      Original Columns
                                    </ListSubheader>
                                  }
                                >
                                  {" "}
                                  <TransitionGroup>
                                    {rowName
                                      .filter((data) => {
                                        return filterColumnForEmployee.toLowerCase() ===
                                          ""
                                          ? data
                                          : data
                                              .toLowerCase()
                                              .includes(
                                                filterColumnForEmployee.toLowerCase()
                                              );
                                      })
                                      .map((data, index) => (
                                        <Collapse key={data}>
                                          <ListItem key={index}>
                                            <Switch
                                              edge="end"
                                              onChange={() =>
                                                handleToggle(data)
                                              }
                                              checked={
                                                checkedForEmployee.indexOf(
                                                  data
                                                ) !== -1
                                              }
                                              className={
                                                checkedForEmployee.indexOf(
                                                  data
                                                ) !== -1
                                                  ? "active"
                                                  : "inactive"
                                              }
                                              inputProps={{
                                                "aria-labelledby": data,
                                              }}
                                            />
                                            <ListItemText
                                              id={data}
                                              primary={data}
                                              disableTypography
                                            />
                                          </ListItem>
                                        </Collapse>
                                      ))}
                                  </TransitionGroup>
                                </List>
                              </div>
                            </div>
                          </Zoom>
                        ) : null}
                      </div>
                    </Grid>
                    <div className="table_container">
                      <Table stickyHeader aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            {checkedForEmployee.indexOf("Name") >= 0 ? (
                              <TableCell component={"td"} align="left">
                                Name
                              </TableCell>
                            ) : null}
                            {checkedForEmployee.indexOf("Employment ID") >=
                            0 ? (
                              <TableCell component={"td"} align="left">
                                EMPLOYMENT ID
                              </TableCell>
                            ) : null}
                            {checkedForEmployee.indexOf("Employment Country") >=
                            0 ? (
                              <TableCell component={"td"} align="left">
                                EMPLOYMENT COUNTRY
                              </TableCell>
                            ) : null}
                            {checkedForEmployee.indexOf("Annual Salary") >=
                            0 ? (
                              <TableCell component={"td"} align="left">
                                ANNUAL SALARY
                              </TableCell>
                            ) : null}

                            {checkedForEmployee.indexOf("Role") >= 0 ? (
                              <TableCell component={"td"} align="left">
                                ROLE
                              </TableCell>
                            ) : null}
                            {checkedForEmployee.indexOf("Employee Status") >=
                            0 ? (
                              <TableCell component={"td"} align="left">
                                Emp. Status
                              </TableCell>
                            ) : null}
                          </TableRow>
                        </TableHead>
                        {allEmployeeArray.length && !loading ? (
                          <TableBody>
                            {allEmployeeArray.map((row, index) => (
                              <TableRow
                                key={row.emp_id.user_id}
                                onClick={() =>
                                  TeamEmployeeDetailsNavigate(
                                    row.emp_id.row_id,
                                    row.row_id,
                                    row.employee_status
                                  )
                                }
                              >
                                {checkedForEmployee.indexOf("Name") >= 0 ? (
                                  <TableCell scope="row" align="left">
                                    <Grid
                                      container
                                      className="avatar_container"
                                      spacing={0}
                                      p={0}
                                    >
                                      <Avatar className="team_avatar">
                                        {row.avtarLogo}
                                      </Avatar>
                                      <span>{row.emp_id.full_name}</span>
                                    </Grid>
                                  </TableCell>
                                ) : null}
                                {checkedForEmployee.indexOf("Employment ID") >=
                                0 ? (
                                  <TableCell align="left">
                                    {row.emp_id.row_id}
                                  </TableCell>
                                ) : null}
                                {checkedForEmployee.indexOf(
                                  "Employment Country"
                                ) >= 0 ? (
                                  <TableCell align="left">
                                    {row.emp_id.nationality}
                                  </TableCell>
                                ) : null}
                                {checkedForEmployee.indexOf("Annual Salary") >=
                                0 ? (
                                  <TableCell align="left">
                                    {row.emp_id.annual_gross_salary}
                                  </TableCell>
                                ) : null}

                                {checkedForEmployee.indexOf("Role") >= 0 ? (
                                  <TableCell align="left">
                                    {row.emp_id.role_id.name}
                                  </TableCell>
                                ) : null}
                                {checkedForEmployee.indexOf(
                                  "Employee Status"
                                ) >= 0 ? (
                                  <TableCell align="left">
                                    {row.employee_status === "active" ? (
                                      <Alert icon={false} severity="success">
                                        Active
                                      </Alert>
                                    ) : (
                                      <Alert icon={false} severity="error">
                                        Inactive
                                      </Alert>
                                    )}
                                  </TableCell>
                                ) : null}
                              </TableRow>
                            ))}
                          </TableBody>
                        ) : !loading ? (
                          <TableBody>
                            <TableRow>
                              <TableCell colSpan={10}>
                                <Zoom in={!allEmployeeArray.length}>
                                  <Grid className="data_table_inner_notfound">
                                    <DataNotFound />
                                    <Typography>No Data Found!</Typography>
                                    <Typography variant="body2">
                                      The Employee You Are Searching For Does
                                      Not Exists!
                                    </Typography>
                                  </Grid>
                                </Zoom>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        ) : (
                          <TableBody>
                            <TableRow>
                              <TableCell colSpan={10}>
                                <Grid className="data_table_inner_notfound">
                                  <CircularProgress />
                                </Grid>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        )}
                      </Table>
                    </div>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      component="div"
                      count={totalEmployee}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={onEmployeePageChange}
                      onRowsPerPageChange={(event) => {
                        setPage(0);
                        setRowsPerPage(+event.target.value);
                      }}
                    />
                  </div>
                </TabPanel>
                <TabPanel value={value} index={1} className="tab-pannel">
                  <div
                    className={`data_table ${
                      !allContractorArray.length && "no_data_found"
                    }`}
                  >
                    <Grid container className="data_table_filters_grid">
                      <TextField
                        spellCheck="false"
                        placeholder="Search"
                        value={searchcontractor}
                        className="outer_searchfilled"
                        sx={{}}
                        onChange={(e) => setSearchcontractor(e.target.value)}
                        InputProps={{
                          autoComplete: "off",
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                      <div className="table_edit_controller">
                        <Grid container className="filter_group">
                          <IconButton
                            onClick={(e) => setFilterDialog(!filterDialog)}
                          >
                            <FilterIcon />
                          </IconButton>
                          <Typography>Filter</Typography>
                        </Grid>{" "}
                        {filterDialog ? (
                          <Zoom in={filterDialog}>
                            <div className="edit_filter_menu is_small">
                              <Button
                                className="edit_filter_menu_close"
                                onClick={() => setFilterDialog(!filterDialog)}
                                disableRipple
                              ></Button>
                              <div className="edit_filter_menu_bx">
                                <List
                                  subheader={
                                    <ListSubheader>Filter Data</ListSubheader>
                                  }
                                >
                                  {filterStatusForContractor.map(
                                    (data, index) => (
                                      <ListItem key={index}>
                                        <Switch
                                          edge="end"
                                          onChange={() =>
                                            handleToggleForFilterStatusForContractor(
                                              data
                                            )
                                          }
                                          checked={
                                            checkedForFilterStatusForContractor.indexOf(
                                              data
                                            ) !== -1
                                          }
                                          className={
                                            checkedForFilterStatusForContractor.indexOf(
                                              data
                                            ) !== -1
                                              ? "active"
                                              : "inactive"
                                          }
                                          inputProps={{
                                            "aria-labelledby": data,
                                          }}
                                        />
                                        <ListItemText
                                          id={data}
                                          primary={data}
                                          disableTypography
                                        />
                                      </ListItem>
                                    )
                                  )}
                                </List>
                              </div>
                            </div>
                          </Zoom>
                        ) : null}
                      </div>
                      <div className="table_edit_controller">
                        <IconButton onClick={editDialogToggle}>
                          <TeamEditIcon />
                        </IconButton>
                        {editDialog ? (
                          <Zoom in={editDialog}>
                            <div className="edit_filter_menu">
                              <Button
                                className="edit_filter_menu_close"
                                onClick={editDialogToggle}
                                disableRipple
                              ></Button>
                              <div className="edit_filter_menu_bx">
                                <TextField
                                  placeholder="Search Columns"
                                  fullWidth
                                  value={filterColumn}
                                  onChange={(e) =>
                                    setfilterColumn(e.target.value)
                                  }
                                  InputProps={{
                                    autoComplete: "off",
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <SearchIcon />
                                      </InputAdornment>
                                    ),
                                  }}
                                />{" "}
                                <List
                                  subheader={
                                    <ListSubheader>
                                      Original Columns
                                    </ListSubheader>
                                  }
                                >
                                  {" "}
                                  <TransitionGroup>
                                    {rowNameForContractor
                                      .filter((data) => {
                                        return filterColumn.toLowerCase() === ""
                                          ? data
                                          : data
                                              .toLowerCase()
                                              .includes(
                                                filterColumn.toLowerCase()
                                              );
                                      })
                                      .map((data, index) => (
                                        <Collapse key={data}>
                                          <ListItem key={index}>
                                            <Switch
                                              edge="end"
                                              onChange={() =>
                                                handleToggleForContractor(data)
                                              }
                                              checked={
                                                checkedForContractor.indexOf(
                                                  data
                                                ) !== -1
                                              }
                                              className={
                                                checkedForContractor.indexOf(
                                                  data
                                                ) !== -1
                                                  ? "active"
                                                  : "inactive"
                                              }
                                              inputProps={{
                                                "aria-labelledby": data,
                                              }}
                                            />
                                            <ListItemText
                                              id={data}
                                              primary={data}
                                              disableTypography
                                            />
                                          </ListItem>
                                        </Collapse>
                                      ))}
                                  </TransitionGroup>
                                </List>
                              </div>
                            </div>
                          </Zoom>
                        ) : null}
                      </div>
                    </Grid>
                    <div className="table_container">
                      <Table stickyHeader aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            {checkedForContractor.indexOf("Name") >= 0 ? (
                              <TableCell component={"td"} align="left">
                                Name
                              </TableCell>
                            ) : null}
                            {checkedForContractor.indexOf("Employment ID") >=
                            0 ? (
                              <TableCell component={"td"} align="left">
                                CONTRACTOR ID
                              </TableCell>
                            ) : null}
                            {checkedForContractor.indexOf(
                              "Employment Country"
                            ) >= 0 ? (
                              <TableCell component={"td"} align="left">
                                CONTRACTOR COUNTRY
                              </TableCell>
                            ) : null}
                            {checkedForContractor.indexOf("Annual Salary") >=
                            0 ? (
                              <TableCell component={"td"} align="left">
                                MONTHLY FEES
                              </TableCell>
                            ) : null}

                            {checkedForContractor.indexOf("Role") >= 0 ? (
                              <TableCell component={"td"} align="left">
                                ROLE
                              </TableCell>
                            ) : null}
                            {checkedForContractor.indexOf(
                              "Contractor Status"
                            ) >= 0 ? (
                              <TableCell component={"td"} align="left">
                                Contractor STATUS
                              </TableCell>
                            ) : null}
                          </TableRow>
                        </TableHead>
                        {allContractorArray.length && !loading ? (
                          <TableBody>
                            {allContractorArray.map((row, index) => (
                              <TableRow
                                key={row.contractor_id.user_id}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                                onClick={() =>
                                  TeamContractorDetails(
                                    row.contractor_id.row_id,
                                    row.row_id,
                                    row.contractor_status
                                  )
                                }
                              >
                                {checkedForContractor.indexOf("Name") >= 0 ? (
                                  <TableCell scope="row" align="left">
                                    <Grid
                                      container
                                      className="avatar_container"
                                      spacing={0}
                                      p={0}
                                    >
                                      <Avatar className="team_avatar">
                                        {row.avtarLogo}
                                      </Avatar>
                                      <span>{row.contractor_id.full_name}</span>
                                    </Grid>
                                  </TableCell>
                                ) : null}
                                {checkedForContractor.indexOf(
                                  "Employment ID"
                                ) >= 0 ? (
                                  <TableCell align="left">
                                    {row.contractor_id.row_id}
                                  </TableCell>
                                ) : null}
                                {checkedForContractor.indexOf(
                                  "Employment Country"
                                ) >= 0 ? (
                                  <TableCell align="left">
                                    {row.contractor_id.nationality}
                                  </TableCell>
                                ) : null}
                                {checkedForContractor.indexOf(
                                  "Annual Salary"
                                ) >= 0 ? (
                                  <TableCell align="left">
                                    {row.contractor_id.monthly_contractor_fees
                                      ? parseInt(
                                          row.contractor_id
                                            .monthly_contractor_fees
                                        )
                                      : "N/A"}
                                  </TableCell>
                                ) : null}

                                {checkedForContractor.indexOf("Role") >= 0 ? (
                                  <TableCell align="left">
                                    {row.contractor_id.role_id.name}
                                  </TableCell>
                                ) : null}
                                {checkedForContractor.indexOf(
                                  "Contractor Status"
                                ) >= 0 ? (
                                  <TableCell align="left">
                                    {row.contractor_status === "active" ? (
                                      <Alert icon={false} severity="success">
                                        Active
                                      </Alert>
                                    ) : (
                                      <Alert icon={false} severity="error">
                                        Inactive
                                      </Alert>
                                    )}
                                  </TableCell>
                                ) : null}
                              </TableRow>
                            ))}
                          </TableBody>
                        ) : !loading ? (
                          <TableBody>
                            <TableRow>
                              <TableCell colSpan={10}>
                                <Zoom in={!allContractorArray.length}>
                                  <Grid className="data_table_inner_notfound">
                                    <DataNotFound />
                                    <Typography>No Data Found!</Typography>
                                    <Typography variant="body2">
                                      The Contractor You Are Searching For Does
                                      Not Exists!
                                    </Typography>
                                  </Grid>
                                </Zoom>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        ) : (
                          <TableBody>
                            <TableRow>
                              <TableCell colSpan={10}>
                                <Grid className="data_table_inner_notfound">
                                  <CircularProgress />
                                </Grid>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        )}
                      </Table>
                    </div>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      component="div"
                      count={totalContractor}
                      rowsPerPage={rowsPerPageForContractor}
                      page={pageForContractor}
                      onPageChange={onContractorPageChange}
                      onRowsPerPageChange={(event) => {
                        setPageForContractor(0);
                        setRowsPerPageForContractor(+event.target.value);
                      }}
                    />
                  </div>
                </TabPanel>
              </div>
            </>
          )}
        </Grid>
      </Slide>
      <HelpButton />
      <EmployeeAndContractorAddModal open={open} close={() => setOpen(false)} />
    </>
  );
};
