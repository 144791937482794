import React, { useEffect, useState } from "react";
import { Grid } from "../SideBar/SideBarImports";
import { Backdrop } from "@mui/material";

export const Loader = (props) => {
  useEffect(() => {
    if (props.open) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  }, [props.open]);

  return (
    <Backdrop
      sx={{ color: "#ffff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={props.open}
      className="loader_backdrop"
    >
      <Grid xs={12} className="pageLoading">
        <div className="lds-grid">
          <div></div> <div></div> <div></div> <div></div>
          <div></div> <div></div> <div></div> <div></div>
          <div></div> <div></div> <div></div> <div></div>
          <div></div> <div></div> <div></div> <div></div>
          <div></div> <div></div> <div></div> <div></div>
        </div>
      </Grid>
    </Backdrop>
  );
};
