import {
  Button,
  FormControlLabel,
  FormLabel,
  IconButton,
  Modal,
  Radio,
  RadioGroup,
  Slide,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import useNotification from "Components/CommonComponents/AlertNotifications/AlertNotifications";
import { CloseIcon } from "Icons/Icons";
import {
  getPricingPlan,
  putPricingForContractor,
  putPricingForEmployee,
} from "Services/AddEmployeeAndContractor";
import { Grid } from "Utils/muiImport";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";

export const AddContractorPricingUpdateModal = (props) => {
  const params = useParams();

  const [msg, sendNotification] = useNotification();

  const [billingCycle, setBillingCycle] = useState("");
  const [monthlyPricing, setMonthlyPricing] = useState(0);
  const [yearlyPricing, setYearlyPricing] = useState(0);
  const [currencyType, setCurrencyType] = useState("");

  useEffect(() => {
    pricingPlan();
    if (props.billingCycle !== "") {
      setBillingCycle(props.billingCycle);
    } else {
      setBillingCycle("billed-annually");
    }
  }, [props.open, props.close]);

  const pricingPlan = async () => {
    await getPricingPlan().then(
      (res) => {
        setMonthlyPricing(res.data.monthly_contractor_pricing);
        setYearlyPricing(res.data.yearly_contractor_pricing);
        setCurrencyType(res.data.currency);
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const updatePlan = (e) => {
    e.preventDefault();
    const id = params.id;
    if (!id) {
      var contractor_id = localStorage.getItem("ContractorRowData");
      var secondary_contractor_id = localStorage.getItem(
        "contraidForDetailView"
      );
      var bill = billingCycle.toLowerCase();
      let price = 0;
      if (billingCycle === "billed-annually") {
        price = yearlyPricing;
      } else {
        price = monthlyPricing;
      }
      const companyID = localStorage.getItem("CompanyRowID");
      const pricing_plan = {
        company_id: companyID,
        contractor_id:
          contractor_id === null ? secondary_contractor_id : contractor_id,
        type: bill,
        price: price,
        currency: currencyType,
      };
      putPricingForContractor(
        pricing_plan,
        contractor_id === null ? secondary_contractor_id : contractor_id
      ).then(
        (res) => {
          localStorage.setItem("ContractorData4", JSON.stringify(pricing_plan));
          sendNotification({
            msg: res.data.message,
            variant: "success",
          });

          props.summuryAPI();
          props.close();
        },
        (err) => {
          sendNotification({
            msg: err.response.data.message,
            variant: "error",
          });
        }
      );
    } else {
      var bill = billingCycle.toLowerCase();
      let price = 0;
      if (billingCycle === "billed-annually") {
        price = yearlyPricing;
      } else {
        price = monthlyPricing;
      }
      const companyID = localStorage.getItem("CompanyRowID");
      const pricing_plan = {
        company_id: companyID,
        contractor_id: id,
        type: bill,
        price: price,
        currency: currencyType,
      };

      putPricingForContractor(pricing_plan, id).then(
        (res) => {
          sendNotification({
            msg: res.data.message,
            variant: "success",
          });

          props.summuryAPI();
          props.close();
        },
        (err) => {
          sendNotification({
            msg: err.response.data.message,
            variant: "error",
          });
        }
      );
    }
  };

  return (
    <>
      <Modal
        onBackdropClick={props.close}
        open={props.modalOpen}
        onClose={props.close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="add_hire_modal new_request_modal"
      >
        <Slide in={props.modalOpen}>
          <Box className="add_hire_modal_bx">
            <div className="modal_header">
              <Grid container>
                <Grid item>
                  <Typography id="modal-modal-title" variant="h6">
                    Update Contractor Pricing Plan.
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton onClick={props.close} className="icon_close_bttn">
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </div>
            <form onSubmit={(e) => updatePlan(e)}>
              <div className="modal_body">
                <div className="employe_profile_body">
                  <div className="register_form">
                    <Grid xs={12}>
                      <FormLabel id="demo-radio-buttons-group-label">
                        Choose your billing cycle
                      </FormLabel>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        value={billingCycle}
                        name="radio-buttons-group"
                        onChange={(e) => setBillingCycle(e.target.value)}
                      >
                        <Grid container>
                          <FormControlLabel
                            value="billed-annually"
                            control={<Radio />}
                            label="Billed annually"
                          />

                          <FormControlLabel
                            value="billed-monthly"
                            control={<Radio />}
                            label="Billed monthly"
                          />
                        </Grid>
                      </RadioGroup>
                    </Grid>

                    <Grid
                      xs={12}
                      container
                      alignItems="center"
                      flexDirection={{ xs: "row", sm: "row" }}
                      className="employe_personal_dtl"
                    >
                      <Typography variant="body1">
                        Pricing per contractor
                      </Typography>
                      <Typography variant="body2">
                        $
                        {billingCycle === "billed-annually"
                          ? yearlyPricing
                          : monthlyPricing}
                        {" " + props.currencyType === " "
                          ? currencyType
                          : props.currencyType}
                      </Typography>
                    </Grid>

                    <Grid
                      xs={12}
                      container
                      alignItems="center"
                      flexDirection={{ xs: "row", sm: "row" }}
                      className="employe_personal_dtl"
                    >
                      <Typography variant="body1">Billing cycle</Typography>
                      <Typography variant="body2">{billingCycle}</Typography>
                    </Grid>

                    <Grid
                      xs={12}
                      container
                      alignItems="center"
                      flexDirection={{ xs: "row", sm: "row" }}
                      className="employe_personal_dtl"
                    >
                      <Typography variant="body1">
                        Total annual price
                      </Typography>
                      <Typography variant="body2">
                        $
                        {billingCycle === "billed-annually"
                          ? yearlyPricing
                          : monthlyPricing * 12}
                        {" " + props.currencyType === " "
                          ? currencyType
                          : props.currencyType}
                      </Typography>
                    </Grid>
                  </div>
                </div>
              </div>
              <div className="modal_footer">
                <Grid container className="modal_foot_bttn_container">
                  <Button className="save_bttn" type="submit">
                    Update Details
                  </Button>
                  <Button className="cancel_bttn" onClick={props.close}>
                    Cancel
                  </Button>
                </Grid>
              </div>
            </form>
          </Box>
        </Slide>
      </Modal>
    </>
  );
};
