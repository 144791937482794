export function Error(err) {
  function isObject(object) {
    return (
      typeof object === "object" && object !== null && !Array.isArray(object)
    );
  }

  if (!isObject(err)) {
    return err;
  } else {
    return Object.keys(err).map((data) => data);
  }
}
