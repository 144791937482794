import "./EmpRegister3.css";
import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  Icon,
  IconButton,
  MenuItem,
  Slide,
  TextField,
  Typography,
} from "@mui/material";

import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import {
  addContractorAddress,
  getStates,
  submitAddress,
} from "Services/AddEmployeeAndContractor";
import { InfoIcon } from "Components/AddContractor/AddContractor1/AddContractorImports";
import { PreviousArrowIcon } from "Icons/Icons";
import useNotification from "Components/CommonComponents/AlertNotifications/AlertNotifications";
import { useSnackbar } from "notistack";
import { Error } from "Utils/Success&Errorfunctions";

export const EmpRegister3 = () => {
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();
  const [msg, sendNotification] = useNotification();

  const [stateArray, setStateArray] = useState([]);
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");

  const goBack = () => {
    navigate("/employeeregister");
  };

  useEffect(() => getstate(), []);

  const getstate = () => {
    getStates().then(
      (res) => {
        setStateArray(res.data);
      },
      (err) => {
        console.log(err.message);
      }
    );
  };

  const submitAddressData = (e) => {
    e.preventDefault();
    var addressData = {
      address1: address1.trim(),
      address2: address2.trim(),
      city: city.trim(),
      state_id: state,
    };
    if (address1.trim() != "" && city.trim() != "" && address2.trim() != "") {
      if (localStorage.getItem("account_type") !== "contractor") {
        submitAddress(addressData).then(
          (res) => {
            var variant = "success";
            enqueueSnackbar(res.data.message, { variant });
            navigate("/employeeregister");
          },
          (err) => {
            var errors = Error(err.response.data.message);

            if (Array.isArray(errors)) {
              errors.forEach((element) => {
                sendNotification({
                  msg: `Please enter valid ${element}`,
                  variant: "error",
                });
              });
            } else {
              sendNotification({
                msg: err.response.data.message,
                variant: "error",
              });
            }
          }
        );
      } else {
        addContractorAddress(addressData).then(
          (res) => {
            var variant = "success";
            enqueueSnackbar(res.data.message, { variant });
            navigate("/employeeregister");
          },
          (err) => {
            var errors = Error(err.response.data.message);

            if (Array.isArray(errors)) {
              errors.forEach((element) => {
                sendNotification({
                  msg: `Please enter valid ${element}`,
                  variant: "error",
                });
              });
            } else {
              sendNotification({
                msg: err.response.data.message,
                variant: "error",
              });
            }
          }
        );
      }
    } else {
      if (address1.trim() == "") {
        sendNotification({
          msg: "Please add valid address line 1",
          variant: "error",
        });
      } else if (address2.trim() == "") {
        sendNotification({
          msg: "Please add valid address line 2",
          variant: "error",
        });
      } else if (city.trim() == "") {
        sendNotification({
          msg: "Please add valid city name",
          variant: "error",
        });
      }
    }
  };

  return (
    <>
      <Slide direction="left" in={true}>
        <Grid xs={8} item className="login_content_grid_item">
          <Grid
            container
            alignItems="center"
            flexDirection={{ xs: "row", sm: "row" }}
            className="auth_top_header"
          >
            <Grid item>
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="label"
                onClick={goBack}
              >
                <PreviousArrowIcon />
              </IconButton>
              Back
            </Grid>
            {/* <Grid item className="auth_top_header_progress">
      <IconButton>
        <HighlightOffRoundedIcon  />
      </IconButton>
    </Grid> */}
          </Grid>
          <div className="login_cont register_cont">
            <Grid xs={12}>
              <Typography variant="h3">Home Address</Typography>
            </Grid>
            <Grid xs={12}>
              <Typography variant="body2">
                Please provide your full home address
              </Typography>
            </Grid>
            <Grid xs={12}>
              <Box className="warning_info_box">
                <Grid container>
                  <Grid item className="warning_box_icon">
                    <Icon>
                      <InfoIcon />
                    </Icon>
                  </Grid>
                  <Grid item className="warning_box_cont">
                    <Typography variant="body1">
                      Your country of residence is expected to be India. If
                      you’re planning to relocate, please reach out to
                      <a href="mailto:info@inrole.io"> info@inrole.io</a>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <form onSubmit={submitAddressData} className="register_form">
              <Grid xs={12}>
                <TextField
                  fullWidth
                  id="Address"
                  label="Address"
                  variant="standard"
                  helperText="The number that appears on your government-issued identification."
                  value={address1}
                  onChange={(e) => setAddress1(e.target.value)}
                  required
                  inputProps={{ autocomplete: "off", maxLength: 128 }}
                />
              </Grid>
              <Grid xs={12}>
                <TextField
                  required
                  fullWidth
                  id="Address2"
                  label="Address line 2"
                  variant="standard"
                  helperText="The number that appears on your government-issued identification."
                  value={address2}
                  onChange={(e) => setAddress2(e.target.value)}
                  inputProps={{ autocomplete: "off", maxLength: 128 }}
                />
              </Grid>
              <Grid xs={12}>
                <TextField
                  required
                  id="City"
                  label="City"
                  variant="standard"
                  fullWidth
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  inputProps={{ autocomplete: "off", maxLength: 128 }}
                />
              </Grid>
              <Grid xs={12}>
                <TextField
                  required
                  fullWidth
                  id="Select-State"
                  select
                  label="Select State"
                  helperText="Please select your State"
                  variant="standard"
                  value={state}
                  onChange={(e) => {
                    setState(e.target.value);
                    // console.log(e.target.value);
                  }}
                >
                  {stateArray.map((option) =>
                    option.state_code.includes("IN") ? (
                      <MenuItem key={option.row_id} value={option.row_id}>
                        {option.name}
                      </MenuItem>
                    ) : null
                  )}
                </TextField>
              </Grid>
              <Grid className="login_bttn_wrapper">
                <Button type="submit" className="theme_bttn fill">
                  Save & Continue
                </Button>
              </Grid>
            </form>
          </div>
        </Grid>
      </Slide>
    </>
  );
};
