import "./Requests.css";
import { React, useEffect, useState } from "react";
import { PropTypes } from "Components/Dashboard/DashboardImports";
import { Button, Grid, Typography } from "Utils/muiImport";
import AssignmentLateOutlinedIcon from "@mui/icons-material/AssignmentLateOutlined";

import { Box } from "@mui/system";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  IconButton,
  Modal,
  TextField,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  TableContainer,
  List,
  ListSubheader,
  ListItem,
  Switch,
  ListItemText,
  Slide,
  Zoom,
  Collapse,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import { TransitionGroup } from "react-transition-group";

import REQUEST_LIST from "../../Assets/Svgs/REQUEST LIST.svg";
import { RequestModal } from "Components/CommonComponents/Modals/Request Modal/RequestModal";
import { HelpButton } from "Components/CommonComponents/Bottom_Help_Button/HelpButton";
import { getAllEmployee } from "Services/AddEmployeeAndContractor";
import { FilterIcon, RequestNotFoundIcon, TeamEditIcon } from "Icons/Icons";
import { getRequestList } from "Services/Requestservice";
import moment from "moment";
import { TabPanel, a11yProps } from "Utils/TabPanel";

export const Requests = (props) => {
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [allRequests, setAllRequests] = useState([]);
  const [page, setPage] = useState(0);
  const [totalRequests, setTotalRequests] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [employeeRequestSearch, setEmployeeRequestSearch] = useState("");
  const [yourRequestSearch, setYourRequestSearch] = useState("");
  const [editDialog, setEditDialog] = useState(false);
  const [filterDialog, setFilterDialog] = useState(false);

  const [filterColumnForRequest, setFilterColumnForRequest] = useState("");
  const [rowNameForRequest, setRowNameForRequest] = useState([
    "Request For",
    "Status",
    "Requested By",
    "Request Date",
  ]);
  const [checkedForRequests, setCheckedForRequests] = useState([
    "Request For",
    "Status",
    "Requested By",
    "Request Date",
  ]);

  const [filterColumnForEmployeeRequest, setFilterColumnForEmployeeRequest] =
    useState("");
  const [rowNameForEmployeeRequest, setRowNameForEmployeeRequest] = useState([
    "Request For",
    "Status",
    "Requested By",
    "Request Date",
  ]);
  const [checkedForEmployeeRequests, setCheckedForEmployeeRequests] = useState([
    "Request For",
    "Status",
    "Requested By",
    "Request Date",
  ]);

  const [filterStatus, setFilterStatus] = useState([
    "All",
    "Approved",
    "Pending",
  ]);
  const [checkedForFilterStatus, setCheckedForFilterStatus] = useState(["All"]);

  const editDialogToggle = () => {
    setEditDialog(!editDialog);
  };

  function handleToggleForRequests(value) {
    const currentIndex = checkedForRequests.indexOf(value);
    const newChecked = [...checkedForRequests];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheckedForRequests(newChecked);
  }

  function handleToggleForEmployeeRequests(value) {
    const currentIndex = checkedForEmployeeRequests.indexOf(value);
    const newChecked = [...checkedForEmployeeRequests];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheckedForEmployeeRequests(newChecked);
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const getData = setTimeout(() => {
      getRequests();
    }, 200);
    return () => clearTimeout(getData);
  }, [
    rowsPerPage,
    yourRequestSearch,
    employeeRequestSearch,
    checkedForFilterStatus,
  ]);

  const showLoading = () => {
    setLoading(true);
  };
  const hideLoading = () => {
    setLoading(false);
  };

  const getRequests = (pageNo = 1) => {
    showLoading();
    getRequestList(
      pageNo,
      rowsPerPage,
      yourRequestSearch ? yourRequestSearch : employeeRequestSearch,
      checkedForFilterStatus[0].toLowerCase()
    ).then(
      (res) => {
        setAllRequests(res.data.data);
        setTotalRequests(res.data.total);
        setTimeout(() => {
          hideLoading();
        }, 500);
      },
      (err) => {
        console.log(err.message);
        props.unAuthorized(err.response.status);
        setAllRequests([]);
        setTotalRequests(0);
        setTimeout(() => {
          hideLoading();
        }, 500);
      }
    );
  };

  const onRequestPageChange = (event, newpage) => {
    setPage(newpage);
    getRequests(newpage + 1);
  };

  function handleToggleForFilterStatus(value) {
    var currentIndex = checkedForFilterStatus.indexOf(value);
    var newChecked = [...checkedForFilterStatus];
    if (currentIndex === -1) {
      newChecked.pop();
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    if (newChecked.length <= 0) {
      setCheckedForFilterStatus(["All"]);
    } else {
      setCheckedForFilterStatus(newChecked);
    }
  }

  return (
    <>
      <Slide in={true} direction="left">
        <Grid item className={props.className}>
          <Grid container className="team_top_heading">
            <Grid item>
              <Typography variant="h3" className="page_heading">
                Requests
              </Typography>
            </Grid>
            <Grid item>
              <Button
                onClick={() => {
                  setModalOpen(true);
                }}
                variant="contained"
                startIcon={<AssignmentLateOutlinedIcon />}
                className="theme_bttn fill"
              >
                Add new request
              </Button>
            </Grid>
            <Grid xs={12} item>
              <Typography variant="body2" className="page_head_para">
                Change requirement agreement or request documents in a few easy
                steps.
              </Typography>
            </Grid>
          </Grid>

          <div className="dash_tabs">
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs "
            >
              <Tab
                label={<Typography>Your requests</Typography>}
                {...a11yProps(0)}
              />

              <Tab
                label={<Typography>Employee requests</Typography>}
                {...a11yProps(1)}
              />
            </Tabs>
            <TabPanel value={value} index={0} className="tab-pannel">
              <div
                className={`data_table ${
                  !allRequests.length && "no_data_found"
                } `}
              >
                <Grid container className="data_table_filters_grid">
                  <TextField
                    spellCheck="false"
                    value={yourRequestSearch}
                    onChange={(e) => setYourRequestSearch(e.target.value)}
                    className="outer_searchfilled"
                    placeholder="Search"
                    id="outlined-start-adornment"
                    InputProps={{
                      autoComplete: "off",
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />

                  <div className="table_edit_controller">
                    <Grid container className="filter_group">
                      <IconButton
                        onClick={(e) => setFilterDialog(!filterDialog)}
                      >
                        <FilterIcon />
                      </IconButton>
                      <Typography>Filter</Typography>
                    </Grid>{" "}
                    {filterDialog ? (
                      <Zoom in={filterDialog}>
                        <div className="edit_filter_menu is_small">
                          <Button
                            className="edit_filter_menu_close"
                            onClick={() => setFilterDialog(!filterDialog)}
                            disableRipple
                          ></Button>
                          <div className="edit_filter_menu_bx">
                            <List
                              subheader={
                                <ListSubheader>Filter Data</ListSubheader>
                              }
                            >
                              {filterStatus.map((data, index) => (
                                <ListItem key={index}>
                                  <Switch
                                    edge="end"
                                    onChange={() =>
                                      handleToggleForFilterStatus(data)
                                    }
                                    checked={
                                      checkedForFilterStatus.indexOf(data) !==
                                      -1
                                    }
                                    className={
                                      checkedForFilterStatus.indexOf(data) !==
                                      -1
                                        ? "active"
                                        : "inactive"
                                    }
                                    inputProps={{
                                      "aria-labelledby": data,
                                    }}
                                  />
                                  <ListItemText
                                    id={data}
                                    primary={data}
                                    disableTypography
                                  />
                                </ListItem>
                              ))}
                            </List>
                          </div>
                        </div>
                      </Zoom>
                    ) : null}
                  </div>

                  <div className="table_edit_controller">
                    <IconButton onClick={editDialogToggle}>
                      <TeamEditIcon />
                    </IconButton>
                    {editDialog ? (
                      <Zoom in={editDialog}>
                        <div className="edit_filter_menu">
                          <Button
                            className="edit_filter_menu_close"
                            onClick={editDialogToggle}
                            disableRipple
                          ></Button>
                          <div className="edit_filter_menu_bx">
                            <TextField
                              placeholder="Search Columns"
                              fullWidth
                              value={filterColumnForRequest}
                              onChange={(e) =>
                                setFilterColumnForRequest(e.target.value)
                              }
                              InputProps={{
                                autoComplete: "off",
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <SearchIcon />
                                  </InputAdornment>
                                ),
                              }}
                            />{" "}
                            <List
                              subheader={
                                <ListSubheader>Original Columns</ListSubheader>
                              }
                            >
                              {" "}
                              <TransitionGroup>
                                {rowNameForRequest
                                  .filter((data) => {
                                    return filterColumnForRequest.toLowerCase() ===
                                      ""
                                      ? data
                                      : data
                                          .toLowerCase()
                                          .includes(
                                            filterColumnForRequest.toLowerCase()
                                          );
                                  })
                                  .map((data, index) => (
                                    <Collapse key={data}>
                                      <ListItem key={index}>
                                        <Switch
                                          edge="end"
                                          onChange={() =>
                                            handleToggleForRequests(data)
                                          }
                                          checked={
                                            checkedForRequests.indexOf(data) !==
                                            -1
                                          }
                                          className={
                                            checkedForRequests.indexOf(data) !==
                                            -1
                                              ? "active"
                                              : "inactive"
                                          }
                                          inputProps={{
                                            "aria-labelledby": data,
                                          }}
                                        />
                                        <ListItemText
                                          id={data}
                                          primary={data}
                                          disableTypography
                                        />
                                      </ListItem>
                                    </Collapse>
                                  ))}
                              </TransitionGroup>
                            </List>
                          </div>
                        </div>
                      </Zoom>
                    ) : null}
                  </div>
                </Grid>
                <div className="table_container">
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        {checkedForRequests.indexOf("Request For") >= 0 ? (
                          <TableCell component={"td"} align="left">
                            REQUEST FOR
                          </TableCell>
                        ) : null}
                        {checkedForRequests.indexOf("Status") >= 0 ? (
                          <TableCell component={"td"} align="left">
                            STATUS
                          </TableCell>
                        ) : null}
                        {checkedForRequests.indexOf("Requested By") >= 0 ? (
                          <TableCell component={"td"} align="left">
                            REQUESTED BY
                          </TableCell>
                        ) : null}
                        {checkedForRequests.indexOf("Request Date") >= 0 ? (
                          <TableCell component={"td"} align="left">
                            REQUEST DATE
                          </TableCell>
                        ) : null}
                      </TableRow>
                    </TableHead>
                    {allRequests.length && !loading ? (
                      <TableBody>
                        {allRequests.map((data) => (
                          <TableRow id={data.emp_id} key={data.row_id}>
                            {checkedForRequests.indexOf("Request For") >= 0 ? (
                              <TableCell> {data.emp_id.full_name}</TableCell>
                            ) : null}
                            {checkedForRequests.indexOf("Status") >= 0 ? (
                              <TableCell>{data.status}</TableCell>
                            ) : null}
                            {checkedForRequests.indexOf("Requested By") >= 0 ? (
                              <TableCell>{data.company_id.full_name}</TableCell>
                            ) : null}
                            {/* <TableCell>AMOUNT TAX</TableCell> 
                            <TableCell>{data.frequency}</TableCell>*/}
                            {checkedForRequests.indexOf("Request Date") >= 0 ? (
                              <TableCell>
                                {moment(data.created_date).format("ll")}
                              </TableCell>
                            ) : null}
                          </TableRow>
                        ))}
                      </TableBody>
                    ) : !loading ? (
                      <TableBody>
                        <TableRow>
                          <TableCell colSpan={6}>
                            <Zoom in={!allRequests.length}>
                              <Grid className="data_table_inner_notfound">
                                <RequestNotFoundIcon />
                                <Typography>
                                  Nothing needs attention right now
                                </Typography>
                                <Typography variant="body2">
                                  When your request changes or documents, they
                                  appear here
                                </Typography>
                              </Grid>
                            </Zoom>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ) : (
                      <TableBody>
                        <TableRow>
                          <TableCell colSpan={10}>
                            <Grid className="data_table_inner_notfound">
                              <CircularProgress />
                            </Grid>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </div>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={totalRequests}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={onRequestPageChange}
                  onRowsPerPageChange={(event) => {
                    setPage(0);
                    setRowsPerPage(+event.target.value);
                  }}
                />
              </div>
            </TabPanel>
            <TabPanel value={value} index={1} className="tab-pannel">
              <div
                className={`data_table ${
                  !allRequests.length && "no_data_found"
                }`}
              >
                <Grid container className="data_table_filters_grid">
                  <TextField
                    spellCheck="false"
                    value={employeeRequestSearch}
                    onChange={(e) => setEmployeeRequestSearch(e.target.value)}
                    className="outer_searchfilled"
                    placeholder="Search"
                    id="outlined-start-adornment"
                    InputProps={{
                      autoComplete: "off",
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <div className="table_edit_controller">
                    <Grid container className="filter_group">
                      <IconButton
                        onClick={(e) => setFilterDialog(!filterDialog)}
                      >
                        <FilterIcon />
                      </IconButton>
                      <Typography>Filter</Typography>
                    </Grid>{" "}
                    {filterDialog ? (
                      <Zoom in={filterDialog}>
                        <div className="edit_filter_menu is_small">
                          <Button
                            className="edit_filter_menu_close"
                            onClick={() => setFilterDialog(!filterDialog)}
                            disableRipple
                          ></Button>
                          <div className="edit_filter_menu_bx">
                            <List
                              subheader={
                                <ListSubheader>Filter Data</ListSubheader>
                              }
                            >
                              {filterStatus.map((data, index) => (
                                <ListItem key={index}>
                                  <Switch
                                    edge="end"
                                    onChange={() =>
                                      handleToggleForFilterStatus(data)
                                    }
                                    checked={
                                      checkedForFilterStatus.indexOf(data) !==
                                      -1
                                    }
                                    className={
                                      checkedForFilterStatus.indexOf(data) !==
                                      -1
                                        ? "active"
                                        : "inactive"
                                    }
                                    inputProps={{
                                      "aria-labelledby": data,
                                    }}
                                  />
                                  <ListItemText
                                    id={data}
                                    primary={data}
                                    disableTypography
                                  />
                                </ListItem>
                              ))}
                            </List>
                          </div>
                        </div>
                      </Zoom>
                    ) : null}
                  </div>
                  <div className="table_edit_controller">
                    <IconButton onClick={editDialogToggle}>
                      <TeamEditIcon />
                    </IconButton>
                    {editDialog ? (
                      <Zoom in={editDialog}>
                        <div className="edit_filter_menu">
                          <Button
                            className="edit_filter_menu_close"
                            onClick={editDialogToggle}
                            disableRipple
                          ></Button>
                          <div className="edit_filter_menu_bx">
                            <TextField
                              placeholder="Search Columns"
                              fullWidth
                              value={filterColumnForEmployeeRequest}
                              onChange={(e) =>
                                setFilterColumnForEmployeeRequest(
                                  e.target.value
                                )
                              }
                              InputProps={{
                                autoComplete: "off",
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <SearchIcon />
                                  </InputAdornment>
                                ),
                              }}
                            />{" "}
                            <List
                              subheader={
                                <ListSubheader>Original Columns</ListSubheader>
                              }
                            >
                              <TransitionGroup>
                                {rowNameForRequest
                                  .filter((data) => {
                                    return filterColumnForEmployeeRequest.toLowerCase() ===
                                      ""
                                      ? data
                                      : data
                                          .toLowerCase()
                                          .includes(
                                            filterColumnForEmployeeRequest.toLowerCase()
                                          );
                                  })
                                  .map((data, index) => (
                                    <Collapse key={data}>
                                      <ListItem key={index}>
                                        <Switch
                                          edge="end"
                                          onChange={() =>
                                            handleToggleForEmployeeRequests(
                                              data
                                            )
                                          }
                                          checked={
                                            checkedForEmployeeRequests.indexOf(
                                              data
                                            ) !== -1
                                          }
                                          className={
                                            checkedForEmployeeRequests.indexOf(
                                              data
                                            ) !== -1
                                              ? "active"
                                              : "inactive"
                                          }
                                          inputProps={{
                                            "aria-labelledby": data,
                                          }}
                                        />
                                        <ListItemText
                                          id={data}
                                          primary={data}
                                          disableTypography
                                        />
                                      </ListItem>
                                    </Collapse>
                                  ))}
                              </TransitionGroup>
                            </List>
                          </div>
                        </div>
                      </Zoom>
                    ) : null}
                  </div>
                </Grid>
                <div className="table_container">
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        {checkedForEmployeeRequests.indexOf("Request For") >=
                        0 ? (
                          <TableCell component={"td"} align="left">
                            REQUEST FOR
                          </TableCell>
                        ) : null}
                        {checkedForEmployeeRequests.indexOf("Status") >= 0 ? (
                          <TableCell component={"td"} align="left">
                            STATUS
                          </TableCell>
                        ) : null}
                        {checkedForEmployeeRequests.indexOf("Requested By") >=
                        0 ? (
                          <TableCell component={"td"} align="left">
                            REQUESTED BY
                          </TableCell>
                        ) : null}
                        {checkedForEmployeeRequests.indexOf("Request Date") >=
                        0 ? (
                          <TableCell component={"td"} align="left">
                            REQUEST DATE
                          </TableCell>
                        ) : null}
                      </TableRow>
                    </TableHead>
                    {allRequests.length && !loading ? (
                      <TableBody>
                        {allRequests.map((data) => (
                          <TableRow id={data.emp_id} key={data.row_id}>
                            {checkedForRequests.indexOf("Request For") >= 0 ? (
                              <TableCell> {data.emp_id.full_name}</TableCell>
                            ) : null}
                            {checkedForRequests.indexOf("Status") >= 0 ? (
                              <TableCell>{data.status}</TableCell>
                            ) : null}
                            {checkedForRequests.indexOf("Requested By") >= 0 ? (
                              <TableCell>{data.company_id.full_name}</TableCell>
                            ) : null}
                            {/* <TableCell>AMOUNT TAX</TableCell> 
                            <TableCell>{data.frequency}</TableCell>*/}
                            {checkedForRequests.indexOf("Request Date") >= 0 ? (
                              <TableCell>
                                {moment(data.created_date).format("ll")}
                              </TableCell>
                            ) : null}
                          </TableRow>
                        ))}
                      </TableBody>
                    ) : !loading ? (
                      <TableBody>
                        <TableRow>
                          <TableCell colSpan={6}>
                            <Zoom in={!allRequests.length}>
                              <Grid className="data_table_inner_notfound">
                                <RequestNotFoundIcon />
                                <Typography>
                                  Nothing needs attention right now
                                </Typography>
                                <Typography variant="body2">
                                  When your request changes or documents, they
                                  appear here
                                </Typography>
                              </Grid>
                            </Zoom>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ) : (
                      <TableBody>
                        <TableRow>
                          <TableCell colSpan={10}>
                            <Grid className="data_table_inner_notfound">
                              <CircularProgress />
                            </Grid>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </div>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={totalRequests}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={onRequestPageChange}
                  onRowsPerPageChange={(event) => {
                    setPage(0);
                    setRowsPerPage(+event.target.value);
                  }}
                />
              </div>
            </TabPanel>
          </div>
        </Grid>
      </Slide>
      <RequestModal
        modalOpen={modalOpen}
        close={() => setModalOpen(false)}
        allRequests={() => getRequests()}
      />
      <HelpButton />
    </>
  );
};
