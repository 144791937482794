import "./TeamContractorDetails.css";
import { React, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Avatar,
  IconButton,
  Typography,
  Grid,
  Box,
  Tabs,
  Tab,
  PropTypes,
} from "./TeamcontractorDetailsImports";
import {
  activateContractor,
  deactivateContractor,
  getContractorAddress,
  getContractorByIdData,
  getContractorEmergencyContact,
  getPricingForContractor,
} from "Services/AddEmployeeAndContractor";
import { useParams } from "react-router-dom";
import {
  Alert,
  Backdrop,
  Button,
  ButtonGroup,
  CircularProgress,
  Icon,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Skeleton,
  Slide,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Zoom,
} from "@mui/material";
import { SearchIcon } from "../Teams/TeamImpoerts";
import {
  DataNotFound,
  PreviousArrowIcon,
  TeamEditIcon,
  TeamEmployeeDetailsBasics,
  TeamEmployeeDetailsContractSubscription,
  TeamemployeeDetailsEditAgreement,
  TeamEmployeeDetailsEmergencyContact,
  TeamEmployeeDetailsHome,
  TeamEmployeeDetailsInActive,
} from "Icons/Icons";
import PersonIcon from "@mui/icons-material/Person";
import moment from "moment";
import { HideLoading, showWaitingLoading } from "Utils/loader";
import useNotification from "Components/CommonComponents/AlertNotifications/AlertNotifications";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import {
  documentDownload,
  documentListingForContractor,
} from "Services/DocumentServices";

import DownloadSharpIcon from "@mui/icons-material/DownloadSharp";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { AddContractorPricingUpdateModal } from "Components/CommonComponents/Modals/AddContractor Edit Modal/AddContractorPricingUpdateModal";
import { useSnackbar } from "notistack";
import { ContractorEditAgreement } from "Components/CommonComponents/Modals/Edit Agreement Modal/ContractorEditAgreement";
import { Error } from "Utils/Success&Errorfunctions";
import { TabPanel, a11yProps } from "Utils/TabPanel";

export const TeamContractorDetails = (props) => {
  const downloadURL = process.env.REACT_APP_DOWNLOAD_DOCUMENT;
  const navigate = useNavigate();
  const params = useParams();

  const [msg, sendNotification] = useNotification();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const [loadingArray, setLoadingArray] = useState([]);

  const [BackdropOpen, setBackdropOpen] = useState(false);
  const [imgSRC, setImgSRC] = useState("");

  const [value, setValue] = useState(0);
  const [contractorData, setContractorData] = useState([]);
  const [contractorAddress, setContractorAddress] = useState([]);
  const [contractorEmergencyContact, setContractorEmergencyContact] = useState(
    []
  );

  const [avtarText, setAvtarText] = useState("");

  const [fullLegalName, setFullLegalName] = useState("");
  const [birthdate, setBirthdate] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [nationality, setNationality] = useState("");
  const [contractorID, setContractorID] = useState("");
  const [addedBy, setAddedBy] = useState("");

  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [postalCode, setpostalCode] = useState("");
  const [country, setCountry] = useState("");

  const [fullName_Emergency, setFullName_Emergency] = useState("");
  const [email_Emergency, setEmail_Emergency] = useState("");
  const [relation, setRelation] = useState("");
  const [countryCode_Emergency, setCountryCode_Emergency] = useState("");
  const [phoneNumber_Emergency, setPhoneNumber_Emergency] = useState("");

  const [billingCycle, setBillingCycle] = useState("billed-annually");
  const [pricingPerEmployee, setPricingPerEmployee] = useState(0);
  const [currency, setCurrency] = useState("");

  const [isDeactivationID, setIsDeactivationID] = useState(false);

  const [documentsArray, setDocumentsArray] = useState([]);
  const [totalDocument, setTotalDocument] = useState(0);

  const [pageForDocument, setPageForDocument] = useState(0);
  const [rowsPerPageForDocuments, setRowsPerPageForDocuments] = useState(5);
  const [documentSearch, setDocumentSearch] = useState("");
  const [filterColumn, setFilterColumn] = useState("");

  const [editDialog, setEditDialog] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const [rowName, setRowName] = useState([
    "Document Name",
    "Type",
    "Date",
    "Action",
  ]);
  const [checked, setChecked] = useState([
    "Document Name",
    "Type",
    "Date",
    "Action",
  ]);

  useEffect(() => {
    getContractorByID();
  }, []);

  useEffect(() => {
    const getData = setTimeout(() => {
      contractorDocuments();
    }, 200);

    return () => clearTimeout(getData);
  }, [rowsPerPageForDocuments, documentSearch]);

  const showLoading = () => {
    setLoading(true);
  };
  const HideLoading = () => {
    setLoading(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const editDialogToggle = () => {
    setEditDialog(!editDialog);
  };

  function handleToggle(value) {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  }

  const GoBack = () => {
    localStorage.removeItem("contraidForDetailView");
    localStorage.removeItem("contradactivateIDForDetailView");
    localStorage.removeItem("contractorActivationID");
    navigate("/team?view=contractor");
  };

  const getContractorByID = () => {
    var id = localStorage.getItem("contraidForDetailView");

    var deactivateid = localStorage.getItem("contradactivateIDForDetailView");
    if (deactivateid != null) {
      setIsDeactivationID(true);
    } else {
      setIsDeactivationID(false);
    }
    getContractorByIdData(id)
      .then(
        (res) => {
          var Name = [res.data].map((data) => data.full_name);
          var avtarLogo = Name[0].split(" ")[0].charAt(0).toUpperCase();
          if (Name[0].split(" ")[1]) {
            var avtarLogo2 = Name[0].split(" ")[1].charAt(0).toUpperCase();
            var finallogo = avtarLogo.concat(avtarLogo2);
            setAvtarText(finallogo);
          } else {
            setAvtarText("");
          }
          setFullLegalName(res.data.full_name);
          setCountryCode(res.data.country_code);
          setPhoneNumber(res.data.phone_number);
          setEmail(res.data.email);
          setContractorID(res.data.row_id);
          setBirthdate(res.data.birthdate);
          setNationality(res.data.nationality);
          setAddedBy(res.data.added_by);
          setCountry(res.data.country_id.name);
          setContractorData([res.data]);
        },
        (err) => {
          console.log(err.message);
          props.unAuthorized(err.response.status);
          setContractorData(["N/A"]);
        }
      )
      .then(
        getContractorAddress(id).then(
          (res) => {
            setAddress1(res.data.address1);
            setAddress2(res.data.address2);
            setCity(res.data.city);
            setState(res.data.state_id.name);
            setpostalCode(res.data.state_id.state_code);

            setContractorAddress([res.data]);
          },
          (err) => {
            console.log(err.message);
            props.unAuthorized(err.response.status);
            setContractorAddress(["N/A"]);
          }
        )
      )
      .then(
        getContractorEmergencyContact(id).then(
          (res) => {
            setFullName_Emergency(res.data.full_name);
            setRelation(res.data.relationship);
            setEmail_Emergency(res.data.email);
            setCountryCode_Emergency(res.data.country_code);
            setPhoneNumber_Emergency(res.data.phone_number);

            setContractorEmergencyContact([res.data]);
          },
          (err) => {
            console.log(err.message);
            props.unAuthorized(err.response.status);
            setContractorEmergencyContact(["N/A"]);
          }
        )
      )
      .then(
        getPricingForContractor(id).then(
          (res) => {
            setBillingCycle(res.data.type);
            setPricingPerEmployee(res.data.price);
            setCurrency(res.data.currency);
          },
          (err) => {
            setCurrency(" ");
            console.log(err);
          }
        )
      );
  };

  const activate = () => {
    showLoading();
    var activationID = localStorage.getItem("contractorActivationID");
    if (activationID) {
      activateContractor(activationID).then(
        (res) => {
          localStorage.removeItem("employeeStatus");
          localStorage.setItem("contradactivateIDForDetailView", activationID);
          localStorage.removeItem("contractorActivationID");
          var variant = "success";
          enqueueSnackbar(res.data.message, {
            variant,
          });
          getContractorByID();
          HideLoading();
        },
        (err) => {
          sendNotification({
            msg: err.response.statusText,
            variant: "error",
          });

          props.unAuthorized(err.response.status);
          HideLoading();
        }
      );
    } else {
      var variant = "warning";
      enqueueSnackbar("Contractor hasn't onboarded yet can't activate now.", {
        variant,
      });
      HideLoading();
    }
  };

  const deactivate = () => {
    showLoading();
    var id = localStorage.getItem("contradactivateIDForDetailView");

    deactivateContractor(id).then(
      (res) => {
        localStorage.setItem("contractorActivationID", id);
        localStorage.removeItem("contradactivateIDForDetailView");
        var variant = "success";
        enqueueSnackbar(res.data.message, {
          variant,
        });
        getContractorByID();
        HideLoading();
      },
      (err) => {
        sendNotification({
          msg: err.response.statusText,
          variant: "error",
        });
        HideLoading();
        props.unAuthorized(err.response.status);
      }
    );
  };

  const contractorDocuments = (pageNo = 1) => {
    var id = localStorage.getItem("contraidForDetailView");
    documentListingForContractor(
      id,
      pageNo,
      rowsPerPageForDocuments,
      documentSearch
    ).then(
      (res) => {
        const data = res.data;
        setTotalDocument(data.total);
        setDocumentsArray(data.data);
      },
      (err) => {
        setTotalDocument(0);
        setDocumentsArray([]);
      }
    );
  };

  const documentLink = (name, link, index, doc_id) => {
    showLoading();
    var downloadData = {
      document_name: name,
      file_path: link,
      doc_id: doc_id,
    };
    setLoadingArray([index]);
    documentDownload(downloadData).then(
      (res) => {
        var downloadlink = downloadURL + link;
        downloadImage(downloadlink);
        var variant = "success";
        enqueueSnackbar(res.data.message, { variant });
        HideLoading();
        if (res.data.message) {
          setLoadingArray([]);
        } else {
          setLoadingArray([index]);
        }
      },
      (err) => {
        var errors = Error(err.response.data.message);

        if (Array.isArray(errors)) {
          errors.forEach((element) => {
            sendNotification({
              msg: `Please enter valid ${element}`,
              variant: "error",
            });
          });
          HideLoading();
        } else {
          sendNotification({
            msg: err.response.data.message,
            variant: "error",
          });
          HideLoading();
        }
        setLoadingArray([]);
      }
    );
  };

  const downloadImage = async (imageSrc, name) => {
    await fetch(imageSrc)
      .then((response) => response.blob())
      .then((blob) => {
        let blobUrl = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.download = imageSrc.replace(/^.*[\\\/]/, "");
        a.href = blobUrl;
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  };

  const viewImage = (name, link, doc_id) => {
    showLoading();
    setImgSRC(null);
    var downloadData = {
      document_name: name,
      file_path: link,
      doc_id: doc_id,
    };

    documentDownload(downloadData).then(
      (res) => {
        var downloadLink = downloadURL + link;
        window.open(downloadLink, "_blank", "noopener,noreferrer");
        HideLoading();
      },
      (err) => {
        var errors = Error(err.response.data.message);

        if (Array.isArray(errors)) {
          errors.forEach((element) => {
            sendNotification({
              msg: `Please enter valid ${element}`,
              variant: "error",
            });
            HideLoading();
          });
        } else {
          sendNotification({
            msg: err.response.data.message,
            variant: "error",
          });
          HideLoading();
        }
        setBackdropOpen(false);
      }
    );
  };

  const onDocumentPageChange = (event, newpage) => {
    setPageForDocument(newpage);
    contractorDocuments(newpage + 1);
  };

  return (
    <>
      <Slide in={true} direction="left">
        <Grid item className={props.className}>
          <Grid
            container
            alignItems="center"
            flexdirection={{ xs: "row", sm: "row" }}
            className="auth_top_header justify_start"
          >
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="label"
              onClick={GoBack}
            >
              <PreviousArrowIcon />
            </IconButton>
            Back
          </Grid>
          <Grid
            container
            alignItems="center"
            wrap="nowrap"
            flexdirection={{ xs: "row", sm: "row" }}
            className="employe_avatar_wrapper"
            spacing={1}
          >
            {" "}
            <Grid item>
              {contractorData.length ? (
                <Avatar className="employe_avatar">
                  {avtarText ? avtarText : <PersonIcon />}
                </Avatar>
              ) : (
                <Skeleton
                  animation="wave"
                  variant="circular"
                  width={40}
                  height={40}
                  sx={{ marginRight: 1 }}
                />
              )}
            </Grid>
            <Grid item>
              <Typography>
                {contractorData.length ? (
                  fullLegalName
                ) : (
                  <Skeleton
                    variant="rounded"
                    animation="wave"
                    height={25}
                    width={200}
                  />
                )}
              </Typography>
            </Grid>
          </Grid>
          <div className="dash_tabs">
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs "
            >
              <Tab label={<Typography>Profile</Typography>} {...a11yProps(0)} />

              <Tab
                label={<Typography>Documents({totalDocument})</Typography>}
                {...a11yProps(1)}
              />
            </Tabs>
          </div>
          <TabPanel value={value} index={0} className="tab-pannel">
            <Grid container className="employe_profile_container">
              <Grid xs={8} className="employe_profile_container_item">
                {/* Basic box */}
                <Zoom in={true} style={{ transitionDelay: "100ms" }}>
                  <Box className="employe_profile_bx  responsive_collapse">
                    <Grid
                      container
                      alignItems="center"
                      justifyContent={"space-between"}
                      className="employe_profile_head"
                      spacing={0}
                    >
                      <Grid item>
                        <Grid container alignItems="center">
                          <Icon>
                            <TeamEmployeeDetailsBasics />
                          </Icon>
                          <Typography>Basics</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <div className="employe_profile_body">
                      <Grid
                        xs={12}
                        container
                        alignItems="center"
                        flexdirection={{ xs: "row", sm: "row" }}
                        className="employe_personal_dtl"
                      >
                        <Typography variant="body1">Full legal name</Typography>
                        <Typography variant="body2">
                          {" "}
                          {contractorData.length ? (
                            fullLegalName ? (
                              fullLegalName
                            ) : (
                              <Alert icon={false} severity="error">
                                MISSING DETAILS
                              </Alert>
                            )
                          ) : (
                            <Skeleton variant="rounded" animation="wave" />
                          )}
                        </Typography>
                      </Grid>
                      <Grid
                        xs={12}
                        container
                        alignItems="center"
                        flexdirection={{ xs: "row", sm: "row" }}
                        className="employe_personal_dtl"
                      >
                        <Typography variant="body1">Preferred name</Typography>
                        <Typography variant="body2">
                          {contractorData.length ? (
                            fullLegalName ? (
                              fullLegalName.split(" ")[0]
                            ) : (
                              <Alert icon={false} severity="error">
                                MISSING DETAILS
                              </Alert>
                            )
                          ) : (
                            <Skeleton variant="rounded" animation="wave" />
                          )}
                        </Typography>
                      </Grid>
                      <Grid
                        xs={12}
                        container
                        alignItems="center"
                        flexdirection={{ xs: "row", sm: "row" }}
                        className="employe_personal_dtl"
                      >
                        <Typography variant="body1">Birthdate</Typography>
                        <Typography variant="body2">
                          {" "}
                          {contractorData.length ? (
                            birthdate ? (
                              birthdate
                            ) : (
                              <Alert icon={false} severity="error">
                                MISSING DETAILS
                              </Alert>
                            )
                          ) : (
                            <Skeleton variant="rounded" animation="wave" />
                          )}
                        </Typography>
                      </Grid>
                      <Grid
                        xs={12}
                        container
                        alignItems="center"
                        flexdirection={{ xs: "row", sm: "row" }}
                        className="employe_personal_dtl"
                      >
                        <Typography variant="body1">Mobile Number</Typography>
                        <Typography variant="body2">
                          {contractorData.length ? (
                            countryCode && phoneNumber ? (
                              countryCode + "-" + phoneNumber
                            ) : (
                              <Alert icon={false} severity="error">
                                MISSING DETAILS
                              </Alert>
                            )
                          ) : (
                            <Skeleton variant="rounded" animation="wave" />
                          )}
                        </Typography>
                      </Grid>
                      <Grid
                        xs={12}
                        container
                        alignItems="center"
                        flexdirection={{ xs: "row", sm: "row" }}
                        className="employe_personal_dtl"
                      >
                        <Typography variant="body1">Email</Typography>
                        <Typography variant="body2">
                          {contractorData.length ? (
                            email ? (
                              email
                            ) : (
                              <Alert icon={false} severity="error">
                                MISSING DETAILS
                              </Alert>
                            )
                          ) : (
                            <Skeleton variant="rounded" animation="wave" />
                          )}
                        </Typography>
                      </Grid>
                      <Grid
                        xs={12}
                        container
                        alignItems="center"
                        flexdirection={{ xs: "row", sm: "row" }}
                        className="employe_personal_dtl"
                      >
                        <Typography variant="body1">Nationality</Typography>
                        <Typography variant="body2">
                          {contractorData.length ? (
                            nationality ? (
                              nationality
                            ) : (
                              <Alert icon={false} severity="error">
                                MISSING DETAILS
                              </Alert>
                            )
                          ) : (
                            <Skeleton variant="rounded" animation="wave" />
                          )}
                        </Typography>
                      </Grid>
                      <Grid
                        xs={12}
                        container
                        alignItems="center"
                        flexdirection={{ xs: "row", sm: "row" }}
                        className="employe_personal_dtl"
                      >
                        <Typography variant="body1">Contractor ID</Typography>
                        <Typography variant="body2">
                          {contractorData.length ? (
                            contractorID ? (
                              contractorID
                            ) : (
                              <Alert icon={false} severity="error">
                                MISSING DETAILS
                              </Alert>
                            )
                          ) : (
                            <Skeleton variant="rounded" animation="wave" />
                          )}
                        </Typography>
                      </Grid>
                      <Grid
                        xs={12}
                        container
                        alignItems="center"
                        flexdirection={{ xs: "row", sm: "row" }}
                        className="employe_personal_dtl"
                      >
                        <Typography variant="body1">Added By</Typography>
                        <Typography variant="body2">
                          {" "}
                          {contractorData.length ? (
                            addedBy
                          ) : (
                            <Skeleton variant="rounded" animation="wave" />
                          )}
                        </Typography>
                      </Grid>
                    </div>
                  </Box>
                </Zoom>
                {/* Home Address box */}
                <Zoom in={true} style={{ transitionDelay: "100ms" }}>
                  <Box className="employe_profile_bx responsive_collapse">
                    <Grid
                      container
                      alignItems="center"
                      justifyContent={"space-between"}
                      className="employe_profile_head"
                      spacing={0}
                    >
                      <Grid item>
                        <Grid container alignItems="center">
                          <Icon>
                            <TeamEmployeeDetailsHome />
                          </Icon>
                          <Typography>Home Address</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <div className="employe_profile_body">
                      <>
                        <Grid
                          xs={12}
                          container
                          alignItems="center"
                          flexdirection={{ xs: "row", sm: "row" }}
                          className="employe_personal_dtl"
                        >
                          <Typography variant="body1">Address</Typography>
                          <Typography variant="body2">
                            {contractorAddress.length ? (
                              address1 ? (
                                address1
                              ) : (
                                <Alert icon={false} severity="error">
                                  MISSING DETAILS
                                </Alert>
                              )
                            ) : (
                              <Skeleton variant="rounded" animation="wave" />
                            )}
                          </Typography>
                        </Grid>
                        <Grid
                          xs={12}
                          container
                          alignItems="center"
                          flexdirection={{ xs: "row", sm: "row" }}
                          className="employe_personal_dtl"
                        >
                          <Typography variant="body1">
                            Address (cont.)
                          </Typography>
                          <Typography variant="body2">
                            {contractorAddress.length ? (
                              address2 == null ||
                              (address2 == "" ? (
                                <Alert icon={false} severity="error">
                                  MISSING DETAILS
                                </Alert>
                              ) : (
                                address2
                              ))
                            ) : (
                              <Skeleton variant="rounded" animation="wave" />
                            )}
                          </Typography>
                        </Grid>
                        <Grid
                          xs={12}
                          container
                          alignItems="center"
                          flexdirection={{ xs: "row", sm: "row" }}
                          className="employe_personal_dtl"
                        >
                          <Typography variant="body1">City</Typography>
                          <Typography variant="body2">
                            {" "}
                            {contractorAddress.length ? (
                              city ? (
                                city
                              ) : (
                                <Alert icon={false} severity="error">
                                  MISSING DETAILS
                                </Alert>
                              )
                            ) : (
                              <Skeleton variant="rounded" animation="wave" />
                            )}
                          </Typography>
                        </Grid>
                        <Grid
                          xs={12}
                          container
                          alignItems="center"
                          flexdirection={{ xs: "row", sm: "row" }}
                          className="employe_personal_dtl"
                        >
                          <Typography variant="body1">State</Typography>
                          <Typography variant="body2">
                            {" "}
                            {contractorAddress.length ? (
                              state ? (
                                state
                              ) : (
                                <Alert icon={false} severity="error">
                                  MISSING DETAILS
                                </Alert>
                              )
                            ) : (
                              <Skeleton variant="rounded" animation="wave" />
                            )}
                          </Typography>
                        </Grid>

                        <Grid
                          xs={12}
                          container
                          alignItems="center"
                          flexdirection={{ xs: "row", sm: "row" }}
                          className="employe_personal_dtl"
                        >
                          <Typography variant="body1">Country</Typography>
                          <Typography variant="body2">
                            {" "}
                            {contractorAddress.length ? (
                              country ? (
                                country
                              ) : (
                                <Alert icon={false} severity="error">
                                  MISSING DETAILS
                                </Alert>
                              )
                            ) : (
                              <Skeleton variant="rounded" animation="wave" />
                            )}
                          </Typography>
                        </Grid>
                      </>
                    </div>
                  </Box>
                </Zoom>
                {/* Emergency Contact Box */}
                <Zoom in={true} style={{ transitionDelay: "100ms" }}>
                  <Box className="employe_profile_bx responsive_collapse">
                    <Grid
                      container
                      alignItems="center"
                      justifyContent={"space-between"}
                      className="employe_profile_head"
                      spacing={0}
                    >
                      <Grid item>
                        <Grid container alignItems="center">
                          <Icon>
                            <TeamEmployeeDetailsEmergencyContact />
                          </Icon>
                          <Typography>Emergency contact</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <div className="employe_profile_body">
                      <>
                        <Grid
                          xs={12}
                          container
                          alignItems="center"
                          flexdirection={{ xs: "row", sm: "row" }}
                          className="employe_personal_dtl"
                        >
                          <Typography variant="body1">Full Name</Typography>
                          <Typography variant="body2">
                            {contractorEmergencyContact.length ? (
                              fullName_Emergency ? (
                                fullName_Emergency
                              ) : (
                                <Alert icon={false} severity="error">
                                  MISSING DETAILS
                                </Alert>
                              )
                            ) : (
                              <Skeleton variant="rounded" animation="wave" />
                            )}
                          </Typography>
                        </Grid>
                        <Grid
                          xs={12}
                          container
                          alignItems="center"
                          flexdirection={{ xs: "row", sm: "row" }}
                          className="employe_personal_dtl"
                        >
                          <Typography variant="body1">Relationship</Typography>
                          <Typography variant="body2">
                            {contractorEmergencyContact.length ? (
                              relation ? (
                                relation
                              ) : (
                                <Alert icon={false} severity="error">
                                  MISSING DETAILS
                                </Alert>
                              )
                            ) : (
                              <Skeleton variant="rounded" animation="wave" />
                            )}
                          </Typography>
                        </Grid>
                        <Grid
                          xs={12}
                          container
                          alignItems="center"
                          flexdirection={{ xs: "row", sm: "row" }}
                          className="employe_personal_dtl"
                        >
                          <Typography variant="body1">Their Email</Typography>
                          <Typography variant="body2">
                            {contractorEmergencyContact.length ? (
                              email_Emergency ? (
                                email_Emergency
                              ) : (
                                <Alert icon={false} severity="error">
                                  MISSING DETAILS
                                </Alert>
                              )
                            ) : (
                              <Skeleton variant="rounded" animation="wave" />
                            )}
                          </Typography>
                        </Grid>
                        <Grid
                          xs={12}
                          container
                          alignItems="center"
                          flexdirection={{ xs: "row", sm: "row" }}
                          className="employe_personal_dtl"
                        >
                          <Typography variant="body1">Phone Number</Typography>
                          <Typography variant="body2">
                            {" "}
                            {contractorEmergencyContact.length ? (
                              countryCode_Emergency && phoneNumber_Emergency ? (
                                countryCode_Emergency +
                                "-" +
                                phoneNumber_Emergency
                              ) : (
                                <Alert icon={false} severity="error">
                                  MISSING DETAILS
                                </Alert>
                              )
                            ) : (
                              <Skeleton variant="rounded" animation="wave" />
                            )}
                          </Typography>
                        </Grid>
                      </>
                    </div>
                  </Box>
                </Zoom>
                {/* Contract Subscription fees box */}
                <Zoom in={true} style={{ transitionDelay: "100ms" }}>
                  <Box className="employe_profile_bx responsive_collapse">
                    <Grid
                      container
                      alignItems="center"
                      justifyContent={"space-between"}
                      className="employe_profile_head"
                      spacing={0}
                    >
                      <Grid item>
                        <Grid container alignItems="center">
                          <Icon>
                            <TeamEmployeeDetailsContractSubscription />
                          </Icon>
                          <Typography>Contract Subscription Fees</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <div className="employe_profile_body">
                      <Grid
                        xs={12}
                        container
                        alignItems="center"
                        flexdirection={{ xs: "row", sm: "row" }}
                        className="employe_personal_dtl"
                      >
                        <Typography variant="body1">Price</Typography>
                        <Typography variant="body2">
                          {contractorEmergencyContact.length ? (
                            currency !== " " ? (
                              pricingPerEmployee +
                              " " +
                              currency +
                              "/" +
                              billingCycle
                            ) : (
                              "N/A"
                            )
                          ) : (
                            <Skeleton variant="rounded" animation="wave" />
                          )}
                        </Typography>
                      </Grid>
                      <Grid
                        xs={12}
                        container
                        alignItems="center"
                        flexdirection={{ xs: "row", sm: "row" }}
                        className="employe_personal_dtl"
                      >
                        <Typography variant="body1">
                          Last Billing Period
                        </Typography>
                        <Typography variant="body2">
                          {" "}
                          {contractorEmergencyContact.length ? (
                            "N/A"
                          ) : (
                            <Skeleton variant="rounded" animation="wave" />
                          )}
                        </Typography>
                      </Grid>
                    </div>
                  </Box>
                </Zoom>
              </Grid>
              {/* Employment status box */}
              <Zoom in={true} style={{ transitionDelay: "300ms" }}>
                <Grid
                  xs={4}
                  className="employe_status employe_profile_container_item"
                >
                  <Box className="employe_profile_bx">
                    <Grid
                      xs={12}
                      container
                      className="employe_profile_head emp_status"
                      spacing={0}
                    >
                      <Grid xs={12}>
                        <Typography>Employment Status</Typography>
                      </Grid>
                      {isDeactivationID ? (
                        <>
                          <Grid>
                            <span className="status_dot"></span>
                          </Grid>
                          <Grid>
                            <Typography variant="body2" ml={1} mb={0}>
                              Active
                            </Typography>
                          </Grid>
                        </>
                      ) : localStorage.getItem("employeeStatus") !==
                        "onboarding" ? (
                        <>
                          <Grid>
                            <span className={`status_dot ${"inactive"}`}></span>
                          </Grid>
                          <Grid>
                            <Typography variant="body2" ml={1} mb={0}>
                              Inactive
                            </Typography>
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid>
                            <span
                              className={`status_dot ${"onboarding"}`}
                            ></span>
                          </Grid>
                          <Grid>
                            <Typography variant="body2" ml={1} mb={0}>
                              Onboarding
                            </Typography>
                          </Grid>
                        </>
                      )}
                    </Grid>
                    <Grid xs={12} container className="employe_profile_head">
                      <Grid xs={12}>
                        <Typography>Country</Typography>
                      </Grid>
                      <Grid xs={12}>
                        {/* <img src={India} /> */}
                        {nationality}
                      </Grid>
                      <Grid xs={12}>
                        <Typography variant="caption">
                          <Link>SEE PUBLIC HOLIDAYS</Link>
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid xs={12} container className="employe_profile_head">
                      <Grid xs={12}>
                        <Typography>Agreement Date</Typography>
                      </Grid>
                      <Grid xs={12}>
                        {contractorData.map((data) =>
                          data.agreement_date ? (
                            <Typography
                              variant="body2"
                              key={data.agreement_date}
                            >
                              {moment(data.agreement_date).format("ll")}
                            </Typography>
                          ) : (
                            <Typography variant="body2">N/A</Typography>
                          )
                        )}
                      </Grid>
                    </Grid>
                    <Grid xs={12} container className="employe_profile_head">
                      <Grid xs={12}>
                        <Typography>Contract</Typography>
                      </Grid>
                      <Grid xs={12}>
                        {contractorData.map((data) => (
                          <Typography variant="body2" key={data.job_title}>
                            {data.job_title}
                          </Typography>
                        ))}
                      </Grid>
                    </Grid>
                    <Grid
                      xs={12}
                      spacing={0}
                      className="dotted_lighting_bx_wrapper"
                      direction={"column"}
                    >
                      <Button
                        className="dotted_lighting_bx variant_green"
                        onClick={(e) => setModalOpen(true)}
                      >
                        <Grid
                          container
                          flexdirection={{ xs: "row", sm: "row" }}
                        >
                          <Grid item className="img_grid_item">
                            <Icon className="img_bx">
                              <TeamemployeeDetailsEditAgreement />
                            </Icon>
                          </Grid>
                          <Grid item className="cont_grid_item">
                            <Typography variant="body1">
                              Edit agreement
                            </Typography>
                            <Typography variant="body2">
                              Edit the pending contract agrement
                            </Typography>
                          </Grid>
                        </Grid>
                      </Button>
                      {isDeactivationID ? (
                        <Button
                          disabled={loading ? true : false}
                          onClick={deactivate}
                          className="dotted_lighting_bx variant_red"
                        >
                          <Grid
                            container
                            flexDirection={{ xs: "row", sm: "row" }}
                            spacing={1}
                          >
                            <Grid item className="img_grid_item">
                              <Icon className="img_bx">
                                <TeamEmployeeDetailsInActive />
                              </Icon>
                            </Grid>
                            <Grid item className="cont_grid_item">
                              <Typography variant="body1">
                                Set to inactive
                              </Typography>
                              <Typography variant="body2">
                                Deactivate the contractor profile
                              </Typography>
                            </Grid>
                          </Grid>
                        </Button>
                      ) : (
                        <Button
                          disabled={loading ? true : false}
                          onClick={activate}
                          className="dotted_lighting_bx variant_green"
                        >
                          <Grid
                            container
                            flexDirection={{ xs: "row", sm: "row" }}
                            spacing={1}
                          >
                            <Grid item className="img_grid_item">
                              <Icon className="img_bx">
                                <PersonAddIcon />
                              </Icon>
                            </Grid>
                            <Grid item className="cont_grid_item">
                              <Typography variant="body1">
                                Set to active
                              </Typography>
                              <Typography variant="body2">
                                Activate the contractor profile.
                              </Typography>
                            </Grid>
                          </Grid>
                        </Button>
                      )}
                    </Grid>
                  </Box>
                </Grid>
              </Zoom>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={1} className="tab-pannel">
            <div
              className={`data_table ${
                !documentsArray.length && "no_data_found"
              }`}
            >
              <Grid container className="data_table_filters_grid">
                <TextField
                  spellCheck="false"
                  value={documentSearch}
                  onChange={(e) => setDocumentSearch(e.target.value)}
                  className="outer_searchfilled"
                  placeholder="Search"
                  id="outlined-start-adornment"
                  InputProps={{
                    autoComplete: "off",
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />

                <div className="table_edit_controller">
                  <IconButton onClick={editDialogToggle}>
                    <TeamEditIcon />
                  </IconButton>

                  {editDialog ? (
                    <div className="edit_filter_menu">
                      <Button
                        className="edit_filter_menu_close"
                        onClick={editDialogToggle}
                        disableRipple
                      ></Button>
                      <div className="edit_filter_menu_bx">
                        <TextField
                          placeholder="Search Columns"
                          fullWidth
                          value={filterColumn}
                          id="outlined-start-adornment"
                          sx={{
                            mt: "10px",
                            ml: "10px",
                            mr: "5px",
                            width: "auto",
                          }}
                          onChange={(e) => setFilterColumn(e.target.value)}
                          InputProps={{
                            autoComplete: "off",
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            ),
                          }}
                        />{" "}
                        <List
                          subheader={
                            <ListSubheader>Original Columns</ListSubheader>
                          }
                        >
                          {rowName
                            .filter((data) => {
                              return filterColumn.toLowerCase() == ""
                                ? data
                                : data
                                    .toLowerCase()
                                    .includes(filterColumn.toLowerCase());
                            })
                            .map((data, index) => (
                              <ListItem key={index}>
                                <Switch
                                  edge="end"
                                  onChange={() => handleToggle(data)}
                                  checked={checked.indexOf(data) !== -1}
                                  className={
                                    checked.indexOf(data) !== -1
                                      ? "active"
                                      : "inactive"
                                  }
                                  inputProps={{
                                    "aria-labelledby": data,
                                  }}
                                />
                                <ListItemText id={data} primary={data} />
                              </ListItem>
                            ))}
                        </List>
                      </div>
                    </div>
                  ) : null}
                </div>
              </Grid>
              <div className="table_container">
                <Table>
                  <TableHead>
                    <TableRow>
                      {checked.indexOf("Document Name") >= 0 ? (
                        <TableCell component={"td"} align="left">
                          DOCUMENT NAME
                        </TableCell>
                      ) : null}
                      {checked.indexOf("Type") >= 0 ? (
                        <TableCell component={"td"} align="left">
                          TYPE
                        </TableCell>
                      ) : null}
                      {checked.indexOf("Date") >= 0 ? (
                        <TableCell component={"td"} align="left">
                          DATE
                        </TableCell>
                      ) : null}
                      {checked.indexOf("Action") >= 0 ? (
                        <TableCell component={"td"} align="left">
                          Actions
                        </TableCell>
                      ) : null}
                    </TableRow>
                  </TableHead>
                  {documentsArray.length && !loading ? (
                    <TableBody>
                      {documentsArray.map((row, index) => (
                        <TableRow key={row.row_id} id={row.row_id}>
                          {checked.indexOf("Document Name") >= 0 ? (
                            <TableCell scope="row" align="left">
                              <Grid container className="avatar_container">
                                {row.document_name}
                              </Grid>
                            </TableCell>
                          ) : null}
                          {checked.indexOf("Type") >= 0 ? (
                            <TableCell>
                              {" "}
                              {row.doc_type !== null ? row.doc_type.name : null}
                            </TableCell>
                          ) : null}{" "}
                          {checked.indexOf("Date") >= 0 ? (
                            <TableCell>
                              {moment(row.created_date).format("ll")}
                            </TableCell>
                          ) : null}
                          {checked.indexOf("Action") >= 0 ? (
                            <TableCell component={"td"} align="left">
                              <ButtonGroup>
                                {" "}
                                <IconButton
                                  color="dark"
                                  variant="text"
                                  onClick={() => {
                                    viewImage(
                                      row.document_name,
                                      row.download_link,
                                      row.row_id
                                    );
                                  }}
                                >
                                  <VisibilityIcon />
                                </IconButton>
                                {!loadingArray.includes(index) ? (
                                  <IconButton
                                    color="dark"
                                    variant="text"
                                    onClick={() =>
                                      documentLink(
                                        row.document_name,
                                        row.download_link,
                                        index,
                                        row.row_id
                                      )
                                    }
                                  >
                                    <DownloadSharpIcon color="dark" />
                                  </IconButton>
                                ) : loadingArray.includes(index) ? (
                                  <IconButton disabled>
                                    <CircularProgress size={20} />
                                  </IconButton>
                                ) : (
                                  <IconButton
                                    color="dark"
                                    variant="text"
                                    onClick={() =>
                                      documentLink(
                                        row.document_name,
                                        row.download_link,
                                        index,
                                        row.row_id
                                      )
                                    }
                                  >
                                    <DownloadSharpIcon color="dark" />
                                  </IconButton>
                                )}
                              </ButtonGroup>
                            </TableCell>
                          ) : null}
                        </TableRow>
                      ))}
                    </TableBody>
                  ) : !loading ? (
                    <>
                      <TableBody>
                        <TableRow>
                          <TableCell colSpan={10}>
                            <Grid className="data_table_inner_notfound">
                              <DataNotFound />
                              <Typography>
                                Nothing needs attention right now
                              </Typography>
                              <Typography variant="body2">
                                When your request changes or documents, they
                                appear here
                              </Typography>
                            </Grid>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </>
                  ) : (
                    <TableBody>
                      <TableRow>
                        <TableCell colSpan={10}>
                          <Grid className="data_table_inner_notfound">
                            <CircularProgress />
                          </Grid>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </div>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={totalDocument}
                rowsPerPage={rowsPerPageForDocuments}
                page={pageForDocument}
                onPageChange={onDocumentPageChange}
                onRowsPerPageChange={(event) => {
                  setPageForDocument(0);
                  setRowsPerPageForDocuments(+event.target.value);
                }}
              />
            </div>
          </TabPanel>
        </Grid>
      </Slide>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={BackdropOpen}
      >
        <IconButton
          className="single_drop_close_bttn"
          onClick={() => setBackdropOpen(false)}
        >
          <CloseIcon />
        </IconButton>
        {imgSRC ? (
          <img src={imgSRC} alt="" className="single_drop_img" />
        ) : (
          <CircularProgress size={80} />
        )}
      </Backdrop>
      <ContractorEditAgreement
        open={modalOpen}
        close={(e) => setModalOpen(false)}
      />
    </>
  );
};
