import { getApi, postApi, putApi } from "./commonservice";

export function postInsentive(data) {
  return postApi("incentive", data);
}

export function updateInsentive(data, id) {
  return putApi("incentive/" + id, data);
}

export function getIncentiveById(id) {
  return getApi("incentive/" + id);
}

export function getIncentives(
  page = 1,
  rowPerPage = 5,
  status = "upcoming",
  search = "",
  filterValue = "all",
  filteBy = "incentive_type"
) {
  if (filterValue !== "all") {
    return getApi(
      `incentive-list?page=${page}&per_page=${rowPerPage}&status=${status}&search=${search}&filter_by=${filteBy}&filter_val=${filterValue}`
    );
  } else {
    return getApi(
      `incentive-list?page=${page}&per_page=${rowPerPage}&status=${status}&search=${search}`
    );
  }
}
