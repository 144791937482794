import "./CompanySettings.css";
import { React, useEffect, useState } from "react";

import { PropTypes } from "Components/Dashboard/DashboardImports";
import { Button, Grid, Typography } from "Utils/muiImport";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { Box } from "@mui/system";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  Alert,
  Divider,
  FormGroup,
  FormHelperText,
  Icon,
  IconButton,
  MenuItem,
  Modal,
  Skeleton,
  Slide,
  TablePagination,
  TextField,
  Tooltip,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  DollarIconComapanySetting,
  EditIcon,
  GeneralInformationIcon,
  HomeIcon,
  HomeIconCompanySetting,
  NotificationBellCompanySettingIcon,
  TearmsAndServiceCompanySettingIcon,
} from "Icons/Icons";
import { CompanySettingModal } from "Components/CommonComponents/Modals/CompanySetting Modal/CompanySettingModal";
import { getAllEmployee } from "Services/AddEmployeeAndContractor";
import {
  companyUserListing,
  getBankdetails,
  getCompanyAddress,
  getCompanyData,
  getCompanyStates,
} from "Services/CompanyServices";
import moment from "moment";
import { Link } from "react-router-dom";
import { BankDetailsModal } from "Components/CommonComponents/Modals/CompanySetting Modal/BankDetailsModal";
import { AddressModal } from "Components/CommonComponents/Modals/CompanySetting Modal/AddressModal";
import { TabPanel, a11yProps } from "Utils/TabPanel";
import jwtDecode from "jwt-decode";

export const CompanySettings = (props) => {
  const [loading, setLoading] = useState(true);

  const [value, setValue] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [bankModalOpen, setBankModalOpen] = useState(false);
  const [addressModalOpen, setAddressModalOpen] = useState(false);

  const [comapnyData, setComapnyData] = useState([]);
  const [comapanyAddressData, setCompanyAddressData] = useState([]);

  const [companyName, setCompanyName] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [vatNumber, setVatNumber] = useState("");
  const [registrationNumber, setRegistrationNumber] = useState("");
  const [inroleComoanyID, setInroleComoanyID] = useState("");

  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [country, setCountry] = useState("");

  const [accountHolderName, setAccountHolderName] = useState("");
  const [accountOwnershipType, setAccountOwnershipType] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [bankNumber, setBankNumber] = useState("");
  const [fullbankName, setFullbankName] = useState("");
  const [bankBranchNumber, setBankBranchNumber] = useState("");
  const [SWIFT, setSWIFT] = useState("");
  const [currency, setCurrency] = useState("");

  const [agreedOn, setAgreedOn] = useState("");
  const [agreedBy, setAgreedBy] = useState("");

  const [numberOfEmployee, setNumberOfEmployee] = useState(0);
  const [numberOfContractor, setNumberOfContractor] = useState(0);

  const [companyUsersArray, setCompanyUsersArray] = useState([]);

  const [search, setSearch] = useState("");
  const [totalCompanyEmployee, setTotalCompanyEmployee] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    getCompanyProfile();
    UserListing();
  }, []);
  useEffect(() => {
    UserListing();
  }, [rowsPerPage]);

  const showLoading = () => {
    setLoading(true);
  };

  const hideloading = () => {
    setLoading(false);
  };

  const onCompanyEmployeePageChange = (event, newPage) => {
    setPage(newPage);
    UserListing(newPage + 1);
  };

  const UserListing = (pageNo = 1) => {
    var companyID = localStorage.getItem("CompanyRowID");
    companyUserListing(companyID, pageNo, rowsPerPage, search).then(
      (res) => {
        setTotalCompanyEmployee(res.data.total);

        setCompanyUsersArray(res.data.data);
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const getCompanyProfile = async () => {
    var token = localStorage.getItem("JWT");
    if (token !== null) {
      var decodetoken = jwtDecode(token);
      showLoading();
      var companyID = localStorage.getItem("CompanyRowID");
      await getCompanyData(decodetoken.row_id)
        .then(
          async (res) => {
            var addressRow = [res.data].map((data) => data.row_id);

            await getCompanyAddress(addressRow).then(
              (res) => {
                setCompanyAddressData([res.data]);
                [res.data].map((data) => {
                  setAddress1(data.address1);
                  setAddress2(data.address2);
                  setCity(data.city);

                  setPostalCode(data.postal_code);
                  setCountry(data.country_id.name);
                });
                hideloading();
              },
              (err) => {
                props.unAuthorized(err.response.status);
                hideloading();
                console.log(err);
              }
            );
            setComapnyData([res.data]);
            [res.data].map((data) => {
              setCompanyName(data.company_name);
              setCountryCode(data.country_code);
              setContactNo(data.phone_number);
              setVatNumber("");
              setRegistrationNumber(data.company_registration_number);
              setInroleComoanyID(data.user_id);
              setAgreedOn(data.created_date);
              setAgreedBy(data.full_name);
            });
          },
          (err) => {
            props.unAuthorized(err.response.status);
            console.log(err);
          }
        )
        .then(
          getBankdetails(companyID).then(
            (res) => {
              [res.data].map((data) => {
                setAccountHolderName(data.account_holder_name);
                setAccountOwnershipType(data.ownership_type);
                setAccountNumber(data.account_number);
                setBankNumber(data.ifsc_code);
                setFullbankName(data.full_bank_name);
                setBankBranchNumber(data.ifsc_code);
                setSWIFT(data.swift);

                setCurrency(data.currency);
              });
            },
            (err) => {
              props.unAuthorized(err.response.status);
              console.log(err.data.message);
            }
          )
        )
        .then(
          getCompanyStates().then(
            (res) => {
              setNumberOfContractor(res.data.message.no_of_contractor);
              setNumberOfEmployee(res.data.message.no_of_employee);
            },
            (err) => {
              props.unAuthorized(err.response.status);
              console.log(err.data.message);
            }
          )
        );
    }
  };

  return (
    <>
      <Slide in={true} direction="left">
        <Grid item className={props.className}>
          <Grid container className="team_top_heading">
            <Grid item>
              <Typography className="page_heading">Company Settings</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" className="page_head_para">
                Keep all your company information in one place for your own
                reference and to enable smooth payouts.
              </Typography>
            </Grid>
          </Grid>

          <div className="dash_tabs">
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs "
            >
              <Tab
                label={<Typography>Company Information</Typography>}
                {...a11yProps(0)}
              />

              <Tab
                label={<Typography>Users and Permissions</Typography>}
                {...a11yProps(1)}
              />
            </Tabs>
            <TabPanel value={value} index={0} className="tab-pannel">
              <Grid container spacing={0} className="company_profile_layer">
                <Grid item xs={12} lg={9}>
                  <Box className="employe_profile_bx">
                    <Grid
                      container
                      alignItems="center"
                      justifyContent={"space-between"}
                      className="employe_profile_head"
                      spacing={0}
                    >
                      <Grid item>
                        <Grid container alignItems="center">
                          <Icon>
                            <GeneralInformationIcon />
                          </Icon>
                          <Typography>General Information</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <div className="employe_profile_body">
                      <Grid
                        xs={12}
                        container
                        alignItems="center"
                        flexDirection={{ xs: "row", sm: "row" }}
                        className="employe_personal_dtl"
                      >
                        <Typography variant="body1">Company name</Typography>
                        {!loading ? (
                          companyName ? (
                            <Typography variant="body2">
                              {companyName}
                            </Typography>
                          ) : (
                            <Alert icon={false} severity="error">
                              MISSING DETAILS
                            </Alert>
                          )
                        ) : (
                          <Skeleton variant="rounded" animation="wave">
                            <Typography variant="body2">
                              companyNamecompanyNamecompanyName
                            </Typography>
                          </Skeleton>
                        )}
                      </Grid>
                      <Grid
                        xs={12}
                        container
                        alignItems="center"
                        flexDirection={{ xs: "row", sm: "row" }}
                        className="employe_personal_dtl"
                      >
                        <Typography variant="body1">Phone number</Typography>
                        {!loading ? (
                          countryCode && contactNo ? (
                            <Typography variant="body2">
                              {countryCode + "-" + contactNo}
                            </Typography>
                          ) : (
                            <Alert icon={false} severity="error">
                              MISSING DETAILS
                            </Alert>
                          )
                        ) : (
                          <Skeleton variant="rounded" animation="wave">
                            <Typography variant="body2">
                              companyNamecompanyNamecompanyName
                            </Typography>
                          </Skeleton>
                        )}
                      </Grid>
                      {/* <Grid
                      xs={12}
                      container
                      alignItems="center"
                      flexDirection={{ xs: "row", sm: "row" }}
                      className="employe_personal_dtl"
                    >
                      <Typography variant="body1">VAT number</Typography>
                      {vatNumber ? (
                        <Typography variant="body2">{vatNumber}</Typography>
                      ) : (
                        <Alert icon={false} severity="error">
                          MISSING DETAILS
                        </Alert>
                      )}
                    </Grid> */}
                      <Grid
                        xs={12}
                        container
                        alignItems="center"
                        flexDirection={{ xs: "row", sm: "row" }}
                        className="employe_personal_dtl"
                      >
                        <Typography variant="body1">
                          Registration number
                        </Typography>
                        {!loading ? (
                          registrationNumber ? (
                            <Typography variant="body2">
                              {registrationNumber}
                            </Typography>
                          ) : (
                            <Alert icon={false} severity="error">
                              MISSING DETAILS
                            </Alert>
                          )
                        ) : (
                          <Skeleton variant="rounded" animation="wave">
                            <Typography variant="body2">
                              companyNamecompanyNamecompanyName
                            </Typography>
                          </Skeleton>
                        )}
                      </Grid>
                      <Grid
                        xs={12}
                        container
                        alignItems="center"
                        flexDirection={{ xs: "row", sm: "row" }}
                        className="employe_personal_dtl"
                      >
                        <Typography variant="body1">
                          InRole company ID
                        </Typography>

                        {!loading ? (
                          inroleComoanyID ? (
                            <Typography variant="body2">
                              {inroleComoanyID}
                            </Typography>
                          ) : (
                            <Alert icon={false} severity="error">
                              MISSING DETAILS
                            </Alert>
                          )
                        ) : (
                          <Skeleton variant="rounded" animation="wave">
                            <Typography variant="body2">
                              companyNamecompanyNamecompanyName
                            </Typography>
                          </Skeleton>
                        )}
                      </Grid>
                    </div>
                  </Box>
                  <Box className="employe_profile_bx">
                    <Grid
                      container
                      alignItems="center"
                      justifyContent={"space-between"}
                      className="employe_profile_head"
                      spacing={0}
                    >
                      <Grid item>
                        <Grid container alignItems="center">
                          <Icon>
                            <HomeIconCompanySetting />
                          </Icon>
                          <Typography>Location</Typography>
                        </Grid>
                      </Grid>
                      <Grid item alignItems="center">
                        <Button
                          className="theme_bttn transparent"
                          startIcon={<EditIcon />}
                          onClick={() => setAddressModalOpen(true)}
                        >
                          Edit
                        </Button>
                      </Grid>
                    </Grid>
                    <div className="employe_profile_body">
                      <Grid
                        xs={12}
                        container
                        alignItems="center"
                        flexDirection={{ xs: "row", sm: "row" }}
                        className="employe_personal_dtl"
                      >
                        <Typography variant="body1">Address</Typography>
                        {!loading ? (
                          address1 ? (
                            <Typography variant="body2">{address1}</Typography>
                          ) : (
                            <Alert icon={false} severity="error">
                              MISSING DETAILS
                            </Alert>
                          )
                        ) : (
                          <Skeleton variant="rounded" animation="wave">
                            <Typography variant="body2">
                              companyNamecompanyNamecompanyName
                            </Typography>
                          </Skeleton>
                        )}
                      </Grid>
                      <Grid
                        xs={12}
                        container
                        alignItems="center"
                        flexDirection={{ xs: "row", sm: "row" }}
                        className="employe_personal_dtl"
                      >
                        <Typography variant="body1">Address (cont.)</Typography>
                        {!loading ? (
                          address2 ? (
                            <Typography variant="body2">{address2}</Typography>
                          ) : (
                            <Alert icon={false} severity="error">
                              MISSING DETAILS
                            </Alert>
                          )
                        ) : (
                          <Skeleton variant="rounded" animation="wave">
                            <Typography variant="body2">
                              companyNamecompanyNamecompanyName
                            </Typography>
                          </Skeleton>
                        )}
                      </Grid>
                      <Grid
                        xs={12}
                        container
                        alignItems="center"
                        flexDirection={{ xs: "row", sm: "row" }}
                        className="employe_personal_dtl"
                      >
                        <Typography variant="body1">City</Typography>
                        {!loading ? (
                          city ? (
                            <Typography variant="body2">{city}</Typography>
                          ) : (
                            <Alert icon={false} severity="error">
                              MISSING DETAILS
                            </Alert>
                          )
                        ) : (
                          <Skeleton variant="rounded" animation="wave">
                            <Typography variant="body2">
                              companyNamecompanyNamecompanyName
                            </Typography>
                          </Skeleton>
                        )}
                      </Grid>
                      <Grid
                        xs={12}
                        container
                        alignItems="center"
                        flexDirection={{ xs: "row", sm: "row" }}
                        className="employe_personal_dtl"
                      >
                        <Typography variant="body1">Postal code</Typography>
                        {!loading ? (
                          postalCode ? (
                            <Typography variant="body2">
                              {postalCode}
                            </Typography>
                          ) : (
                            <Alert icon={false} severity="error">
                              MISSING DETAILS
                            </Alert>
                          )
                        ) : (
                          <Skeleton variant="rounded" animation="wave">
                            <Typography variant="body2">
                              companyNamecompanyNamecompanyName
                            </Typography>
                          </Skeleton>
                        )}
                      </Grid>
                      <Grid
                        xs={12}
                        container
                        alignItems="center"
                        flexDirection={{ xs: "row", sm: "row" }}
                        className="employe_personal_dtl"
                      >
                        <Typography variant="body1">Country</Typography>
                        {!loading ? (
                          country ? (
                            <Typography variant="body2">{country}</Typography>
                          ) : (
                            <Alert icon={false} severity="error">
                              MISSING DETAILS
                            </Alert>
                          )
                        ) : (
                          <Skeleton variant="rounded" animation="wave">
                            <Typography variant="body2">
                              companyNamecompanyNamecompanyName
                            </Typography>
                          </Skeleton>
                        )}
                      </Grid>
                    </div>
                  </Box>
                  <Box className="employe_profile_bx">
                    <Grid
                      container
                      alignItems="center"
                      justifyContent={"space-between"}
                      className="employe_profile_head"
                      spacing={0}
                    >
                      <Grid item>
                        <Grid container alignItems="center">
                          <Icon>
                            <DollarIconComapanySetting />
                          </Icon>
                          <Typography>Bank details</Typography>
                        </Grid>
                      </Grid>
                      <Grid item alignItems="center">
                        <Button
                          className="theme_bttn transparent"
                          startIcon={<EditIcon />}
                          onClick={() => setBankModalOpen(true)}
                        >
                          Edit
                        </Button>
                      </Grid>
                    </Grid>
                    <div className="employe_profile_body">
                      <Box className="warning_info_box">
                        <Grid container>
                          <Grid item className="warning_box_icon">
                            <Icon>
                              <InfoOutlinedIcon />
                            </Icon>
                          </Grid>
                          <Grid item className="warning_box_cont">
                            <Typography variant="body2">
                              These details are for issuing refunds. To view
                              your methods for paying, go to your{" "}
                              <Link>payment methods</Link>.
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                      <Grid
                        xs={12}
                        container
                        alignItems="center"
                        flexDirection={{ xs: "row", sm: "row" }}
                        className="employe_personal_dtl"
                      >
                        <Typography variant="body1">
                          Account holder name
                        </Typography>
                        {!loading ? (
                          accountHolderName ? (
                            <Typography variant="body2">
                              {accountHolderName}
                            </Typography>
                          ) : (
                            <Alert icon={false} severity="error">
                              MISSING DETAILS
                            </Alert>
                          )
                        ) : (
                          <Skeleton variant="rounded" animation="wave">
                            <Typography variant="body2">
                              companyNamecompanyNamecompanyName
                            </Typography>
                          </Skeleton>
                        )}
                      </Grid>
                      <Grid
                        xs={12}
                        container
                        alignItems="center"
                        flexDirection={{ xs: "row", sm: "row" }}
                        className="employe_personal_dtl"
                      >
                        <Typography variant="body1">
                          Account ownership type
                        </Typography>
                        {!loading ? (
                          accountOwnershipType ? (
                            <Typography variant="body2">
                              {accountOwnershipType}
                            </Typography>
                          ) : (
                            <Alert icon={false} severity="error">
                              MISSING DETAILS
                            </Alert>
                          )
                        ) : (
                          <Skeleton variant="rounded" animation="wave">
                            <Typography variant="body2">
                              companyNamecompanyNamecompanyName
                            </Typography>
                          </Skeleton>
                        )}
                      </Grid>
                      <Grid
                        xs={12}
                        container
                        alignItems="center"
                        flexDirection={{ xs: "row", sm: "row" }}
                        className="employe_personal_dtl"
                      >
                        <Typography variant="body1">Account number</Typography>
                        {!loading ? (
                          accountNumber ? (
                            <Typography variant="body2">
                              {accountNumber}
                            </Typography>
                          ) : (
                            <Alert icon={false} severity="error">
                              MISSING DETAILS
                            </Alert>
                          )
                        ) : (
                          <Skeleton variant="rounded" animation="wave">
                            <Typography variant="body2">
                              companyNamecompanyNamecompanyName
                            </Typography>
                          </Skeleton>
                        )}
                      </Grid>
                      {/* <Grid
                      xs={12}
                      container
                      alignItems="center"
                      flexDirection={{ xs: "row", sm: "row" }}
                      className="employe_personal_dtl"
                    >
                      <Typography variant="body1">Bank number</Typography>
                      {bankNumber ? (
                        <Typography variant="body2">{bankNumber}</Typography>
                      ) : (
                        <Alert icon={false} severity="error">
                          MISSING DETAILS
                        </Alert>
                      )}
                    </Grid> */}
                      <Grid
                        xs={12}
                        container
                        alignItems="center"
                        flexDirection={{ xs: "row", sm: "row" }}
                        className="employe_personal_dtl"
                      >
                        <Typography variant="body1">Full bank name</Typography>
                        {!loading ? (
                          fullbankName ? (
                            <Typography variant="body2">
                              {fullbankName}
                            </Typography>
                          ) : (
                            <Alert icon={false} severity="error">
                              MISSING DETAILS
                            </Alert>
                          )
                        ) : (
                          <Skeleton variant="rounded" animation="wave">
                            <Typography variant="body2">
                              companyNamecompanyNamecompanyName
                            </Typography>
                          </Skeleton>
                        )}
                      </Grid>
                      <Grid
                        xs={12}
                        container
                        alignItems="center"
                        flexDirection={{ xs: "row", sm: "row" }}
                        className="employe_personal_dtl"
                      >
                        <Typography variant="body1">
                          Bank branch number (IFSC)
                        </Typography>
                        {!loading ? (
                          bankBranchNumber ? (
                            <Typography variant="body2">
                              {bankBranchNumber}
                            </Typography>
                          ) : (
                            <Alert icon={false} severity="error">
                              MISSING DETAILS
                            </Alert>
                          )
                        ) : (
                          <Skeleton variant="rounded" animation="wave">
                            <Typography variant="body2">
                              companyNamecompanyNamecompanyName
                            </Typography>
                          </Skeleton>
                        )}
                      </Grid>
                      <Grid
                        xs={12}
                        container
                        alignItems="center"
                        flexDirection={{ xs: "row", sm: "row" }}
                        className="employe_personal_dtl"
                      >
                        <Typography variant="body1">
                          SWIFT (if applicable)
                        </Typography>
                        {!loading ? (
                          SWIFT ? (
                            <Typography variant="body2">{SWIFT}</Typography>
                          ) : (
                            <Alert icon={false} severity="error">
                              MISSING DETAILS
                            </Alert>
                          )
                        ) : (
                          <Skeleton variant="rounded" animation="wave">
                            <Typography variant="body2">
                              companyNamecompanyNamecompanyName
                            </Typography>
                          </Skeleton>
                        )}
                      </Grid>
                      <Grid
                        xs={12}
                        container
                        alignItems="center"
                        flexDirection={{ xs: "row", sm: "row" }}
                        className="employe_personal_dtl"
                      >
                        <Typography variant="body1">Currency</Typography>
                        {!loading ? (
                          currency ? (
                            <Typography variant="body2">{currency}</Typography>
                          ) : (
                            <Alert icon={false} severity="error">
                              MISSING DETAILS
                            </Alert>
                          )
                        ) : (
                          <Skeleton variant="rounded" animation="wave">
                            <Typography variant="body2">
                              companyNamecompanyNamecompanyName
                            </Typography>
                          </Skeleton>
                        )}
                      </Grid>
                    </div>
                  </Box>
                  <Box className="employe_profile_bx">
                    <Grid
                      container
                      alignItems="center"
                      justifyContent={"space-between"}
                      className="employe_profile_head"
                      spacing={0}
                    >
                      <Grid item>
                        <Grid container alignItems="center">
                          <Icon>
                            <TearmsAndServiceCompanySettingIcon />
                          </Icon>
                          <Typography>Terms of service</Typography>
                        </Grid>
                      </Grid>
                      {/* <Grid item alignItems="center">
                      <Button
                        className="theme_bttn transparent"
                        startIcon={<EditIcon />}
                      >
                        Edit
                      </Button>
                    </Grid> */}
                    </Grid>
                    <div className="employe_profile_body">
                      <Grid
                        xs={12}
                        container
                        alignItems="center"
                        flexDirection={{ xs: "row", sm: "row" }}
                        className="employe_personal_dtl"
                      >
                        <Typography variant="body1">
                          Terms of Service
                        </Typography>
                        {!loading ? (
                          <Typography variant="body2">
                            Check the Terms of Service
                          </Typography>
                        ) : (
                          <Skeleton variant="rounded" animation="wave">
                            <Typography variant="body2">
                              companyNamecompanyNamecompanyName
                            </Typography>
                          </Skeleton>
                        )}
                      </Grid>
                      <Grid
                        xs={12}
                        container
                        alignItems="center"
                        flexDirection={{ xs: "row", sm: "row" }}
                        className="employe_personal_dtl"
                      >
                        <Typography variant="body1">Agreed on</Typography>
                        {!loading ? (
                          agreedOn ? (
                            <Typography variant="body2">
                              {moment(agreedOn).format("llll") + "UTC"}
                              {/* Fri, 30 Sep 2022 03:00:07 UTC */}
                            </Typography>
                          ) : (
                            <Alert icon={false} severity="error">
                              MISSING DETAILS
                            </Alert>
                          )
                        ) : (
                          <Skeleton variant="rounded" animation="wave">
                            <Typography variant="body2">
                              companyNamecompanyNamecompanyName
                            </Typography>
                          </Skeleton>
                        )}
                      </Grid>
                      <Grid
                        xs={12}
                        container
                        alignItems="center"
                        flexDirection={{ xs: "row", sm: "row" }}
                        className="employe_personal_dtl"
                      >
                        <Typography variant="body1">Agreed by</Typography>
                        {!loading ? (
                          agreedBy ? (
                            <Typography variant="body2">{agreedBy}</Typography>
                          ) : (
                            <Alert icon={false} severity="error">
                              MISSING DETAILS
                            </Alert>
                          )
                        ) : (
                          <Skeleton variant="rounded" animation="wave">
                            <Typography variant="body2">
                              companyNamecompanyNamecompanyName
                            </Typography>
                          </Skeleton>
                        )}
                      </Grid>
                    </div>
                  </Box>
                  <Box className="employe_profile_bx">
                    <Grid
                      container
                      alignItems="center"
                      justifyContent={"space-between"}
                      className="employe_profile_head"
                      spacing={0}
                    >
                      <Grid item>
                        <Grid container alignItems="center">
                          <Icon>
                            <NotificationsActiveIcon />
                          </Icon>
                          <Typography>Notification</Typography>
                        </Grid>
                      </Grid>
                      {/* <Grid item alignItems="center">
                      <Button
                        className="theme_bttn transparent"
                        startIcon={<EditIcon />}
                      >
                        Edit
                      </Button>
                    </Grid> */}
                    </Grid>
                    <div className="employe_profile_body">
                      <Grid
                        xs={12}
                        container
                        alignItems="center"
                        flexDirection={{ xs: "row", sm: "row" }}
                        className="employe_personal_dtl"
                      >
                        <Typography variant="body1">
                          Send expenses notifications to
                        </Typography>
                        {!loading ? (
                          <Typography variant="body2">
                            Managers and Admins
                          </Typography>
                        ) : (
                          <Skeleton variant="rounded" animation="wave">
                            <Typography variant="body2">
                              companyNamecompanyNamecompanyName
                            </Typography>
                          </Skeleton>
                        )}
                      </Grid>
                      <Grid
                        xs={12}
                        container
                        alignItems="center"
                        flexDirection={{ xs: "row", sm: "row" }}
                        className="employe_personal_dtl"
                      >
                        <Typography variant="body1">Billing Email</Typography>
                        {!loading ? (
                          <Alert icon={false} severity="error">
                            MISSING DETAILS
                          </Alert>
                        ) : (
                          <Skeleton variant="rounded" animation="wave">
                            <Typography variant="body2">
                              companyNamecompanyNamecompanyName
                            </Typography>
                          </Skeleton>
                        )}
                      </Grid>
                      <Grid
                        xs={12}
                        container
                        alignItems="center"
                        flexDirection={{ xs: "row", sm: "row" }}
                        className="employe_personal_dtl"
                      >
                        <Typography variant="body1">
                          Send contact update notifications to
                        </Typography>
                        {!loading ? (
                          agreedBy ? (
                            <Typography variant="body2">No one</Typography>
                          ) : (
                            <Alert icon={false} severity="error">
                              MISSING DETAILS
                            </Alert>
                          )
                        ) : (
                          <Skeleton variant="rounded" animation="wave">
                            <Typography variant="body2">
                              companyNamecompanyNamecompanyName
                            </Typography>
                          </Skeleton>
                        )}
                      </Grid>
                    </div>
                  </Box>
                </Grid>
                <Grid item xs={12} lg={3} className="company_info_sidebar_grid">
                  <Box className="company_info_sidebar">
                    <Grid xs={12} className="company_info_sidebar_dtl">
                      <Typography variant="body1" gutterBottom>
                        Stats about your company
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        Below are some quick stats about your company for this
                        year.
                      </Typography>
                    </Grid>

                    <Grid xs={12} className="company_info_sidebar_dtl">
                      <Typography variant="body2" gutterBottom>
                        No. Contractor <br />
                        {!loading ? (
                          numberOfContractor
                        ) : (
                          <Skeleton variant="rounded" animation="wave">
                            <Typography variant="body2">0</Typography>
                          </Skeleton>
                        )}
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        No. employees <br />
                        {!loading ? (
                          numberOfEmployee
                        ) : (
                          <Skeleton variant="rounded" animation="wave">
                            <Typography variant="body2">0</Typography>
                          </Skeleton>
                        )}
                      </Typography>
                      {/* <Typography variant="body2" gutterBottom>
                      Total net paid to employees <br />-
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      Total paid in employer contribution <br />-
                    </Typography> */}
                    </Grid>
                    <Grid xs={12} className="company_info_sidebar_dtl">
                      <Typography variant="h6" gutterBottom>
                        If you have any questions or feedback, please contact
                        us.
                      </Typography>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={1} className="tab-pannel">
              <div className="data_table">
                <Grid container className="data_table_filters_grid">
                  <Button
                    className="page_head_bttn"
                    onClick={(e) => {
                      setModalOpen(true);
                    }}
                  >
                    Invite
                  </Button>
                </Grid>
                <div className="table_container">
                  <Table aria-label="user-and-permission-table">
                    <TableHead>
                      <TableRow>
                        <TableCell component={"td"} align="left">
                          NAME
                        </TableCell>
                        <TableCell component={"td"} align="left">
                          EMAIL
                        </TableCell>
                        <TableCell component={"td"} align="left">
                          <Grid
                            container
                            alignItems={"center"}
                            spacing={0}
                            padding={0}
                            flexWrap={"nowrap"}
                          >
                            <Grid item>ROLE</Grid>
                            <Grid item ml={1}>
                              {/* <Tooltip
                              title="ROLE encompasses rights, obligations, liabilities, and expectations associated with a position or status in a society or group, guiding individuals' behaviors and responsibilities."
                              placement="right-start"
                              arrow
                            > */}
                              <InfoOutlinedIcon />
                              {/* </Tooltip> */}
                            </Grid>
                          </Grid>
                        </TableCell>
                        <TableCell component={"td"} align="left">
                          JOB TITLE
                        </TableCell>
                        <TableCell component={"td"} align="left">
                          ASSIGNMENT
                        </TableCell>
                        <TableCell component={"td"} align="left">
                          ADDED BY
                        </TableCell>
                        <TableCell component={"td"} align="left">
                          STATUS
                        </TableCell>
                        <TableCell component={"td"} align="left">
                          ACTIONS
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {companyUsersArray.map((row) => (
                        <TableRow
                          key={row.full_name}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="left">{row.full_name}</TableCell>
                          <TableCell align="left">
                            {row.user_id.email}
                          </TableCell>
                          <TableCell align="left">{row.role_id.name}</TableCell>
                          <TableCell align="left">{row.job_title}</TableCell>
                          <TableCell align="left">{row.Assignment}</TableCell>
                          <TableCell align="left">{row.created_by}</TableCell>
                          <TableCell align="left">{row.status}</TableCell>
                          <TableCell align="left">
                            <IconButton>
                              <MoreHorizIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={totalCompanyEmployee}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={onCompanyEmployeePageChange}
                  onRowsPerPageChange={(event) => {
                    setPage(0);
                    setRowsPerPage(+event.target.value);
                  }}
                />
              </div>
            </TabPanel>
          </div>
        </Grid>
      </Slide>
      <CompanySettingModal
        modalOpen={modalOpen}
        close={(e) => {
          setModalOpen(false);
          UserListing();
        }}
      />
      <BankDetailsModal
        modalOpen={bankModalOpen}
        close={(e) => {
          setBankModalOpen(false);
          getCompanyProfile();
        }}
        accountHolderName={accountHolderName}
        accountNumber={accountNumber}
        ifsccode={bankNumber}
        bankname={fullbankName}
        swift={SWIFT}
      />
      <AddressModal
        modalOpen={addressModalOpen}
        close={(e) => {
          setAddressModalOpen(false);
          getCompanyProfile();
        }}
        country={country}
        city={city}
        address1={address1}
        address2={address2}
        postalCode={postalCode}
      />
    </>
  );
};
