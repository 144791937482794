import React, { useEffect, useState } from "react";
import { AuthSidebar, Grid, Typography } from "Components/Login/LoginImport";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Checkbox,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Slide,
  Step,
  StepLabel,
  Stepper,
  useMediaQuery,
} from "@mui/material";
import { AddContractor } from "../AddContractor1/AddContractor";
import { AddContractor2 } from "../AddContractor2/AddContractor2";
import { AddContractor3 } from "../AddContractor3/AddContractor3";
import { AddContractorPricingPlan } from "../AddContractorPricingPlan/AddContractorPricingPlan";
import {
  FinalPageEmployee_ContractorDetailsDoneIcon,
  PreviousArrowIcon,
} from "Icons/Icons";
import { InfoIcon } from "../AddContractor1/AddContractorImports";
import { AddContractorFinal } from "../AddContractorFinal/AddContractorFinal";
import { LinearProgressWithLabel } from "Utils/TabPanel";

export const AddContractorSideBar = (props) => {
  const params = useParams();
  const location = useLocation();
  const [id, setId] = useState(params.id);

  return (
    <>
      <Slide in={true} direction="right">
        <Grid container className="login_grid show_sidebar_content">
          {location.pathname !== "/addcontractorfinal" &&
          location.pathname !== `/addcontractorfinal/${id}` &&
          location.pathname !== `/addcontractorfinal/` ? (
            <AuthSidebar>
              <div className="register-sidebar addcontractor-sidebar">
                <Typography variant="h3" gutterBottom>
                  Add a new contractor
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Adding a new contractor is simple and fast. Managing
                  contractors in Inrole gives you:
                </Typography>
                <List disableRipple readOnly className="sidebar_checkbox_list">
                  {[
                    "Compliant, localized contracts",
                    " Global contractor payments",
                    " Easy invoicing tools",
                  ].map((value) => {
                    const labelId = `${value}`;

                    return (
                      <ListItem disablePadding disableRipple>
                        <ListItemButton dense disableRipple>
                          <ListItemIcon>
                            <Checkbox
                              readOnly
                              defaultChecked
                              checked="checked"
                              edge="start"
                              disableRipple
                              inputProps={{ "aria-labelledby": labelId }}
                            />
                          </ListItemIcon>
                          <ListItemText id={labelId} primary={`${value}`} />
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                </List>
                <Typography variant="h4" className="terms_condition">
                  Terms and conditions
                </Typography>
                <AddContractorStepper />
              </div>
            </AuthSidebar>
          ) : null}
          <ContractorHeader
            addContractor1={props.addContractor1}
            addContractor2={props.addContractor2}
            addContractor3={props.addContractor3}
            addContractor4={props.addContractor4}
            addContractor5={props.addContractor5}
          />
          {/* {props.addContractor1 && <AddContractor />}
        {props.addContractor2 && <AddContractor2 />}
        {props.addContractor3 && <AddContractor3 />}
        {props.addContractor4 && <AddContractorPricingPlan />} */}
        </Grid>
      </Slide>
    </>
  );
};

export const AddContractorStepper = (props) => {
  const location = useLocation();
  const params = useParams();
  const [activeStep, setActiveStep] = useState(0);
  const steps = [
    {
      label: "Basic Information",
    },
    {
      label: "Select your contract",
    },

    {
      label: "Pricing plan",
    },
  ];
  const steps1 = [
    {
      label: "Basic Information",
    },
    {
      label: "Select your contract",
    },
    {
      label: "Contract details",
    },
    {
      label: "Pricing plan",
    },
  ];

  useEffect(() => {
    if (
      location.pathname === "/addcontractor" ||
      location.pathname === "/addcontractor/" + params.id
    ) {
      setActiveStep(0);
    } else if (
      location.pathname === "/addcontractor/2" ||
      location.pathname === "/addcontractor/2/" + params.id
    ) {
      setActiveStep(1);
    } else if (
      location.pathname === "/addcontractor/3" ||
      location.pathname === "/addcontractor/3/" + params.id
    ) {
      setActiveStep(2);
    } else if (
      location.pathname === "/addcontractorpricingplan" ||
      location.pathname === "/addcontractorpricingplan/" + params.id
    ) {
      setActiveStep(3);
    } else if (
      location.pathname === "/addcontractorfinal" ||
      location.pathname === "/addcontractorfinal/" + params.id
    ) {
      setActiveStep(4);
    }
  }, [location.pathname]);

  return (
    <Stepper
      activeStep={parseInt(activeStep)}
      orientation={props.orientation ? "horizontal" : "vertical"}
      className={`register-stepper ${
        activeStep == 4 ? "all-steps-completed" : ""
      } ${props.className}`}
    >
      {!localStorage.getItem("ContractorData2")
        ? steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel>{props.noLabel ? null : step.label}</StepLabel>
            </Step>
          ))
        : steps1.map((step, index) => (
            <Step key={step.label}>
              <StepLabel>{props.noLabel ? null : step.label}</StepLabel>
            </Step>
          ))}
    </Stepper>
  );
};

export const ContractorHeader = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const tabletScreen = useMediaQuery("(max-width:899px)");
  const [progress, setProgress] = useState(0);
  var rowdata = JSON.parse(localStorage.getItem("ContractorRowData"));

  useEffect(() => {
    if (
      location.pathname === "/addcontractor" ||
      location.pathname === "/addcontractor/" + params.id
    ) {
      setProgress(20);
    } else if (
      location.pathname === "/addcontractor/2" ||
      location.pathname === "/addcontractor/2/" + params.id
    ) {
      setProgress(40);
    } else if (
      location.pathname === "/addcontractor/3" ||
      location.pathname === "/addcontractor/3/" + params.id
    ) {
      setProgress(60);
    } else if (
      location.pathname === "/addcontractorpricingplan" ||
      location.pathname === "/addcontractorpricingplan/" + params.id
    ) {
      setProgress(80);
    } else if (
      location.pathname === "/addcontractorfinal" ||
      location.pathname === "/addcontractorfinal/" + params.id
    ) {
      setProgress(100);
    }
  }, [location.pathname]);

  const goBack = () => {
    if (
      location.pathname === "/addcontractor" ||
      location.pathname === "/addcontractor/" + params.id
    ) {
      if (!id) {
        navigate("/team");
      } else {
        var data = JSON.parse(localStorage.getItem("ContractorData2"));
        var contractData = JSON.parse(localStorage.getItem("ContractorData3"));
        if (data) {
          if (data.contract_option_id !== 13) {
            navigate("/addcontractorfinal/" + id);
          } else if (contractData) {
            navigate("/addcontractorfinal/" + id);
          } else {
            navigate("/onboarding?view=contractor");
          }
        } else {
          navigate("/onboarding?view=contractor");
        }
      }
    } else if (
      location.pathname === "/addcontractor/2" ||
      location.pathname === "/addcontractor/2/" + params.id
    ) {
      if (!id) {
        navigate("/addcontractor/" + rowdata);
      } else {
        navigate("/addcontractor/" + id);
      }
    } else if (
      location.pathname === "/addcontractor/3" ||
      location.pathname === "/addcontractor/3/" + params.id
    ) {
      if (!id) {
        navigate("/addcontractor/2/" + rowdata);
      } else {
        navigate("/addcontractor/2/" + id);
      }
    } else if (
      location.pathname === "/addcontractorpricingplan" ||
      location.pathname === "/addcontractorpricingplan/" + params.id
    ) {
      var path = JSON.parse(localStorage.getItem("ContractorData2"));
      if (path.contract_option_id == 13) {
        if (!id) {
          navigate("/addcontractor/3/" + rowdata);
        } else {
          navigate("/addcontractor/3/" + id);
        }
      } else {
        if (!id) {
          navigate("/addcontractor/2/" + rowdata);
        } else {
          navigate("/addcontractor/2/" + id);
        }
      }
    } else if (
      location.pathname === "/addcontractorfinal" ||
      location.pathname === "/addcontractorfinal/" + params.id ||
      location.pathname === `/addcontractorfinal/`
    ) {
      var row_id = localStorage.getItem("ContractorRowData");
      var id = params.id;
      if (id || row_id) {
        localStorage.removeItem("ContractorData1");
        localStorage.removeItem("ContractorData2");
        localStorage.removeItem("ContractorData3");
        localStorage.removeItem("ContractorRowData");

        localStorage.removeItem("summuaryData");

        navigate("/onboarding?view=contractor");
      } else {
        navigate("/team");
      }
    } else {
      navigate("/onboarding?view=contractor");
    }
  };

  return (
    <>
      <Grid
        xs={8}
        item
        className={`login_content_grid_item ${
          props.addContractor5 ? "add_employe_final_cont" : null
        }`}
      >
        <Grid
          container
          alignItems="center"
          flexDirection={{ xs: "row", sm: "row" }}
          className="auth_top_header"
        >
          <Grid item>
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="label"
              onClick={goBack}
            >
              <PreviousArrowIcon />
            </IconButton>
            {props.addContractor1 ? "Previous" : "Back"}
          </Grid>
          <Grid item className="auth_top_header_progress">
            <LinearProgressWithLabel value={progress} />
          </Grid>
        </Grid>
        <div className="login_cont register_cont">
          {props.addContractor1 && (
            <Typography variant="h3">Basic information</Typography>
          )}
          {props.addContractor2 && (
            <>
              <Typography variant="h3">Contract options</Typography>
            </>
          )}
          {props.addContractor3 && (
            <Typography variant="h3">Contract details</Typography>
          )}

          {props.addContractor4 && (
            <Typography variant="h3">Pricing plan</Typography>
          )}

          {tabletScreen ? (
            <AddContractorStepper
              className="horizontal_Stepper"
              orientation="horizontal"
              noLabel
            />
          ) : location.pathname === "/addcontractorfinal" ||
            location.pathname === "/addcontractorfinal/" + params.id ? (
            <Grid xs={12} className="check_img_svg">
              <FinalPageEmployee_ContractorDetailsDoneIcon />
            </Grid>
          ) : null}

          {props.addContractor1 && (
            <Box className="warning_info_box">
              <Grid container>
                <Grid item className="warning_box_icon">
                  <Icon>
                    <InfoIcon />
                  </Icon>
                </Grid>
                <Grid item className="warning_box_cont">
                  <Typography variant="h3">
                    Contractor payment not supported in some countries
                  </Typography>
                  <Typography variant="body2">
                    We’re working on adding additional countries
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          )}

          {props.addContractor2 && (
            <Typography variant="body2">
              Managing your relationship with contractors in India is easy: your
              contract is fully editable in Inrole with locally compliant,
              legally reviewed contract templates specific to India. After all
              parties sign in Inrole, you're ready to go.
            </Typography>
          )}

          {props.addContractor3 && (
            <Typography variant="body2">
              Please make sure this information is accurate. We’ll craft the
              contract with the details you provide.
            </Typography>
          )}

          {props.addContractor4 && (
            <>
              <Typography variant="body2">
                No hidden fees, deposit requirements, or exclusivity clauses.
                Pay a flat fee per employee based on the country where they’ll
                be working.
              </Typography>
              <Typography variant="body2">
                <Link>View FAQs about pricing</Link>
              </Typography>
            </>
          )}

          {props.addContractor1 && <AddContractor />}
          {props.addContractor2 && <AddContractor2 />}
          {props.addContractor3 && <AddContractor3 />}
          {props.addContractor4 && <AddContractorPricingPlan />}
          {props.addContractor5 && <AddContractorFinal />}
        </div>
      </Grid>
    </>
  );
};
