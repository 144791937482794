import { getApi } from "./commonservice";

export function getOnboardingdata(
  page = 1,
  rowPerPage = 5,
  searchData = "",
  filterValue = "all",
  filterBy = "status"
) {
  if (filterValue !== "all") {
    return getApi(
      `onboarding?page=${page}&per_page=${rowPerPage}&search=${searchData}&filter_by=${filterBy}&filter_val=${filterValue}`
    );
  } else {
    return getApi(
      `onboarding?page=${page}&per_page=${rowPerPage}&search=${searchData}`
    );
  }
}

export function getOnboardingDataForContractor(
  page = 1,
  rowPerPage = 5,
  searchData = "",
  filterValue = "all",
  filterBy = "status"
) {
  if (filterValue !== "all") {
    return getApi(
      `onboarding-contractor?page=${page}&per_page=${rowPerPage}&search=${searchData}&filter_by=${filterBy}&filter_val=${filterValue}`
    );
  } else {
    return getApi(
      `onboarding-contractor?page=${page}&per_page=${rowPerPage}&search=${searchData}`
    );
  }
}
