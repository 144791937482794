import "./AddContractor3.css";
import { React, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Grid,
  IconButton,
  Typography,
  Stepper,
  Step,
  StepLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Checkbox,
  moment,
  TextField,
  InputAdornment,
  Input,
  LocalizationProvider,
  DesktopDatePicker,
  AdapterDayjs,
  getCountries,
  getStates,
} from "./AddContractor3Imports.js";
import { updateContractor } from "Services/AddEmployeeAndContractor";
import { AuthSidebar } from "Components/Login/LoginImport";
import { FormLabel } from "@mui/material";
import { HelpButton } from "Components/CommonComponents/Bottom_Help_Button/HelpButton";
import { PreviousArrowIcon } from "Icons/Icons";
import useNotification from "Components/CommonComponents/AlertNotifications/AlertNotifications";
import { DatePicker } from "@mui/x-date-pickers";
import { useSnackbar } from "notistack";
import { Error } from "Utils/Success&Errorfunctions";

export const AddContractor3 = () => {
  const navigate = useNavigate();
  const params = useParams();

  const { enqueueSnackbar } = useSnackbar();
  const [msg, sendNotification] = useNotification();

  const [progress, setProgress] = useState(50);
  const [countryArray, setCountryArray] = useState([]);
  const [stateArray, setStateArray] = useState([]);
  const [startDate, setStartDate] = useState(moment(Date.now()));
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [periodOfContract, setPeriodOfContract] = useState("");
  const [roleDescription, setRoleDescription] = useState("");
  const [monthlyFees, setMonthlyFees] = useState("");
  const [joiningDate, setJoiningDate] = useState(moment(Date.now()));

  useEffect(() => {
    fillform();
    getState();
    getcountry();
    setTimeout(async () => {
      await setProgress(75);
    }, 1000);
  }, []);

  const getState = () => {
    getStates().then(
      (res) => {
        setStateArray(res.data);
      },
      (err) => {
        throw err;
      }
    );
  };

  const getcountry = () => {
    getCountries().then(
      (res) => {
        setCountryArray(res.data);
      },
      (err) => {
        throw err;
      }
    );
  };

  const agreementDateChange = (newValue) => {
    setStartDate(newValue);
  };

  const goBack = () => {
    var id = params.id;
    var rowdata = JSON.parse(localStorage.getItem("ContractorRowData"));

    if (!id) {
      navigate("/addcontractor/2/" + rowdata);
    } else {
      navigate("/addcontractor/2/" + id);
    }
  };

  const fillform = () => {
    var contractordata = JSON.parse(localStorage.getItem("ContractorData3"));
    if (contractordata || contractordata != null) {
      setStartDate(contractordata.agreement_date);
      setPeriodOfContract(contractordata.period_of_contract);
      setRoleDescription(contractordata.role_description);
      setMonthlyFees(contractordata.monthly_contractor_fees);
      setState(contractordata.legal_jurisdiction_state);
      setCountry(contractordata.legal_jurisdiction_country_id);
    }
  };

  const submitcontractordata = (e) => {
    e.preventDefault();
    if (startDate !== null) {
      if (joiningDate !== null) {
        var agreementFormatedDate = moment(new Date(startDate)).format(
          "YYYY-MM-DD"
        );
        var joiningFormatedDate = moment(new Date(joiningDate)).format(
          "YYYY-MM-DD"
        );
        var rowdata = JSON.parse(localStorage.getItem("ContractorRowData"));
        var id = params.id;
        if (
          periodOfContract <= 0 ||
          monthlyFees <= 0 ||
          agreementFormatedDate === "Invalid date" ||
          joiningFormatedDate === "Invalid date"
        ) {
          if (periodOfContract <= 0) {
            sendNotification({
              msg: "Period of contract can't be 0 or less than 0",
              variant: "error",
            });
          } else if (monthlyFees <= 0) {
            sendNotification({
              msg: "Monthly fees can't be 0 or less than 0",
              variant: "error",
            });
          } else if (agreementFormatedDate === "Invalid date") {
            sendNotification({
              msg: "Please enter valid agreement date",
              variant: "error",
            });
          } else if (joiningFormatedDate === "Invalid date") {
            sendNotification({
              msg: "Please enter valid joining date",
              variant: "error",
            });
          }
        } else {
          if (!id) {
            var contractordata2 = JSON.parse(
              localStorage.getItem("ContractorData2")
            );
            const contractordata = {
              agreement_date: agreementFormatedDate,
              date_of_joining: joiningFormatedDate,
              period_of_contract: periodOfContract,
              role_description: roleDescription.trim(),
              monthly_contractor_fees: parseFloat(monthlyFees).toFixed(2),
              is_draft: "True",
            };
            var finaldata = {
              ...contractordata2,
              ...contractordata,
            };
            if (roleDescription.trim() !== "") {
              updateContractor(finaldata, rowdata).then(
                (res) => {
                  var variant = "success";
                  enqueueSnackbar(res.data.message, { variant });

                  localStorage.setItem(
                    "ContractorData3",
                    JSON.stringify(contractordata)
                  );
                  navigate("/addcontractorpricingplan");
                },
                (err) => {
                  var errors = Error(err.response.data.message);
                  if (Array.isArray(errors)) {
                    errors.forEach((element) => {
                      sendNotification({
                        msg: `Please enter valid ${element}`,
                        variant: "error",
                      });
                    });
                  } else {
                    sendNotification({
                      msg: err.response.data.message,
                      variant: "error",
                    });
                  }
                }
              );
            } else {
              if (roleDescription.trim() == "") {
                sendNotification({
                  msg: "Please enter valid Role Description!",
                  variant: "error",
                });
              }
            }
          } else {
            var contractordata2 = JSON.parse(
              localStorage.getItem("ContractorData2")
            );
            const contractordata = {
              agreement_date: agreementFormatedDate,
              date_of_joining: joiningFormatedDate,
              // legal_jurisdiction_state: state,
              // legal_jurisdiction_country_id: country,
              period_of_contract: periodOfContract,
              role_description: roleDescription.trim(),
              monthly_contractor_fees: parseFloat(monthlyFees).toFixed(2),
              is_draft: "True",
            };
            var finaldata = {
              ...contractordata2,
              ...contractordata,
            };
            if (roleDescription.trim() !== "") {
              updateContractor(finaldata, id).then(
                (res) => {
                  var variant = "success";
                  enqueueSnackbar(res.data.message, { variant });

                  localStorage.setItem(
                    "ContractorData3",
                    JSON.stringify(contractordata)
                  );
                  navigate("/addcontractorpricingplan/" + id);
                },
                (err) => {
                  var errors = Error(err.response.data.message);
                  if (Array.isArray(errors)) {
                    errors.forEach((element) => {
                      sendNotification({
                        msg: `Please enter valid ${element}`,
                        variant: "error",
                      });
                    });
                  } else {
                    sendNotification({
                      msg: err.response.data.message,
                      variant: "error",
                    });
                  }
                }
              );
            } else {
              if (roleDescription.trim() == "") {
                sendNotification({
                  msg: "Please enter valid role description!",
                  variant: "error",
                });
              }
            }
          }
        }
      } else {
        sendNotification({
          msg: "Please select valid joining date.",
          variant: "error",
        });
      }
    } else {
      sendNotification({
        msg: "Please select valid startDate date.",
        variant: "error",
      });
    }
  };

  return (
    <>
      <form onSubmit={submitcontractordata} className="register_form">
        <Grid container xs={12} className="date_picker_employee">
          <Grid xs={12} spacing={0}>
            <FormLabel>Agreement Date</FormLabel>
          </Grid>
          <Grid xs={12} spacing={0}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                disablePast
                minDate={startDate}
                inputFormat="YYYY/MM/DD"
                value={startDate}
                onChange={agreementDateChange}
                renderInput={(params) => (
                  <TextField varient="standard" {...params} />
                )}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>

        <Grid xs={12}>
          <TextField
            required
            fullWidth
            id="Period of contract (in months)"
            label="Period of contract (in months)"
            helperText="How long the contract last. "
            variant="standard"
            type="number"
            value={periodOfContract}
            onChange={(e) => setPeriodOfContract(e.target.value)}
            inputProps={{
              autoComplete: "off",
              min: 1,
              max: 9999999999,
            }}
          />
        </Grid>
        <Grid container xs={12} className="date_picker_employee">
          <Grid xs={12} spacing={0}>
            <FormLabel>Joining Date</FormLabel>
          </Grid>
          <Grid xs={12} spacing={0}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                disablePast
                minDate={startDate}
                inputFormat="YYYY/MM/DD"
                value={joiningDate}
                onChange={(newValue) => setJoiningDate(newValue)}
                renderInput={(params) => (
                  <TextField varient="standard" {...params} />
                )}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>

        <Grid xs={12}>
          <Typography className="inputHeader">Role description</Typography>
          <TextField
            multiline
            rows={3}
            required
            fullWidth
            id="Period of contract (in months)"
            helperText="Please add 3 tasks minimum (at least 100 characters in total). You can copy this from the job description."
            inputProps={{ maxLength: 10000, autoComplete: "off" }}
            value={roleDescription}
            onChange={(e) => setRoleDescription(e.target.value)}
          />
        </Grid>
        <Grid xsOffset={11}>
          {roleDescription && roleDescription.length < 9500 ? (
            <Typography className="counter">
              {roleDescription.length}/10000
            </Typography>
          ) : (
            <Typography className="counter danger">
              {roleDescription ? roleDescription.length : 0}/10000
            </Typography>
          )}
        </Grid>
        <Grid xs={12} className="accual_gross_salary">
          <FormLabel htmlFor="standard-adornment-amount">
            Monthly contractor fees
          </FormLabel>
          <Grid xs={12}>
            <Input
              required
              value={monthlyFees}
              type="number"
              onChange={(e) => setMonthlyFees(e.target.value)}
              id="monthlyfees"
              placeholder="999.99"
              inputProps={{
                autoComplete: "off",
                min: 1,
                max: 9999999999,
                step: "any",
              }}
              startAdornment={
                <InputAdornment position="start">INR(₹)</InputAdornment>
              }
            />
          </Grid>
        </Grid>
        <Grid xs={12} className="login_bttn_wrapper">
          <Button type="submit" className="theme_bttn fill">
            Continue
          </Button>
        </Grid>
      </form>

      <HelpButton />
    </>
  );
};
