import {
  Box,
  Button,
  FormHelperText,
  FormLabel,
  IconButton,
  Modal,
  Slide,
  TextField,
  Typography,
} from "@mui/material";
import {
  DatePicker,
  DesktopDatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import useNotification from "Components/CommonComponents/AlertNotifications/AlertNotifications";
import { CloseIcon } from "Icons/Icons";
import { updateContractor } from "Services/AddEmployeeAndContractor";
import { Error } from "Utils/Success&Errorfunctions";
import { Grid } from "Utils/muiImport";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const AddContractorContractUpdate = (props) => {
  const params = useParams();
  const navigate = useNavigate();

  const [msg, sendNotification] = useNotification();

  const [contractType, setContractType] = useState("");
  const [periodOfContract, setPeriodOfContract] = useState(0);
  const [monthlyContractorFees, setMonthlyContractorFees] = useState(0);
  const [roleDescription, setRoleDescription] = useState("");
  const [agreementDate, setAgreementDate] = useState(
    moment(Date.now()).format("YYYY/MM/DD")
  );
  const [dateOfJoining, setDateOfJoining] = useState(
    moment(Date.now()).format("YYYY/MM/DD")
  );

  useEffect(() => {
    setContractType(props.contractType);
    setPeriodOfContract(props.periodOfContract);
    setMonthlyContractorFees(props.monthlyContractFees);
    setRoleDescription(props.roleDescription);
    // console.log(props);
    if (props.agreementDate !== "") {
      setAgreementDate(props.agreementDate);
    } else {
      setAgreementDate(moment(Date.now()).format("YYYY/MM/DD"));
    }
    if (props.dateOfJoining !== "") {
      setDateOfJoining(props.dateOfJoining);
    } else {
      setDateOfJoining(moment(Date.now()).format("YYYY/MM/DD"));
    }
  }, [props.modalOpen, props.close]);

  const handleChange = (newValue) => {
    setAgreementDate(newValue);
  };
  const handleChangeDateOfJoining = (newValue) => {
    setDateOfJoining(newValue);
  };

  const submitcontractordata = (e) => {
    e.preventDefault();
    if (agreementDate !== null) {
      if (dateOfJoining !== null) {
        var agreementFormatedDate = moment(new Date(agreementDate)).format(
          "YYYY-MM-DD"
        );
        var joiningFormateDate = moment(new Date(dateOfJoining)).format(
          "YYYY-MM-DD"
        );

        var rowdata = JSON.parse(localStorage.getItem("ContractorRowData"));
        var id = params.id;
        if (
          periodOfContract <= 0 ||
          monthlyContractorFees <= 0 ||
          agreementFormatedDate === "Invalid date" ||
          roleDescription.trim() === "" ||
          joiningFormateDate === "Invalid date"
        ) {
          if (periodOfContract <= 0) {
            sendNotification({
              msg: "Period Of Contract can't be 0 or less than 0",
              variant: "error",
            });
          } else if (monthlyContractorFees <= 0) {
            sendNotification({
              msg: "Monthly fees can't be 0 or less than 0",
              variant: "error",
            });
          } else if (agreementFormatedDate === "Invalid date") {
            sendNotification({
              msg: "Please enter valid Agreement Date",
              variant: "error",
            });
          } else if (roleDescription.trim() === "") {
            sendNotification({
              msg: "Please Add Valid Role Description!",
              variant: "error",
            });
          } else if (joiningFormateDate === "Invalid date") {
            sendNotification({
              msg: "Please enter valid joining Date",
              variant: "error",
            });
          }
        } else {
          if (!id) {
            var contractordata2 = JSON.parse(
              localStorage.getItem("ContractorData2")
            );
            const contractordata = {
              agreement_date: agreementFormatedDate,
              date_of_joining: joiningFormateDate,
              period_of_contract: parseInt(periodOfContract),
              role_description: roleDescription.trim(),
              monthly_contractor_fees: parseFloat(
                monthlyContractorFees
              ).toFixed(2),
              is_draft: "True",
            };
            var finaldata = {
              ...contractordata2,
              ...contractordata,
            };
            updateContractor(finaldata, rowdata).then(
              (res) => {
                sendNotification({
                  msg: res.data.message,
                  variant: "success",
                });
                localStorage.setItem(
                  "ContractorData3",
                  JSON.stringify(contractordata)
                );

                props.close();
              },
              (err) => {
                var errors = Error(err.response.data.message);

                if (Array.isArray(errors)) {
                  errors.forEach((element) => {
                    sendNotification({
                      msg: `Please enter valid ${element}`,
                      variant: "error",
                    });
                  });
                } else {
                  sendNotification({
                    msg: err.response.data.message,
                    variant: "error",
                  });
                }
                props.close();
                if (err.response.data.message == "Contractor already invited") {
                  setTimeout(() => {
                    navigate("/onboarding?view=contractor");
                  }, 1000);
                }
              }
            );
          } else {
            var contractordata2 = JSON.parse(
              localStorage.getItem("ContractorData2")
            );
            const contractordata = {
              agreement_date: agreementFormatedDate,
              date_of_joining: joiningFormateDate,
              // legal_jurisdiction_state: state,
              // legal_jurisdiction_country_id: country,
              period_of_contract: parseInt(periodOfContract),
              role_description: roleDescription.trim(),
              monthly_contractor_fees: parseFloat(
                monthlyContractorFees
              ).toFixed(2),
              is_draft: "True",
            };
            var finaldata = {
              ...contractordata2,
              ...contractordata,
            };
            updateContractor(finaldata, id).then(
              (res) => {
                sendNotification({
                  msg: res.data.message,
                  variant: "success",
                });
                localStorage.setItem("addContractorStepper", 3);
                localStorage.setItem(
                  "ContractorData3",
                  JSON.stringify(contractordata)
                );
                props.close();
              },
              (err) => {
                var errors = Error(err.response.data.message);

                if (Array.isArray(errors)) {
                  errors.forEach((element) => {
                    sendNotification({
                      msg: `Please enter valid ${element}`,
                      variant: "error",
                    });
                  });
                } else {
                  sendNotification({
                    msg: err.response.data.message,
                    variant: "error",
                  });
                }
                props.close();
                if (err.response.data.message == "Contractor already invited") {
                  setTimeout(() => {
                    navigate("/onboarding?view=contractor");
                  }, 1000);
                }
              }
            );
          }
        }
      } else {
        sendNotification({
          msg: "Please select valid joining date",
          variant: "error",
        });
      }
    } else {
      sendNotification({
        msg: "Please select valid agreement date",
        variant: "error",
      });
    }
  };

  return (
    <>
      <Modal
        onBackdropClick={props.close}
        open={props.modalOpen}
        onClose={props.close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="add_hire_modal new_request_modal"
      >
        <Slide in={props.modalOpen}>
          <Box className="add_hire_modal_bx">
            <div className="modal_header">
              <Grid container>
                <Grid item>
                  <Typography id="modal-modal-title" variant="h6">
                    Update Contractor Contract.
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton onClick={props.close} className="icon_close_bttn">
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </div>
            <form onSubmit={(e) => submitcontractordata(e)}>
              <div className="modal_body">
                <div className="register_form">
                  <Grid xs={12}>
                    <TextField
                      required
                      fullWidth
                      disabled
                      label="Contract Type"
                      variant="standard"
                      value={contractType}
                      onChange={(e) => setContractType(e.target.value)}
                      id="Contract Type"
                      inputProps={{ maxLength: 128, autoComplete: "off" }}
                    />
                  </Grid>
                  <Grid xs={12}>
                    <TextField
                      required
                      fullWidth
                      label="Period Of Contract"
                      type="number"
                      variant="standard"
                      value={periodOfContract}
                      onChange={(e) => setPeriodOfContract(e.target.value)}
                      id="Period Of Contract"
                      inputProps={{ maxLength: 128, autoComplete: "off" }}
                    />
                  </Grid>
                  <Grid xs={12}>
                    <TextField
                      required
                      fullWidth
                      label="Monthly Contract fees"
                      type="number"
                      variant="standard"
                      value={monthlyContractorFees}
                      onChange={(e) => setMonthlyContractorFees(e.target.value)}
                      id="Monthly Contract fees"
                      inputProps={{ maxLength: 128, autoComplete: "off" }}
                    />
                  </Grid>
                  <Grid xs={12}>
                    <FormHelperText>Role description</FormHelperText>
                    <TextField
                      required
                      fullWidth
                      id="outlined-textarea"
                      helperText="Please add 3 tasks minimum (at least 100 characters in total). You can copy this from the job description."
                      rows={3}
                      multiline
                      value={roleDescription}
                      className="roledesctextfield"
                      onChange={(e) => setRoleDescription(e.target.value)}
                      inputProps={{ maxLength: 10000, required: true }}
                    />

                    <Grid xsOffset={11}>
                      {roleDescription && roleDescription.length < 9500 ? (
                        <Typography className="counter">
                          {" "}
                          {roleDescription.length}/10000
                        </Typography>
                      ) : (
                        <Typography className="counter danger">
                          {" "}
                          {roleDescription ? roleDescription.length : 0}/10000
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container xs={12} className="date_picker_employee">
                    <Grid xs={12} spacing={0}>
                      <FormLabel>Provisional start date</FormLabel>
                    </Grid>
                    <Grid xs={12} spacing={0}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          inputFormat="YYYY/MM/DD"
                          value={agreementDate}
                          disablePast
                          onChange={handleChange}
                          renderInput={(params) => (
                            <TextField varient="standard" {...params} />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                  <Grid container xs={12} className="date_picker_employee">
                    <Grid xs={12} spacing={0}>
                      <FormLabel>Joining date</FormLabel>
                    </Grid>
                    <Grid xs={12} spacing={0}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          inputFormat="YYYY/MM/DD"
                          disablePast
                          value={dateOfJoining}
                          onChange={handleChangeDateOfJoining}
                          renderInput={(params) => (
                            <TextField varient="standard" {...params} />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                </div>
              </div>

              <div className="modal_footer">
                <Grid container className="modal_foot_bttn_container">
                  <Button className="save_bttn" type="submit">
                    Update Details
                  </Button>
                  <Button className="cancel_bttn" onClick={props.close}>
                    Cancel
                  </Button>
                </Grid>
              </div>
            </form>
          </Box>
        </Slide>
      </Modal>
    </>
  );
};
