import axios from "axios";

const baseAPI = process.env.REACT_APP_DB_BASEURL;

const headers = () => {
  let token = localStorage.getItem("JWT");
  if (!token || token == null) {
    return {
      "Content-Type": "application/json",
    };
  } else {
    let token = JSON.parse(localStorage.getItem("JWT")).access_token;

    return {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };
  }
};

const formDataHeader = () => {
  let token = localStorage.getItem("JWT");
  if (!token || token == null) {
    return {
      "Content-Type": "multipart/form-data",
    };
  } else {
    let token = JSON.parse(localStorage.getItem("JWT")).access_token;

    return {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + token,
    };
  }
};

export async function getApi(endUrl, params) {
  var url = baseAPI + endUrl;
  return await axios({
    method: "GET",
    url: url,
    params: params,
    headers: headers(),
  });
}

export async function postApi(endUrl, data) {
  var url = baseAPI + endUrl;
  return await axios({
    method: "POST",
    url: url,
    data: data,
    headers: headers(),
  });
}

export async function postApiWithFormData(endUrl, data) {
  var url = baseAPI + endUrl;
  return await axios({
    method: "POST",
    url: url,
    data: data,
    headers: formDataHeader(),
  });
}

export async function putApi(endUrl, data) {
  var url = baseAPI + endUrl;
  return await axios({
    method: "PUT",
    url: url,
    data: data,
    headers: headers(),
  });
}

export async function deleteApi(endUrl, params) {
  var url = baseAPI + endUrl;
  return await axios({
    method: "DELETE",
    url: url,
    headers: headers(),
    params: params,
  });
}
