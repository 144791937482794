import {
  AuthSidebar,
  Button,
  Register2MailIllustration,
  Typography,
} from "Components/Login/LoginImport";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Grid, jwt_decode } from "../Register1/Register1Imports";
import { useAuth0 } from "@auth0/auth0-react";

export const RegisterEmailVerified = (props) => {
  const navigate = useNavigate();

  const navigation = async () => {
    await props.loginAPI();
    const JWT = JSON.parse(localStorage.getItem("JWT"));
    const DecodedJWT = jwt_decode(JWT.access_token);
    const accountType = DecodedJWT.account_type;

    localStorage.setItem("account_type", accountType);
    if (accountType === "contractor" || accountType === "employee") {
      navigate("/employeeregister");
    } else {
      navigate("/register");
    }
  };

  return (
    <Grid container spacing={2} xs={12} className="login_grid">
      <AuthSidebar>
        <div className="register-sidebar">
          <Typography variant="h3" gutterBottom>
            Sign Up with Inrole
          </Typography>
          <Typography variant="body2" gutterBottom>
            Get Started by creating your account
          </Typography>
        </div>
      </AuthSidebar>
      <Grid xs={8} item className="login_content_grid_item">
        <div className="login_cont register_cont">
          <Typography variant="h3" gutterBottom>
            Thanks for confirming your mail,
          </Typography>
          <img src={Register2MailIllustration} alt="Mail Confirmation" />
          <Typography variant="body2" gutterBottom>
            Please click on continue button to start onboarding process.
          </Typography>
          <div className="login_bttn_wrapper">
            <Button
              variant="contained"
              type="button"
              className="theme_bttn fill"
              onClick={navigation}
            >
              Continue
            </Button>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};
