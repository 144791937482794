import { Grid } from "Utils/muiImport";
import { styled, useTheme } from "@mui/material/styles";
import { Dashboard } from "Components/Dashboard/Dashboard";
import { Teams } from "Components/Teams/Teams/Teams";
import { TeamEmployeeDetails } from "Components/Teams/TeamEmployeeDetails/TeamEmployeeDetails";
import { Onboarding } from "Components/Onboardin/Onboarding";
import { Incentives } from "Components/Incentives/Incentives";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";

import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";

import enrolllogo from "../../../Assets/Svgs/InRole Logo.svg";
import search from "../../../Assets/Svgs/search.svg";
import home from "../../../Assets/Svgs/Home.svg";
import team from "../../../Assets/Svgs/Group.svg";
import onboarding from "../../../Assets/Svgs/Add User.svg";
import incentive from "../../../Assets/Svgs/Incentives.svg";
import requests from "../../../Assets/Svgs/Requests.svg";
import billing from "../../../Assets/Svgs/Billing.svg";
import settings from "../../../Assets/Svgs/Companysetting.svg";
import documents from "../../../Assets/Svgs/Documents.svg";

export {
  Grid,
  styled,
  useTheme,
  Dashboard,
  Teams,
  TeamEmployeeDetails,
  Onboarding,
  Incentives,
  CircularProgress,
  Box,
  MuiDrawer,
  List,
  CssBaseline,
  Typography,
  IconButton,
  MenuIcon,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Avatar,
  enrolllogo,
  search,
  home,
  team,
  onboarding,
  incentive,
  requests,
  billing,
  settings,
  documents,
};
//Drawer
const drawerWidth = 250;

export const openedMixin = (theme) => ({
  width: drawerWidth,

  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

export const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
