import "./Register4.css";
import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Grid,
  Button,
  FormControlLabel,
  IconButton,
  Autocomplete,
  FormControl,
  Box,
  Radio,
  RadioGroup,
} from "./Register4Imports";
import { FormLabel, Typography } from "@mui/material";
import countries from "../../../Assets/JSONs/Countries.json";
import { PreviousArrowIcon } from "Icons/Icons";
import useNotification from "Components/CommonComponents/AlertNotifications/AlertNotifications";
import { jwt_decode } from "../Register1/Register1Imports";

export const Register4 = ({ classes }) => {
  const navigate = useNavigate();
  const [msg, sendNotification] = useNotification();

  const [indexOfCountryCode, setIndexOfCountryCode] = useState(92);

  const [value, setValue] = useState();
  const [companyName, setCompanyName] = useState("");
  const [countryCode, setCountryCode] = useState("+91");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isTaxIdNumber, setIsTaxIdNumber] = useState("");
  const [registrationNumber, setRegistrationNumber] = useState("");
  const [website, setWebsite] = useState("");
  const [progress, setProgress] = useState(33);

  useEffect(() => {
    fillform();
    getEmail();
  }, []);

  const getEmail = () => {
    if (JSON.parse(localStorage.getItem("JWT")) !== null) {
      const JWT = JSON.parse(localStorage.getItem("JWT"));
      const DecodedJWT = jwt_decode(JWT.access_token);
      const accountType = DecodedJWT.account_type;
      if (accountType === "contractor" || accountType === "employee") {
        navigate("/employeeregister");
      }

      const isUserProfileUpdated = DecodedJWT.user_profile_updated;
      // console.log(isUserProfileUpdated);
      if (isUserProfileUpdated == true) {
        navigate("/register/6");
      }
    }
  };

  const submit = (e) => {
    e.preventDefault();
    const data = {
      company_name: companyName.trim(),
      country_code: countryCode,
      phone_number: phoneNumber,
      is_taxid: isTaxIdNumber.trim(),
      company_registration_number: registrationNumber.trim(),
      website: website.trim(),
    };

    if (
      companyName.trim() !== "" &&
      isTaxIdNumber.trim() !== "" &&
      registrationNumber.trim() !== "" &&
      website.trim() !== ""
    ) {
      localStorage.setItem("Registrationdata2", JSON.stringify(data));

      navigate("/register/5");
    } else {
      if (companyName.trim() == "") {
        sendNotification({
          msg: "Please enter valid company name!",
          variant: "error",
        });
      } else if (isTaxIdNumber.trim() == "") {
        sendNotification({
          msg: "Please select valid TaxID Number!",
          variant: "error",
        });
      } else if (registrationNumber.trim() == "") {
        sendNotification({
          msg: "Please enter valid Registration Number!",
          variant: "error",
        });
      } else if (website.trim() === "") {
        sendNotification({
          msg: "Please enter valid Website!",
          variant: "error",
        });
      }
    }
  };

  const handleChange = (event) => {
    setIsTaxIdNumber(event.target.value);
  };

  const fillform = () => {
    var data = JSON.parse(localStorage.getItem("Registrationdata2"));
    if (data || data != null) {
      setCompanyName(data.company_name);
      setPhoneNumber(data.phone_number);
      setIsTaxIdNumber(data.is_taxid);
      setRegistrationNumber(data.company_registration_number);
      setWebsite(data.website);
    }
  };

  function restrictAlphabets(e) {
    var x = e.which || e.keycode;
    if (x >= 48 && x <= 57) {
      return true;
    } else {
      e.preventDefault();
    }
  }
  return (
    <>
      <form onSubmit={submit} className="register_form">
        <Grid xs={12}>
          <TextField
            required
            fullWidth
            value={companyName}
            label="Company name"
            placeholder="Inrole"
            helperText="This is what will show on your invoices"
            variant="standard"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => {
              setCompanyName(e.target.value);
            }}
            inputProps={{
              required: "required",
              maxLength: 128,
              autoComplete: "off",
            }}
          />
        </Grid>
        <Grid
          container
          flexDirection={{ xs: "row", sm: "row" }}
          className="phone_input_group"
        >
          <Grid item xs={12}>
            <FormLabel required>Phone Number</FormLabel>
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              id="country-code"
              options={countries}
              defaultValue={countries[indexOfCountryCode]}
              autoHighlight
              onChange={(event, newValue) => {
                setValue(newValue.phone);
              }}
              inputValue={countryCode}
              onInputChange={(event, newInputValue) => {
                setCountryCode(newInputValue);
              }}
              getOptionLabel={(option) => " +" + option.phone}
              filterOptions={(options, { inputValue }) =>
                options.filter((option) =>
                  (option.label + " +" + option.phone)
                    .toLowerCase()
                    .includes(inputValue.toLowerCase())
                )
              }
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  <img
                    loading="lazy"
                    width="20"
                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                    alt=""
                  />
                  {option.label} +{option.phone}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  label={false}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "off",
                  }}
                  variant="standard"
                />
              )}
              className="autocomplete"
            />
          </Grid>
          <Grid item xs={9}>
            <TextField
              required
              fullWidth
              type="text"
              placeholder="9999999999"
              variant="standard"
              value={phoneNumber}
              onKeyPress={(e) => restrictAlphabets(e)}
              onChange={(e) => setPhoneNumber(e.target.value)}
              inputProps={{
                required: "required",
                maxLength: 10,
                max: "10",
                autoComplete: "off",
                pattern: "[0-9]{10}",
              }}
            />
          </Grid>
        </Grid>
        <Grid xs={12}>
          <label className="MuiInputLabel-root">
            Does your company have a VAT/EIN/GSTIN/PAN or Tax ID Number?
          </label>
          <br />
          <FormControl required>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={handleChange}
              value={isTaxIdNumber}
            >
              <FormControlLabel value="true" control={<Radio />} label="Yes" />
              <FormControlLabel value="false" control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid xs={12}>
          <TextField
            required
            fullWidth
            value={registrationNumber}
            label="Your company registration number"
            id="fullWidth"
            onChange={(e) => {
              setRegistrationNumber(e.target.value);
            }}
            inputProps={{ maxLength: 20, autoComplete: "off" }}
            helperText="This is the unique series of numbers and/or letters provided to your company when it was incorporated"
            variant="standard"
          />
        </Grid>
        <Grid xs={12}>
          <label className="MuiInputLabel-root">Website*</label>
          <br />
          <TextField
            fullWidth
            value={website}
            placeholder="Inrole"
            id="fullWidth"
            helperText="Please provide your company website URL"
            variant="standard"
            onChange={(e) => setWebsite(e.target.value)}
            inputProps={{
              required: "required",
              maxLength: 128,
              autoComplete: "off",
            }}
          />
        </Grid>
        <Grid xs={12} className="login_bttn_wrapper">
          <Button
            variant="contained"
            type="submit"
            color="primary"
            className="theme_bttn fill"
          >
            Continue
          </Button>
        </Grid>
      </form>
    </>
  );
};
