import React from "react";
import { Navigate } from "react-router-dom";
import { Guarded, NotGuarded } from "./PrivateRoute";
// import { Error404NotFound } from "Components/CommonComponents/ErrorPage/Error404NotFound";

const NotFound = (props) => {
  return (
    <>
      {/* After Login Route */}
      <Guarded isAuthenticated={props.isAuthenticated}>
        <Navigate to="/home" replace />
      </Guarded>

      {/* Before Login Route */}

      <NotGuarded isAuthenticated={props.isAuthenticated}>
        <Navigate to="/login" replace />
      </NotGuarded>
    </>
  );
};

export default NotFound;
