import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
// import { Alert, IconButton, Slide, Snackbar } from "@mui/material";
// import CloseIcon from "@mui/icons-material/Close";

const useNotification = () => {
  const [conf, setConf] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (conf?.msg) {
      let variant = "default";
      if (conf.variant) {
        variant = conf.variant;
      }
      enqueueSnackbar(conf.msg, {
        variant: variant,
      });
    }
  }, [conf]);
  return [conf, setConf];
};
export default useNotification;

// export const ErrorAlertNotifications = (props) => {
//   // if (props.errorOpen === true) {
//   //   var variant = "error";

//   //   enqueueSnackbar(props.response, {
//   //     variant,
//   //   });
//   // }

//   function TransitionRight(props) {
//     return <Slide {...props} direction="left" />;
//   }

//   return (
//     <>
//       <Snackbar
//         anchorOrigin={{ vertical: "top", horizontal: "right" }}
//         open={props.errorOpen}
//         autoHideDuration={4000}
//         onClose={props.errorClose}
//         TransitionComponent={TransitionRight}
//       >
//         <Alert
//           style={{
//             boxShadow:
//               "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)",
//           }}
//           severity="error"
//           action={
//             <IconButton
//               aria-label="close"
//               color="inherit"
//               size="small"
//               onClick={props.errorClose}
//             >
//               <CloseIcon fontSize="inherit" />
//             </IconButton>
//           }
//         >
//           {/* <AlertTitle>Error</AlertTitle> */}
//           <strong>{props.response}</strong>
//         </Alert>
//       </Snackbar>
//     </>
//   );
// };

// export const SuccessAlertNotification = (props) => {
//   function TransitionRight(props) {
//     return <Slide {...props} direction="left" />;
//   }
//   return (
//     <Snackbar
//       anchorOrigin={{ vertical: "top", horizontal: "right" }}
//       open={props.successOpen}
//       autoHideDuration={4000}
//       onClose={props.successClose}
//       TransitionComponent={TransitionRight}
//     >
//       <Alert
//         style={{
//           boxShadow:
//             "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)",
//         }}
//         severity="success"
//         action={
//           <IconButton
//             aria-label="close"
//             color="inherit"
//             size="small"
//             onClick={props.successClose}
//           >
//             <CloseIcon fontSize="inherit" />
//           </IconButton>
//         }
//       >
//         <strong>{props.response}</strong>
//       </Alert>
//     </Snackbar>
//   );
// };

// export const WarningAlertNotification = (props) => {
//   function TransitionRight(props) {
//     return <Slide {...props} direction="left" />;
//   }
//   return (
//     <Snackbar
//       anchorOrigin={{ vertical: "top", horizontal: "right" }}
//       open={props.warningOpen}
//       autoHideDuration={4000}
//       onClose={props.warningClose}
//       TransitionComponent={TransitionRight}
//     >
//       <Alert
//         style={{
//           boxShadow:
//             "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)",
//         }}
//         severity="warning"
//         action={
//           <IconButton
//             aria-label="close"
//             color="inherit"
//             size="small"
//             onClick={props.warningClose}
//           >
//             <CloseIcon fontSize="inherit" />
//           </IconButton>
//         }
//       >
//         <strong>{props.response}</strong>
//       </Alert>
//     </Snackbar>
//   );
// };

// export const InfoAlertNotification = (props) => {
//   function TransitionRight(props) {
//     return <Slide {...props} direction="left" />;
//   }
//   return (
//     <Snackbar
//       anchorOrigin={{ vertical: "top", horizontal: "right" }}
//       open={props.infoOpen}
//       autoHideDuration={4000}
//       onClose={props.infoClose}
//       TransitionComponent={TransitionRight}
//     >
//       <Alert
//         style={{
//           boxShadow:
//             "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)",
//         }}
//         severity="info"
//         action={
//           <IconButton
//             aria-label="close"
//             color="inherit"
//             size="small"
//             onClick={props.infoClose}
//           >
//             <CloseIcon fontSize="inherit" />
//           </IconButton>
//         }
//       >
//         <strong>{props.response}</strong>
//       </Alert>
//     </Snackbar>
//   );
// };
