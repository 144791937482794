import {
  Autocomplete,
  Box,
  Button,
  FormLabel,
  IconButton,
  Modal,
  Slide,
  TextField,
  Typography,
} from "@mui/material";
import {
  DatePicker,
  DesktopDatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import useNotification from "Components/CommonComponents/AlertNotifications/AlertNotifications";
import { CloseIcon } from "Icons/Icons";
import { putEmployeeData } from "Services/AddEmployeeAndContractor";
import { Grid } from "Utils/muiImport";
import moment from "moment";
import countries from "../../../../Assets/JSONs/Countries.json";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Error } from "Utils/Success&Errorfunctions";
import { useSnackbar } from "notistack";

export const AddEmployeeSummaryUpdate = (props) => {
  const navigate = useNavigate();
  const params = useParams();

  const { enqueueSnackbar } = useSnackbar();
  const [msg, sendNotification] = useNotification();

  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState();
  const [index, setIndex] = useState(0);

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [startDate, setStartDate] = useState(
    moment(Date.now()).format("YYYY/MM/DD")
  );

  useEffect(() => {
    setFullName(props.fullName);
    setEmail(props.email);
    setJobTitle(props.jobTitle);
    setStartDate(props.provisionalStartDate);
    setPhoneNumber(`${props.contactNumber}`);
    const Index = countries.findIndex(
      (country) => country.phone === props.countryCode.split("+")[1]
    );
    setIndex(Index);
  }, [props.modalOpen, props.close]);

  function restrictAlphabets(e) {
    var x = e.which || e.keycode;
    if (x >= 48 && x <= 57) {
      return true;
    } else {
      e.preventDefault();
    }
  }

  const showLoading = () => {
    setLoading(true);
  };

  const hideLoading = () => {
    setLoading(false);
  };

  const handleChange = (newValue) => {
    setStartDate(newValue);
  };

  function putEmployee(e) {
    e.preventDefault();
    showLoading();
    if (startDate !== null) {
      var formatedDate = moment(new Date(startDate)).format("YYYY-MM-DD");
      const empData = {
        full_name: fullName.trim(),
        job_title: jobTitle.trim(),
        country_code: countryCode,
        phone_number: phoneNumber,
        provisional_start_date: formatedDate,
      };
      var id = params.id;
      // console.log(phoneNumber.length);
      if (
        fullName.trim() !== "" &&
        jobTitle.trim() !== "" &&
        countryCode !== "" &&
        phoneNumber.trim().length === 10
      ) {
        if (id) {
          putEmployeeData(empData, id).then(
            (res) => {
              props.close();
              props.summuryAPI();
              hideLoading();
              var variant = "success";
              enqueueSnackbar(res.data.message, {
                variant,
              });
            },
            (err) => {
              var errors = Error(err.response.data.message);

              if (Array.isArray(errors)) {
                errors.forEach((element) => {
                  sendNotification({
                    msg: `Please enter valid ${element}`,
                    variant: "error",
                  });
                });
                hideLoading();
              } else {
                sendNotification({
                  msg: err.response.data.message,
                  variant: "error",
                });

                hideLoading();
              }
              setTimeout(() => {
                if (err.response.data.message === "Employee already invited") {
                  navigate("/onboarding");
                }
              }, 2000);
              hideLoading();
            }
          );
        } else {
          var rowID = localStorage.getItem("empRowData");
          putEmployeeData(empData, rowID).then(
            (res) => {
              const empData = {
                full_name: fullName.trim(),
                email: email,
                job_title: jobTitle.trim(),
                country_code: countryCode,
                phone_number: phoneNumber,
                provisional_start_date: formatedDate,
              };
              localStorage.setItem("Empdata1", JSON.stringify(empData));
              var variant = "success";
              enqueueSnackbar(res.data.message, {
                variant,
              });
              props.summuryAPI();
              props.close();
              hideLoading();
            },
            (err) => {
              var errors = Error(err.response.data.message);

              if (Array.isArray(errors)) {
                errors.forEach((element) => {
                  sendNotification({
                    msg: `Please enter valid ${element}`,
                    variant: "error",
                  });
                });
                hideLoading();
              } else {
                sendNotification({
                  msg: err.response.data.message,
                  variant: "error",
                });
                hideLoading();
              }
              if (err.response.data.message == "Employee already invited") {
                setTimeout(() => {
                  navigate("/onboarding");
                }, 1000);
              }
              hideLoading();
            }
          );
        }
      } else {
        if (fullName.trim() === "") {
          sendNotification({
            msg: "Please enter valid Name!",
            variant: "error",
          });
        } else if (jobTitle.trim() === "") {
          sendNotification({
            msg: "Please enter valid Job Title!",
            variant: "error",
          });
        } else if (countryCode === "") {
          sendNotification({
            msg: "Please Select Country Code!",
            variant: "error",
          });
        } else if (phoneNumber.trim().length !== 10) {
          sendNotification({
            msg: "Please enter valid Contact Number!",
            variant: "error",
          });
        }
      }
    } else {
      sendNotification({
        msg: "Please enter valid date!",
        variant: "error",
      });
    }
  }

  return (
    <>
      <Modal
        onBackdropClick={props.close}
        open={props.modalOpen}
        onClose={props.close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="add_hire_modal new_request_modal"
      >
        <Slide in={props.modalOpen}>
          <Box className="add_hire_modal_bx">
            <div className="modal_header">
              <Grid container>
                <Grid item>
                  <Typography id="modal-modal-title" variant="h6">
                    Update Employee Summary.
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton onClick={props.close} className="icon_close_bttn">
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </div>
            <form onSubmit={(e) => putEmployee(e)}>
              <div className="modal_body">
                <div className="register_form">
                  <Grid>
                    <TextField
                      fullWidth
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                      id="fullName"
                      label="Full Name"
                      helperText="Full employee name as it appears on identification document"
                      variant="standard"
                      inputProps={{ autoComplete: "off", maxLength: 128 }}
                    />
                  </Grid>
                  <Grid>
                    <TextField
                      fullWidth
                      disabled
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      id="email"
                      type="email"
                      label="Email"
                      helperText="E.g. jane@gmail.com"
                      variant="standard"
                      inputProps={{ autoComplete: "off", maxLength: 128 }}
                    />
                  </Grid>
                  <Grid
                    container
                    flexDirection={{ xs: "row", sm: "row" }}
                    className="phone_input_group"
                  >
                    <Grid item xs={12}>
                      <FormLabel required>Phone Number</FormLabel>
                    </Grid>
                    <Grid item xs={3}>
                      <Autocomplete
                        id="country-code"
                        options={countries}
                        defaultValue={countries[index]}
                        autoHighlight
                        onChange={(event, newValue) => {
                          setValue(newValue.phone);
                        }}
                        inputValue={countryCode}
                        onInputChange={(event, newInputValue) => {
                          setCountryCode(newInputValue);
                        }}
                        getOptionLabel={(option) => " +" + option.phone}
                        filterOptions={(options, { inputValue }) =>
                          options.filter((option) =>
                            (option.label + " +" + option.phone)
                              .toLowerCase()
                              .includes(inputValue.toLowerCase())
                          )
                        }
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            <img
                              loading="lazy"
                              width="20"
                              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                              alt=""
                            />
                            {option.label} +{option.phone}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            required
                            {...params}
                            label={false}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "off",
                            }}
                            variant="standard"
                          />
                        )}
                        className="autocomplete"
                      />
                    </Grid>
                    <Grid item xs={9}>
                      <TextField
                        required
                        fullWidth
                        type="text"
                        placeholder="9999999999"
                        variant="standard"
                        value={phoneNumber}
                        onKeyPress={(e) => restrictAlphabets(e)}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        inputProps={{
                          required: "required",
                          maxLength: 10,
                          max: "10",
                          autoComplete: "off",
                          pattern: "[0-9]{10}",
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid xs={12}>
                    <TextField
                      required
                      fullWidth
                      label="Job title"
                      variant="standard"
                      helperText="E.g. product designer"
                      value={jobTitle}
                      onChange={(e) => setJobTitle(e.target.value)}
                      id="email"
                      inputProps={{ maxLength: 128, autoComplete: "off" }}
                    />
                  </Grid>
                  <Grid container xs={12} className="date_picker_employee">
                    <Grid xs={12} spacing={0}>
                      <FormLabel>Provisional start date</FormLabel>
                    </Grid>
                    <Grid xs={12} spacing={0}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          inputFormat="YYYY/MM/DD"
                          disablePast
                          value={startDate}
                          onChange={handleChange}
                          renderInput={(params) => (
                            <TextField varient="standard" {...params} />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                </div>
              </div>

              <div className="modal_footer">
                {/* <a href="https://api.inrole.dev/media/pqr.jpeg" _download>
              Download File.
            </a> */}
                <Grid container className="modal_foot_bttn_container">
                  <Button className="save_bttn" type="submit">
                    Update Details
                  </Button>
                  <Button className="cancel_bttn" onClick={props.close}>
                    Cancel
                  </Button>
                </Grid>
              </div>
            </form>
          </Box>
        </Slide>
      </Modal>
    </>
  );
};
