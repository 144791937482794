import { getApi, postApi, postApiWithFormData } from "./commonservice";

export function uploadEmployeeDocuments(data) {
  return postApiWithFormData("company-document-employee", data);
}
export function uploadCompanyDocuments(data) {
  return postApiWithFormData("company-document", data);
}

export function documentDownload(data) {
  return postApi("download-doc", data);
}

export function documentListingForCompany(
  page = 1,
  rowPerPage = 5,
  searchData = "",
  typeOfDocument = "",
  filterBy = "document_type"
) {
  var companyID = localStorage.getItem("CompanyRowID");
  if (typeOfDocument !== "all") {
    return getApi(
      `company-document-list/${companyID}?page=${page}&per_page=${rowPerPage}&search=${searchData}&filter_by=${filterBy}&filter_val=${typeOfDocument}`
    );
  } else {
    return getApi(
      `company-document-list/${companyID}?page=${page}&per_page=${rowPerPage}&search=${searchData}`
    );
  }
}

export function documentListingForEmployee(
  empID,
  page = 1,
  rowPerPage = 5,
  searchData = ""
) {
  return getApi(
    `employee-document/${empID}?page=${page}&per_page=${rowPerPage}&search=${searchData}`
  );
}

export function documentListingForContractor(
  cotractorID,
  page = 1,
  rowPerPage = 5,
  searchData = ""
) {
  return getApi(
    `contractor-document/${cotractorID}?page=${page}&per_page=${rowPerPage}&search=${searchData}`
  );
}

export function documentListngForEmployeeAndContractor(
  page = 1,
  rowPerPage = 5,
  searchData = "",
  typeOfDocument = "",
  filterBy = "document_type"
) {
  if (typeOfDocument !== "all") {
    return getApi(
      `emp-contractor-doc-list?page=${page}&per_page=${rowPerPage}&search=${searchData}&filter_by=${filterBy}&filter_val=${typeOfDocument}`
    );
  } else {
    return getApi(
      `emp-contractor-doc-list?page=${page}&per_page=${rowPerPage}&search=${searchData}`
    );
  }
}

export function getEmployeeAndContractorDocumentsType() {
  return getApi("doc-types");
}
