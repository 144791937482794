import { getApi, postApi } from "./commonservice";

export function getRequestList(
  page = 1,
  rowPerPage = 5,
  search = "",
  statusValue = "approved",
  status = "status"
) {
  if (statusValue !== "all") {
    return getApi(
      `company-requests?per_page=${rowPerPage}&page=${page}&search=${search}&filter_by=${status}&filter_val=${statusValue}`
    );
  } else {
    return getApi(
      `company-requests?per_page=${rowPerPage}&page=${page}&search=${search}`
    );
  }
}

export function postRequest(data) {
  return postApi("company-request", data);
}
