import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  MenuItem,
  Modal,
  Slide,
  TextField,
  Typography,
} from "@mui/material";
import { CloseIcon } from "Icons/Icons";
import useNotification from "Components/CommonComponents/AlertNotifications/AlertNotifications";
import { updateAddressDetails } from "Services/CompanyServices";
import { getCountries } from "Services/Login&Registrationservice";
import { Error } from "Utils/Success&Errorfunctions";
import { Grid } from "Utils/muiImport";
import jwtDecode from "jwt-decode";
import React, { useEffect, useState } from "react";

export const AddressModal = (props) => {
  const [msg, sendNotification] = useNotification();

  const [countryArray, setCountryArray] = useState([]);

  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");

  useEffect(() => {
    getCountry();

    var selectedCountry = countryArray.filter((data) => {
      return data.name == props.country;
    });

    var countryselect = selectedCountry.map((data) => data.row_id);
    setCountry(countryselect[0]);
    setCity(props.city);
    setAddress1(props.address1);
    setAddress2(props.address2);
    setPostalCode(props.postalCode);
  }, [props.modalOpen, props.close]);

  const getCountry = () => {
    getCountries().then(
      (res) => {
        setCountryArray(res.data);
      },
      (err) => {
        throw err;
      }
    );
  };

  const updateAddress = (e) => {
    e.preventDefault();
    var addressData = {
      country_id: country,
      city: city.trim(),
      postal_code: postalCode.trim(),
      address1: address1.trim(),
      address2: address2.trim(),
    };
    if (city.trim() != "" && postalCode.trim() != "" && address1.trim() != "") {
      var companyID = localStorage.getItem("CompanyRowID");
      updateAddressDetails(addressData, companyID).then(
        (res) => {
          sendNotification({
            msg: res.data.message,
            variant: "success",
          });
          props.close();
        },
        (err) => {
          var errors = Error(err.response.data.message);

          if (Array.isArray(errors)) {
            errors.forEach((element) => {
              sendNotification({
                msg: `Please enter valid ${element}`,
                variant: "error",
              });
            });
          } else {
            sendNotification({
              msg: err.response.data.message,
              variant: "error",
            });
          }
        }
      );
    } else {
      if (city.trim() == "") {
        sendNotification({
          msg: "Please enter valid City",
          variant: "error",
        });
      } else if (address1.trim() == "") {
        sendNotification({
          msg: "Please enter valid address line 1",
          variant: "error",
        });
      } else if (postalCode.trim() == "") {
        sendNotification({
          msg: "Please enter valid postal code",
          variant: "error",
        });
      }
    }
  };

  return (
    <>
      <Modal
        onBackdropClick={props.close}
        open={props.modalOpen}
        onClose={props.close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="add_hire_modal new_request_modal"
      >
        <Slide in={props.modalOpen}>
          <Box className="add_hire_modal_bx">
            <div className="modal_header">
              <Grid container>
                <Grid item>
                  <Typography id="modal-modal-title" variant="h6">
                    Update Address Details
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton onClick={props.close} className="icon_close_bttn">
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </div>
            <form onSubmit={(e) => updateAddress(e)}>
              <div className="modal_body">
                <div className="register_form">
                  <Grid xs={12} className="modal_select_employe_dropdown">
                    <TextField
                      fullWidth
                      required
                      select
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                      id="country"
                      variant="standard"
                      label="Country"
                    >
                      {countryArray.map((data) => (
                        <MenuItem value={data.row_id} key={data.name}>
                          {data.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid>
                    <TextField
                      fullWidth
                      required
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                      id="city"
                      label="City"
                      variant="standard"
                      inputProps={{
                        autoComplete: "off",
                        maxLength: 128,
                      }}
                    />
                  </Grid>
                  <Grid>
                    <TextField
                      fullWidth
                      required
                      value={address1}
                      onChange={(e) => setAddress1(e.target.value)}
                      id="address1"
                      label="Address 1"
                      variant="standard"
                      inputProps={{ autoComplete: "off", maxLength: 128 }}
                    />
                  </Grid>
                  <Grid>
                    <TextField
                      fullWidth
                      value={address2}
                      onChange={(e) => setAddress2(e.target.value)}
                      id="address2"
                      label="Address 2"
                      variant="standard"
                      inputProps={{ autoComplete: "off", maxLength: 128 }}
                    />
                  </Grid>
                  <Grid>
                    <TextField
                      fullWidth
                      value={postalCode}
                      onChange={(e) => setPostalCode(e.target.value)}
                      id="postalcode"
                      label="Postal Code"
                      variant="standard"
                      inputProps={{ autoComplete: "off", maxLength: 11 }}
                    />
                  </Grid>
                </div>
              </div>
              <div className="modal_footer">
                <Grid container className="modal_foot_bttn_container">
                  <Button className="theme_bttn fill" type="submit">
                    Update Details
                  </Button>
                  <Button className="theme_bttn" onClick={props.close}>
                    Cancel
                  </Button>
                </Grid>
              </div>
            </form>
          </Box>
        </Slide>
      </Modal>
    </>
  );
};
