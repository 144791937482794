import {
  Button,
  IconButton,
  MenuItem,
  Modal,
  Slide,
  TextField,
  Typography,
  Zoom,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Grid } from "Utils/muiImport";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { CloseIcon } from "Icons/Icons";
import { getAllEmployee } from "Services/AddEmployeeAndContractor";
import { postRequest } from "Services/Requestservice";
import { data } from "jquery";
import useNotification from "Components/CommonComponents/AlertNotifications/AlertNotifications";
import { useSnackbar } from "notistack";
import { Error } from "Utils/Success&Errorfunctions";

export const RequestModal = (props) => {
  const [msg, sendNotification] = useNotification();

  const [loading, setLoading] = useState(false);

  const [allEmployeeArray, setAllEmployeeArray] = useState([]);
  const [employee, setEmployee] = useState("");
  const [note, setNote] = useState("");

  useEffect(() => {
    getEmployee();
    setEmployee("");
    setNote("");
  }, [props.open, props.close]);

  const showLoading = () => {
    setLoading(true);
  };

  const hideLoading = () => {
    setLoading(false);
  };

  const getEmployee = () => {
    getAllEmployee(1, 9999).then(
      (res) => {
        setAllEmployeeArray(res.data.data);
      },
      (err) => {
        console.log(err.message);
      }
    );
  };

  const requestPost = (e) => {
    e.preventDefault();
    showLoading();
    var data = {
      emp_id: employee,
      note: note.trim(),
    };

    if (note.trim() != "" && employee != "") {
      postRequest(data).then(
        (res) => {
          sendNotification({
            msg: res.data.message,
            variant: "success",
          });

          props.allRequests();
          hideLoading();
          props.close();
        },
        (err) => {
          var errors = Error(err.response.data.message);

          if (Array.isArray(errors)) {
            errors.forEach((element) => {
              sendNotification({
                msg: `Please enter valid ${element}`,
                variant: "error",
              });
            });
            hideLoading();
          } else {
            sendNotification({
              msg: err.response.data.message,
              variant: "error",
            });
            hideLoading();
          }
          hideLoading();
        }
      );
    } else {
      if (employee == "") {
        sendNotification({
          msg: "Please select an Employee!",
          variant: "error",
        });
        hideLoading();
      } else if (note.trim() == "") {
        sendNotification({
          msg: "Please add valid Note!",
          variant: "error",
        });
        hideLoading();
      }
    }
  };

  return (
    <>
      <Modal
        onBackdropClick={props.close}
        open={props.modalOpen}
        onClose={props.close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="add_hire_modal"
      >
        <Slide in={props.modalOpen}>
          <Box className="add_hire_modal_bx">
            <div className="modal_header">
              <Grid container>
                <Grid item>
                  <Typography id="modal-modal-title" variant="h6">
                    New Request
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton onClick={props.close} className="icon_close_bttn">
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </div>

            <form onSubmit={(e) => requestPost(e)}>
              <div className="modal_body">
                <div className="register_form">
                  <Grid xs={12}>
                    <Box className="new_request_modal_cont">
                      <Grid container>
                        <Grid xs={2} md={1}>
                          <InfoOutlinedIcon />
                        </Grid>
                        <Grid xs={10} md={11}>
                          <Typography>
                            You can request employee changes, information, or
                            documents from our team. Select an employee to get
                            started.
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid
                    xs={12}
                    className="register_form modal_select_employe_dropdown"
                  >
                    <TextField
                      required
                      variant="standard"
                      select
                      label="Employee"
                      fullWidth
                      value={employee}
                      onChange={(e) => setEmployee(e.target.value)}
                    >
                      {allEmployeeArray.length ? (
                        allEmployeeArray.map((data) => (
                          <MenuItem
                            value={data.emp_id.row_id}
                            key={data.emp_id.row_id}
                            className="request-modal-employe-listitem"
                          >
                            <span>
                              {data.emp_id.full_name}
                            </span>
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem disabled>
                          There Are No Active Employees
                        </MenuItem>
                      )}
                    </TextField>
                  </Grid>
                  <Grid xs={12}>
                    <TextField
                      required
                      variant="standard"
                      label="Note"
                      fullWidth
                      value={note}
                      onChange={(e) => setNote(e.target.value)}
                      inputProps={{ autoComplete: "off", maxLength: 250 }}
                    />
                  </Grid>
                </div>
              </div>

              <div className="modal_footer">
                <Grid container className="modal_foot_bttn_container">
                  <Button
                    className="save_bttn"
                    type="submit"
                    disabled={loading}
                  >
                    Submit
                  </Button>
                  <Button className="cancel_bttn" onClick={props.close}>
                    Cancel
                  </Button>
                </Grid>
              </div>
            </form>
          </Box>
        </Slide>
      </Modal>
    </>
  );
};
