import {
  Box,
  Button,
  IconButton,
  Modal,
  Radio,
  RadioGroup,
  Slide,
  Typography,
} from "@mui/material";
import useNotification from "Components/CommonComponents/AlertNotifications/AlertNotifications";
import { CloseIcon } from "Icons/Icons";
import { updateContractor } from "Services/AddEmployeeAndContractor";
import { Grid } from "Utils/muiImport";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const AddContractorSelectContract = (props) => {
  const params = useParams();

  const [msg, sendNotification] = useNotification();

  const [contractOptions, setContractOptions] = useState([]);

  const [contractOption, setContractOption] = useState("");

  useEffect(() => {
    var contractOption = JSON.parse(localStorage.getItem("Contract_options"));
    if (contractOption !== null) {
      setContractOptions(contractOption);
    } else {
      setContractOptions([]);
    }
  }, []);

  const submit = (e) => {
    e.preventDefault();
    var id = params.id;
    const data = {
      contract_option_id: parseInt(contractOption),
      is_draft: "True",
    };

    updateContractor(data, id).then(
      (res) => {
        sendNotification({
          msg: res.data.message,
          variant: "success",
        });

        props.close();
      },
      (err) => {
        sendNotification({
          msg: err.response.data.message,
          variant: "error",
        });
      }
    );
  };

  return (
    <>
      <Modal
        onBackdropClick={props.close}
        open={props.modalOpen}
        onClose={props.close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="add_hire_modal new_request_modal"
      >
        <Slide in={props.modalOpen}>
          <Box className="add_hire_modal_bx">
            <div className="modal_header">
              <Grid container>
                <Grid item>
                  <Typography id="modal-modal-title" variant="h6">
                    Update Contractor Summary.
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton onClick={props.close} className="icon_close_bttn">
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </div>
            <form onSubmit={(e) => submit(e)}>
              <div className="modal_body">
                <div className="register_form">
                  <Grid xs={12}>
                    <RadioGroup
                      required
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      onChange={(e) => {
                        setContractOption(e.target.value);
                      }}
                      className="register_form_card_group"
                    >
                      {contractOptions.map((data) => (
                        <Box
                          className="register_form_card"
                          key={data.row_id}
                          alignItems="center"
                        >
                          <Grid
                            fullWidth
                            container
                            className="register_form_card_grid"
                            spacing={0}
                            alignItems="center"
                            justifyContent={"space-between"}
                          >
                            <Grid item xs={10}>
                              <Typography variant="h4">{data.value}</Typography>
                              <Typography variant="body2">
                                {data.description}
                              </Typography>
                            </Grid>
                            <Radio value={data.row_id} />
                          </Grid>
                        </Box>
                      ))}
                    </RadioGroup>
                  </Grid>
                </div>
              </div>
              <div className="modal_footer">
                {/* <a href="https://api.inrole.dev/media/pqr.jpeg" _download>
          Download File.
        </a> */}
                {!contractOption ? (
                  <>
                    <Grid xs={12} className="login_bttn_wrapper">
                      <Button
                        disabled
                        variant="contained"
                        type="submit"
                        color="primary"
                        className="theme_bttn fill"
                      >
                        Continue
                      </Button>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid xs={12} className="login_bttn_wrapper">
                      <Button
                        variant="contained"
                        type="submit"
                        color="primary"
                        className="theme_bttn fill"
                      >
                        Continue
                      </Button>
                    </Grid>
                  </>
                )}
              </div>
            </form>
          </Box>
        </Slide>
      </Modal>
    </>
  );
};
