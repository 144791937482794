import "./EmpRegister5.css";
import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormHelperText,
  FormLabel,
  IconButton,
  Input,
  InputLabel,
  List,
  ListItem,
  ListSubheader,
  MenuItem,
  Select,
  Slide,
  TextField,
  Typography,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Verifyidentity from "../../../Assets/Svgs/Verifyidentity.svg";
import {
  contractorDocument,
  employeedocs,
} from "Services/AddEmployeeAndContractor";
import { EmployeeDocumentRegisterLogo, PreviousArrowIcon } from "Icons/Icons";
import { UploadFile } from "@mui/icons-material";
import useNotification from "Components/CommonComponents/AlertNotifications/AlertNotifications";
import { getEmployeeAndContractorDocumentsType } from "Services/DocumentServices";
import { useSnackbar } from "notistack";
import { Error } from "Utils/Success&Errorfunctions";
export const EmpRegister5 = () => {
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();
  const [msg, sendNotification] = useNotification();

  const [loading, setLoading] = useState(false);

  const [state, setState] = useState([]);
  const [documentTypeArray, setDocumentTypeArray] = useState([]);
  const [documentType, setDocumentType] = useState("");
  const [tempDocumentType, setTempDocumentType] = useState("");
  const [file, setFile] = useState([]);
  const [documentNo, setDocumentNo] = useState("");
  const [fileSize, setFileSize] = useState("");
  const [fileError, setFileError] = useState(false);

  const goBack = () => {
    navigate("/employeeregister");
  };

  useEffect(() => {
    getDocuments();
  }, []);

  useEffect(() => {
    validateSelectedFile();
  }, [file.name]);

  useEffect(() => {
    if (documentType == "1") {
      setTempDocumentType("Drivers Licence");
    } else if (documentType == "2") {
      setTempDocumentType("Passport");
    } else if (documentType == "4") {
      setTempDocumentType("Pan Card");
    } else if (documentType == "3") {
      setTempDocumentType("AadharCard");
    } else if (documentType == "5") {
      setTempDocumentType("Voter ID");
    }
  }, [documentType]);

  const UploadFile = (e) => {
    if (e.target.files && e.target.files.length) {
      setFile(e.target.files[0]);
      validateSelectedFile();
    }
  };

  const showLoading = () => {
    setLoading(true);
  };

  const hideLoading = () => {
    setLoading(false);
  };

  const getDocuments = () => {
    getEmployeeAndContractorDocumentsType().then(
      (res) => {
        setDocumentTypeArray(res.data);
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const data = (e) => {
    showLoading();
    e.preventDefault();
    var data = new FormData();
    var imagedata = file;
    data.append("file_data", imagedata);
    data.append("doc_type", documentType);
    data.append("document_no", documentNo.trim());
    if (documentNo.trim() != "") {
      if (localStorage.getItem("account_type") !== "contractor") {
        employeedocs(data).then(
          (res) => {
            var variant = "success";
            enqueueSnackbar(res.data.message, { variant });
            navigate("/employeeregister");
            hideLoading();
          },
          (err) => {
            var errors = Error(err.response.data.message);

            if (Array.isArray(errors)) {
              errors.forEach((element) => {
                sendNotification({
                  msg: `Please enter valid ${element}`,
                  variant: "error",
                });
              });
              hideLoading();
            } else {
              sendNotification({
                msg: err.response.data.message,
                variant: "error",
              });
              hideLoading();
            }
            hideLoading();
          }
        );
      } else {
        contractorDocument(data).then(
          (res) => {
            var variant = "success";
            enqueueSnackbar(res.data.message, { variant });
            navigate("/employeeregister");
            hideLoading();
          },
          (err) => {
            var errors = Error(err.response.data.message);

            if (Array.isArray(errors)) {
              errors.forEach((element) => {
                sendNotification({
                  msg: `Please enter valid ${element}`,
                  variant: "error",
                });
              });
              hideLoading();
            } else {
              sendNotification({
                msg: err.response.data.message,
                variant: "error",
              });
              hideLoading();
            }
            hideLoading();
          }
        );
      }
    } else {
      sendNotification({
        msg: "Please enter valid document number!",
        variant: "error",
      });

      hideLoading();
    }
  };

  const validateSelectedFile = () => {
    const MAX_FILE_SIZE = 2048;

    if (!file) {
      sendNotification({
        msg: "Please choose a file",
        variant: "error",
      });
      return;
    }

    const fileSizeKiloBytes = file.size / 1024;
    setFileSize(fileSizeKiloBytes * 0.001);

    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      setFileError(true);
      sendNotification({
        msg: "File size is greater than maximum limit of 2MB",
        variant: "error",
      });
    } else {
      setFileError(false);
    }
  };

  return (
    <>
      <Slide direction="left" in={true}>
        <Grid xs={8} item className="login_content_grid_item">
          <Grid
            container
            alignItems="center"
            flexDirection={{ xs: "row", sm: "row" }}
            className="auth_top_header"
          >
            <Grid item>
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="label"
                onClick={goBack}
              >
                <PreviousArrowIcon />
              </IconButton>
              Previous
            </Grid>
          </Grid>
          <div className="login_cont register_cont">
            <Grid xs={12}>
              <Typography variant="h3">Verify your identity</Typography>
            </Grid>

            <Grid xs={12} className="empidentity_verification_form">
              <form className="register_form">
                <Grid xs={12}>
                  <InputLabel id="demo-simple-select-helper-label">
                    Document Type
                  </InputLabel>
                  <Select
                    required
                    fullWidth
                    variant="standard"
                    value={documentType}
                    label="Document Type"
                    onChange={(e) => setDocumentType(e.target.value)}
                  >
                    {documentTypeArray.map((data) => (
                      <MenuItem value={data.row_id}>{data.name}</MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    Which Document would you like to upload?
                  </FormHelperText>
                </Grid>
                {documentType ? (
                  <>
                    <Grid xs={12}>
                      <TextField
                        required
                        label={
                          documentType
                            ? tempDocumentType + "  Number"
                            : "Number"
                        }
                        variant="standard"
                        value={documentNo}
                        onChange={(e) => setDocumentNo(e.target.value)}
                        inputProps={{
                          autocomplete: "off",
                          maxLength: 12,
                          pattern: "^[a-zA-Z0-9]{12}$",
                        }}
                        fullWidth
                      />
                    </Grid>
                    <Grid xs={12} className="file_upload">
                      <Button
                        className={"theme_bttn "}
                        component="label"
                        startIcon={
                          !file.name ? <CloudUploadIcon /> : <CheckCircleIcon />
                        }
                      >
                        {!file.name ? "Upload" : file.name}
                        <input
                          hidden
                          type="file"
                          required
                          onChange={UploadFile}
                          accept="image/*,.pdf,.doc,.docx"
                        />
                      </Button>
                    </Grid>
                  </>
                ) : null}{" "}
                {file.name ? (
                  <>
                    {fileError ? (
                      <Grid item className="dotted_lighting_bx variant_red">
                        <Typography variant="body1">
                          File size is {fileSize.toFixed(2)} MB
                        </Typography>
                      </Grid>
                    ) : (
                      <Grid item className="dotted_lighting_bx variant_green">
                        <Typography variant="body1">
                          File size is {fileSize.toFixed(2)} MB
                        </Typography>
                      </Grid>
                    )}
                  </>
                ) : (
                  <>
                    <Grid item className="dotted_lighting_bx variant_green">
                      <Typography variant="body1">
                        Maximum File Size 2MB
                      </Typography>
                    </Grid>
                    <Grid item className="dotted_lighting_bx variant_green">
                      <Typography variant="body1">
                        Supported File Format: pdf,doc,Jpeg,JPG,PNG.
                      </Typography>
                    </Grid>
                  </>
                )}
              </form>
            </Grid>

            <Grid container xs={12} mt={5} mb={5}></Grid>
            <Grid xs={12}>
              <Typography variant="h5" textAlign={"start"}>
                Government ID
              </Typography>
              <List>
                <ListItem>Clear and readable.</ListItem>
                <ListItem>Good lighting helps.</ListItem>
                <ListItem>
                  Not too close to expiration date Picture required, no
                  screenshots or copies of any kind{" "}
                </ListItem>
              </List>
            </Grid>
            <Grid className="login_bttn_wrapper">
              {/* {file.name ? ( */}
              <Button
                disabled={fileError || loading || file.length <= 0}
                type="button"
                className="theme_bttn fill"
                onClick={data}
                startIcon={
                  loading ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : null
                }
              >
                Save & Continue
              </Button>
            </Grid>
          </div>
        </Grid>
      </Slide>
    </>
  );
};
