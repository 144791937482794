import "./Billing.css";
import { React, useEffect, useState } from "react";

import { Button, Grid, Typography } from "Utils/muiImport";

import {
  Divider,
  IconButton,
  Modal,
  TextField,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  List,
  ListSubheader,
  ListItem,
  Switch,
  ListItemText,
  ButtonGroup,
  Slide,
  Zoom,
  Collapse,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";

import { HelpButton } from "Components/CommonComponents/Bottom_Help_Button/HelpButton";
import { BillingTableIcon, FilterIcon, TeamEditIcon } from "Icons/Icons";
import { getBillingList } from "Services/Billingservice";
import moment from "moment";
import DownloadSharpIcon from "@mui/icons-material/DownloadSharp";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { documentDownload } from "Services/DocumentServices";
import useNotification from "Components/CommonComponents/AlertNotifications/AlertNotifications";
import { useSnackbar } from "notistack";
import { TransitionGroup } from "react-transition-group";

export const Billing = (props) => {
  const downloadURL = process.env.REACT_APP_DOWNLOAD_DOCUMENT;

  const { enqueueSnackbar } = useSnackbar();
  const [msg, sendNotification] = useNotification();

  const [loading, setLoading] = useState(true);
  const [allBillList, setAllBillList] = useState([]);
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [pageForBilling, setPageForBilling] = useState(0);

  const [editDialog, setEditDialog] = useState(false);
  const [filterDialog, setFilterDialog] = useState(false);

  const [billingSearch, setBillingSearch] = useState("");
  const [filterColumn, setfilterColumn] = useState("");
  const [checked, setChecked] = useState([
    "Invoice Number",
    "Status",
    "Issued By",
    "Invoice Amount",
    "Applied Credits",
    "Amount",
    "Amount Due",
    "Created Date",
    "Period",
    "Actions",
  ]);
  const [rowName, setRowName] = useState([
    "Invoice Number",
    "Status",
    "Issued By",
    "Invoice Amount",
    "Applied Credits",
    "Amount",
    "Amount Due",
    "Created Date",
    "Period",
    "Actions",
  ]);
  const [filterStatus, setFilterStatus] = useState(["All", "Paid", "Unpaid"]);
  const [checkedForFilterStatus, setCheckedForFilterStatus] = useState(["All"]);

  useEffect(() => {
    const getData = setTimeout(() => {
      getAllBillList();
    }, 200);

    return () => clearTimeout(getData);
  }, [rowsPerPage, billingSearch, checkedForFilterStatus]);

  const showLoading = () => {
    setLoading(true);
  };
  const hideLoading = () => {
    setLoading(false);
  };

  const editDialogToggle = () => {
    setEditDialog(!editDialog);
  };

  function handleToggle(value) {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  }

  const getAllBillList = (pageNo = 1) => {
    showLoading();

    getBillingList(
      pageNo,
      rowsPerPage,
      "asc",
      "invoice_amount",
      billingSearch,
      checkedForFilterStatus[0].toLowerCase()
    ).then(
      (res) => {
        setAllBillList(res.data.data);
        setTotal(res.data.total);
        hideLoading();
      },
      (err) => {
        props.unAuthorized(err.response.status);
        setAllBillList([]);
        setTotal(0);
        hideLoading();
      }
    );
  };

  const onIncentivePageChange = (event, newPage) => {
    setPageForBilling(newPage);

    getAllBillList(newPage + 1);
  };

  function handleToggleForFilterStatus(value) {
    var currentIndex = checkedForFilterStatus.indexOf(value);
    var newChecked = [...checkedForFilterStatus];
    if (currentIndex === -1) {
      newChecked.pop();
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    if (newChecked.length <= 0) {
      setCheckedForFilterStatus(["All"]);
    } else {
      setCheckedForFilterStatus(newChecked);
    }
  }

  const viewImage = (name, link, index, doc_id) => {
    showLoading();
    var downloadData = {
      document_name: name,
      file_path: link,
      doc_id: doc_id,
    };
    documentDownload(downloadData).then(
      (res) => {
        var downloadLink = downloadURL + link;
        window.open(downloadLink, "_blank", "noopener,noreferrer");

        hideLoading();
      },
      (err) => {
        sendNotification({
          msg: err.response.data.message,
          variant: "error",
        });

        hideLoading();
      }
    );
  };

  const downloadImage = async (imageSrc) => {
    await fetch(imageSrc)
      .then((response) => response.blob())
      .then((blob) => {
        let blobUrl = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.download = imageSrc.replace(/^.*[\\\/]/, "");
        a.href = blobUrl;
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  };

  const documentLink = (name, link, index, doc_id) => {
    showLoading();
    var downloadData = {
      document_name: name,
      file_path: link,
      doc_id: doc_id,
    };
    documentDownload(downloadData).then(
      (res) => {
        var downloadLink = downloadURL + link;
        downloadImage(downloadLink);
        var variant = "success";
        enqueueSnackbar(res.data.message, { variant });
        hideLoading();
      },
      (err) => {
        sendNotification({
          msg: err.response.data.message,
          variant: "error",
        });
        hideLoading();
      }
    );
  };

  return (
    <>
      <Slide in={true} direction="left">
        <Grid item className={props.className}>
          <Grid container className="team_top_heading">
            <Grid item>
              <Typography className="page_heading">Billing</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" className="page_head_para">
                Find all your past payments, check their status and access
                invoices. Manage payment methods
              </Typography>
            </Grid>
          </Grid>

          <div
            className={`data_table ${!allBillList.length && "no_data_found"}`}
          >
            <Grid container className="data_table_filters_grid">
              <TextField
                spellCheck="false"
                value={billingSearch}
                onChange={(e) => setBillingSearch(e.target.value)}
                className="outer_searchfilled"
                placeholder="Search"
                id="outlined-start-adornment"
                InputProps={{
                  autoComplete: "off",
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />

              <div className="table_edit_controller">
                <Grid container className="filter_group">
                  <IconButton onClick={(e) => setFilterDialog(!filterDialog)}>
                    <FilterIcon />
                  </IconButton>
                  <Typography>Filter</Typography>
                </Grid>{" "}
                {filterDialog ? (
                  <Zoom in={filterDialog}>
                    <div className="edit_filter_menu is_small">
                      <Button
                        className="edit_filter_menu_close"
                        onClick={() => setFilterDialog(!filterDialog)}
                        disableRipple
                      ></Button>
                      <div className="edit_filter_menu_bx">
                        <List
                          subheader={<ListSubheader>Filter Data</ListSubheader>}
                        >
                          {filterStatus.map((data, index) => (
                            <ListItem key={index}>
                              <Switch
                                edge="end"
                                onChange={() =>
                                  handleToggleForFilterStatus(data)
                                }
                                checked={
                                  checkedForFilterStatus.indexOf(data) !== -1
                                }
                                className={
                                  checkedForFilterStatus.indexOf(data) !== -1
                                    ? "active"
                                    : "inactive"
                                }
                                inputProps={{
                                  "aria-labelledby": data,
                                }}
                              />
                              <ListItemText
                                id={data}
                                primary={data}
                                disableTypography
                              />
                            </ListItem>
                          ))}
                        </List>
                      </div>
                    </div>
                  </Zoom>
                ) : null}
              </div>

              <div className="table_edit_controller">
                <IconButton onClick={editDialogToggle}>
                  <TeamEditIcon />
                </IconButton>
                {editDialog ? (
                  <Zoom in={editDialog}>
                    <div className="edit_filter_menu">
                      <Button
                        className="edit_filter_menu_close"
                        onClick={editDialogToggle}
                        disableRipple
                      ></Button>
                      <div className="edit_filter_menu_bx">
                        <TextField
                          placeholder="Search Columns"
                          fullWidth
                          value={filterColumn}
                          onChange={(e) => setfilterColumn(e.target.value)}
                          InputProps={{
                            autoComplete: "off",
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            ),
                          }}
                        />{" "}
                        <List
                          subheader={
                            <ListSubheader>Original Columns</ListSubheader>
                          }
                        >
                          {" "}
                          <TransitionGroup>
                            {rowName
                              .filter((data) => {
                                return filterColumn.toLowerCase() === ""
                                  ? data
                                  : data
                                      .toLowerCase()
                                      .includes(filterColumn.toLowerCase());
                              })
                              .map((data, index) => (
                                <Collapse key={data}>
                                  <ListItem key={index}>
                                    <Switch
                                      edge="end"
                                      onChange={() => handleToggle(data)}
                                      checked={checked.indexOf(data) !== -1}
                                      className={
                                        checked.indexOf(data) !== -1
                                          ? "active"
                                          : "inactive"
                                      }
                                      inputProps={{
                                        "aria-labelledby": data,
                                      }}
                                    />
                                    <ListItemText
                                      id={data}
                                      primary={data}
                                      disableTypography
                                    />
                                  </ListItem>
                                </Collapse>
                              ))}
                          </TransitionGroup>
                        </List>
                      </div>
                    </div>
                  </Zoom>
                ) : null}
              </div>
            </Grid>
            <div className="table_container">
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {checked.indexOf("Invoice Number") >= 0 ? (
                      <TableCell component={"td"} align="left">
                        Invoice Number
                      </TableCell>
                    ) : null}
                    {checked.indexOf("Status") >= 0 ? (
                      <TableCell component={"td"} align="left">
                        Status
                      </TableCell>
                    ) : null}
                    {checked.indexOf("Issued By") >= 0 ? (
                      <TableCell component={"td"} align="left">
                        Issued By
                      </TableCell>
                    ) : null}
                    {checked.indexOf("Invoice Amount") >= 0 ? (
                      <TableCell component={"td"} align="left">
                        Invoice Amount
                      </TableCell>
                    ) : null}
                    {checked.indexOf("Applied Credits") >= 0 ? (
                      <TableCell component={"td"} align="left">
                        Applied Credits
                      </TableCell>
                    ) : null}
                    {checked.indexOf("Amount") >= 0 ? (
                      <TableCell component={"td"} align="left">
                        Amount
                      </TableCell>
                    ) : null}
                    {checked.indexOf("Amount Due") >= 0 ? (
                      <TableCell component={"td"} align="left">
                        Amount Due
                      </TableCell>
                    ) : null}
                    {checked.indexOf("Created Date") >= 0 ? (
                      <TableCell component={"td"} align="left">
                        Created Date
                      </TableCell>
                    ) : null}
                    {checked.indexOf("Period") >= 0 ? (
                      <TableCell component={"td"} align="left">
                        Period
                      </TableCell>
                    ) : null}
                    {checked.indexOf("Actions") >= 0 ? (
                      <TableCell component={"td"} align="left">
                        Actions
                      </TableCell>
                    ) : null}
                  </TableRow>
                </TableHead>
                {allBillList.length && !loading ? (
                  <TableBody>
                    {allBillList.map((data, index) => (
                      <TableRow id={data.row_id} key={data.row_id}>
                        {checked.indexOf("Invoice Number") >= 0 ? (
                          <TableCell>{data.invoice_number}</TableCell>
                        ) : null}
                        {checked.indexOf("Status") >= 0 ? (
                          <TableCell>{data.status}</TableCell>
                        ) : null}
                        {checked.indexOf("Issued By") >= 0 ? (
                          <TableCell>{data.issue_by}</TableCell>
                        ) : null}
                        {checked.indexOf("Invoice Amount") >= 0 ? (
                          <TableCell>{data.invoice_amount}</TableCell>
                        ) : null}
                        {checked.indexOf("Applied Credits") >= 0 ? (
                          <TableCell>{data.applied_credits}</TableCell>
                        ) : null}
                        {checked.indexOf("Amount") >= 0 ? (
                          <TableCell>{data.amount}</TableCell>
                        ) : null}
                        {checked.indexOf("Amount Due") >= 0 ? (
                          <TableCell>{data.amount_due}</TableCell>
                        ) : null}
                        {checked.indexOf("Created Date") >= 0 ? (
                          <TableCell>
                            {moment(data.created_date).format("DD-MM-YYYY")}
                          </TableCell>
                        ) : null}
                        {checked.indexOf("Period") >= 0 ? (
                          <TableCell>{data.period}</TableCell>
                        ) : null}
                        {checked.indexOf("Actions") >= 0 ? (
                          <TableCell>
                            <ButtonGroup className="action_bttn_group">
                              <IconButton
                                color="dark"
                                variant="text"
                                onClick={() => {
                                  viewImage(
                                    data.invoice_file_name,
                                    data.download_link,
                                    index,
                                    data.row_id
                                  );
                                }}
                              >
                                <VisibilityIcon />
                              </IconButton>

                              <IconButton
                                color="dark"
                                variant="text"
                                onClick={() =>
                                  documentLink(
                                    data.invoice_file_name,
                                    data.download_link,
                                    index,
                                    data.row_id
                                  )
                                }
                              >
                                <DownloadSharpIcon color="dark" />
                              </IconButton>
                            </ButtonGroup>
                          </TableCell>
                        ) : null}
                      </TableRow>
                    ))}
                  </TableBody>
                ) : !loading ? (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={10}>
                        <Zoom in={!allBillList.length}>
                          <Grid className="data_table_inner_notfound">
                            <BillingTableIcon />
                            <Typography>Sorry! No results</Typography>
                            <Typography variant="body2">
                              We’ll let you know when you have new invoices
                              breakdown,
                              <br />
                              and details to check
                            </Typography>
                          </Grid>
                        </Zoom>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={10}>
                        <Grid className="data_table_inner_notfound">
                          <CircularProgress />
                        </Grid>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </div>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={total}
              rowsPerPage={rowsPerPage}
              page={pageForBilling}
              onPageChange={onIncentivePageChange}
              onRowsPerPageChange={(event) => {
                setPageForBilling(0);
                setRowsPerPage(+event.target.value);
              }}
            />
          </div>
        </Grid>
      </Slide>
      <HelpButton />
    </>
  );
};
