import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  IconButton,
  Input,
  InputAdornment,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Slide,
  TextField,
  Typography,
} from "@mui/material";
import useNotification from "Components/CommonComponents/AlertNotifications/AlertNotifications";
import { CloseIcon } from "Icons/Icons";
import {
  getCurrencies,
  getbenifit,
  updateEmployee,
} from "Services/AddEmployeeAndContractor";
import { Grid } from "Utils/muiImport";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CurrencyConverter } from "../CurrencyModal/CurrencyConverter";
import { Error } from "Utils/Success&Errorfunctions";
import { useSnackbar } from "notistack";

export const AddEmployeeContractUpdate = (props) => {
  const params = useParams();
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();
  const [msg, sendNotification] = useNotification();

  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [currency, setCurrency] = useState([]);
  const [Experience, setExperience] = useState([]);
  const [allBenifits, setAllBenifits] = useState([]);
  const [empprovidantFund, setEmpprovidantFund] = useState([]);

  const [total, setTotal] = useState(0);

  const [errorOpen, setErrorOpen] = useState(false);

  const [contractDuration, setContractDuration] = useState("");
  const [termsOfContract, setTermsOfContract] = useState("");

  const [probationPeriod, setProbationPeriod] = useState("");
  const [typeOfEmployee, setTypeOfEmployee] = useState("");
  const [workingHours, setWorkingHours] = useState("");
  const [annualSalary, setAnnualSalary] = useState("");
  const [signingBonus, setSigningBonus] = useState("");
  const [signingBonusText, setSigningBonusText] = useState("");
  const [otherBonus, setOtherBonus] = useState("");
  const [otherBonusText, setOtherBonusText] = useState("");
  const [comission, setComission] = useState("");
  const [comissionText, setComissionText] = useState("");
  const [paidLeaves, setPaidLeaves] = useState("");
  const [roleDescription, setRoleDescription] = useState("");
  const [superviserName, setSuperviserName] = useState("");
  const [experienceLevel, setExperienceLevel] = useState("");
  const [nonSolicitationClause, setNonSolicitationClause] = useState("No");
  const [benefits, setBenefits] = useState(false);
  const [employeeProvidentFund, setEmployeeProvidentFund] = useState("");

  useEffect(() => {
    getExperience();
    if (props.contractDuration == null) {
      setContractDuration("indefinite");
    } else {
      setContractDuration(props.contractDuration);
    }
    if (props.termsOfContract !== null) {
      setTermsOfContract(props.termsOfContract);
    }
    setProbationPeriod(props.probationPeriod);
    if (props.typeOfEmployee !== null) {
      setTypeOfEmployee(props.typeOfEmployee);
    } else {
      setTypeOfEmployee("full-time");
    }
    setWorkingHours(props.workingHours);
    setAnnualSalary(props.annualSalary);
    if (props.signingBonus == "Yes") {
      setSigningBonus("True");
      setSigningBonusText(props.signingBonusValue);
    } else {
      setSigningBonus("False");
      setSigningBonusText("");
    }
    if (props.otherBonus == "Yes") {
      setOtherBonus("True");
      setOtherBonusText(props.otherBonusvalue);
    } else {
      setOtherBonus("False");
      setOtherBonusText();
    }
    if (props.comission == "Yes") {
      setComission("True");
      setComissionText(props.comissionBonusValue);
    } else {
      setComission("False");
      setComissionText();
    }
    setPaidLeaves(props.paidLeaves);
    if (props.roleDescription !== null) {
      setRoleDescription(props.roleDescription);
    } else {
      setRoleDescription("");
    }
    if (props.superviserName != null) {
      setSuperviserName(props.superviserName);
    } else {
      setSuperviserName("");
    }

    if (props.experienceLevel != "") {
      var row_id = Experience.filter((value) => {
        return value.value === props.experienceLevel;
      });

      var data = row_id.map((data) => data.row_id);
      setExperienceLevel(data[0]);
    } else {
      setExperienceLevel("4");
    }
    setNonSolicitationClause(props.nonSolicitationClause);

    setBenefits(props.benifits);

    var data = empprovidantFund.filter((data) => {
      return data.value === props.employeeProvidentFund;
    });

    var row = data.map((data) => data.row_id);
    if (row[0] != undefined) {
      setEmployeeProvidentFund(row[0]);
    } else {
      setEmployeeProvidentFund("");
    }
    getbenifits();
  }, [props.modalOpen, props.close]);

  const showLoading = () => {
    setLoading(true);
  };

  const hideLoading = () => {
    setLoading(false);
  };

  const getExperience = () => {
    if (JSON.parse(localStorage.getItem("experience_level")) !== null) {
      setExperience(JSON.parse(localStorage.getItem("experience_level")));
    } else {
      setExperience([]);
    }
    if (JSON.parse(localStorage.getItem("employee_provident_fund")) !== null) {
      setEmpprovidantFund(
        JSON.parse(localStorage.getItem("employee_provident_fund"))
      );
    } else {
      setEmpprovidantFund([]);
    }
  };

  const getbenifits = () => {
    getbenifit().then(
      (res) => {
        let tempTotal = 0;
        res.data.map((value) => {
          if (value.mandatory) tempTotal += value.price;
          value.checked = value.mandatory;
        });
        setAllBenifits(res.data);
        setTotal(tempTotal);
      },
      (err) => {
        console.log(err.message);
      }
    );
  };

  const onBenifitsChecked = (e, index) => {
    allBenifits[index].checked = e.target.checked;
    setAllBenifits(allBenifits);
    if (e.target.checked) {
      let tempTotal = total;
      setTotal(tempTotal + allBenifits[index].price);
    } else {
      let tempTotal = total;
      setTotal(tempTotal - allBenifits[index].price);
    }
  };

  const getCurrenctConversion = (data) => {
    getCurrencies(data).then(
      (res) => {
        setCurrency(res.data);
      },
      (err) => {
        console.log(err.message);
      }
    );
  };

  const changetext = () => {
    if (signingBonus == "False") {
      setSigningBonusText("");
    }
    if (otherBonus == "False") {
      setOtherBonusText("");
    }
    if (comission == "False") {
      setComissionText("");
    }
  };

  const submtnewemployee = (e) => {
    e.preventDefault();

    let otherArray = [];
    allBenifits.map((val) => {
      if (val.checked) {
        otherArray.push({ benefit_id: val.row_id });
      }
    });
    if (contractDuration === "fixed-term" && termsOfContract <= 0) {
      setErrorOpen(true);
      sendNotification({
        msg: "Terms of Contract cant't be 0",
        variant: "error",
      });
      hideLoading();
    } else if (probationPeriod <= 0) {
      setErrorOpen(true);
      sendNotification({
        msg: "Probation Period cant't be less than 0",
        variant: "error",
      });
      hideLoading();
    } else if (workingHours <= 0) {
      setErrorOpen(true);
      sendNotification({
        msg: "Working Hours cant't be less than 0",
        variant: "error",
      });
      hideLoading();
    } else if (annualSalary <= 0) {
      setErrorOpen(true);
      sendNotification({
        msg: "Annual Salary cant't be less than 0",
        variant: "error",
      });
      hideLoading();
    } else if (signingBonus == "True" && signingBonusText <= 0) {
      setErrorOpen(true);
      sendNotification({
        msg: "Signing Bonus cant't be less than 0",
        variant: "error",
      });
      hideLoading();
    } else if (otherBonus == "True" && otherBonusText <= 0) {
      setErrorOpen(true);
      sendNotification({
        msg: "Other Bonus cant't be less than 0",
        variant: "error",
      });
      hideLoading();
    } else if (comission == "True" && comissionText <= 0) {
      setErrorOpen(true);
      sendNotification({
        msg: "Comission Bonus cant't be less than 0",
        variant: "error",
      });
      hideLoading();
    } else if (paidLeaves < 27 || paidLeaves > 365) {
      setErrorOpen(true);
      sendNotification({
        msg: `Paid Leaves cant't be less than 27 or greater than 365`,
        variant: "error",
      });
      hideLoading();
    } else if (roleDescription.trim() === "") {
      setErrorOpen(true);
      sendNotification({
        msg: "Please enter valid role description!",
        variant: "error",
      });
      hideLoading();
    } else if (superviserName.trim() === "") {
      setErrorOpen(true);
      sendNotification({
        msg: "Please enter valid supervisor name!",
        variant: "error",
      });

      hideLoading();
    } else if (errorOpen == false) {
      const empData = {
        contract_duration: contractDuration,
        term_of_contract: parseInt(termsOfContract),
        probation_period: probationPeriod,
        type_of_employee: typeOfEmployee,
        work_hours: workingHours,
        annual_gross_salary: annualSalary,
        employee_provident_fund: employeeProvidentFund,
        is_signing_bonus: signingBonus,
        signing_bonus: signingBonusText,
        is_other_bonuses: otherBonus,
        other_bonuses: otherBonusText,
        is_commission: comission,
        commission: comissionText,

        role_description: roleDescription.trim(),
        supervisor_name: superviserName.trim(),
        experience_level: experienceLevel,

        non_solicitation: nonSolicitationClause,
        benefits: benefits,
        paid_time_off_days: parseInt(paidLeaves),
        employee_benefits: otherArray,
      };

      var row_id = localStorage.getItem("empRowData");
      var id = params.id;

      const Summary = JSON.parse(localStorage.getItem("Empdata1"));

      const Masterdata = {
        ...Summary,
        ...empData,
        is_draft: "True",
      };

      if (!id) {
        updateEmployee(Masterdata, row_id).then(
          (res) => {
            localStorage.setItem("Empdata2", JSON.stringify(empData));

            var variant = "success";
            enqueueSnackbar(res.data.message, { variant });
            props.close();
            props.summuryAPI();
          },
          (err) => {
            var errors = Error(err.response.data.message);
            if (Array.isArray(errors)) {
              errors.forEach((element) => {
                sendNotification({
                  msg: `Please enter valid ${element}`,
                  variant: "error",
                });
              });
              hideLoading();
            } else {
              sendNotification({
                msg: err.response.data.message,
                variant: "error",
              });

              hideLoading();
            }
            if (err.response.data.message == "Employee already invited") {
              setTimeout(() => {
                navigate("/onboarding");
              }, 1000);
            }
          }
        );
      } else {
        updateEmployee(Masterdata, id).then(
          (res) => {
            var variant = "success";
            enqueueSnackbar(res.data.message, { variant });
            props.close();
            props.summuryAPI();
          },
          (err) => {
            setErrorOpen(true);
            var errors = Error(err.response.data.message);
            if (Array.isArray(errors)) {
              errors.forEach((element) => {
                sendNotification({
                  msg: `Please enter valid ${element}`,
                  variant: "error",
                });
              });
              hideLoading();
            } else {
              sendNotification({
                msg: err.response.data.message,
                variant: "error",
              });

              hideLoading();
            }

            setTimeout(() => {
              if (err.response.data.message === "Employee already invited") {
                navigate("/onboarding");
              }
            }, 2000);
          }
        );
      }
    } else {
      var row_id = localStorage.getItem("empRowData");
      var id = params.id;
      const empData = {
        contract_duration: contractDuration,
        term_of_contract: parseInt(termsOfContract),
        probation_period: probationPeriod,
        type_of_employee: typeOfEmployee,
        work_hours: workingHours,
        annual_gross_salary: annualSalary,
        employee_provident_fund: employeeProvidentFund,
        is_signing_bonus: signingBonus,
        signing_bonus: signingBonusText,
        is_other_bonuses: otherBonus,
        other_bonuses: otherBonusText,
        is_commission: comission,
        commission: comissionText,

        role_description: roleDescription.trim(),
        supervisor_name: superviserName.trim(),
        experience_level: experienceLevel,

        non_solicitation: nonSolicitationClause,
        benefits: benefits,
        paid_time_off_days: parseInt(paidLeaves),
        employee_benefits: otherArray,
      };

      const Summary = JSON.parse(localStorage.getItem("Empdata1"));
      const Masterdata = {
        ...Summary,
        ...empData,
        is_draft: "True",
      };

      if (!id) {
        updateEmployee(Masterdata, row_id).then(
          (res) => {
            localStorage.setItem("Empdata2", JSON.stringify(empData));

            var variant = "success";
            enqueueSnackbar(res.data.message, { variant });
            props.close();
            props.summuryAPI();
          },
          (err) => {
            var errors = Error(err.response.data.message);
            if (Array.isArray(errors)) {
              errors.forEach((element) => {
                sendNotification({
                  msg: `Please enter valid ${element}`,
                  variant: "error",
                });
              });
              hideLoading();
            } else {
              sendNotification({
                msg: err.response.data.message,
                variant: "error",
              });

              hideLoading();
            }
            if (err.response.data.message == "Employee already invited") {
              setTimeout(() => {
                navigate("/onboarding");
              }, 1000);
            }
          }
        );
      } else {
        updateEmployee(Masterdata, id).then(
          (res) => {
            var variant = "success";
            enqueueSnackbar(res.data.message, { variant });
            props.close();
            props.summuryAPI();
          },
          (err) => {
            setErrorOpen(true);
            var errors = Error(err.response.data.message);
            if (Array.isArray(errors)) {
              errors.forEach((element) => {
                sendNotification({
                  msg: `Please enter valid ${element}`,
                  variant: "error",
                });
              });
              hideLoading();
            } else {
              sendNotification({
                msg: err.response.data.message,
                variant: "error",
              });

              hideLoading();
            }

            setTimeout(() => {
              if (err.response.data.message === "Employee already invited") {
                navigate("/onboarding");
              }
            }, 2000);
          }
        );
      }
    }
  };
  const changeTermsOfContract = () => {
    if (contractDuration === "indefinite") {
      setTermsOfContract("");
    }
  };

  return (
    <>
      <Modal
        onBackdropClick={props.close}
        open={props.modalOpen}
        onClose={props.close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="add_hire_modal new_request_modal"
      >
        <Slide in={props.modalOpen}>
          <Box className="add_hire_modal_bx">
            <div className="modal_header">
              <Grid container>
                <Grid item>
                  <Typography id="modal-modal-title" variant="h6">
                    Update Employee Contract.
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton onClick={props.close} className="icon_close_bttn">
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </div>
            <form onSubmit={(e) => submtnewemployee(e)}>
              <div className="modal_body">
                <div className="register_form">
                  <Grid xs={12}>
                    <FormLabel
                      id="demo-radio-buttons-group-label"
                      className="lable_color_theme"
                    >
                      Contract duration
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      value={contractDuration}
                      name="radio-buttons-group"
                      onChange={(e) => {
                        setContractDuration(e.target.value);
                        changeTermsOfContract();
                      }}
                    >
                      <Grid
                        container
                        spacing={0}
                        className="justify_content_start"
                      >
                        <FormControlLabel
                          value="indefinite"
                          control={<Radio />}
                          label="Indefinite"
                          spacing={0}
                        />
                        <FormControlLabel
                          value="fixed-term"
                          control={<Radio />}
                          label="Fixed Term"
                          spacing={0}
                        />
                      </Grid>
                    </RadioGroup>
                    {contractDuration === "fixed-term" && (
                      <Grid xs={12}>
                        <TextField
                          required
                          fullWidth
                          type="number"
                          placeholder="Term Of Contract."
                          variant="standard"
                          value={termsOfContract}
                          onChange={(e) => setTermsOfContract(e.target.value)}
                          id="probationperiod"
                          inputProps={{
                            max: 99,
                            autoComplete: "off",
                            min: 1,
                            maxLength: 2,
                            pattern: "/^d*$/",
                          }}
                          className="lable_color_theme"
                        />
                      </Grid>
                    )}
                    <FormHelperText className="helper_color_theme">
                      Learn more about contract duration
                    </FormHelperText>
                  </Grid>

                  <Grid xs={12}>
                    <TextField
                      required
                      fullWidth
                      type="number"
                      label="Probation period (in months)"
                      variant="standard"
                      helperText="How would you describe their status in their selected country?"
                      value={probationPeriod}
                      onChange={(e) => setProbationPeriod(e.target.value)}
                      id="probationperiod"
                      inputProps={{
                        max: 99,
                        autoComplete: "off",
                        min: 1,
                        maxLength: 2,
                        pattern: "/^d*$/",
                      }}
                      className="lable_color_theme"
                    />
                    <FormHelperText className="helper_color_theme">
                      Learn more about probation period
                    </FormHelperText>
                  </Grid>

                  <Grid xs={12}>
                    <FormLabel
                      id="demo-radio-buttons-group-label"
                      className="lable_color_theme"
                    >
                      Type of employee
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      value={typeOfEmployee}
                      name="radio-buttons-group"
                      onChange={(e) => setTypeOfEmployee(e.target.value)}
                    >
                      <Grid container>
                        <FormControlLabel
                          value="full-time"
                          control={<Radio />}
                          label="Full-time"
                        />
                        <FormControlLabel
                          value="part-time"
                          control={<Radio />}
                          label="Part-time"
                        />
                      </Grid>
                    </RadioGroup>
                  </Grid>

                  <Grid xs={12}>
                    <TextField
                      required
                      type="number"
                      fullWidth
                      label="Work hours (in months)"
                      variant="standard"
                      value={workingHours}
                      onChange={(e) => setWorkingHours(e.target.value)}
                      id="workingHours"
                      inputProps={{
                        max: 999,
                        autoComplete: "off",
                        min: 0,
                        maxLength: 10,
                        pattern: "/^d*$/",
                      }}
                      className="lable_color_theme"
                    />
                  </Grid>

                  <Grid xs={12} className="accual_gross_salary">
                    <FormLabel>Annual gross salary</FormLabel>
                    <Grid xs={12}>
                      <Input
                        type="number"
                        value={annualSalary}
                        onChange={(e) => setAnnualSalary(e.target.value)}
                        placeholder="Annual gross salary"
                        id="standard-adornment-amount"
                        inputProps={{
                          max: 99999999999999,
                          autoComplete: "off",
                          min: 1,
                          maxLength: 10,
                          pattern: "/^d*$/",
                        }}
                        startAdornment={
                          <InputAdornment position="start">INR</InputAdornment>
                        }
                      />
                    </Grid>
                    <FormHelperText
                      id="standard-weight-helper-text"
                      className="helper_color_theme"
                    >
                      See Conversion
                      <Link
                        className="modalLink"
                        onClick={() => {
                          setModalOpen(true);
                          getCurrenctConversion(annualSalary);
                        }}
                      >
                        see here
                      </Link>
                    </FormHelperText>
                  </Grid>
                  <Grid xs={12}>
                    <TextField
                      label="Employee Provident Fund"
                      onChange={(e) => {
                        setEmployeeProvidentFund(e.target.value);
                      }}
                      value={employeeProvidentFund}
                      fullWidth
                      id="standard-select-currency-native"
                      select
                      defaultValue="EUR"
                      variant="standard"
                    >
                      {empprovidantFund.map((option) => (
                        <MenuItem key={option.value} value={option.row_id}>
                          {option.value}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid xs={12}>
                    <FormLabel
                      id="demo-radio-buttons-group-label"
                      className="lable_color_theme"
                    >
                      Signing bonus
                    </FormLabel>
                    <FormHelperText>
                      Do you want to include a one-time signing bonus?
                    </FormHelperText>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      value={signingBonus}
                      name="radio-buttons-group"
                      onChange={(e) =>
                        setSigningBonus(e.target.value, changetext())
                      }
                    >
                      <Grid container>
                        <FormControlLabel
                          value="True"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="False"
                          control={<Radio />}
                          label="No"
                        />
                      </Grid>
                    </RadioGroup>
                  </Grid>

                  {signingBonus == "True" ? (
                    <Grid xs={12}>
                      <Input
                        type="number"
                        value={signingBonusText}
                        onChange={(e) => setSigningBonusText(e.target.value)}
                        id="standard-adornment-weight"
                        startAdornment={
                          <InputAdornment position="end">INR</InputAdornment>
                        }
                        aria-describedby="standard-weight-helper-text"
                        inputProps={{
                          max: 99999,
                          autoComplete: "off",
                          min: 0,
                          maxLength: 10,
                          pattern: "/^d*$/",
                        }}
                      />
                      <FormHelperText id="standard-weight-helper-text">
                        See Conversion
                        <Link
                          className="modalLink"
                          onClick={() => {
                            setModalOpen(true);
                            getCurrenctConversion(signingBonusText);
                          }}
                        >
                          see here
                        </Link>
                      </FormHelperText>
                    </Grid>
                  ) : null}
                  <Grid xs={12}>
                    <FormLabel
                      id="demo-radio-buttons-group-label"
                      className="lable_color_theme"
                    >
                      Other bonuses
                    </FormLabel>
                    <FormHelperText>
                      Do you want to include any other type of bonuses, such as
                      performance-related bonuses?
                    </FormHelperText>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      value={otherBonus}
                      name="radio-buttons-group"
                      onChange={(e) =>
                        setOtherBonus(e.target.value, changetext())
                      }
                    >
                      <Grid container>
                        <FormControlLabel
                          value="True"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="False"
                          control={<Radio />}
                          label="No"
                        />
                      </Grid>
                    </RadioGroup>
                  </Grid>

                  {otherBonus == "True" ? (
                    <Grid xs={12}>
                      <Input
                        value={otherBonusText}
                        type="number"
                        onChange={(e) => setOtherBonusText(e.target.value)}
                        id="standard-adornment-other"
                        startAdornment={
                          <InputAdornment position="end">INR</InputAdornment>
                        }
                        aria-describedby="standard-weight-helper-text"
                        inputProps={{
                          max: 99999,
                          autoComplete: "off",
                          min: 0,
                          maxLength: 10,
                          pattern: "/^d*$/",
                        }}
                      />
                      <FormHelperText id="standard-weight-helper-text">
                        See Conversion{" "}
                        <Link
                          className="modalLink"
                          onClick={() => {
                            setModalOpen(true);
                            getCurrenctConversion(otherBonusText);
                          }}
                        >
                          see here
                        </Link>
                      </FormHelperText>
                    </Grid>
                  ) : null}
                  <Grid xs={12}>
                    <FormLabel
                      id="demo-radio-buttons-group-label"
                      className="lable_color_theme"
                    >
                      Commission
                    </FormLabel>
                    <FormHelperText>
                      Do you want to include any commission?
                    </FormHelperText>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      value={comission}
                      name="radio-buttons-group"
                      onChange={(e) =>
                        setComission(e.target.value, changetext())
                      }
                    >
                      <Grid container>
                        <FormControlLabel
                          value="True"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="False"
                          control={<Radio />}
                          label="No"
                        />
                      </Grid>
                    </RadioGroup>
                  </Grid>
                  {comission == "True" ? (
                    <Grid xs={12}>
                      <Input
                        value={comissionText}
                        type="number"
                        onChange={(e) => setComissionText(e.target.value)}
                        id="standard-adornment-comissionText"
                        startAdornment={
                          <InputAdornment position="end">INR</InputAdornment>
                        }
                        aria-describedby="standard-weight-helper-text"
                        inputProps={{
                          max: 99999,
                          autoComplete: "off",
                          min: 0,
                          maxLength: 10,
                          pattern: "/^d*$/",
                        }}
                      />
                      <FormHelperText id="standard-weight-helper-text">
                        See Conversion{" "}
                        <Link
                          className="modalLink"
                          onClick={() => {
                            setModalOpen(true);
                            getCurrenctConversion(comissionText);
                          }}
                        >
                          see here
                        </Link>
                      </FormHelperText>
                    </Grid>
                  ) : null}

                  <Grid xs={12}>
                    <TextField
                      required
                      fullWidth
                      label="   Number of paid time off days"
                      helperText="According to Employment laws in India we need to offer a minimum
                  of 27 PTOs per year: ( 15 days Privilege Leave , 6 days Casual
                  Leave, 6 days Sick Leave). In addition to the PTOs, there are
                  Public holidays that are mandatory to be given."
                      variant="standard"
                      type="number"
                      value={paidLeaves}
                      onChange={(e) => setPaidLeaves(e.target.value)}
                      id="paidleaves"
                      inputProps={{
                        max: 365,
                        min: 27,
                        maxLength: 3,
                        pattern: "/^d*$/",
                      }}
                    />
                  </Grid>

                  <Grid xs={12}>
                    <FormHelperText>Role description</FormHelperText>
                    <TextField
                      required
                      fullWidth
                      id="outlined-textarea"
                      helperText="Please add 3 tasks minimum (at least 100 characters in total). You can copy this from the job description."
                      rows={3}
                      multiline
                      value={roleDescription}
                      className="roledesctextfield"
                      onChange={(e) => setRoleDescription(e.target.value)}
                      inputProps={{ maxLength: 10000, required: true }}
                    />

                    <Grid xsOffset={11}>
                      {roleDescription && roleDescription.length < 9500 ? (
                        <Typography className="counter">
                          {" "}
                          {roleDescription.length}/10000
                        </Typography>
                      ) : (
                        <Typography className="counter danger">
                          {" "}
                          {roleDescription ? roleDescription.length : 0}/10000
                        </Typography>
                      )}
                    </Grid>
                  </Grid>

                  <Grid xs={12}>
                    <TextField
                      required
                      fullWidth
                      label="Supervisor name"
                      variant="standard"
                      helperText="Name of the direct supervisor or line manager"
                      value={superviserName}
                      onChange={(e) => setSuperviserName(e.target.value)}
                      id="probationperiod"
                      inputProps={{ maxLength: 128, autoComplete: "off" }}
                    />
                  </Grid>

                  <Grid xs={12}>
                    <FormLabel
                      id="demo-radio-buttons-group-label"
                      className="lable_color_theme"
                    >
                      Experience Level
                    </FormLabel>
                    <FormHelperText>
                      Please select the experience level that aligns with this
                      role based on the job description (not the employees
                      overall experience)
                    </FormHelperText>

                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      value={experienceLevel}
                      name="radio-buttons-group"
                      onChange={(e) => setExperienceLevel(e.target.value)}
                    >
                      {Experience.map((data) => (
                        <>
                          <div key={data.value}>
                            <FormControlLabel
                              value={data.row_id}
                              control={<Radio />}
                              label={data.value}
                            />

                            <FormHelperText>{data.description}</FormHelperText>
                          </div>
                        </>
                      ))}
                    </RadioGroup>
                  </Grid>

                  <Grid xs={12}>
                    <FormLabel
                      id="demo-radio-buttons-group-label"
                      className="lable_color_theme"
                    >
                      Apply a non-solicitation clause?
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      value={nonSolicitationClause}
                      name="radio-buttons-group"
                      onChange={(e) => setNonSolicitationClause(e.target.value)}
                    >
                      <div>
                        <FormControlLabel
                          value="Yes"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormHelperText className="addEmployee-radiohelper">
                          This will prevent the employee from reaching out to
                          your company’s clients or customers for their own
                          benefit, after their employment with you is done.
                        </FormHelperText>
                      </div>
                      <div>
                        <FormControlLabel
                          value="No"
                          control={<Radio />}
                          label="No"
                        />
                      </div>
                    </RadioGroup>
                  </Grid>

                  <Grid xs={12} className="BoxGrid">
                    <Box className="BenifitsBox-2">
                      {allBenifits.map((data, index) => (
                        <>
                          <Grid
                            container
                            alignItems="center"
                            flexDirection={{ xs: "row", sm: "row" }}
                            key={data.row_id}
                          >
                            <Grid xs={6}>
                              <Typography className="BenifitType">
                                {data.name}
                              </Typography>
                            </Grid>
                            <Grid xs={6}>
                              {data.mandatory ? (
                                <FormControlLabel
                                  className="BenifitCheckbox"
                                  control={<Checkbox defaultChecked disabled />}
                                  label={
                                    <Typography className="BenifitCheckbox">
                                      {data.price} $
                                    </Typography>
                                  }
                                />
                              ) : (
                                <FormControlLabel
                                  className="BenifitCheckbox"
                                  onChange={(e) => {
                                    onBenifitsChecked(e, index);
                                  }}
                                  control={<Checkbox />}
                                  label={
                                    <Typography className="BenifitCheckbox">
                                      {data.price} $
                                    </Typography>
                                  }
                                />
                              )}
                            </Grid>
                          </Grid>
                          <Divider />
                        </>
                      ))}
                      <Grid
                        container
                        alignItems="center"
                        flexDirection={{ xs: "row", sm: "row" }}
                      >
                        <Grid xs={6}>
                          <FormLabel className="lable_color_theme">
                            Grand Total
                          </FormLabel>
                        </Grid>
                        <Grid xs={6}>
                          <Typography className="BenifitCheckbox">
                            {total}$
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>

                  <Grid xs={12}>
                    <FormControlLabel
                      control={<Checkbox checked={benefits} />}
                      onChange={(e) => setBenefits(e.target.checked)}
                      label="Benefits"
                      className="lable_color_theme"
                    />
                    <FormHelperText className="addEmployee-radiohelper">
                      I acknowledge that the benefits shown above will be
                      included in this employee's profile.
                    </FormHelperText>
                  </Grid>
                </div>
              </div>

              <div className="modal_footer">
                <Grid container className="modal_foot_bttn_container">
                  <Button className="save_bttn" type="submit">
                    Update Details
                  </Button>
                  <Button className="cancel_bttn" onClick={props.close}>
                    Cancel
                  </Button>
                </Grid>
              </div>
            </form>
          </Box>
        </Slide>
      </Modal>

      <CurrencyConverter
        modalOpen={modalOpen}
        close={() => setModalOpen(false)}
        currencyData={currency}
      />
    </>
  );
};
