import { getApi, postApi, putApi } from "./commonservice";

export function register(Name, Username, Email, Password) {
  const data = {
    name: Name,
    username: Username,
    email: Email,
    password: Password,
  };
  return postApi("users", data);
}
export function users() {
  return getApi("users");
}
export function health() {
  return getApi("health");
}

export function login(data) {
  return postApi("login", data);
}

export function registerUser(data, id) {
  return putApi("company/" + id, data);
}

export function getCountries() {
  return getApi("countries");
}

export function getquestions() {
  return getApi("common-settings-list?keyrequirement");
}

export function resendVerificationMail(data) {
  return postApi("resend-verification-email", data);
}
