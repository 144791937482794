import "./Onboarding.css";
import { React, useEffect, useState } from "react";
import {
  PropTypes,
  Typography,
  Avatar,
  IconButton,
  Link,
  Grid,
  Box,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  LinearProgress,
} from "./OnboardingImports";
import {
  Alert,
  Button,
  CircularProgress,
  Collapse,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
  Slide,
  Switch,
  TablePagination,
  TextField,
  Tooltip,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  getOnboardingdata,
  getOnboardingDataForContractor,
} from "Services/OnboardingService";
import { SearchIcon } from "Components/Teams/Teams/TeamImpoerts";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  activateContractor,
  activateEmployee,
} from "Services/AddEmployeeAndContractor";
import Zoom from "@mui/material/Zoom";
import { EmployeeAndContractorAddModal } from "Components/CommonComponents/Modals/Employee-Contractor Add Modal/EmployeeAndContractorAddModal";
import { HelpButton } from "Components/CommonComponents/Bottom_Help_Button/HelpButton";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { DataNotFound, FilterIcon, TeamEditIcon } from "Icons/Icons";
import moment from "moment";
import useNotification from "Components/CommonComponents/AlertNotifications/AlertNotifications";
import { TabPanel, a11yProps } from "Utils/TabPanel";
import { TransitionGroup } from "react-transition-group";

export const Onboarding = (props) => {
  const navigate = useNavigate();
  const [msg, sendNotification] = useNotification();

  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(0);

  const [totalEmployee, setTotalEmployee] = useState(0);
  const [allEmployeeOnboardingData, setAllEmployeeOnboardingData] = useState(
    []
  );
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [Search, setSearch] = useState("");

  const [totalContractor, setTotalContractor] = useState(0);
  const [allContractorOnboardingData, setAllContractorOnboardingData] =
    useState([]);
  const [pageForContractor, setPageForContractor] = useState(0);
  const [rowsPerPageForContractor, setRowsPerPageForContractor] = useState(5);
  const [SearchContractor, setSearchContractor] = useState("");

  const [editDialog, setEditDialog] = useState(false);

  const editDialogToggle = () => {
    setEditDialog(!editDialog);
  };

  const [filterColumnForContractor, setfilterColumnForContractor] =
    useState("");
  const [filterColumnForEmployee, setFilterColumnForEmployee] = useState("");
  const [rowName, setRowName] = useState([
    "Name",
    "Progress",
    "Status",
    "Country",
    "Start Date",
    "Added By",
    "Actions",
  ]);
  const [rowNameForContractor, setRowNameForContractor] = useState([
    "Name",
    "Progress",
    "Status",
    "Country",
    "Start Date",
    "Added By",
    "Actions",
  ]);
  const [checkedForEmployee, setCheckedForEmployee] = useState([
    "Name",
    "Progress",
    "Status",
    "Country",
    "Start Date",
    "Added By",
    "Actions",
  ]);
  const [checkedForContractor, setCheckedForContractor] = useState([
    "Name",
    "Progress",
    "Status",
    "Country",
    "Start Date",
    "Added By",
    "Actions",
  ]);

  const [filterDialog, setFilterDialog] = useState(false);

  const [filterStatus, setFilterStatus] = useState([
    "All",
    "Draft",
    "Invited",
    "Onboarded",
  ]);
  const [checkedForFilterStatus, setCheckedForFilterStatus] = useState(["All"]);
  const [
    checkedForFilterStatusForContractor,
    setCheckedForFilterStatusForContractor,
  ] = useState(["All"]);

  const [anchorEl, setAnchorEl] = useState(null);
  const open1 = Boolean(anchorEl);

  function handleToggleForEmployee(value) {
    const currentIndex = checkedForEmployee.indexOf(value);
    const newChecked = [...checkedForEmployee];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheckedForEmployee(newChecked);
  }

  function handleToggleForContractor(value) {
    const currentIndex = checkedForContractor.indexOf(value);
    const newChecked = [...checkedForContractor];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheckedForContractor(newChecked);
  }

  const hideLoading = () => {
    setLoading(false);
  };
  const showLoading = () => {
    setLoading(true);
  };

  useEffect(() => {
    localStorage.removeItem("ContractorData1");
    localStorage.removeItem("ContractorData2");
    localStorage.removeItem("ContractorData3");
    localStorage.removeItem("ContractorRowData");

    localStorage.removeItem("summuaryData");
    localStorage.removeItem("Empdata1");
    localStorage.removeItem("Empdata2");
    localStorage.removeItem("Empdata3");
    localStorage.removeItem("empRowData");
    localStorage.removeItem("empidForDetailView");
    localStorage.removeItem("empdactivateIDForDetailView");
    localStorage.removeItem("employeeActivationID");
    localStorage.removeItem("contraidForDetailView");
    localStorage.removeItem("contradactivateIDForDetailView");
    localStorage.removeItem("contractorActivationID");
    localStorage.removeItem("employeeStatus");
    localStorage.removeItem("contractorStatus");
    if (window.location.search.includes("contractor")) {
      setValue(1);
    } else {
      setValue(0);
    }
    const getData = setTimeout(() => {
      getAllOnboardingDataForEmployee();
    }, 200);

    return () => clearTimeout(getData);
  }, [rowsPerPage, Search, checkedForFilterStatus]);

  useEffect(() => {
    const getData = setTimeout(() => {
      getAllonboardinbgDataForContractor();
    }, 200);
    return () => clearTimeout(getData);
  }, [
    rowsPerPageForContractor,
    SearchContractor,
    checkedForFilterStatusForContractor,
  ]);

  const handleChange = (event, newValue) => {
    setSearchContractor("");
    setSearch("");
    setValue(newValue);
  };

  const getAllOnboardingDataForEmployee = (pageNo = 1) => {
    showLoading();

    getOnboardingdata(
      pageNo,
      rowsPerPage,
      Search,
      checkedForFilterStatus[0].toLowerCase()
    ).then(
      (res) => {
        setTotalEmployee(res.data.total);
        res.data.data.map((value) => {
          var logo = value.emp_id.full_name.split(" ");
          var logo1 = logo[0].slice(0, 1).toUpperCase();
          if (logo[1] != null) {
            var logo2 = logo[1].slice(0, 1).toUpperCase();
            value.avtarLogo = logo1.concat(logo2);
          }
          // console.log(value.avtarLogo);
          value.totalStatus = value.company_employee_status.length;
          let completedArrayList = value.company_employee_status.filter(
            (val) => {
              return val.completed;
            }
          );
          value.totalCompletedStatus = completedArrayList.length;
        });

        setAllEmployeeOnboardingData(res.data.data);

        setTimeout(() => {
          hideLoading();
        }, 500);
      },
      (err) => {
        setTotalEmployee(0);
        setAllEmployeeOnboardingData([]);
        props.unAuthorized(err.response.status);
        setTimeout(() => {
          hideLoading();
        }, 500);
      }
    );
  };

  const onEmployeePageChange = (event, newpage) => {
    setPage(newpage);
    getAllOnboardingDataForEmployee(newpage + 1);
  };

  const activateEmployeeForTeam = (id) => {
    showLoading();
    setAnchorEl(null);
    activateEmployee(id).then(
      (res) => {
        sendNotification({
          msg: res.data.message,
          variant: "success",
        });
        setTimeout(() => {
          getAllOnboardingDataForEmployee();
          hideLoading();
        }, 1000);
      },
      (err) => {
        sendNotification({
          msg: err.response.statusText,
          variant: "error",
        });

        props.unAuthorized(err.response.status);
        hideLoading();
      }
    );
  };

  const EmployeeNavigation = (index, activationID, status) => {
    localStorage.setItem("employeeStatus", status);
    const data = allEmployeeOnboardingData[index].is_draft;
    const row = allEmployeeOnboardingData[index].emp_id.row_id;
    const totalstatus = allEmployeeOnboardingData[index].totalStatus;

    const totalCompletedStatus =
      allEmployeeOnboardingData[index].totalCompletedStatus;

    if (data === true) {
      navigate("/addemployeefinal/" + row);
    } else if (data === false && totalCompletedStatus === totalstatus - 1) {
      localStorage.setItem("employeeActivationID", activationID);
      localStorage.setItem("empidForDetailView", row);
      navigate("/team/employeeid");
    } else {
      localStorage.setItem("empidForDetailView", row);
      navigate("/team/employeeid");
    }
  };

  const getAllonboardinbgDataForContractor = (pageNo = 1) => {
    showLoading();

    getOnboardingDataForContractor(
      pageNo,
      rowsPerPageForContractor,
      SearchContractor,
      checkedForFilterStatusForContractor[0].toLowerCase()
    ).then(
      (res) => {
        setTotalContractor(res.data.total);

        res.data.data.map((value) => {
          var logo = value.contractor_id.full_name.split(" ");
          var logo1 = logo[0].slice(0, 1).toUpperCase();
          if (logo[1] != null) {
            var logo2 = logo[1].slice(0, 1).toUpperCase();
            value.avtarLogo = logo1.concat(logo2);
          }

          // console.log(value.avtarLogo);
          value.totalStatus = value.company_contractor_status.length;
          let completedArrayList = value.company_contractor_status.filter(
            (val) => {
              return val.completed;
            }
          );
          value.totalCompletedStatus = completedArrayList.length;
        });
        setAllContractorOnboardingData(res.data.data);

        setTimeout(() => {
          hideLoading();
        }, 500);
      },
      (err) => {
        console.log(err.message);
        setTotalContractor(0);
        setAllContractorOnboardingData([]);
        props.unAuthorized(err.response.status);
        setTimeout(() => {
          hideLoading();
        }, 500);
      }
    );
  };

  const onContractorPageChange = (event, newpage) => {
    setPageForContractor(newpage);
    getAllonboardinbgDataForContractor(newpage + 1);
  };

  const activateContractorForTeam = (id) => {
    showLoading();
    setAnchorEl(null);
    activateContractor(id).then(
      (res) => {
        sendNotification({
          msg: res.data.message,
          variant: "success",
        });
        setTimeout(() => {
          getAllonboardinbgDataForContractor();
          hideLoading();
        }, 200);
      },
      (err) => {
        sendNotification({
          msg: err.response.statusText,
          variant: "error",
        });
        props.unAuthorized(err.response.status);
        hideLoading();
      }
    );
  };

  const contractorNavigation = (index, contractorActivationID, status) => {
    localStorage.setItem("employeeStatus", status);
    const data = allContractorOnboardingData[index].is_draft;
    const row = allContractorOnboardingData[index].contractor_id.row_id;

    const totalstatus = allContractorOnboardingData[index].totalStatus;

    const totalCompletedStatus =
      allContractorOnboardingData[index].totalCompletedStatus;

    if (data === true) {
      navigate("/addcontractorfinal/" + row);
    } else if (data === false && totalCompletedStatus === totalstatus - 1) {
      localStorage.setItem("contraidForDetailView", row);
      localStorage.setItem("contractorActivationID", contractorActivationID);
      navigate("/team/contractorid");
    } else {
      localStorage.setItem("contraidForDetailView", row);
      navigate("/team/contractorid");
    }
  };

  function handleToggleForFilterStatus(value) {
    var currentIndex = checkedForFilterStatus.indexOf(value);
    var newChecked = [...checkedForFilterStatus];
    if (currentIndex === -1) {
      newChecked.pop();
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    if (newChecked.length <= 0) {
      setCheckedForFilterStatus(["All"]);
    } else {
      setCheckedForFilterStatus(newChecked);
    }
  }

  function handleToggleForFilterStatusForContractor(value) {
    var currentIndex = checkedForFilterStatusForContractor.indexOf(value);
    var newChecked = [...checkedForFilterStatusForContractor];
    if (currentIndex === -1) {
      newChecked.pop();
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    if (newChecked.length <= 0) {
      setCheckedForFilterStatusForContractor(["All"]);
    } else {
      setCheckedForFilterStatusForContractor(newChecked);
    }
  }

  return (
    <>
      <Slide in={true} direction="left">
        <Grid item className={props.className}>
          <Grid container className="team_top_heading">
            <Grid item>
              <Typography variant="h3" className="page_heading">
                Hire and onboard
              </Typography>
            </Grid>
            <Grid item>
              <Button
                onClick={() => setOpen(true)}
                variant="contained"
                startIcon={<PersonAddIcon />}
                className="theme_bttn fill"
              >
                Add new hire
              </Button>
            </Grid>
            <Grid xs={12} item>
              <Typography variant="body2" className="page_head_para">
                Want to learn more about the onboarding process at InRole?
                <Link>Here is our onboarding guide.</Link>
              </Typography>
            </Grid>
          </Grid>
          <div className="dash_tabs">
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs "
            >
              <Tab
                label={<Typography>Employees ({totalEmployee})</Typography>}
                {...a11yProps(0)}
                onClick={() => navigate("/onboarding")}
              />

              <Tab
                label={<Typography>Contractors ({totalContractor})</Typography>}
                {...a11yProps(1)}
              />
            </Tabs>
          </div>
          <TabPanel
            value={value}
            index={0}
            className="tab-pannel"
            querystring="employee"
          >
            <div
              className={`data_table ${
                !allEmployeeOnboardingData.length && "no_data_found"
              } `}
            >
              <Grid container className="data_table_filters_grid">
                <TextField
                  spellCheck="false"
                  placeholder="Search"
                  value={Search}
                  className="outer_searchfilled"
                  id="outlined-start-adornment"
                  onChange={(e) => setSearch(e.target.value)}
                  InputProps={{
                    autoComplete: "off",
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <div className="table_edit_controller">
                  <Grid container className="filter_group">
                    <IconButton onClick={(e) => setFilterDialog(!filterDialog)}>
                      <FilterIcon />
                    </IconButton>
                    <Typography>Filter</Typography>
                  </Grid>{" "}
                  {filterDialog ? (
                    <Zoom in={filterDialog}>
                      <div className="edit_filter_menu is_small">
                        <Button
                          className="edit_filter_menu_close"
                          onClick={() => setFilterDialog(!filterDialog)}
                          disableRipple
                        ></Button>
                        <div className="edit_filter_menu_bx">
                          <List
                            subheader={
                              <ListSubheader>Filter Data</ListSubheader>
                            }
                          >
                            {filterStatus.map((data, index) => (
                              <ListItem key={index}>
                                <Switch
                                  edge="end"
                                  onChange={() =>
                                    handleToggleForFilterStatus(data)
                                  }
                                  checked={
                                    checkedForFilterStatus.indexOf(data) !== -1
                                  }
                                  className={
                                    checkedForFilterStatus.indexOf(data) !== -1
                                      ? "active"
                                      : "inactive"
                                  }
                                  inputProps={{
                                    "aria-labelledby": data,
                                  }}
                                />
                                <ListItemText
                                  id={data}
                                  primary={data}
                                  disableTypography
                                />
                              </ListItem>
                            ))}
                          </List>
                        </div>
                      </div>
                    </Zoom>
                  ) : null}
                </div>

                <div className="table_edit_controller">
                  <IconButton onClick={editDialogToggle}>
                    <TeamEditIcon />
                  </IconButton>
                  {editDialog ? (
                    <Zoom in={editDialog}>
                      <div className="edit_filter_menu">
                        <Button
                          className="edit_filter_menu_close"
                          onClick={editDialogToggle}
                          disableRipple
                        ></Button>
                        <div className="edit_filter_menu_bx">
                          <TextField
                            placeholder="Search Columns"
                            fullWidth
                            value={filterColumnForEmployee}
                            onChange={(e) =>
                              setFilterColumnForEmployee(e.target.value)
                            }
                            InputProps={{
                              autoComplete: "off",
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchIcon />
                                </InputAdornment>
                              ),
                            }}
                          />{" "}
                          <List
                            subheader={
                              <ListSubheader>Original Columns</ListSubheader>
                            }
                          >
                            {" "}
                            <TransitionGroup>
                              {rowName
                                .filter((data) => {
                                  return filterColumnForEmployee.toLowerCase() ===
                                    ""
                                    ? data
                                    : data
                                        .toLowerCase()
                                        .includes(
                                          filterColumnForEmployee.toLowerCase()
                                        );
                                })
                                .map((data, index) => (
                                  <Collapse key={data}>
                                    <ListItem key={index}>
                                      <Switch
                                        edge="end"
                                        onChange={() =>
                                          handleToggleForEmployee(data)
                                        }
                                        checked={
                                          checkedForEmployee.indexOf(data) !==
                                          -1
                                        }
                                        className={
                                          checkedForEmployee.indexOf(data) !==
                                          -1
                                            ? "active"
                                            : "inactive"
                                        }
                                        inputProps={{
                                          "aria-labelledby": data,
                                        }}
                                      />
                                      <ListItemText
                                        id={data}
                                        primary={data}
                                        disableTypography
                                      />
                                    </ListItem>
                                  </Collapse>
                                ))}
                            </TransitionGroup>
                          </List>
                        </div>
                      </div>
                    </Zoom>
                  ) : null}
                </div>
              </Grid>
              <div className="table_container">
                <Table stickyHeader aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {checkedForEmployee.indexOf("Name") >= 0 ? (
                        <TableCell component={"td"} align="left">
                          Name
                        </TableCell>
                      ) : null}
                      {checkedForEmployee.indexOf("Progress") >= 0 ? (
                        <TableCell component={"td"} align="left">
                          PROGRESS
                        </TableCell>
                      ) : null}
                      {checkedForEmployee.indexOf("Status") >= 0 ? (
                        <TableCell
                          component={"td"}
                          align="left"
                          className="alert_status_cell"
                        >
                          STATUS
                        </TableCell>
                      ) : null}
                      {checkedForEmployee.indexOf("Country") >= 0 ? (
                        <TableCell component={"td"} align="left">
                          COUNTRY
                        </TableCell>
                      ) : null}
                      {checkedForEmployee.indexOf("Start Date") >= 0 ? (
                        <TableCell component={"td"} align="left">
                          START DATE
                        </TableCell>
                      ) : null}
                      {checkedForEmployee.indexOf("Added By") >= 0 ? (
                        <TableCell component={"td"} align="left">
                          ADDED BY
                        </TableCell>
                      ) : null}
                      {checkedForEmployee.indexOf("Actions") >= 0 ? (
                        <TableCell component={"td"} align="left"></TableCell>
                      ) : null}
                    </TableRow>
                  </TableHead>
                  {allEmployeeOnboardingData.length && !loading ? (
                    <TableBody>
                      {allEmployeeOnboardingData.map((row, index) => (
                        <TableRow key={row.row_id}>
                          {checkedForEmployee.indexOf("Name") >= 0 ? (
                            <TableCell
                              scope="row"
                              align="left"
                              onClick={() =>
                                EmployeeNavigation(
                                  index,
                                  row.row_id,
                                  row.employee_status
                                )
                              }
                            >
                              <Grid
                                container
                                className="avatar_container"
                                spacing={0}
                                p={0}
                              >
                                <Avatar className="team_avatar">
                                  {row.avtarLogo}
                                </Avatar>
                                <span>{row.emp_id.full_name}</span>
                              </Grid>
                            </TableCell>
                          ) : null}
                          {checkedForEmployee.indexOf("Progress") >= 0 ? (
                            <TableCell
                              align="left"
                              onClick={() =>
                                EmployeeNavigation(
                                  index,
                                  row.row_id,
                                  row.employee_status
                                )
                              }
                            >
                              <Grid container className="progress_container">
                                <LinearProgress
                                  variant="determinate"
                                  value={
                                    (100 * row.totalCompletedStatus) /
                                    row.totalStatus
                                  }
                                />
                                <Typography>
                                  {row.totalCompletedStatus}/{row.totalStatus}
                                </Typography>
                              </Grid>
                            </TableCell>
                          ) : null}

                          {checkedForEmployee.indexOf("Status") >= 0 ? (
                            !(
                              row.is_draft === false &&
                              row.totalCompletedStatus === row.totalStatus - 1
                            ) ? (
                              row.is_draft === true ? (
                                <TableCell
                                  align="left"
                                  onClick={() =>
                                    EmployeeNavigation(
                                      index,
                                      row.row_id,
                                      row.employee_status
                                    )
                                  }
                                >
                                  {" "}
                                  <Tooltip
                                    TransitionComponent={Zoom}
                                    title="You Are Only One Step Away From Invite."
                                    placement="right-start"
                                    arrow
                                  >
                                    <Alert icon={false} severity="warning">
                                      Draft
                                      <InfoOutlinedIcon />
                                    </Alert>
                                  </Tooltip>
                                </TableCell>
                              ) : (
                                <TableCell
                                  align="left"
                                  onClick={() =>
                                    EmployeeNavigation(
                                      index,
                                      row.row_id,
                                      row.employee_status
                                    )
                                  }
                                >
                                  {" "}
                                  <Tooltip
                                    TransitionComponent={Zoom}
                                    title="Employee Has Been Invited Please Wait While They Complete Self Onboarding Process."
                                    placement="right-start"
                                    arrow
                                  >
                                    <Alert icon={false} severity="success">
                                      Invited
                                      <InfoOutlinedIcon />
                                    </Alert>
                                  </Tooltip>
                                </TableCell>
                              )
                            ) : (
                              <TableCell
                                align="left"
                                onClick={() =>
                                  EmployeeNavigation(
                                    index,
                                    row.row_id,
                                    row.employee_status
                                  )
                                }
                              >
                                {" "}
                                <Tooltip
                                  TransitionComponent={Zoom}
                                  title="employee has completed their onboarding,  please activate the employee by clicking the employee name and checking the button `set to active` "
                                  placement="right-start"
                                  arrow
                                >
                                  <Alert icon={false} severity="info">
                                    Onboarded
                                    <InfoOutlinedIcon />
                                  </Alert>
                                </Tooltip>
                              </TableCell>
                            )
                          ) : null}
                          {checkedForEmployee.indexOf("Country") >= 0 ? (
                            <TableCell
                              align="left"
                              onClick={() =>
                                EmployeeNavigation(
                                  index,
                                  row.row_id,
                                  row.employee_status
                                )
                              }
                            >
                              {row.emp_id.nationality}
                            </TableCell>
                          ) : null}
                          {checkedForEmployee.indexOf("Start Date") >= 0 ? (
                            <TableCell
                              align="left"
                              onClick={() =>
                                EmployeeNavigation(
                                  index,
                                  row.row_id,
                                  row.employee_status
                                )
                              }
                            >
                              {moment(row.emp_id.provisional_start_date).format(
                                "ll"
                              )}
                            </TableCell>
                          ) : null}
                          {checkedForEmployee.indexOf("Added By") >= 0 ? (
                            <TableCell
                              align="left"
                              onClick={() =>
                                EmployeeNavigation(
                                  index,
                                  row.row_id,
                                  row.employee_status
                                )
                              }
                            >
                              {row.created_by}
                            </TableCell>
                          ) : null}
                          {checkedForEmployee.indexOf("Actions") >= 0 ? (
                            <TableCell align="left">
                              {!(
                                row.is_draft === false &&
                                row.totalCompletedStatus === row.totalStatus - 1
                              ) ? (
                                <IconButton
                                  id={row.row_id}
                                  disabled
                                  className="action_bttn"
                                >
                                  <MoreHorizIcon />
                                </IconButton>
                              ) : (
                                <PopupState
                                  variant="popover"
                                  popupId="onboarding-popup-menu"
                                  className="table_popover onboarding_Menu"
                                >
                                  {(popupState) => (
                                    <>
                                      <IconButton
                                        {...bindTrigger(popupState)}
                                        aria-controls={
                                          open1 ? "basic-menu" : undefined
                                        }
                                        aria-haspopup="true"
                                        aria-expanded={
                                          open1 ? "true" : undefined
                                        }
                                        id={row.row_id}
                                        className="action_bttn table_popover_bttn"
                                      >
                                        <MoreHorizIcon />
                                      </IconButton>

                                      <Menu
                                        {...bindMenu(popupState)}
                                        id={index}
                                        anchorOrigin={{
                                          vertical: "bottom",
                                          horizontal: "right",
                                        }}
                                        className="table_popover_menu"
                                      >
                                        <MenuItem
                                          variant="contained"
                                          color="success"
                                          // className="theme_bttn fill"
                                          onClick={() => {
                                            activateEmployeeForTeam(row.row_id);
                                          }}
                                        >
                                          Activate
                                        </MenuItem>
                                      </Menu>
                                    </>
                                  )}
                                </PopupState>
                              )}
                            </TableCell>
                          ) : null}
                        </TableRow>
                      ))}
                    </TableBody>
                  ) : !loading ? (
                    <TableBody>
                      <TableRow>
                        <TableCell colSpan={10}>
                          <Zoom in={!allEmployeeOnboardingData.length}>
                            <Grid className="data_table_inner_notfound">
                              <DataNotFound />
                              <Typography>No Data Found!</Typography>
                              <Typography variant="body2">
                                The Employee You Are Searching For Does Not
                                Exists!
                              </Typography>
                            </Grid>
                          </Zoom>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ) : (
                    <TableBody>
                      <TableRow>
                        <TableCell colSpan={10}>
                          <Grid className="data_table_inner_notfound">
                            <CircularProgress />
                          </Grid>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </div>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={totalEmployee}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={onEmployeePageChange}
                onRowsPerPageChange={(event) => {
                  setPage(0);
                  setRowsPerPage(+event.target.value);
                }}
              />
            </div>
          </TabPanel>
          <TabPanel
            value={value}
            index={1}
            className="tab-pannel"
            querystring="contractor"
          >
            <div
              className={`data_table ${
                !allContractorOnboardingData.length && "no_data_found"
              } `}
            >
              <Grid container className="data_table_filters_grid">
                <TextField
                  spellCheck="false"
                  placeholder="Search"
                  value={SearchContractor}
                  className="outer_searchfilled"
                  id="outlined-start-adornment"
                  sx={{}}
                  onChange={(e) => setSearchContractor(e.target.value)}
                  InputProps={{
                    autoComplete: "off",
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />

                <div className="table_edit_controller">
                  <Grid container className="filter_group">
                    <IconButton onClick={(e) => setFilterDialog(!filterDialog)}>
                      <FilterIcon />
                    </IconButton>
                    <Typography>Filter</Typography>
                  </Grid>{" "}
                  {filterDialog ? (
                    <Zoom in={filterDialog}>
                      <div className="edit_filter_menu is_small">
                        <Button
                          className="edit_filter_menu_close"
                          onClick={() => setFilterDialog(!filterDialog)}
                          disableRipple
                        ></Button>
                        <div className="edit_filter_menu_bx">
                          <List
                            subheader={
                              <ListSubheader>Filter Data</ListSubheader>
                            }
                          >
                            {filterStatus.map((data, index) => (
                              <ListItem key={index}>
                                <Switch
                                  edge="end"
                                  onChange={() =>
                                    handleToggleForFilterStatusForContractor(
                                      data
                                    )
                                  }
                                  checked={
                                    checkedForFilterStatusForContractor.indexOf(
                                      data
                                    ) !== -1
                                  }
                                  className={
                                    checkedForFilterStatusForContractor.indexOf(
                                      data
                                    ) !== -1
                                      ? "active"
                                      : "inactive"
                                  }
                                  inputProps={{
                                    "aria-labelledby": data,
                                  }}
                                />
                                <ListItemText
                                  id={data}
                                  primary={data}
                                  disableTypography
                                />
                              </ListItem>
                            ))}
                          </List>
                        </div>
                      </div>
                    </Zoom>
                  ) : null}
                </div>

                <div className="table_edit_controller">
                  <IconButton onClick={editDialogToggle}>
                    <TeamEditIcon />
                  </IconButton>
                  {editDialog ? (
                    <Zoom in={editDialog}>
                      <div className="edit_filter_menu">
                        <Button
                          className="edit_filter_menu_close"
                          onClick={editDialogToggle}
                          disableRipple
                        ></Button>
                        <div className="edit_filter_menu_bx">
                          <TextField
                            placeholder="Search Columns"
                            fullWidth
                            value={filterColumnForContractor}
                            onChange={(e) =>
                              setfilterColumnForContractor(e.target.value)
                            }
                            InputProps={{
                              autoComplete: "off",
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchIcon />
                                </InputAdornment>
                              ),
                            }}
                          />{" "}
                          <List
                            subheader={
                              <ListSubheader>Original Columns</ListSubheader>
                            }
                          >
                            {" "}
                            <TransitionGroup>
                              {rowNameForContractor
                                .filter((data) => {
                                  return filterColumnForContractor.toLowerCase() ===
                                    ""
                                    ? data
                                    : data
                                        .toLowerCase()
                                        .includes(
                                          filterColumnForContractor.toLowerCase()
                                        );
                                })
                                .map((data, index) => (
                                  <Collapse key={data}>
                                    <ListItem key={index}>
                                      <Switch
                                        edge="end"
                                        onChange={() =>
                                          handleToggleForContractor(data)
                                        }
                                        checked={
                                          checkedForContractor.indexOf(data) !==
                                          -1
                                        }
                                        className={
                                          checkedForContractor.indexOf(data) !==
                                          -1
                                            ? "active"
                                            : "inactive"
                                        }
                                        inputProps={{
                                          "aria-labelledby": data,
                                        }}
                                      />
                                      <ListItemText
                                        id={data}
                                        primary={data}
                                        disableTypography
                                      />
                                    </ListItem>
                                  </Collapse>
                                ))}
                            </TransitionGroup>
                          </List>
                        </div>
                      </div>
                    </Zoom>
                  ) : null}
                </div>
              </Grid>
              <div className="table_container">
                <Table stickyHeader aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {checkedForContractor.indexOf("Name") >= 0 ? (
                        <TableCell component={"td"} align="left">
                          Name
                        </TableCell>
                      ) : null}
                      {checkedForContractor.indexOf("Progress") >= 0 ? (
                        <TableCell component={"td"} align="left">
                          PROGRESS
                        </TableCell>
                      ) : null}
                      {checkedForContractor.indexOf("Status") >= 0 ? (
                        <TableCell
                          component={"td"}
                          align="left"
                          className="alert_status_cell"
                        >
                          STATUS
                        </TableCell>
                      ) : null}
                      {checkedForContractor.indexOf("Country") >= 0 ? (
                        <TableCell component={"td"} align="left">
                          COUNTRY
                        </TableCell>
                      ) : null}
                      {checkedForContractor.indexOf("Start Date") >= 0 ? (
                        <TableCell component={"td"} align="left">
                          START DATE
                        </TableCell>
                      ) : null}
                      {checkedForContractor.indexOf("Added By") >= 0 ? (
                        <TableCell component={"td"} align="left">
                          ADDED BY
                        </TableCell>
                      ) : null}
                      {checkedForContractor.indexOf("Actions") >= 0 ? (
                        <TableCell component={"td"} align="left"></TableCell>
                      ) : null}
                    </TableRow>
                  </TableHead>
                  {allContractorOnboardingData.length && !loading ? (
                    <TableBody>
                      {allContractorOnboardingData.map((row, index) => (
                        <TableRow
                          key={row.row_id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          {checkedForContractor.indexOf("Name") >= 0 ? (
                            <TableCell
                              scope="row"
                              align="left"
                              onClick={() =>
                                contractorNavigation(
                                  index,
                                  row.row_id,
                                  row.contractor_status
                                )
                              }
                            >
                              <Grid
                                container
                                className="avatar_container"
                                spacing={0}
                                p={0}
                              >
                                <Avatar className="team_avatar">
                                  {row.avtarLogo}
                                </Avatar>
                                <span>{row.contractor_id.full_name}</span>
                              </Grid>
                            </TableCell>
                          ) : null}
                          {checkedForContractor.indexOf("Progress") >= 0 ? (
                            <TableCell
                              align="left"
                              onClick={() =>
                                contractorNavigation(
                                  index,
                                  row.row_id,
                                  row.contractor_status
                                )
                              }
                            >
                              <Grid container className="progress_container">
                                <LinearProgress
                                  variant="determinate"
                                  value={
                                    (100 * row.totalCompletedStatus) /
                                    row.totalStatus
                                  }
                                />
                                <Typography>
                                  {row.totalCompletedStatus}/{row.totalStatus}
                                </Typography>
                              </Grid>
                            </TableCell>
                          ) : null}
                          {checkedForContractor.indexOf("Status") >= 0 ? (
                            !(
                              row.is_draft === false &&
                              row.totalCompletedStatus === row.totalStatus - 1
                            ) ? (
                              row.is_draft === true ? (
                                <TableCell
                                  align="left"
                                  onClick={() =>
                                    contractorNavigation(
                                      index,
                                      row.row_id,
                                      row.contractor_status
                                    )
                                  }
                                >
                                  <Tooltip
                                    TransitionComponent={Zoom}
                                    title="You Are Only One Step Away From Invite."
                                    placement="right-start"
                                    arrow
                                  >
                                    <Alert icon={false} severity="warning">
                                      Draft
                                      <InfoOutlinedIcon />
                                    </Alert>
                                  </Tooltip>
                                </TableCell>
                              ) : (
                                <TableCell
                                  align="left"
                                  onClick={() =>
                                    contractorNavigation(
                                      index,
                                      row.row_id,
                                      row.contractor_status
                                    )
                                  }
                                >
                                  <Tooltip
                                    TransitionComponent={Zoom}
                                    title="Contractor Has Been Invited Please Wait While They Complete
                                   Self Onboarding Process."
                                    placement="right-start"
                                    arrow
                                  >
                                    <Alert icon={false} severity="success">
                                      Invited
                                      <InfoOutlinedIcon />
                                    </Alert>
                                  </Tooltip>
                                </TableCell>
                              )
                            ) : (
                              <TableCell
                                align="left"
                                onClick={() =>
                                  contractorNavigation(
                                    index,
                                    row.row_id,
                                    row.contractor_status
                                  )
                                }
                              >
                                {" "}
                                <Tooltip
                                  TransitionComponent={Zoom}
                                  title="contractor has completed their onboarding,  please activate the contractor by clicking the contractor name and checking the button `set to active`."
                                  placement="right-start"
                                  arrow
                                >
                                  <Alert icon={false} severity="info">
                                    Onboarded
                                    <InfoOutlinedIcon />
                                  </Alert>
                                </Tooltip>
                              </TableCell>
                            )
                          ) : null}
                          {checkedForContractor.indexOf("Country") >= 0 ? (
                            <TableCell
                              align="left"
                              onClick={() =>
                                contractorNavigation(
                                  index,
                                  row.row_id,
                                  row.contractor_status
                                )
                              }
                            >
                              {row.contractor_id.country_id.name}
                            </TableCell>
                          ) : null}
                          {checkedForContractor.indexOf("Start Date") >= 0 ? (
                            <TableCell
                              align="left"
                              onClick={() =>
                                contractorNavigation(
                                  index,
                                  row.row_id,
                                  row.contractor_status
                                )
                              }
                            >
                              {moment(
                                row.contractor_id.provisional_start_date
                              ).format("ll")}
                            </TableCell>
                          ) : null}
                          {checkedForContractor.indexOf("Added By") >= 0 ? (
                            <TableCell
                              align="left"
                              onClick={() =>
                                contractorNavigation(
                                  index,
                                  row.row_id,
                                  row.contractor_status
                                )
                              }
                            >
                              {row.created_by}
                            </TableCell>
                          ) : null}
                          {checkedForContractor.indexOf("Actions") >= 0 ? (
                            <TableCell align="left">
                              {!(
                                row.is_draft === false &&
                                row.totalCompletedStatus === row.totalStatus - 1
                              ) ? (
                                <IconButton disabled className="action_bttn">
                                  <MoreHorizIcon />
                                </IconButton>
                              ) : (
                                <>
                                  <PopupState
                                    variant="popover"
                                    popupId="onboarding-popup-menu"
                                    className="onboarding_Menu"
                                  >
                                    {(popupState) => (
                                      <>
                                        <IconButton
                                          {...bindTrigger(popupState)}
                                          aria-controls={
                                            open1 ? "basic-menu" : undefined
                                          }
                                          aria-haspopup="true"
                                          aria-expanded={
                                            open1 ? "true" : undefined
                                          }
                                          id={row.row_id}
                                          className="action_bttn"
                                        >
                                          <MoreHorizIcon />
                                        </IconButton>

                                        <Menu
                                          className="table_popover_menu"
                                          {...bindMenu(popupState)}
                                          id={index}
                                          anchorOrigin={{
                                            vertical: "top",
                                            horizontal: "right",
                                          }}
                                        >
                                          <MenuItem
                                            variant="contained"
                                            color="success"
                                            onClick={() => {
                                              activateContractorForTeam(
                                                row.row_id
                                              );
                                            }}
                                          >
                                            Activate
                                          </MenuItem>
                                        </Menu>
                                      </>
                                    )}
                                  </PopupState>
                                </>
                              )}
                            </TableCell>
                          ) : null}
                        </TableRow>
                      ))}
                    </TableBody>
                  ) : !loading ? (
                    <TableBody>
                      <TableRow>
                        <TableCell colSpan={10}>
                          <Zoom in={!allContractorOnboardingData.length}>
                            <Grid className="data_table_inner_notfound">
                              <DataNotFound />
                              <Typography>No Data Found!</Typography>
                              <Typography variant="body2">
                                The Contractor You Are Searching For Does Not
                                Exists!
                              </Typography>
                            </Grid>
                          </Zoom>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ) : (
                    <TableBody>
                      <TableRow>
                        <TableCell colSpan={10}>
                          <Grid className="data_table_inner_notfound">
                            <CircularProgress />
                          </Grid>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </div>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={totalContractor}
                rowsPerPage={rowsPerPageForContractor}
                page={pageForContractor}
                onPageChange={onContractorPageChange}
                onRowsPerPageChange={(event) => {
                  setPageForContractor(0);
                  setRowsPerPageForContractor(+event.target.value);
                }}
              />
            </div>
          </TabPanel>
        </Grid>
      </Slide>
      <HelpButton />
      <EmployeeAndContractorAddModal open={open} close={() => setOpen(false)} />
    </>
  );
};
