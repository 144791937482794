import {
  Button,
  CircularProgress,
  IconButton,
  MenuItem,
  Modal,
  Slide,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";

import React, { useEffect, useState } from "react";
import { Grid } from "Utils/muiImport";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { CloseIcon } from "Icons/Icons";
import { getAllEmployee } from "Services/AddEmployeeAndContractor";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  uploadEmployeeDocuments,
  uploadCompanyDocuments,
} from "Services/DocumentServices";

export const DocumentModal = (props) => {
  const [loading, setLoading] = useState(false);

  const [allEmployeeArray, setAllEmployeeArray] = useState([]);
  const [Employee, setEmployee] = useState("");
  const [documentType, setDocumentType] = useState("");
  const [file, setFile] = useState([]);
  const [fileSize, setFileSize] = useState("");
  const [fileError, setFileError] = useState(false);
  const [documentNo, setDocumentNo] = useState("");

  const [errorOpen, setErrorOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [response, setResponse] = useState("");

  useEffect(() => {
    getEmployee();
    setEmployee("");
    setDocumentType("");
    setDocumentNo("");
    setFile([]);
  }, [props.modalOpen]);

  useEffect(() => {
    validateSelectedFile();
  }, [file.name]);

  const showLoading = () => {
    setLoading(true);
  };
  const hideLoading = () => {
    setLoading(false);
  };

  const getEmployee = () => {
    getAllEmployee(1, 9999).then(
      (res) => {
        setAllEmployeeArray(res.data.data);
      },
      (err) => {
        console.log(err.message);
      }
    );
  };

  const UploadFile = (e) => {
    if (e.target.files && e.target.files.length) {
      setFile(e.target.files[0]);
      validateSelectedFile();
    }
  };

  const validateSelectedFile = () => {
    const MAX_FILE_SIZE = 2048;

    if (!file) {
      setResponse("Please choose a file");
      setErrorOpen(true);
      return;
    }

    const fileSizeKiloBytes = file.size / 1024;
    setFileSize(fileSizeKiloBytes * 0.001);

    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      setFileError(true);
      setResponse("File size is greater than maximum limit of 2MB");
      setErrorOpen(true);
    } else {
      setFileError(false);
    }
  };

  const uploadDocument = (e) => {
    e.preventDefault();
    showLoading();

    if (
      Employee != "" &&
      documentType != "" &&
      file.length != 0 &&
      documentNo.trim() != 0
    ) {
      var data = new FormData();
      var imagedata = file;
      data.append("emp_id", Employee);
      data.append("document_type", documentType);
      data.append("file_data", imagedata);
      data.append("document_no", documentNo.trim());
      uploadEmployeeDocuments(data).then(
        (res) => {
          setSuccessOpen(true);
          setEmployee("");
          setDocumentType("");
          setFile([]);
          hideLoading();
          props.close();
          setResponse(res.data.message);
        },
        (err) => {
          setErrorOpen(true);
          setResponse(err.response.data.message);
          hideLoading();
        }
      );
    } else {
      if (Employee == "") {
        setErrorOpen(true);
        setResponse("Please Select Employee.");
        hideLoading();
      } else if (documentType == "") {
        setErrorOpen(true);
        setResponse("Please Select Document Type.");
        hideLoading();
      } else if (documentNo.trim() == "") {
        setErrorOpen(true);
        setResponse("Please enter document number");
        hideLoading();
      } else if (file.length <= 0) {
        setErrorOpen(true);
        setResponse("Please select File");
      }
      hideLoading();
    }
  };

  return (
    <>
      <Modal
        onBackdropClick={props.close}
        open={props.modalOpen}
        onClose={props.close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="add_hire_modal new_request_modal"
      >
        <Slide in={props.modalOpen}>
          <Box className="add_hire_modal_bx">
            <div className="modal_header">
              <Grid container>
                <Grid item>
                  <Typography id="modal-modal-title" variant="h6">
                    Add a new document
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton onClick={props.close} className="icon_close_bttn">
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </div>

            <div className="modal_body">
              <form className="register_form">
                <Grid
                  xs={12}
                  className="register_form modal_select_employe_dropdown"
                >
                  <TextField
                    variant="standard"
                    select
                    label="Employee"
                    fullWidth
                    value={Employee}
                    onChange={(e) => setEmployee(e.target.value)}
                  >
                    {allEmployeeArray.map((data) => (
                      <MenuItem
                        value={data.emp_id.row_id}
                        key={data.emp_id.row_id}
                      >
                        {data.emp_id.full_name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid
                  xs={12}
                  className="register_form modal_select_employe_dropdown"
                >
                  <TextField
                    value={documentType}
                    onChange={(e) => setDocumentType(e.target.value)}
                    id="Select-employee"
                    select
                    label="Document type"
                    variant="standard"
                    fullWidth
                  >
                    <MenuItem value="Drivers Licence">Drivers Licence</MenuItem>
                    <MenuItem value="Passports">Passports</MenuItem>
                    <MenuItem value="Aadhar card">Aadhar card</MenuItem>
                    <MenuItem value="PAN Card">PAN Card</MenuItem>
                    <MenuItem value="Voter ID">Voter ID</MenuItem>
                  </TextField>
                </Grid>
                <Grid xs={12}>
                  <TextField
                    required
                    label={documentType ? documentType + "  Number" : null}
                    variant="standard"
                    value={documentNo}
                    onChange={(e) => setDocumentNo(e.target.value)}
                    inputProps={{
                      autocomplete: "off",
                      maxLength: 12,
                      pattern: "^[a-zA-Z0-9]{12}$",
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid xs={12} className="file_upload">
                  <Button
                    className={"theme_bttn"}
                    component="label"
                    startIcon={
                      !file.name ? <CloudUploadIcon /> : <CheckCircleIcon />
                    }
                  >
                    {!file.name ? "Upload" : file.name}
                    <input hidden type="file" required onChange={UploadFile} />
                  </Button>
                </Grid>
              </form>
              {file.name ? (
                <>
                  {fileError ? (
                    <Grid item className="dotted_lighting_bx variant_red">
                      <Typography variant="body1">
                        File size is {fileSize.toFixed(2)} MB
                      </Typography>
                    </Grid>
                  ) : (
                    <Grid item className="dotted_lighting_bx variant_green">
                      <Typography variant="body1">
                        File size is {fileSize.toFixed(2)} MB
                      </Typography>
                    </Grid>
                  )}
                </>
              ) : null}
            </div>
            <div className="modal_footer">
              <Grid container className="modal_foot_bttn_container">
                <Button
                  className="theme_bttn fill"
                  onClick={uploadDocument}
                  disabled={loading || fileError}
                  startIcon={
                    loading && <CircularProgress color="inherit" size={20} />
                  }
                >
                  Add document
                </Button>
                <Button className="theme_bttn" onClick={props.close}>
                  Cancel
                </Button>
              </Grid>
            </div>
          </Box>
        </Slide>
      </Modal>
    </>
  );
};
