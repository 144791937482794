import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  FormLabel,
  IconButton,
  MenuItem,
  Modal,
  Slide,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Grid } from "Utils/muiImport";
import { CloseIcon } from "Icons/Icons";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { uploadCompanyDocuments } from "Services/DocumentServices";
import useNotification from "Components/CommonComponents/AlertNotifications/AlertNotifications";
import ErrorIcon from "@mui/icons-material/Error";

import {
  DatePicker,
  DesktopDatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";
import { getDocumentTypes } from "Services/CompanyServices";
import { Error } from "Utils/Success&Errorfunctions";
import { useSnackbar } from "notistack";

export const CompanyDocumentModal = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [msg, sendNotification] = useNotification();

  const [loading, setLoading] = useState(false);

  const [documentTypeArray, setDocumentTypeArray] = useState([]);

  const [documentType, setDocumentType] = useState("");
  const [tempDocumentType, setTempDocumentType] = useState("");
  const [file, setFile] = useState([]);
  const [fileSize, setFileSize] = useState("");
  const [fileError, setFileError] = useState(false);
  const [documentNo, setDocumentNo] = useState("");

  const [startDate, setStartDate] = useState(
    moment(Date.now()).format("MMMM/YYYY")
  );

  const [endDate, setEndDate] = useState(
    moment(Date.now()).format("MMMM/YYYY")
  );

  useEffect(() => {
    getDocuments();
    setDocumentType("");
    setDocumentNo("");
    setFile([]);

    setStartDate(moment(Date.now()).format("MMMM/YYYY"));
    setEndDate(moment(Date.now()).format("MMMM/YYYY"));
  }, [props.modalOpen]);

  useEffect(() => {
    validateSelectedFile();
  }, [file]);

  useEffect(() => {
    if (documentType == "bank_statements") {
      setTempDocumentType("Bank Statement");
    } else if (documentType == "sales_invoice") {
      setTempDocumentType("Sales Invoice");
    } else if (documentType == "expense_bills") {
      setTempDocumentType("Expense Bills");
    } else if (documentType == "tax_related") {
      setTempDocumentType("Tax Related");
    } else if (documentType == "other") {
      setTempDocumentType("Other");
    } else {
      setTempDocumentType("");
    }
  }, [documentType]);

  const showLoading = () => {
    setLoading(true);
  };
  const hideLoading = () => {
    setLoading(false);
  };

  const getDocuments = () => {
    getDocumentTypes().then(
      (res) => {
        setDocumentTypeArray(res.data);
        hideLoading();
      },
      (err) => {
        console.log(err);
        hideLoading();
      }
    );
  };

  const UploadFile = (e) => {
    if (e.target.files && e.target.files.length) {
      var fileName = e.target.files[0].name;
      var newName = fileName.replace(/[^a-zA-Z0-9.()]/g, "_");
      // console.log(e.target.files[0]);
      // const fileData = new File([file], newName);
      // console.log(fileData);
      setFile(e.target.files[0]);
      validateSelectedFile();
    }
  };

  const validateSelectedFile = () => {
    const MAX_FILE_SIZE = 2048;
    if (!file) {
      sendNotification({
        msg: "Please choose a file",
        variant: "error",
      });
      return;
    }

    const fileSizeKiloBytes = file.size / 1024;
    setFileSize(fileSizeKiloBytes * 0.001);

    if (fileSizeKiloBytes >= MAX_FILE_SIZE) {
      setFileError(true);
      sendNotification({
        msg: "File size is greater than maximum limit of 2MB",
        variant: "error",
      });
    } else {
      setFileError(false);
    }
  };

  const uploadDocument = (e) => {
    e.preventDefault();
    showLoading();

    const currentDate = new Date();
    if (startDate !== null) {
      var formateStartDate = moment(startDate).format("YYYY-MM-DD");
      // var formateEndDate = moment(endDate).format("YYYY-MM-DD");
      var newDate = new Date(startDate);
      if (
        documentType != "" &&
        file.length != 0 &&
        formateStartDate !== "Invalid date" &&
        newDate.getTime() < currentDate.getTime()
      ) {
        var data = new FormData();
        var imagedata = file.name;
        var newName = imagedata.replace(/[^a-zA-Z0-9.()]/g, "_");
        // var invalidFileName = newName.split(".");
        // if (invalidFileName.length > 2) {
        //   hideLoading();
        //   return sendNotification({
        //     msg: "No Special characters allowed in docment name!",
        //     variant: "error",
        //   });
        // }
        const fileData = new File([file], newName);
        console.log(fileData);
        data.append("from_date", formateStartDate);
        data.append("to_date", formateStartDate);
        data.append("doc_type", documentType);
        data.append("file_data", fileData);
        data.append("company_id", localStorage.getItem("CompanyRowID"));
        // data.append("document_no", documentNo.trim());

        uploadCompanyDocuments(data).then(
          (res) => {
            var variant = "success";
            enqueueSnackbar(res.data.message, { variant });

            setDocumentType("");
            setFile([]);
            hideLoading();
            props.close();
            props.documentListingAPI();
          },
          (err) => {
            var errors = Error(err.response.data.message);

            if (Array.isArray(errors)) {
              errors.forEach((element) => {
                sendNotification({
                  msg: `Please enter valid ${element}`,
                  variant: "error",
                });
              });
              hideLoading();
            } else {
              sendNotification({
                msg: err.response.data.message,
                variant: "error",
              });
              hideLoading();
            }
            hideLoading();
          }
        );
      } else {
        if (documentType == "") {
          sendNotification({
            msg: "Please Select Document Type.",
            variant: "error",
          });
          hideLoading();
        } else if (startDate === null) {
          sendNotification({
            msg: "Please enter valid Start Date!",
            variant: "error",
          });
          hideLoading();
        } else if (newDate.getTime() > currentDate.getTime()) {
          sendNotification({
            msg: "Future Date is not allowed!",
            variant: "error",
          });
          hideLoading();
        } else if (formateStartDate === "Invalid date") {
          sendNotification({
            msg: "Please select valid start date",
            variant: "error",
          });
          hideLoading();
        }
        // else if (formateEndDate === "Invalid date") {
        //   sendNotification({
        //     msg: "Please select valid end date",
        //     variant: "error",
        //   });
        //   hideLoading();
        // }
        else if (file.length <= 0) {
          sendNotification({
            msg: "Please select File",
            variant: "error",
          });
          hideLoading();
        } else {
          sendNotification({
            msg: "Some error occured please try again later.",
            variant: "error",
          });
          hideLoading();
        }
        hideLoading();
      }
    } else {
      if (startDate === null) {
        sendNotification({
          msg: "Please enter valid start date.",
          variant: "error",
        });
      } else if (newDate.getTime() > currentDate.getTime()) {
        sendNotification({
          msg: "Future Date is not allowed!",
          variant: "error",
        });
        hideLoading();
      } else {
        sendNotification({
          msg: "Some error occured please try again later.",
          variant: "error",
        });
      }
    }
  };

  return (
    <>
      <Modal
        onBackdropClick={props.close}
        open={props.modalOpen}
        onClose={props.close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="add_hire_modal new_request_modal"
      >
        <Slide in={props.modalOpen}>
          <Box className="add_hire_modal_bx">
            <div className="modal_header">
              <Grid container>
                <Grid item>
                  <Typography id="modal-modal-title" variant="h6">
                    Add a new document
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton onClick={props.close} className="icon_close_bttn">
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </div>
            <form onSubmit={(e) => uploadDocument(e)}>
              <div className="modal_body">
                <div className="register_form">
                  <Grid
                    xs={12}
                    className="register_form modal_select_employe_dropdown"
                  >
                    <TextField
                      required
                      value={documentType}
                      onChange={(e) => setDocumentType(e.target.value)}
                      id="Select-employee"
                      select
                      label="Document type"
                      variant="standard"
                      fullWidth
                    >
                      {documentTypeArray.map((data) => (
                        <MenuItem value={data.row_id} key={data.row_id}>
                          {data.name}
                        </MenuItem>
                      ))}
                      {/* <MenuItem value="sales_invoice">Sales invoices</MenuItem>
                  <MenuItem value="expense_bills">Expense Bills </MenuItem>
                  <MenuItem value="tax_related">Tax related</MenuItem>
                  <MenuItem value="other"> Other </MenuItem> */}
                    </TextField>
                  </Grid>
                  <Grid
                    xs={12}
                    container
                    flexDirection={{ xs: "row", sm: "row" }}
                    className="date_picker_employee_wrap"
                  >
                    <Grid item xs={12} className="date_picker_employee">
                      <Grid xs={12} spacing={0}>
                        <FormLabel>Select date for document</FormLabel>
                      </Grid>
                      <Grid xs={12} spacing={0}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            disableFuture={true}
                            views={["year", "month"]}
                            inputFormat="MMMM,YYYY"
                            value={startDate}
                            onChange={(newValue) => {
                              setStartDate(newValue.$d);
                            }}
                            renderInput={(params) => (
                              <TextField varient="standard" {...params} />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                    {/* <Grid item xs={6} className="date_picker_employee">
                      <Grid xs={12} spacing={0}>
                        <FormLabel>End Date</FormLabel>
                      </Grid>
                      <Grid xs={12} spacing={0}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            minDate={startDate}
                            disableFuture={true}
                            views={["year", "month"]}
                            inputFormat="MMMM,YYYY"
                            value={endDate}
                            onChange={(newValue) => {
                              setEndDate(newValue.$d);
                            }}
                            renderInput={(params) => (
                              <TextField varient="standard" {...params} />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                    </Grid> */}
                  </Grid>
                  <Grid xs={12} className="file_upload">
                    <Button
                      className={!fileError ? "theme_bttn" : "theme_bttn error"}
                      component="label"
                      startIcon={
                        !fileError ? (
                          !file.name ? (
                            <CloudUploadIcon />
                          ) : (
                            <CheckCircleIcon />
                          )
                        ) : (
                          <ErrorIcon />
                        )
                      }
                    >
                      {!fileError
                        ? !file.name
                          ? "Upload"
                          : file.name
                        : "File is not valid"}
                      <input
                        hidden
                        type="file"
                        onChange={UploadFile}
                        accept=".JPG,.JPEG,.PNG,.pdf,.doc,.docx,.xlsx,.xls,.csv,.zip,.rar"
                      />
                    </Button>
                  </Grid>
                </div>
                {file.name ? (
                  <>
                    {fileError ? (
                      <Grid item className="dotted_lighting_bx variant_red">
                        <Typography variant="body1">
                          File size is {fileSize.toFixed(2)} MB
                        </Typography>
                      </Grid>
                    ) : (
                      <Grid item className="dotted_lighting_bx variant_green">
                        <Typography variant="body1">
                          File size is {fileSize.toFixed(2)} MB
                        </Typography>
                      </Grid>
                    )}
                  </>
                ) : (
                  <>
                    <Grid item className="dotted_lighting_bx variant_green">
                      <Typography variant="body1">
                        Maximum File Size 2MB
                      </Typography>
                    </Grid>
                    <Grid item className="dotted_lighting_bx variant_green">
                      <Typography variant="body1">
                        Supported File
                        Format:.JPG,.JPEG,.PNG,.pdf,.doc,.docx,.xlsx,.xls.,.csv,.zip,.rar
                      </Typography>
                    </Grid>
                  </>
                )}
              </div>
              <div className="modal_footer">
                <Grid container className="modal_foot_bttn_container">
                  <Button
                    className="theme_bttn fill"
                    type="submit"
                    disabled={loading || fileError}
                    startIcon={
                      loading && <CircularProgress color="inherit" size={20} />
                    }
                  >
                    Add document
                  </Button>
                  <Button className="theme_bttn" onClick={props.close}>
                    Cancel
                  </Button>
                </Grid>
              </div>
            </form>
          </Box>
        </Slide>
      </Modal>
    </>
  );
};
