import { getApi } from "./commonservice";

export function getBillingList(
  page = 1,
  rowPerPage = 5,
  asc = "asc",
  invoice_amount = "invoice_amount",
  search = "",
  filterValue = "all",
  filterBy = "status"
) {
  if (filterValue !== "all") {
    return getApi(
      `billings?sort=${asc}&sort_by=${invoice_amount}&page=${page}&per_page=${rowPerPage}&search=${search}&filter_by=${filterBy}&filter_val=${filterValue}`
    );
  } else {
    return getApi(
      `billings?sort=${asc}&sort_by=${invoice_amount}&page=${page}&per_page=${rowPerPage}&search=${search}`
    );
  }
}
