import "./Register2.css";
import { React, useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Register2MailIllustration,
} from "./Register2Imports";
import { useAuth0 } from "@auth0/auth0-react";
import { AuthSidebar, Button } from "Components/Login/LoginImport";
import {
  login,
  resendVerificationMail,
} from "Services/Login&Registrationservice";
import { getCompanyData } from "Services/CompanyServices";
import jwtDecode from "jwt-decode";
import { Link, useNavigate } from "react-router-dom";
import { getCommonSettings } from "Services/AddEmployeeAndContractor";
import { jwt_decode } from "../Register1/Register1Imports";

import useNotification from "Components/CommonComponents/AlertNotifications/AlertNotifications";
import { Error } from "Utils/Success&Errorfunctions";

export const Register2 = (props) => {
  const domainForAuth0 = process.env.REACT_APP_DOMAIN_FOR_AUTH_0;

  const [msg, sendNotification] = useNotification();

  const { user, getAccessTokenSilently } = useAuth0();

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");

  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(30);

  useEffect(() => {
    accessToken();
    if (window.location.search.includes("success")) {
      localStorage.setItem("IsEmailVerified", true);
    } else {
      localStorage.setItem("IsEmailVerified", false);
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const accessToken = async () => {
    var token = jwt_decode(
      JSON.parse(localStorage.getItem("JWT")).access_token
    );
    setEmail(token.email);
  };

  const showLoading = () => {
    setLoading(true);
  };

  const hideLoading = () => {
    setLoading(false);
  };

  const resendVerificatiEmail = async () => {
    setMinutes(1);
    setSeconds(30);
    var data = {
      user_id: user?.sub,
    };
    resendVerificationMail(data).then(
      (res) => {
        sendNotification({
          msg: res.data.message,
          variant: "success",
        });
      },
      (err) => {
        var errors = Error(err.response.data.message);

        if (Array.isArray(errors)) {
          errors.forEach((element) => {
            sendNotification({
              msg: `Please enter valid ${element}`,
              variant: "error",
            });
          });
        } else {
          sendNotification({
            msg: err.response.data.message,
            variant: "error",
          });
        }
      }
    );
  };

  return (
    <>
      <Grid container spacing={2} xs={12} className="login_grid">
        <AuthSidebar logoutBttn>
          <div className="register-sidebar">
            <Typography variant="h3" gutterBottom>
              Sign Up with Inrole
            </Typography>
            <Typography variant="body2" gutterBottom>
              Get Started by creating your account
            </Typography>
          </div>
        </AuthSidebar>

        <Grid xs={8} item className="login_content_grid_item">
          <div className="login_cont register_cont">
            <Typography variant="h3" gutterBottom>
              Please confirm your mail
            </Typography>
            <img src={Register2MailIllustration} alt="Mail Confirmation" />
            <Typography variant="body2" gutterBottom>
              Please go to your mailbox and confirm your email ({email}). Be
              sure to check the spam folder.
            </Typography>

            <div className="login_bttn_wrapper">
              {seconds > 0 || minutes > 0 ? (
                <>
                  <Grid container direction={"column"}>
                    <Grid item>
                      <Typography
                        variant="body2"
                        className="page_head_para"
                        style={{ margin: "0" }}
                      >
                        Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}
                        :{seconds < 10 ? `0${seconds}` : seconds}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2" className="page_head_para">
                        <Link variant="contained" type="button" disabled>
                          Resend confirmation email
                        </Link>
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid container direction={"column"}>
                    <Grid item>
                      <Typography
                        variant="body2"
                        className="page_head_para"
                        style={{ margin: "0" }}
                      >
                        Didn't recieve code?
                      </Typography>{" "}
                    </Grid>
                    <Grid item>
                      <Typography variant="body2" className="page_head_para">
                        <Link
                          variant="contained"
                          type="button"
                          onClick={() => resendVerificatiEmail()}
                        >
                          Resend confirmation email
                        </Link>
                      </Typography>{" "}
                    </Grid>
                  </Grid>
                </>
              )}
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};
