import { React, useEffect, useState } from "react";
import { PropTypes } from "Components/Dashboard/DashboardImports";
import { Button, Grid, Typography } from "Utils/muiImport";
import { Box } from "@mui/system";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  ButtonGroup,
  CircularProgress,
  Collapse,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Skeleton,
  Slide,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Zoom,
} from "@mui/material";

import { SearchIcon } from "Components/Teams/Teams/TeamImpoerts";

import { HelpButton } from "Components/CommonComponents/Bottom_Help_Button/HelpButton";

import { DataNotFound, FilterIcon, TeamEditIcon } from "Icons/Icons";
import {
  documentDownload,
  documentListingForCompany,
} from "Services/DocumentServices";
import DownloadSharpIcon from "@mui/icons-material/DownloadSharp";
import VisibilityIcon from "@mui/icons-material/Visibility";

import useNotification from "Components/CommonComponents/AlertNotifications/AlertNotifications";
import moment from "moment";
import Backdrop from "@mui/material/Backdrop";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { CompanyDocumentModal } from "Components/CommonComponents/Modals/Document Modal/CompanyDocumentModal";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CloseIcon from "@mui/icons-material/Close";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { useSnackbar } from "notistack";
import { Error } from "Utils/Success&Errorfunctions";
import { TabPanel, a11yProps } from "Utils/TabPanel";
import { TransitionGroup } from "react-transition-group";

export const CompanyDocuuments = (props) => {
  const downloadURL = process.env.REACT_APP_DOWNLOAD_DOCUMENT;

  const { enqueueSnackbar } = useSnackbar();
  const [msg, sendNotification] = useNotification();

  const [loading, setLoading] = useState(true);
  const [loadingArray, setLoadingArray] = useState([]);

  const [BackdropOpen, setBackdropOpen] = useState(false);
  const [imgSRC, setImgSRC] = useState("");
  const [value, setValue] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [allDocumentArray, setAllDocumentArray] = useState([]);

  const [documentSearch, setDocumentSearch] = useState("");
  const [totalDocument, setTotalDocument] = useState(0);
  const [pageForDocument, setPageForDocument] = useState(0);
  const [rowsPerPageForDocuments, setRowsPerPageForDocuments] = useState(5);

  const [editDialog, setEditDialog] = useState(false);
  const [filterDialog, setFilterDialog] = useState(false);

  const [filterColumn, setFilterColumn] = useState("");
  const [rowName, setRowName] = useState([
    "Upload Date",
    "Type Of File",
    "Name Of File",
    "Experts Remark",
    "Actions",
  ]);
  const [checked, setChecked] = useState([
    "Upload Date",
    "Type Of File",
    "Name Of File",
    "Experts Remark",
    "Actions",
  ]);
  const [filterStatus, setFilterStatus] = useState([
    { name: "Bank Statements", value: "bank_statements" },
    { name: "Sales Invoice", value: "sales_invoice" },
    { name: "Expense Bills", value: "expense_bills" },
    { name: "Tax Related", value: "tax_related" },
    { name: "Other", value: "other" },
  ]);
  const [checkedForFilterStatus, setCheckedForFilterStatus] = useState([
    { name: "All", value: "all" },
  ]);

  useEffect(() => {
    const getData = setTimeout(() => {
      documentListing();
    }, 200);
    return () => clearTimeout(getData);
  }, [rowsPerPageForDocuments, documentSearch, checkedForFilterStatus]);

  const editDialogToggle = () => {
    setEditDialog(!editDialog);
  };

  const showLoading = () => {
    setLoading(true);
  };

  const hideLoading = () => {
    setLoading(false);
  };

  function handleToggle(value) {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onDocumentPageChange = (event, newpage) => {
    setPageForDocument(newpage);
    documentListing(newpage + 1);
  };

  const downloadImage = async (imageSrc) => {
    await fetch(imageSrc)
      .then((response) => response.blob())
      .then((blob) => {
        let blobUrl = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.download = imageSrc.replace(/^.*[\\\/]/, "");
        a.href = blobUrl;
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  };

  const documentListing = (pageNo = 1) => {
    showLoading();
    documentListingForCompany(
      pageNo,
      rowsPerPageForDocuments,
      documentSearch,
      checkedForFilterStatus[0].value
    ).then(
      (res) => {
        setTotalDocument(res.data.total);
        setAllDocumentArray(res.data.data);
        hideLoading();
      },
      (err) => {
        console.log(err);
        setTotalDocument(0);
        setAllDocumentArray([]);
        hideLoading();
      }
    );
  };

  const documentLink = (name, link, index, doc_id) => {
    showLoading();
    var downloadData = {
      document_name: name,
      file_path: link,
      doc_id: doc_id,
    };

    setLoadingArray([index]);

    documentDownload(downloadData).then(
      (res) => {
        var downloadLink = downloadURL + link;
        downloadImage(downloadLink);
        var variant = "success";
        enqueueSnackbar(res.data.message, { variant });
        hideLoading();
        if (res.data.message) {
          setLoadingArray([]);
        } else {
          setLoadingArray([index]);
        }
      },
      (err) => {
        var errors = Error(err.response.data.message);

        if (Array.isArray(errors)) {
          errors.forEach((element) => {
            sendNotification({
              msg: `Please enter valid ${element}`,
              variant: "error",
            });
          });
          hideLoading();
        } else {
          sendNotification({
            msg: err.response.data.message,
            variant: "error",
          });
        }
        hideLoading();
        setLoadingArray([]);
      }
    );
  };

  const viewImage = (name, link, index, doc_id) => {
    // setBackdropOpen(true);
    showLoading();
    setImgSRC(null);
    var downloadData = {
      document_name: name,
      file_path: link,
      doc_id: doc_id,
    };
    setLoadingArray([index]);
    documentDownload(downloadData).then(
      (res) => {
        var downloadLink = downloadURL + link;
        window.open(downloadLink, "_blank", "noopener,noreferrer");
        // setImgSRC(downloadLink);
        hideLoading();
        if (res.data.message) {
          setLoadingArray([]);
        } else {
          setLoadingArray([index]);
        }
      },
      (err) => {
        var errors = Error(err.response.data.message);

        if (Array.isArray(errors)) {
          errors.forEach((element) => {
            sendNotification({
              msg: `Please enter valid ${element}`,
              variant: "error",
            });
          });
          hideLoading();
        } else {
          sendNotification({
            msg: err.response.data.message,
            variant: "error",
          });
        }
        setBackdropOpen(false);
        hideLoading();
        setLoadingArray([]);
      }
    );
  };

  function handleToggleForFilterStatus(value) {
    var currentIndex = checkedForFilterStatus.indexOf(value);
    var newChecked = [...checkedForFilterStatus];
    if (currentIndex === -1) {
      newChecked.pop();
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    if (newChecked.length <= 0) {
      setCheckedForFilterStatus([{ name: "All", value: "all" }]);
    } else {
      setCheckedForFilterStatus(newChecked);
    }
  }
  return (
    <>
      <Slide in={true} direction="left">
        <Grid item className={props.className}>
          <Grid container className="team_top_heading">
            <Grid item>
              <Typography className="page_heading">
                Company Documents
              </Typography>
            </Grid>

            <Grid item>
              <Button
                onClick={() => {
                  setModalOpen(true);
                }}
                variant="contained"
                startIcon={<FileUploadIcon />}
                className="theme_bttn fill"
              >
                Upload Document
              </Button>
            </Grid>

            <Grid xs={12} item>
              <Typography variant="body2" className="page_head_para">
                All documents you upload can always be found on this page as
                well as the contracts that are sent out to your contractors.
              </Typography>
            </Grid>
          </Grid>
          <div className="dash_tabs">
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs "
            >
              <Tab
                label={
                  <Typography>Company Documents({totalDocument})</Typography>
                }
                {...a11yProps(0)}
              />
            </Tabs>
            <TabPanel value={value} index={0} className="tab-pannel">
              <div
                className={`data_table ${
                  !allDocumentArray.length && "no_data_found"
                }`}
              >
                <Grid container className="data_table_filters_grid">
                  <TextField
                    spellCheck="false"
                    value={documentSearch}
                    onChange={(e) => setDocumentSearch(e.target.value)}
                    className="outer_searchfilled"
                    placeholder="Search"
                    id="outlined-start-adornment"
                    InputProps={{
                      autoComplete: "off",
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <div className="table_edit_controller">
                    <Grid container className="filter_group">
                      <IconButton
                        onClick={(e) => setFilterDialog(!filterDialog)}
                      >
                        <FilterIcon />
                      </IconButton>
                      <Typography>Filter</Typography>
                    </Grid>{" "}
                    {filterDialog ? (
                      <Zoom in={filterDialog}>
                        <div className="edit_filter_menu is_small">
                          <Button
                            className="edit_filter_menu_close"
                            onClick={() => setFilterDialog(!filterDialog)}
                            disableRipple
                          ></Button>
                          <div className="edit_filter_menu_bx">
                            <List
                              subheader={
                                <ListSubheader>Filter Data</ListSubheader>
                              }
                            >
                              {filterStatus.map((data, index) => (
                                <ListItem key={index}>
                                  <Switch
                                    edge="end"
                                    onChange={() =>
                                      handleToggleForFilterStatus(data)
                                    }
                                    checked={
                                      checkedForFilterStatus.indexOf(data) !==
                                      -1
                                    }
                                    className={
                                      checkedForFilterStatus.indexOf(data) !==
                                      -1
                                        ? "active"
                                        : "inactive"
                                    }
                                    inputProps={{
                                      "aria-labelledby": data.name,
                                    }}
                                  />
                                  <ListItemText
                                    id={data.name}
                                    primary={data.name}
                                    disableTypography
                                  />
                                </ListItem>
                              ))}
                            </List>
                          </div>
                        </div>
                      </Zoom>
                    ) : null}
                  </div>
                  <div className="table_edit_controller">
                    <IconButton onClick={editDialogToggle}>
                      <TeamEditIcon />
                    </IconButton>

                    {editDialog ? (
                      <Zoom in={editDialog}>
                        <div className="edit_filter_menu">
                          <Button
                            className="edit_filter_menu_close"
                            onClick={editDialogToggle}
                            disableRipple
                          ></Button>
                          <div className="edit_filter_menu_bx">
                            <TextField
                              placeholder="Search Columns"
                              fullWidth
                              value={filterColumn}
                              id="outlined-start-adornment"
                              sx={{
                                mt: "10px",
                                ml: "10px",
                                mr: "5px",
                                width: "auto",
                              }}
                              onChange={(e) => setFilterColumn(e.target.value)}
                              InputProps={{
                                autoComplete: "off",
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <SearchIcon />
                                  </InputAdornment>
                                ),
                              }}
                            />{" "}
                            <List
                              subheader={
                                <ListSubheader>Original Columns</ListSubheader>
                              }
                            >
                              {" "}
                              <TransitionGroup>
                                {rowName
                                  .filter((data) => {
                                    return filterColumn.toLowerCase() == ""
                                      ? data
                                      : data
                                          .toLowerCase()
                                          .includes(filterColumn.toLowerCase());
                                  })
                                  .map((data, index) => (
                                    <Collapse key={data}>
                                      <ListItem key={index}>
                                        <Switch
                                          edge="end"
                                          onChange={() => handleToggle(data)}
                                          checked={checked.indexOf(data) !== -1}
                                          className={
                                            checked.indexOf(data) !== -1
                                              ? "active"
                                              : "inactive"
                                          }
                                          inputProps={{
                                            "aria-labelledby": data,
                                          }}
                                        />
                                        <ListItemText
                                          id={data}
                                          primary={data}
                                          disableTypography
                                        />
                                      </ListItem>
                                    </Collapse>
                                  ))}
                              </TransitionGroup>
                            </List>
                          </div>
                        </div>
                      </Zoom>
                    ) : null}
                  </div>
                </Grid>
                <div className="table_container">
                  <Table>
                    <TableHead>
                      <TableRow>
                        {checked.indexOf("Upload Date") >= 0 ? (
                          <TableCell component={"td"} align="left">
                            UPLOAD DATE
                          </TableCell>
                        ) : null}
                        {checked.indexOf("Type Of File") >= 0 ? (
                          <TableCell component={"td"} align="left">
                            TYPE OF FILE
                          </TableCell>
                        ) : null}
                        {checked.indexOf("Name Of File") >= 0 ? (
                          <TableCell component={"td"} align="left">
                            NAME OF FILE
                          </TableCell>
                        ) : null}
                        {checked.indexOf("Experts Remark") >= 0 ? (
                          <TableCell component={"td"} align="left">
                            EXPERT REMARKS
                          </TableCell>
                        ) : null}
                        {checked.indexOf("Actions") >= 0 ? (
                          <TableCell component={"td"} align="left">
                            ACTIONS
                          </TableCell>
                        ) : null}
                      </TableRow>
                    </TableHead>
                    {allDocumentArray.length && !loading ? (
                      <TableBody>
                        {allDocumentArray.map((row, index) => (
                          <TableRow key={row.row_id} id={row.row_id}>
                            {checked.indexOf("Upload Date") >= 0 ? (
                              <TableCell scope="row" align="left">
                                {moment(new Date(row.created_date)).format(
                                  "DD-MM-YYYY"
                                )}
                              </TableCell>
                            ) : null}
                            {checked.indexOf("Type Of File") >= 0 ? (
                              <TableCell>
                                {row.doc_type !== null
                                  ? row.doc_type.name
                                  : null}
                              </TableCell>
                            ) : null}
                            {checked.indexOf("Name Of File") >= 0 ? (
                              <TableCell>{row.document_name}</TableCell>
                            ) : null}
                            {checked.indexOf("Experts Remark") >= 0 ? (
                              <TableCell>{row.expert_remarks}</TableCell>
                            ) : null}
                            {checked.indexOf("Actions") >= 0 ? (
                              <TableCell component={"td"} align="left">
                                <ButtonGroup>
                                  {!loadingArray.includes(index) ? (
                                    <IconButton
                                      color="dark"
                                      variant="text"
                                      onClick={() => {
                                        viewImage(
                                          row.document_name,
                                          row.download_link,
                                          index,
                                          row.row_id
                                        );
                                      }}
                                    >
                                      <VisibilityIcon />
                                    </IconButton>
                                  ) : loadingArray.includes(index) ? (
                                    <IconButton disabled>
                                      <CircularProgress size={20} />
                                    </IconButton>
                                  ) : (
                                    <IconButton
                                      color="dark"
                                      variant="text"
                                      onClick={() =>
                                        documentLink(
                                          row.document_name,
                                          row.download_link,
                                          index,
                                          row.row_id
                                        )
                                      }
                                    >
                                      <DownloadSharpIcon color="dark" />
                                    </IconButton>
                                  )}

                                  {!loadingArray.includes(index) ? (
                                    <IconButton
                                      color="dark"
                                      variant="text"
                                      onClick={() =>
                                        documentLink(
                                          row.document_name,
                                          row.download_link,
                                          index,
                                          row.row_id
                                        )
                                      }
                                    >
                                      <DownloadSharpIcon color="dark" />
                                    </IconButton>
                                  ) : loadingArray.includes(index) ? (
                                    <IconButton disabled>
                                      <CircularProgress size={20} />
                                    </IconButton>
                                  ) : (
                                    <IconButton
                                      color="dark"
                                      variant="text"
                                      onClick={() =>
                                        documentLink(
                                          row.document_name,
                                          row.download_link,
                                          index,
                                          row.row_id
                                        )
                                      }
                                    >
                                      <DownloadSharpIcon color="dark" />
                                    </IconButton>
                                  )}
                                </ButtonGroup>
                              </TableCell>
                            ) : null}
                          </TableRow>
                        ))}
                      </TableBody>
                    ) : !loading ? (
                      <>
                        <TableBody>
                          <TableRow>
                            <TableCell colSpan={10}>
                              <Zoom in={!allDocumentArray.length}>
                                <Grid className="data_table_inner_notfound">
                                  <DataNotFound />
                                  <Typography>
                                    Nothing needs attention right now
                                  </Typography>
                                  <Typography variant="body2">
                                    When your request changes or documents, they
                                    appear here
                                  </Typography>
                                </Grid>
                              </Zoom>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </>
                    ) : (
                      <TableBody>
                        <TableRow>
                          <TableCell colSpan={10}>
                            <Grid className="data_table_inner_notfound">
                              <CircularProgress />
                            </Grid>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </div>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={totalDocument}
                  rowsPerPage={rowsPerPageForDocuments}
                  page={pageForDocument}
                  onPageChange={onDocumentPageChange}
                  onRowsPerPageChange={(event) => {
                    setPageForDocument(0);
                    setRowsPerPageForDocuments(+event.target.value);
                  }}
                />
              </div>
            </TabPanel>
          </div>
        </Grid>
      </Slide>
      <HelpButton />
      <CompanyDocumentModal
        modalOpen={modalOpen}
        close={() => setModalOpen(false)}
        documentListingAPI={() => documentListing()}
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={BackdropOpen}
      >
        <IconButton
          className="single_drop_close_bttn"
          onClick={() => setBackdropOpen(false)}
        >
          <CloseIcon />
        </IconButton>
        {imgSRC ? (
          <ImageList cols={1}>
            <ImageListItem>
              {/* <DocViewer
                pluginRenderers={DocViewerRenderers}
                documents={imgSRC}
              /> */}
              <img src={imgSRC} alt="" className="single_drop_img" />
            </ImageListItem>
          </ImageList>
        ) : (
          <CircularProgress size={80} />
        )}
      </Backdrop>
    </>
  );
};
