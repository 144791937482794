import "./Incentives.css";
import { React, useEffect, useState } from "react";
import { PropTypes } from "Components/Dashboard/DashboardImports";
import { Button, Grid, Typography } from "Utils/muiImport";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { Box } from "@mui/system";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  List,
  ListSubheader,
  ListItem,
  Switch,
  ListItemText,
  Slide,
  Zoom,
  Collapse,
} from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";

import { getIncentives } from "Services/Incentive";
import IncentiveModal from "Components/CommonComponents/Modals/Incentive Modal/IncentiveModal";
import { HelpButton } from "Components/CommonComponents/Bottom_Help_Button/HelpButton";
import { FilterIcon, IncentiveLadder, TeamEditIcon } from "Icons/Icons";
import { TabPanel, a11yProps } from "Utils/TabPanel";
import { TransitionGroup } from "react-transition-group";

export const Incentives = (props) => {
  const [loading, setLoading] = useState(true);

  const [value, setValue] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);

  const [allIncentives, setAllIncentives] = useState([]);
  const [paidIncentives, setPaidIncentives] = useState([]);
  const [cancledIncentives, setCancledIncentives] = useState([]);

  const [totalIncentives, setTotalIncentives] = useState(0);
  const [totalPaidIncentive, setTotalPaidIncentive] = useState(0);
  const [totalCancledIncentive, setTotalCancledIncentive] = useState(0);

  const [rowsPerPageForIncentives, setRowsPerPageForIncentives] = useState(5);
  const [rowsPerPageForPaidIncentive, setRowsPerPageForPaidIncentive] =
    useState(5);
  const [rowsPerPageForCancledIncentive, setRowsPerPageForCancledIncentive] =
    useState(5);

  const [pageForIncentives, setPageForIncentives] = useState(0);
  const [pageForPaidIncentive, setPageForPaidIncentive] = useState(0);
  const [pageForCancledIncentive, setPageForCancledIncentive] = useState(0);

  const [SearchForIncentives, setSearchForIncentives] = useState("");
  const [searchForPaidIncentive, setSearchForPaidIncentive] = useState("");
  const [searchForCancelledIncentive, setSearchForCancelledIncentive] =
    useState("");

  const [editDialog, setEditDialog] = useState(false);
  const [filterDialog, setFilterDialog] = useState(false);

  const [filterColumnForUpComing, setfilterColumnForUpComing] = useState("");
  const [filterColumnForPaid, setfilterColumnForPaid] = useState("");
  const [filterColumnForCancelled, setfilterColumnForCancelled] = useState("");

  const [paidSearch, setPaidSearch] = useState("");
  const [cancelledSearch, setCancelledSearch] = useState("");

  const editDialogToggle = () => {
    setEditDialog(!editDialog);
  };

  const [checkedForUpComing, setCheckedForUpComing] = useState([
    "Employee",
    "Type",
    "Amount",
    "Amount Tax",
    "Payment Frequency",
    "Payment Cycle",
  ]);
  const [checkedForPaid, setCheckedForPaid] = useState([
    "Employee",
    "Type",
    "Amount",
    "Amount Tax",
    "Payment Frequency",
    "Payment Cycle",
  ]);
  const [checkedForCancelled, setCheckedForCancelled] = useState([
    "Employee",
    "Type",
    "Amount",
    "Amount Tax",
    "Payment Frequency",
    "Payment Cycle",
  ]);

  const [rowNameForUpComing, setRowNameForUpComing] = useState([
    "Employee",
    "Type",
    "Amount",
    "Amount Tax",
    "Payment Frequency",
    "Payment Cycle",
  ]);
  const [rowNameForPaid, setRowNameForPaid] = useState([
    "Employee",
    "Type",
    "Amount",
    "Amount Tax",
    "Payment Frequency",
    "Payment Cycle",
  ]);
  const [rowNameForCancelled, setRowNameForCancelled] = useState([
    "Employee",
    "Type",
    "Amount",
    "Amount Tax",
    "Payment Frequency",
    "Payment Cycle",
  ]);
  const [filterStatus, setFilterStatus] = useState([
    "All",
    "Signing Bonus",
    "Bonus",
    "Commission",
    "Allowance",
    "Stipend",
    "Other",
  ]);
  const [checkedForFilterStatus, setCheckedForFilterStatus] = useState(["All"]);

  const [checkedForFilterStatusForPaid, setCheckedForFilterStatusForPaid] =
    useState(["All"]);

  const [
    checkedForFilterStatusForCancelled,
    setCheckedForFilterStatusForCancelled,
  ] = useState(["All"]);

  function handleToggleForUpComing(value) {
    const currentIndex = checkedForUpComing.indexOf(value);
    const newChecked = [...checkedForUpComing];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheckedForUpComing(newChecked);
  }
  function handleToggleForPaid(value) {
    const currentIndex = checkedForPaid.indexOf(value);
    const newChecked = [...checkedForPaid];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheckedForPaid(newChecked);
  }
  function handleToggleForCancelled(value) {
    const currentIndex = checkedForCancelled.indexOf(value);
    const newChecked = [...checkedForCancelled];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheckedForCancelled(newChecked);
  }

  useEffect(() => {
    const getData = setTimeout(() => {
      getAllIncentive();
    }, 200);
    return () => clearTimeout(getData);
  }, [rowsPerPageForIncentives, SearchForIncentives, checkedForFilterStatus]);

  useEffect(() => {
    const getData = setTimeout(() => {
      getAllPaidIncentives();
    }, 200);
    return () => clearTimeout(getData);
  }, [rowsPerPageForPaidIncentive, paidSearch, checkedForFilterStatusForPaid]);

  useEffect(() => {
    const getData = setTimeout(() => {
      getAllCancledIncentives();
    }, 200);
    return () => clearTimeout(getData);
  }, [
    rowsPerPageForCancledIncentive,
    cancelledSearch,
    checkedForFilterStatusForCancelled,
  ]);

  const updateAllIncentive = () => {
    getAllIncentive();
    getAllPaidIncentives();
    getAllCancledIncentives();
  };

  const hideLoading = () => {
    setLoading(false);
  };
  const showLoading = () => {
    setLoading(true);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getAllIncentive = (pageNoForAll = 1) => {
    showLoading();
    getIncentives(
      pageNoForAll,
      rowsPerPageForIncentives,
      "upcoming",
      SearchForIncentives,
      checkedForFilterStatus[0].toLowerCase()
    ).then(
      (res) => {
        setAllIncentives(res.data.data);
        setTotalIncentives(res.data.total);
        hideLoading();
      },
      (err) => {
        console.log(err.message);
        props.unAuthorized(err.response.status);
        hideLoading();
      }
    );
  };
  const getAllPaidIncentives = (pageNo = 1) => {
    showLoading();
    getIncentives(
      pageNo,
      rowsPerPageForPaidIncentive,
      "paid",
      paidSearch,
      checkedForFilterStatusForPaid[0].toLowerCase()
    ).then(
      (res) => {
        setPaidIncentives(res.data.data);
        setTotalPaidIncentive(res.data.total);

        hideLoading();
      },
      (err) => {
        console.log(err.message);
        hideLoading();
      }
    );
  };
  const getAllCancledIncentives = (pageNo = 1) => {
    showLoading();
    getIncentives(
      pageNo,
      rowsPerPageForCancledIncentive,
      "cancel",
      cancelledSearch,
      checkedForFilterStatusForCancelled[0].toLowerCase()
    ).then(
      (res) => {
        setCancledIncentives(res.data.data);
        setTotalCancledIncentive(res.data.total);

        hideLoading();
      },
      (err) => {
        console.log(err.message);
        hideLoading();
      }
    );
  };

  const onIncentivePageChange = (event, newPage) => {
    setPageForIncentives(newPage);

    getAllIncentive(newPage + 1, 1, 1);
  };

  const onPaidIncentivePageChange = (event, newPage) => {
    setPageForPaidIncentive(newPage);

    getAllIncentive(1, newPage + 1, 1);
  };

  const onCancleIncentivePageChange = (event, newPage) => {
    setPageForCancledIncentive(newPage);

    getAllIncentive(1, 1, newPage + 1);
  };

  function handleToggleForFilterStatus(value) {
    var currentIndex = checkedForFilterStatus.indexOf(value);
    var newChecked = [...checkedForFilterStatus];
    if (currentIndex === -1) {
      newChecked.pop();
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    if (newChecked.length <= 0) {
      setCheckedForFilterStatus(["All"]);
    } else {
      setCheckedForFilterStatus(newChecked);
    }
  }

  function handleToggleForFilterStatusForPaid(value) {
    var currentIndex = checkedForFilterStatusForPaid.indexOf(value);
    var newChecked = [...checkedForFilterStatusForPaid];
    if (currentIndex === -1) {
      newChecked.pop();
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    if (newChecked.length <= 0) {
      setCheckedForFilterStatusForPaid(["All"]);
    } else {
      setCheckedForFilterStatusForPaid(newChecked);
    }
  }

  function handleToggleForFilterStatusForCancelled(value) {
    var currentIndex = checkedForFilterStatusForCancelled.indexOf(value);
    var newChecked = [...checkedForFilterStatusForCancelled];
    if (currentIndex === -1) {
      newChecked.pop();
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    if (newChecked.length <= 0) {
      setCheckedForFilterStatusForCancelled(["All"]);
    } else {
      setCheckedForFilterStatusForCancelled(newChecked);
    }
  }

  return (
    <>
      <Slide in={true} direction="left">
        <Grid item className={props.className}>
          <Grid container className="team_top_heading">
            <Grid item>
              <Typography variant="h3" className="page_heading">
                Incentive Payment
              </Typography>
            </Grid>
            <Grid item>
              <Button
                onClick={() => setModalOpen(true)}
                variant="contained"
                startIcon={<FavoriteBorderIcon />}
                className="theme_bttn fill"
              >
                Add incentive pay
              </Button>
            </Grid>
            <Grid xs={12} item>
              <Typography variant="body2" className="page_head_para">
                Pay out bonuses, commissions and other stipends to your
                employees.
              </Typography>
            </Grid>
          </Grid>

          <div className="dash_tabs">
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs "
            >
              <Tab
                label={<Typography>Upcoming ({totalIncentives})</Typography>}
                {...a11yProps(0)}
              />

              <Tab
                label={<Typography>Paid ({totalPaidIncentive})</Typography>}
                {...a11yProps(1)}
              />
              <Tab
                label={
                  <Typography>Cancelled ({totalCancledIncentive})</Typography>
                }
                {...a11yProps(2)}
              />
            </Tabs>
            <TabPanel value={value} index={0} className="tab-pannel">
              <div
                className={`data_table ${
                  !allIncentives.length && "no_data_found"
                }`}
              >
                <Grid container className="data_table_filters_grid">
                  <TextField
                    spellCheck="false"
                    value={SearchForIncentives}
                    onChange={(e) => setSearchForIncentives(e.target.value)}
                    className="outer_searchfilled"
                    placeholder="Search"
                    id="outlined-start-adornment"
                    InputProps={{
                      autoComplete: "off",
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <div className="table_edit_controller">
                    <Grid container className="filter_group">
                      <IconButton
                        onClick={(e) => setFilterDialog(!filterDialog)}
                      >
                        <FilterIcon />
                      </IconButton>
                      <Typography>Filter</Typography>
                    </Grid>{" "}
                    {filterDialog ? (
                      <Zoom in={filterDialog}>
                        <div className="edit_filter_menu is_small">
                          <Button
                            className="edit_filter_menu_close"
                            onClick={() => setFilterDialog(!filterDialog)}
                            disableRipple
                          ></Button>
                          <div className="edit_filter_menu_bx">
                            <List
                              subheader={
                                <ListSubheader>Filter Data</ListSubheader>
                              }
                            >
                              {filterStatus.map((data, index) => (
                                <ListItem key={index}>
                                  <Switch
                                    edge="end"
                                    onChange={() =>
                                      handleToggleForFilterStatus(data)
                                    }
                                    checked={
                                      checkedForFilterStatus.indexOf(data) !==
                                      -1
                                    }
                                    className={
                                      checkedForFilterStatus.indexOf(data) !==
                                      -1
                                        ? "active"
                                        : "inactive"
                                    }
                                    inputProps={{
                                      "aria-labelledby": data,
                                    }}
                                  />
                                  <ListItemText
                                    id={data}
                                    primary={data}
                                    disableTypography
                                  />
                                </ListItem>
                              ))}
                            </List>
                          </div>
                        </div>
                      </Zoom>
                    ) : null}
                  </div>
                  <div className="table_edit_controller">
                    <IconButton onClick={editDialogToggle}>
                      <TeamEditIcon />
                    </IconButton>
                    {editDialog ? (
                      <Zoom in={editDialog}>
                        <div className="edit_filter_menu">
                          <Button
                            className="edit_filter_menu_close"
                            onClick={editDialogToggle}
                            disableRipple
                          ></Button>
                          <div className="edit_filter_menu_bx">
                            <TextField
                              placeholder="Search Columns"
                              fullWidth
                              value={filterColumnForUpComing}
                              onChange={(e) =>
                                setfilterColumnForUpComing(e.target.value)
                              }
                              InputProps={{
                                autoComplete: "off",
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <SearchIcon />
                                  </InputAdornment>
                                ),
                              }}
                            />{" "}
                            <List
                              subheader={
                                <ListSubheader>Original Columns</ListSubheader>
                              }
                            >
                              {" "}
                              <TransitionGroup>
                                {rowNameForUpComing
                                  .filter((Data) => {
                                    return filterColumnForUpComing.toLowerCase() ==
                                      ""
                                      ? Data
                                      : Data.toLowerCase().includes(
                                          filterColumnForUpComing.toLowerCase()
                                        );
                                  })
                                  .map((data, index) => (
                                    <Collapse key={data}>
                                      <ListItem key={index}>
                                        <Switch
                                          edge="end"
                                          onChange={() =>
                                            handleToggleForUpComing(data)
                                          }
                                          checked={
                                            checkedForUpComing.indexOf(data) !==
                                            -1
                                          }
                                          className={
                                            checkedForUpComing.indexOf(data) !==
                                            -1
                                              ? "active"
                                              : "inactive"
                                          }
                                          inputProps={{
                                            "aria-labelledby": data,
                                          }}
                                        />
                                        <ListItemText
                                          id={data}
                                          primary={data}
                                          disableTypography
                                        />
                                      </ListItem>
                                    </Collapse>
                                  ))}
                              </TransitionGroup>
                            </List>
                          </div>
                        </div>
                      </Zoom>
                    ) : null}
                  </div>
                </Grid>
                <div className="table_container">
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        {checkedForUpComing.indexOf("Employee") >= 0 ? (
                          <TableCell component={"td"} align="left">
                            Employee
                          </TableCell>
                        ) : null}
                        {checkedForUpComing.indexOf("Type") >= 0 ? (
                          <TableCell component={"td"} align="left">
                            Type
                          </TableCell>
                        ) : null}
                        {checkedForUpComing.indexOf("Amount") >= 0 ? (
                          <TableCell component={"td"} align="left">
                            Amount
                          </TableCell>
                        ) : null}
                        {checkedForUpComing.indexOf("Amount Tax") >= 0 ? (
                          <TableCell component={"td"} align="left">
                            Amount Tax
                          </TableCell>
                        ) : null}
                        {checkedForUpComing.indexOf("Payment Frequency") >=
                        0 ? (
                          <TableCell component={"td"} align="left">
                            Payment Frequency
                          </TableCell>
                        ) : null}
                        {checkedForUpComing.indexOf("Payment Cycle") >= 0 ? (
                          <TableCell component={"td"} align="left">
                            Payment Cycle
                          </TableCell>
                        ) : null}
                      </TableRow>
                    </TableHead>

                    {allIncentives.length && !loading ? (
                      <TableBody>
                        {allIncentives.map((data) => (
                          <TableRow id={data.emp_id} key={data.row_id}>
                            {checkedForUpComing.indexOf("Employee") >= 0 ? (
                              <TableCell> {data.emp_id.full_name}</TableCell>
                            ) : null}
                            {checkedForUpComing.indexOf("Type") >= 0 ? (
                              <TableCell>{data.incentive_type}</TableCell>
                            ) : null}
                            {checkedForUpComing.indexOf("Amount") >= 0 ? (
                              <TableCell>Amount</TableCell>
                            ) : null}
                            {checkedForUpComing.indexOf("Amount Tax") >= 0 ? (
                              <TableCell>Amount Tax</TableCell>
                            ) : null}
                            {checkedForUpComing.indexOf("Payment Frequency") >=
                            0 ? (
                              <TableCell>{data.frequency}</TableCell>
                            ) : null}
                            {checkedForUpComing.indexOf("Payment Cycle") >=
                            0 ? (
                              <TableCell>{data.frequency}</TableCell>
                            ) : null}
                          </TableRow>
                        ))}
                      </TableBody>
                    ) : !loading ? (
                      <TableBody>
                        <TableRow>
                          <TableCell colSpan={6}>
                            <Zoom in={!allIncentives.length}>
                              <Grid className="data_table_inner_notfound">
                                <IncentiveLadder />
                                <Typography>Sorry! No results</Typography>
                                <Typography variant="body2">
                                  Try resetting the filters or using different
                                  ones.
                                </Typography>
                              </Grid>
                            </Zoom>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ) : (
                      <TableBody>
                        <TableRow>
                          <TableCell colSpan={10}>
                            <Grid className="data_table_inner_notfound">
                              <CircularProgress />
                            </Grid>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </div>
                <>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={totalIncentives}
                    rowsPerPage={rowsPerPageForIncentives}
                    page={pageForIncentives}
                    onPageChange={onIncentivePageChange}
                    onRowsPerPageChange={(event) => {
                      setPageForIncentives(0);
                      setRowsPerPageForIncentives(+event.target.value);
                    }}
                  />
                </>
              </div>
            </TabPanel>
            <TabPanel value={value} index={1} className="tab-pannel">
              <div
                className={`data_table ${
                  !paidIncentives.length && "no_data_found"
                } `}
              >
                <Grid container className="data_table_filters_grid">
                  <TextField
                    spellCheck="false"
                    value={paidSearch}
                    onChange={(e) => setPaidSearch(e.target.value)}
                    className="outer_searchfilled"
                    placeholder="Search"
                    id="outlined-start-adornment"
                    InputProps={{
                      autoComplete: "off",
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <div className="table_edit_controller">
                    <Grid container className="filter_group">
                      <IconButton
                        onClick={(e) => setFilterDialog(!filterDialog)}
                      >
                        <FilterIcon />
                      </IconButton>
                      <Typography>Filter</Typography>
                    </Grid>{" "}
                    {filterDialog ? (
                      <Zoom in={filterDialog}>
                        <div className="edit_filter_menu is_small">
                          <Button
                            className="edit_filter_menu_close"
                            onClick={() => setFilterDialog(!filterDialog)}
                            disableRipple
                          ></Button>
                          <div className="edit_filter_menu_bx">
                            <List
                              subheader={
                                <ListSubheader>Filter Data</ListSubheader>
                              }
                            >
                              {filterStatus.map((data, index) => (
                                <ListItem key={index}>
                                  <Switch
                                    edge="end"
                                    onChange={() =>
                                      handleToggleForFilterStatusForPaid(data)
                                    }
                                    checked={
                                      checkedForFilterStatusForPaid.indexOf(
                                        data
                                      ) !== -1
                                    }
                                    className={
                                      checkedForFilterStatusForPaid.indexOf(
                                        data
                                      ) !== -1
                                        ? "active"
                                        : "inactive"
                                    }
                                    inputProps={{
                                      "aria-labelledby": data,
                                    }}
                                  />
                                  <ListItemText
                                    id={data}
                                    primary={data}
                                    disableTypography
                                  />
                                </ListItem>
                              ))}
                            </List>
                          </div>
                        </div>
                      </Zoom>
                    ) : null}
                  </div>
                  <div className="table_edit_controller">
                    <IconButton onClick={editDialogToggle}>
                      <TeamEditIcon />
                    </IconButton>
                    {editDialog ? (
                      <Zoom in={editDialog}>
                        <div className="edit_filter_menu">
                          <Button
                            className="edit_filter_menu_close"
                            onClick={editDialogToggle}
                            disableRipple
                          ></Button>
                          <div className="edit_filter_menu_bx">
                            <TextField
                              placeholder="Search Columns"
                              fullWidth
                              value={filterColumnForPaid}
                              onChange={(e) =>
                                setfilterColumnForPaid(e.target.value)
                              }
                              InputProps={{
                                autoComplete: "off",
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <SearchIcon />
                                  </InputAdornment>
                                ),
                              }}
                            />{" "}
                            <List
                              subheader={
                                <ListSubheader>Original Columns</ListSubheader>
                              }
                            >
                              <TransitionGroup>
                                {rowNameForUpComing
                                  .filter((Data) => {
                                    return filterColumnForPaid.toLowerCase() ==
                                      ""
                                      ? Data
                                      : Data.toLowerCase().includes(
                                          filterColumnForPaid.toLowerCase()
                                        );
                                  })
                                  .map((data, index) => (
                                    <Collapse key={data}>
                                      <ListItem key={index}>
                                        <Switch
                                          edge="end"
                                          onChange={() =>
                                            handleToggleForPaid(data)
                                          }
                                          checked={
                                            checkedForPaid.indexOf(data) !== -1
                                          }
                                          className={
                                            checkedForPaid.indexOf(data) !== -1
                                              ? "active"
                                              : "inactive"
                                          }
                                          inputProps={{
                                            "aria-labelledby": data,
                                          }}
                                        />
                                        <ListItemText
                                          id={data}
                                          primary={data}
                                          disableTypography
                                        />
                                      </ListItem>
                                    </Collapse>
                                  ))}
                              </TransitionGroup>
                            </List>
                          </div>
                        </div>
                      </Zoom>
                    ) : null}
                  </div>
                </Grid>
                <div className="table_container">
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        {checkedForPaid.indexOf("Employee") >= 0 ? (
                          <TableCell component={"td"} align="left">
                            Employee
                          </TableCell>
                        ) : null}
                        {checkedForPaid.indexOf("Type") >= 0 ? (
                          <TableCell component={"td"} align="left">
                            Type
                          </TableCell>
                        ) : null}
                        {checkedForPaid.indexOf("Amount") >= 0 ? (
                          <TableCell component={"td"} align="left">
                            Amount
                          </TableCell>
                        ) : null}
                        {checkedForPaid.indexOf("Amount Tax") >= 0 ? (
                          <TableCell component={"td"} align="left">
                            Amount Tax
                          </TableCell>
                        ) : null}
                        {checkedForPaid.indexOf("Payment Frequency") >= 0 ? (
                          <TableCell component={"td"} align="left">
                            Payment Frequency
                          </TableCell>
                        ) : null}
                        {checkedForPaid.indexOf("Payment Cycle") >= 0 ? (
                          <TableCell component={"td"} align="left">
                            Payment Cycle
                          </TableCell>
                        ) : null}
                      </TableRow>
                    </TableHead>
                    {!paidIncentives.length <= 0 && !loading ? (
                      <TableBody>
                        {paidIncentives.map((data) => (
                          <TableRow id={data.emp_id} key={data.row_id}>
                            {checkedForPaid.indexOf("Employee") >= 0 ? (
                              <TableCell> {data.emp_id.full_name}</TableCell>
                            ) : null}
                            {checkedForPaid.indexOf("Type") >= 0 ? (
                              <TableCell>{data.incentive_type}</TableCell>
                            ) : null}
                            {checkedForPaid.indexOf("Amount") >= 0 ? (
                              <TableCell>Amount</TableCell>
                            ) : null}
                            {checkedForPaid.indexOf("Amount Tax") >= 0 ? (
                              <TableCell>Amount Tax</TableCell>
                            ) : null}
                            {checkedForPaid.indexOf("Payment Frequency") >=
                            0 ? (
                              <TableCell>{data.frequency}</TableCell>
                            ) : null}
                            {checkedForPaid.indexOf("Payment Cycle") >= 0 ? (
                              <TableCell>{data.frequency}</TableCell>
                            ) : null}
                          </TableRow>
                        ))}
                      </TableBody>
                    ) : !loading ? (
                      <TableBody>
                        <TableRow>
                          <TableCell colSpan={6}>
                            <Zoom in={!paidIncentives.length}>
                              <Grid className="data_table_inner_notfound">
                                <IncentiveLadder />
                                <Typography>Sorry! No results</Typography>
                                <Typography variant="body2">
                                  Try resetting the filters or using different
                                  ones.
                                </Typography>
                              </Grid>
                            </Zoom>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ) : (
                      <TableBody>
                        <TableRow>
                          <TableCell colSpan={10}>
                            <Grid className="data_table_inner_notfound">
                              <CircularProgress />
                            </Grid>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </div>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={totalPaidIncentive}
                  rowsPerPage={rowsPerPageForPaidIncentive}
                  page={pageForPaidIncentive}
                  onPageChange={onPaidIncentivePageChange}
                  onRowsPerPageChange={(event) => {
                    setPageForPaidIncentive(0);
                    setRowsPerPageForPaidIncentive(+event.target.value);
                  }}
                />
              </div>
            </TabPanel>
            <TabPanel value={value} index={2} className="tab-pannel">
              <div
                className={`data_table ${
                  !cancledIncentives.length && "no_data_found"
                }`}
              >
                <Grid container className="data_table_filters_grid">
                  <TextField
                    spellCheck="false"
                    value={cancelledSearch}
                    onChange={(e) => setCancelledSearch(e.target.value)}
                    className="outer_searchfilled"
                    placeholder="Search"
                    id="outlined-start-adornment"
                    InputProps={{
                      autoComplete: "off",
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />

                  <div className="table_edit_controller">
                    <Grid container className="filter_group">
                      <IconButton
                        onClick={(e) => setFilterDialog(!filterDialog)}
                      >
                        <FilterIcon />
                      </IconButton>
                      <Typography>Filter</Typography>
                    </Grid>{" "}
                    {filterDialog ? (
                      <Zoom in={filterDialog}>
                        <div className="edit_filter_menu is_small">
                          <Button
                            className="edit_filter_menu_close"
                            onClick={() => setFilterDialog(!filterDialog)}
                            disableRipple
                          ></Button>
                          <div className="edit_filter_menu_bx">
                            <List
                              subheader={
                                <ListSubheader>Filter Data</ListSubheader>
                              }
                            >
                              {filterStatus.map((data, index) => (
                                <ListItem key={index}>
                                  <Switch
                                    edge="end"
                                    onChange={() =>
                                      handleToggleForFilterStatusForCancelled(
                                        data
                                      )
                                    }
                                    checked={
                                      checkedForFilterStatusForCancelled.indexOf(
                                        data
                                      ) !== -1
                                    }
                                    className={
                                      checkedForFilterStatusForCancelled.indexOf(
                                        data
                                      ) !== -1
                                        ? "active"
                                        : "inactive"
                                    }
                                    inputProps={{
                                      "aria-labelledby": data,
                                    }}
                                  />
                                  <ListItemText
                                    id={data}
                                    primary={data}
                                    disableTypography
                                  />
                                </ListItem>
                              ))}
                            </List>
                          </div>
                        </div>
                      </Zoom>
                    ) : null}
                  </div>
                  <div className="table_edit_controller">
                    <IconButton onClick={editDialogToggle}>
                      <TeamEditIcon />
                    </IconButton>
                    {editDialog ? (
                      <Zoom in={editDialog}>
                        <div className="edit_filter_menu">
                          <Button
                            className="edit_filter_menu_close"
                            onClick={editDialogToggle}
                            disableRipple
                          ></Button>
                          <div className="edit_filter_menu_bx">
                            <TextField
                              placeholder="Search Columns"
                              fullWidth
                              value={filterColumnForCancelled}
                              onChange={(e) =>
                                setfilterColumnForCancelled(e.target.value)
                              }
                              InputProps={{
                                autoComplete: "off",
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <SearchIcon />
                                  </InputAdornment>
                                ),
                              }}
                            />{" "}
                            <List
                              subheader={
                                <ListSubheader>Original Columns</ListSubheader>
                              }
                            >
                              <TransitionGroup>
                                {rowNameForCancelled
                                  .filter((data) => {
                                    return filterColumnForCancelled.toLowerCase() ===
                                      ""
                                      ? data
                                      : data
                                          .toLowerCase()
                                          .includes(
                                            filterColumnForCancelled.toLowerCase()
                                          );
                                  })
                                  .map((data, index) => (
                                    <Collapse key={data}>
                                      <ListItem key={index}>
                                        <Switch
                                          edge="end"
                                          onChange={() =>
                                            handleToggleForCancelled(data)
                                          }
                                          checked={
                                            checkedForCancelled.indexOf(
                                              data
                                            ) !== -1
                                          }
                                          className={
                                            checkedForCancelled.indexOf(
                                              data
                                            ) !== -1
                                              ? "active"
                                              : "inactive"
                                          }
                                          inputProps={{
                                            "aria-labelledby": data,
                                          }}
                                        />
                                        <ListItemText
                                          id={data}
                                          primary={data}
                                          disableTypography
                                        />
                                      </ListItem>
                                    </Collapse>
                                  ))}
                              </TransitionGroup>
                            </List>
                          </div>
                        </div>
                      </Zoom>
                    ) : null}
                  </div>
                </Grid>
                <div className="table_container">
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        {checkedForCancelled.indexOf("Employee") >= 0 ? (
                          <TableCell component={"td"} align="left">
                            Employee
                          </TableCell>
                        ) : null}
                        {checkedForCancelled.indexOf("Type") >= 0 ? (
                          <TableCell component={"td"} align="left">
                            Type
                          </TableCell>
                        ) : null}
                        {checkedForCancelled.indexOf("Amount") >= 0 ? (
                          <TableCell component={"td"} align="left">
                            Amount
                          </TableCell>
                        ) : null}
                        {checkedForCancelled.indexOf("Amount Tax") >= 0 ? (
                          <TableCell component={"td"} align="left">
                            Amount Tax
                          </TableCell>
                        ) : null}
                        {checkedForCancelled.indexOf("Payment Frequency") >=
                        0 ? (
                          <TableCell component={"td"} align="left">
                            Payment Frequency
                          </TableCell>
                        ) : null}
                        {checkedForCancelled.indexOf("Payment Cycle") >= 0 ? (
                          <TableCell component={"td"} align="left">
                            Payment Cycle
                          </TableCell>
                        ) : null}
                      </TableRow>
                    </TableHead>
                    {!cancledIncentives.length <= 0 && !loading ? (
                      <TableBody>
                        {cancledIncentives.map((data) => (
                          <TableRow id={data.emp_id} key={data.row_id}>
                            {checkedForCancelled.indexOf("Employee") >= 0 ? (
                              <TableCell> {data.emp_id.full_name}</TableCell>
                            ) : null}
                            {checkedForCancelled.indexOf("Type") >= 0 ? (
                              <TableCell>{data.incentive_type}</TableCell>
                            ) : null}
                            {checkedForCancelled.indexOf("Amount") >= 0 ? (
                              <TableCell>Amount</TableCell>
                            ) : null}
                            {checkedForCancelled.indexOf("Amount Tax") >= 0 ? (
                              <TableCell>Amount Tax</TableCell>
                            ) : null}
                            {checkedForCancelled.indexOf("Payment Frequency") >=
                            0 ? (
                              <TableCell>{data.frequency}</TableCell>
                            ) : null}
                            {checkedForCancelled.indexOf("Payment Cycle") >=
                            0 ? (
                              <TableCell>{data.frequency}</TableCell>
                            ) : null}
                          </TableRow>
                        ))}
                      </TableBody>
                    ) : !loading ? (
                      <TableBody>
                        <TableRow>
                          <TableCell colSpan={6}>
                            <Zoom in={!cancledIncentives.length}>
                              <Grid className="data_table_inner_notfound">
                                <IncentiveLadder />
                                <Typography>Sorry! No results</Typography>
                                <Typography variant="body2">
                                  Try resetting the filters or using different
                                  ones.
                                </Typography>
                              </Grid>
                            </Zoom>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ) : (
                      <TableBody>
                        <TableRow>
                          <TableCell colSpan={10}>
                            <Grid className="data_table_inner_notfound">
                              <CircularProgress />
                            </Grid>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </div>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={totalCancledIncentive}
                  rowsPerPage={rowsPerPageForCancledIncentive}
                  page={pageForCancledIncentive}
                  onPageChange={onCancleIncentivePageChange}
                  onRowsPerPageChange={(event) => {
                    setPageForCancledIncentive(0);
                    setRowsPerPageForCancledIncentive(+event.target.value);
                  }}
                />
              </div>
            </TabPanel>
          </div>
        </Grid>
      </Slide>

      <IncentiveModal
        open={modalOpen}
        close={() => setModalOpen(false)}
        saveSuccessfully={() => {
          updateAllIncentive();
        }}
      />
      <HelpButton />
    </>
  );
};
