import "./Register.css";
import { React, forwardRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  jwt_decode,
  Stepper,
  Step,
  StepLabel,
  Link,
  TextField,
  Grid,
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  Container,
  IconButton,
} from "./Register1Imports";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { FormLabel, Typography } from "@mui/material";
import { AuthSidebar } from "Components/Login/LoginImport";
import useNotification from "Components/CommonComponents/AlertNotifications/AlertNotifications";

export const Register = () => {
  const navigate = useNavigate();
  const [msg, sendNotification] = useNotification();

  const [firstName, setFirstName] = useState("");
  const [userName, setUserName] = useState("");
  const [promotionalCode, setPromotionalCode] = useState("");
  const [termsAndConditionChecked, setTermsAndConditionChecked] =
    useState(false);
  const [enrollForBlogChecked, setEnrollForBlogChecked] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    getEmail();
    filldata();
  }, []);

  const getEmail = () => {
    if (JSON.parse(localStorage.getItem("JWT")) !== null) {
      const JWT = JSON.parse(localStorage.getItem("JWT"));
      const DecodedJWT = jwt_decode(JWT.access_token);
      const accountType = DecodedJWT.account_type;
      localStorage.setItem("account_type", accountType);
      if (accountType === "contractor" || accountType === "employee") {
        navigate("/employeeregister");
      }
      const email = DecodedJWT.email;
      setUserName(email);
      const isUserProfileUpdated = DecodedJWT.user_profile_updated;
      // console.log(isUserProfileUpdated);
      if (isUserProfileUpdated == true) {
        navigate("/register/6");
      }
    }
  };

  const submitRegister = (e) => {
    e.preventDefault();
    if (promotionalCode == null) {
      setPromotionalCode("");
    }
    const data = {
      full_name: firstName.trim(),
      email: userName,
      promo_code: promotionalCode.trim(),
      accepted_terms: termsAndConditionChecked,
      accepted_blog_updates: enrollForBlogChecked,
    };
    if (firstName.trim() !== "") {
      localStorage.setItem("Registrationdata", JSON.stringify(data));
      navigate("/register/3");
      clearState();
    } else {
      if (firstName.trim() === "") {
        sendNotification({
          msg: "Please enter valid first name",
          variant: "error",
        });
      }
    }
  };

  const filldata = () => {
    const data = JSON.parse(localStorage.getItem("Registrationdata"));
    if (data || data != null) {
      // console.log(data);
      setFirstName(data.full_name);
      setPromotionalCode(data.promo_code);
      setTermsAndConditionChecked(data.accepted_terms);
      setEnrollForBlogChecked(data.accepted_blog_updates);
    }
  };

  const clearState = () => {
    setFirstName("");
    setUserName("");
    setTermsAndConditionChecked(false);
    setEnrollForBlogChecked(false);
  };

  return (
    <>
      <form onSubmit={submitRegister} className="register_form">
        <Grid xs={12}>
          <TextField
            required
            fullWidth
            label="Your first and last name"
            variant="standard"
            helperText="Your legal name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            id="standard-adornment-name"
            inputProps={{
              minLength: 1,
              maxLength: 128,
              autoComplete: "off",
            }}
          />
        </Grid>
        <Grid xs={12}>
          <TextField
            readOnly
            required
            fullWidth
            id="standard-basic1"
            label="What is your company email?"
            helperText="E.g you@companyname.com"
            type="email"
            variant="standard"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
            inputProps={{
              maxLength: 128,
              readOnly: "readOnly",
              autoComplete: "off",
            }}
          />
        </Grid>

        <Grid xs={12}>
          <TextField
            fullWidth
            id="standard-basic1"
            label="Do you have promotional code?"
            type="text"
            variant="standard"
            value={promotionalCode}
            onChange={(e) => setPromotionalCode(e.target.value)}
            inputProps={{ maxLength: 20, autoComplete: "off" }}
          />
        </Grid>
        <Grid xs={12} fullWidth>
          <FormControlLabel
            control={
              <>
                <Checkbox
                  required
                  name="termsandcondition"
                  checked={termsAndConditionChecked}
                  onChange={(e) => {
                    setTermsAndConditionChecked(e.target.checked);
                  }}
                  inputProps={{ required: "required" }}
                />
                <FormLabel>
                  I agree to the <Link to="">Terms and conditions &#8599;</Link>
                </FormLabel>
              </>
            }
          />
        </Grid>
        <Grid xs={12} fullWidth>
          <FormControlLabel
            control={
              <Checkbox
                name="enrollforblog"
                checked={enrollForBlogChecked}
                onChange={(e) => {
                  setEnrollForBlogChecked(e.target.checked);
                }}
              />
            }
            label="I also want to receive Inrole's blog updates."
          />
        </Grid>
        <Grid container className="login_bttn_wrapper">
          <Button variant="contained" type="submit" className="theme_bttn fill">
            Next
          </Button>
        </Grid>
      </form>
    </>
  );
};
