import "./AddEmployee.css";
import { React, useState } from "react";
import {
  PropTypes,
  LanguageIcon,
  TextField,
  Box,
  LinearProgress,
  Typography,
  Stepper,
  Step,
  StepLabel,
  moment,
  Button,
  Grid,
  IconButton,
  Container,
  useNavigate,
  Icon,
  DesktopDatePicker,
  LocalizationProvider,
  AdapterDayjs,
  useEffect,
  postEmployee,
} from "./AddEmployee1Import";
import { useParams } from "react-router-dom";
import { putEmployeeData } from "Services/AddEmployeeAndContractor";
import countries from "../../../Assets/JSONs/Countries.json";
import { Autocomplete, FormLabel, Slide } from "@mui/material";
import { AuthSidebar } from "Components/Login/LoginImport";
import { HelpButton } from "Components/CommonComponents/Bottom_Help_Button/HelpButton";
import { PreviousArrowIcon } from "Icons/Icons";

import useNotification from "Components/CommonComponents/AlertNotifications/AlertNotifications";
import { DatePicker } from "@mui/x-date-pickers";
import { Error } from "Utils/Success&Errorfunctions";
import { useSnackbar } from "notistack";
import { AddEmployeeStepper } from "../AddEmployeeSideBar/AddEmployeeSideBar";

import useMediaQuery from "@mui/material/useMediaQuery";

export const AddEmployee = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const tabletScreen = useMediaQuery("(max-width:899px)");

  const { enqueueSnackbar } = useSnackbar();
  const [msg, sendNotification] = useNotification();

  const [loading, setLoading] = useState(false);

  const [value, setValue] = useState();
  const [indexOfCountryCode, setIndexOfCountryCode] = useState(92);

  const [progress, setProgress] = useState(0);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [countryCode, setCountryCode] = useState("+91");
  const [contactNumber, setContactNumber] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [startDate, setStartDate] = useState(
    moment(Date.now()).format("YYYY/MM/DD")
  );
  const [transistion, setTransistion] = useState(true);
  const [CountryArray, setCountryArray] = useState([]);
  const [isParams, setIsParams] = useState(false);

  useEffect(() => {
    fillform();
    Countrycode();
    setTimeout(() => {
      setProgress(25);
    }, 500);
  }, []);

  const showLoading = () => {
    setLoading(true);
  };

  const hideLoading = () => {
    setLoading(false);
  };

  const goBack = () => {
    var id = params.id;
    if (!id) {
      localStorage.removeItem("Empdata1");
      localStorage.removeItem("empRowData");
      localStorage.removeItem("Empdata2");
      localStorage.removeItem("Empdata3");

      navigate("/team");
    } else {
      var data = JSON.parse(localStorage.getItem("Empdata2"));
      if (data) {
        navigate("/addemployeefinal/" + id);
      } else {
        localStorage.removeItem("Empdata1");
        localStorage.removeItem("empRowData");
        localStorage.removeItem("Empdata2");
        localStorage.removeItem("Empdata3");

        navigate("/onboarding");
      }
    }
  };

  const Countrycode = () => {
    setCountryArray(countries);
  };

  const submitEmployeeData1 = (e) => {
    showLoading();
    e.preventDefault();
    const id = params.id;
    try {
      const currentDate = new Date(Date.now());

      var newDate = new Date(startDate);

      var formatedDate = moment(new Date(startDate)).format("YYYY-MM-DD");

      if (startDate !== null) {
        if (
          (newDate.getDate() === currentDate.getDate() &&
            newDate.getMonth() === currentDate.getMonth() &&
            newDate.getFullYear() === currentDate.getFullYear()) ||
          newDate.getTime() > currentDate.getTime()
        ) {
          if (!id) {
            const empData = {
              full_name: fullName.trim(),
              email: email.toLowerCase().trim(),
              country_code: countryCode,
              phone_number: contactNumber,
              job_title: jobTitle.trim(),
              provisional_start_date: formatedDate,
            };
            postEmployeeData(empData);
            hideLoading();
          } else {
            const empData = {
              full_name: fullName.trim(),
              country_code: countryCode,
              phone_number: contactNumber,
              job_title: jobTitle.trim(),
              provisional_start_date: formatedDate,
            };
            putEmployee(empData, id);
            hideLoading();
          }
        } else {
          sendNotification({
            msg: "Please enter valid Date!(past dates are not allowed.)",
            variant: "error",
          });
          hideLoading();
          return;
        }
      } else {
        sendNotification({
          msg: "Please enter valid Date!",
          variant: "error",
        });
        hideLoading();
      }
    } catch (e) {
      console.log(e.message);
      hideLoading();
    }
    hideLoading();
  };

  function postEmployeeData(empData) {
    try {
      postEmployee(empData).then(
        (res) => {
          var variant = "success";
          enqueueSnackbar(res.data.message, { variant });
          var emprowdata = res.data.row_id;

          localStorage.setItem("empRowData", emprowdata);
          localStorage.setItem("Empdata1", JSON.stringify(empData));
          navigate("/addemployee/2");
          hideLoading();
        },
        (err) => {
          var errors = Error(err.response.data.message);

          if (Array.isArray(errors)) {
            errors.forEach((element) => {
              sendNotification({
                msg: `Please enter valid ${element}`,
                variant: "error",
              });
            });
            hideLoading();
          } else {
            sendNotification({
              msg: err.response.data.message,
              variant: "error",
            });
            hideLoading();
          }
        }
      );
    } catch (e) {
      console.log(e.message);
      throw e;
    }
  }

  function putEmployee(empdata, id) {
    try {
      var formatedDate = moment(new Date(startDate)).format("YYYY-MM-DD");
      putEmployeeData(empdata, id).then(
        (res) => {
          var variant = "success";
          enqueueSnackbar(res.data.message, { variant });
          const empData1 = {
            full_name: fullName,
            email: email.toLowerCase().trim(),
            country_code: countryCode,
            phone_number: contactNumber,
            job_title: jobTitle,
            provisional_start_date: formatedDate,
          };

          localStorage.setItem("Empdata1", JSON.stringify(empData1));
          navigate("/addemployee/2/" + id);
          hideLoading();
        },
        (err) => {
          var errors = Error(err.response.data.message);

          if (Array.isArray(errors)) {
            errors.forEach((element) => {
              sendNotification({
                msg: `Please enter valid ${element}`,
                variant: "error",
              });
            });
            hideLoading();
          } else {
            sendNotification({
              msg: err.response.data.message,
              variant: "error",
            });
            hideLoading();
          }

          setTimeout(() => {
            if (err.response.data.message === "Employee already invited") {
              navigate("/onboarding");
            }
          }, 2000);
          hideLoading();
        }
      );
    } catch (e) {
      console.log(e.message);
      throw e;
    }
  }

  const fillform = () => {
    const id = params.id;
    if (id) {
      setIsParams(true);
    }
    const empdata1 = JSON.parse(localStorage.getItem("Empdata1"));
    if (empdata1 || empdata1 !== null) {
      setFullName(empdata1.full_name);
      setEmail(empdata1.email);
      var code = empdata1.country_code.split("+")[1];
      // console.log(code);
      var indexOfCountryCode = countries.findIndex(
        (country) => country.phone === code
      );
      // console.log(indexOfCountryCode);
      setIndexOfCountryCode(indexOfCountryCode);
      let array = countries.filter((val) => {
        return val.phone == code;
      });

      setValue(array.map((data) => data.label));
      setCountryCode(array.map((data) => data.countryCode));
      setContactNumber(empdata1.phone_number);

      setJobTitle(empdata1.job_title);
      setStartDate(empdata1.provisional_start_date);
    }
  };

  const handleChange = (newValue) => {
    setStartDate(newValue);
  };

  function restrictAlphabets(e) {
    var x = e.which || e.keycode;
    if (x >= 48 && x <= 57) {
      return true;
    } else {
      e.preventDefault();
    }
  }

  return (
    <>
      <Box className="warning_info_box">
        <Grid container>
          <Grid item className="warning_box_icon">
            <Box className="icon_bx">
              <Icon>
                <LanguageIcon />
              </Icon>
            </Box>
          </Grid>
          <Grid item className="warning_box_cont">
            <Typography variant="h3">Would you like some help?</Typography>
            <Typography variant="body2">
              Here is our guide to employing people in India
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <form onSubmit={submitEmployeeData1} className="register_form">
        <Grid xs={12}>
          <TextField
            required
            fullWidth
            label="Full Name"
            variant="standard"
            helperText="Full employee name as it appears on identification document"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            id="fullname"
            inputProps={{ maxLength: 128, autoComplete: "off" }}
          />
        </Grid>
        <Grid xs={12}>
          <TextField
            disabled={!isParams ? false : true}
            required
            fullWidth
            type="email"
            label="Personal Email"
            variant="standard"
            helperText="E.g. jane@gmail.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            id="email"
            inputProps={{ maxLength: 128, autoComplete: "off" }}
          />
        </Grid>
        <Grid
          container
          flexDirection={{ xs: "row", sm: "row" }}
          className="phone_input_group"
        >
          <Grid item xs={12}>
            <FormLabel required>Phone Number</FormLabel>
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              id="country-code"
              options={countries}
              defaultValue={countries[indexOfCountryCode]}
              autoHighlight
              onChange={(event, newValue) => {
                setValue(newValue.phone);
              }}
              inputValue={countryCode}
              onInputChange={(event, newInputValue) => {
                setCountryCode(newInputValue);
              }}
              getOptionLabel={(option) => " +" + option.phone}
              filterOptions={(options, { inputValue }) =>
                options.filter((option) =>
                  (option.label + " +" + option.phone)
                    .toLowerCase()
                    .includes(inputValue.toLowerCase())
                )
              }
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  <img
                    loading="lazy"
                    width="20"
                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                    alt=""
                  />
                  {option.label} +{option.phone}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  label={false}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "off",
                  }}
                  variant="standard"
                />
              )}
              className="autocomplete"
            />
          </Grid>
          <Grid item xs={9}>
            <TextField
              required
              fullWidth
              type="text"
              placeholder="9999999999"
              variant="standard"
              value={contactNumber}
              onKeyPress={(e) => restrictAlphabets(e)}
              onChange={(e) => setContactNumber(e.target.value)}
              inputProps={{
                required: "required",
                maxLength: 10,
                max: "10",
                autoComplete: "off",
                pattern: "[0-9]{10}",
              }}
            />
          </Grid>
        </Grid>
        <Grid xs={12}>
          <TextField
            required
            fullWidth
            label="Job title"
            variant="standard"
            helperText="E.g. product designer"
            value={jobTitle}
            onChange={(e) => setJobTitle(e.target.value)}
            id="email"
            inputProps={{ maxLength: 128, autoComplete: "off" }}
          />
        </Grid>
        <Grid container xs={12} className="date_picker_employee">
          <Grid xs={12} spacing={0}>
            <FormLabel>Provisional start date</FormLabel>
          </Grid>
          <Grid xs={12} spacing={0}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                inputFormat="YYYY/MM/DD"
                disablePast
                value={startDate}
                onChange={handleChange}
                renderInput={(params) => (
                  <TextField varient="standard" {...params} />
                )}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Grid className="login_bttn_wrapper">
          <Button
            type="submit"
            className="theme_bttn fill"
            // disabled={loading ? true : false}
          >
            Continue
          </Button>
        </Grid>
      </form>

      <HelpButton />
    </>
  );
};
