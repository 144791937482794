import "./EmpRegister4.css";
import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  Icon,
  IconButton,
  MenuItem,
  Slide,
  TextField,
  Typography,
} from "@mui/material";

import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import {
  addContractorEmergencyContact,
  emergencyContact,
  getStates,
} from "Services/AddEmployeeAndContractor";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Autocomplete from "@mui/material/Autocomplete";
import countries from "../../../Assets/JSONs/Countries.json";
import { InfoIcon } from "Components/AddContractor/AddContractor1/AddContractorImports";
import { PreviousArrowIcon } from "Icons/Icons";
import useNotification from "Components/CommonComponents/AlertNotifications/AlertNotifications";
import { useSnackbar } from "notistack";
import { Error } from "Utils/Success&Errorfunctions";

export const EmpRegister4 = () => {
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();
  const [msg, sendNotification] = useNotification();

  const [value, setValue] = useState();
  const [fullName, setFullName] = useState("");
  const [relationship, setRelationship] = useState("father");
  const [email, setEmail] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [contactno, setContactno] = useState("");

  const [errorOpen, setErrorOpen] = useState(false);
  const [response, setResponse] = useState("");

  const goBack = () => {
    navigate("/employeeregister");
  };

  const submitemergencycontact = (e) => {
    e.preventDefault();
    const emergencydata = {
      full_name: fullName.trim(),
      relationship: relationship,
      email: email.trim().toLowerCase(),
      country_code: countryCode,
      phone_number: contactno,
    };
    if (fullName.trim() != "" && email.trim().toLowerCase() !== "") {
      if (localStorage.getItem("account_type") !== "contractor") {
        emergencyContact(emergencydata).then(
          (res) => {
            var variant = "success";
            enqueueSnackbar(res.data.message, { variant });
            navigate("/employeeregister");
          },
          (err) => {
            var errors = Error(err.response.data.message);

            if (Array.isArray(errors)) {
              errors.forEach((element) => {
                sendNotification({
                  msg: `Please enter valid ${element}`,
                  variant: "error",
                });
              });
            } else {
              sendNotification({
                msg: err.response.data.message,
                variant: "error",
              });
            }
          }
        );
      } else {
        addContractorEmergencyContact(emergencydata).then(
          (res) => {
            var variant = "success";
            enqueueSnackbar(res.data.message, { variant });
            navigate("/employeeregister");
          },
          (err) => {
            var errors = Error(err.response.data.message);

            if (Array.isArray(errors)) {
              errors.forEach((element) => {
                sendNotification({
                  msg: `Please enter valid ${element}`,
                  variant: "error",
                });
              });
            } else {
              sendNotification({
                msg: err.response.data.message,
                variant: "error",
              });
            }
          }
        );
      }
    } else {
      setErrorOpen(true);
      if (fullName.trim() === "") {
        sendNotification({
          msg: "Please enter your emergency contact's name",
          variant: "error",
        });
      } else if (email.trim().toLowerCase() === "") {
        sendNotification({
          msg: "Please enter valid email!",
          variant: "error",
        });
      }
    }
  };

  return (
    <>
      <Slide direction="left" in={true}>
        <Grid xs={8} item className="login_content_grid_item">
          <Grid
            container
            alignItems="center"
            flexDirection={{ xs: "row", sm: "row" }}
            className="auth_top_header"
          >
            <Grid item>
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="label"
                onClick={goBack}
              >
                <PreviousArrowIcon />
              </IconButton>
              Back
            </Grid>
          </Grid>
          <div className="login_cont register_cont">
            <Grid xs={12}>
              <Typography variant="h3">Emergency contact</Typography>
            </Grid>
            <Grid xs={12}>
              <Typography variant="body2">
                Who we’ll call in case of emergency.
              </Typography>
            </Grid>
            <Grid xs={12}>
              <Box className="warning_info_box">
                <Grid container>
                  <Grid item className="warning_box_icon">
                    <Icon>
                      <InfoIcon />
                    </Icon>
                  </Grid>
                  <Grid item className="warning_box_cont">
                    <Typography variant="body1">
                      Your country of residence is expected to be India. If
                      you’re planning to relocate, please reach out to
                      <a href="mailto:help@inrole.in"> help@inrole.in</a>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <form onSubmit={submitemergencycontact} className="register_form">
              <Grid xs={12}>
                <TextField
                  required
                  fullWidth
                  id="Full Name"
                  label="Full Name"
                  variant="standard"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  inputProps={{ autocomplete: "off", maxLength: 128 }}
                />
              </Grid>
              <Grid xs={12}>
                <TextField
                  required
                  select
                  fullWidth
                  id="Relationship"
                  label="Relationship"
                  variant="standard"
                  helperText="For example mother, father, or spouse."
                  onChange={(e) => setRelationship(e.target.value)}
                  value={relationship}
                >
                  <MenuItem value="father">Father</MenuItem>
                  <MenuItem value="mother">Mother</MenuItem>
                  <MenuItem value="spouse">Spouse</MenuItem>
                  <MenuItem value="other">Other</MenuItem>
                </TextField>
              </Grid>

              <Grid xs={12}>
                <TextField
                  required
                  id="Their email"
                  label="Their email"
                  variant="standard"
                  fullWidth
                  value={email}
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  inputProps={{ autocomplete: "off", maxLength: 128 }}
                />
              </Grid>

              <Grid
                container
                alignItems="center"
                justifyContent="center"
                flexDirection={{ xs: "row", sm: "row" }}
                className="phone_input_group"
              >
                <Grid item xs={12}>
                  <label className="MuiInputLabel-root"> Mobile Number</label>
                </Grid>
                <Grid item xs={3}>
                  <Autocomplete
                    id="country-code"
                    defaultValue={countries[92]}
                    options={countries}
                    autoHighlight
                    onChange={(event, newValue) => {
                      setValue(newValue.phone);
                    }}
                    inputValue={countryCode}
                    onInputChange={(event, newInputValue) => {
                      setCountryCode(newInputValue);
                    }}
                    getOptionLabel={(option) => " +" + option.phone}
                    filterOptions={(options, { inputValue }) =>
                      options.filter((option) =>
                        (option.label + " +" + option.phone)
                          .toLowerCase()
                          .includes(inputValue.toLowerCase())
                      )
                    }
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        <img
                          loading="lazy"
                          width="20"
                          src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                          srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                          alt=""
                        />
                        {option.label} +{option.phone}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        label={false}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "off", // disable autocomplete and autofill
                        }}
                        variant="standard"
                      />
                    )}
                    className="autocomplete"
                  />
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    required
                    fullWidth
                    type="phonenumber"
                    placeholder="9999999999"
                    id="fullWidth"
                    variant="standard"
                    onChange={(e) => setContactno(e.target.value)}
                    inputProps={{
                      required: "required",
                      maxLength: 10,
                      max: "10",
                      autocomplete: "off",
                      pattern: "[0-9]{10}",
                    }}
                  />
                </Grid>
              </Grid>
              <Grid className="login_bttn_wrapper">
                <Button type="submit" className="theme_bttn fill">
                  Save & Continue
                </Button>
              </Grid>
            </form>
          </div>
        </Grid>
      </Slide>
    </>
  );
};
