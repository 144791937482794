import {
  Button,
  Grid,
  Grow,
  IconButton,
  Modal,
  Slide,
  Typography,
} from "@mui/material";
import "../../../Teams/Teams/Team.css";
import { Box } from "@mui/system";

import {
  ModalContractorvector,
  ModalEmployevector,
} from "Components/Teams/Teams/TeamImpoerts";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  CloseIcon,
  TeamContractorModalIcon,
  TeamEmployeeModalIcon,
} from "Icons/Icons";
import { DocumentModal } from "./DocumentModal";
import { CompanyDocumentModal } from "./CompanyDocumentModal";

export const DocumentSelecterModal = (props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpenCompany, setModalOpenCompany] = useState(false);
  return (
    <>
      <Modal
        onBackdropClick={props.close}
        open={props.open}
        onClose={props.close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="add_hire_modal"
      >
        <Slide in={props.modalOpen}>
          <Box className="add_hire_modal_bx">
            <Grow in={props.open}>
              <Grid>
                <div className="modal_header">
                  <Grid container flexDirection={{ xs: "row", sm: "row" }}>
                    <Grid item>
                      <Typography>Document Upload</Typography>
                    </Grid>
                    <Grid item>
                      <IconButton
                        onClick={props.close}
                        className="icon_close_bttn"
                      >
                        <CloseIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </div>
                <div className="modal_body">
                  <Button
                    className="modal_employe_type_bx"
                    onClick={() => {
                      setModalOpen(true);
                      props.close();
                    }}
                  >
                    <Grid container flexDirection={{ xs: "row", sm: "row" }}>
                      <Grid item xs={3} md={2}>
                        <TeamEmployeeModalIcon />
                      </Grid>
                      <Grid item xs={8} md={9}>
                        <Typography variant="h6">Employee Documents</Typography>
                        <Typography>
                          Upload necessary document for employee.
                        </Typography>
                      </Grid>
                    </Grid>
                  </Button>
                  <Button
                    className="modal_employe_type_bx"
                    onClick={() => {
                      setModalOpenCompany(true);
                      props.close();
                    }}
                  >
                    <Grid container flexDirection={{ xs: "row", sm: "row" }}>
                      <Grid item xs={3} md={2}>
                        <TeamContractorModalIcon />
                      </Grid>
                      <Grid item xs={8} md={9}>
                        <Typography variant="h6">Company Documents</Typography>
                        <Typography>
                          Upload necessary document for company.
                        </Typography>
                      </Grid>
                    </Grid>
                  </Button>
                </div>
                <div className="modal_footer">
                  <Typography variant="h6">Need more guidance?</Typography>
                  <Typography>
                    We’re happy to provide you with more information about how
                    document upload works at InRole.
                  </Typography>
                  <Button className="see_guide_bttn">See Guide</Button>
                </div>
              </Grid>
            </Grow>
          </Box>
        </Slide>
      </Modal>
      <DocumentModal modalOpen={modalOpen} close={() => setModalOpen(false)} />
      <CompanyDocumentModal
        modalOpen={modalOpenCompany}
        close={() => setModalOpenCompany(false)}
      />
    </>
  );
};
