import React, { useEffect, useState } from "react";
import { CircularProgress, Modal, Slide } from "@mui/material";
import {
  Button,
  Checkbox,
  Dialog,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import useNotification from "Components/CommonComponents/AlertNotifications/AlertNotifications";

import { CloseIcon } from "Icons/Icons";

import { Link } from "react-router-dom";
import { getAllEmployee } from "Services/AddEmployeeAndContractor";
import { companyUser, Roles } from "Services/CompanyServices";
import { Box, Grid } from "Utils/muiImport";
import { Error } from "Utils/Success&Errorfunctions";

export const CompanySettingModal = (props) => {
  const [msg, sendNotification] = useNotification();

  const [loading, setLoading] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [response, setResponse] = useState("");

  const [roleArray, setRoleArray] = useState([]);

  const [Employee, setEmployee] = useState("");
  const [email, setEmail] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [role, setRole] = useState("");
  const [authorizedRepresentative, setAuthorizedRepresentative] =
    useState(false);
  const [isAuthorisedRepresentative, setIsAuthorisedRepresentative] =
    useState(false);

  useEffect(() => {
    if (isAuthorisedRepresentative === true) {
      setAuthorizedRepresentative("True");
    } else {
      setAuthorizedRepresentative("False");
    }
  }, [isAuthorisedRepresentative]);

  useEffect(() => {
    getRoles();
    setEmployee("");
    setEmail("");
    setJobTitle("");
    setRole("");
    setAuthorizedRepresentative(false);
    setIsAuthorisedRepresentative(false);
  }, [props.open, props.close]);

  const getRoles = () => {
    Roles().then(
      (res) => {
        setRoleArray(res.data);
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const showLoading = () => {
    setLoading(true);
  };

  const hideLoading = () => {
    setLoading(false);
  };

  const addCompanyUser = (e) => {
    e.preventDefault();
    showLoading();
    var userData = {
      company_id: localStorage.getItem("CompanyRowID"),
      full_name: Employee.trim(),
      email: email.trim().toLowerCase(),
      job_title: jobTitle.trim(),
      authorized_representative: authorizedRepresentative,
      role_id: role,
    };
    if (
      Employee.trim() != "" &&
      email.trim() != "" &&
      jobTitle.trim() != "" &&
      role !== ""
    ) {
      companyUser(userData).then(
        (res) => {
          sendNotification({
            msg: res.data.message,
            variant: "success",
          });

          hideLoading();
          props.close();
        },
        (err) => {
          hideLoading();
          var errors = Error(err.response.data.message);

          if (Array.isArray(errors)) {
            errors.forEach((element) => {
              sendNotification({
                msg: `Please enter valid ${element}`,
                variant: "error",
              });
            });
            hideLoading();
          } else {
            sendNotification({
              msg: err.response.data.message,
              variant: "error",
            });
          }
        }
      );
    } else {
      if (Employee.trim() == "") {
        sendNotification({
          msg: "Please enter valid employee name",
          variant: "error",
        });
        hideLoading();
      } else if (email.trim() == "") {
        sendNotification({
          msg: "Please enter valid email",
          variant: "error",
        });
        hideLoading();
      } else if (role == "") {
        sendNotification({
          msg: "Please select valid role",
          variant: "error",
        });
        hideLoading();
      } else if (jobTitle.trim() == "") {
        sendNotification({
          msg: "Please enter valid job title",
          variant: "error",
        });
        hideLoading();
      }
    }
  };
  return (
    <>
      <Modal
        onBackdropClick={props.close}
        open={props.modalOpen}
        onClose={props.close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="add_hire_modal company_setting_modal"
      >
        <Slide in={props.modalOpen}>
          <Box className="add_hire_modal_bx">
            <div className="modal_header">
              <Grid container>
                <Grid item>
                  <Typography id="modal-modal-title" variant="h6">
                    Add New Team Member
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton onClick={props.close}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </div>
            <form onSubmit={(e) => addCompanyUser(e)}>
              <div className="modal_body">
                <Typography variant="body2">
                  You don’t have to do all the work alone! Invite a team member
                  or an external collaboration using their work email so you can
                  manage your company on InRole together.
                </Typography>
                <Typography variant="body2">
                  By adding a new team member to a role, you are giving them
                  access to specific areas of the platform. For more details,
                  see our <Link to="">help articles.</Link>
                </Typography>
                <Grid xs={12} className="register_form">
                  <Grid xs={12}>
                    <TextField
                      fullWidth
                      required
                      variant="standard"
                      label="Full Name Of Employee"
                      value={Employee}
                      onChange={(e) => setEmployee(e.target.value)}
                      inputProps={{ autoComplete: "off", maxLength: 128 }}
                    ></TextField>
                  </Grid>
                  <Grid xs={12}>
                    <TextField
                      fullWidth
                      required
                      value={email}
                      type="email"
                      onChange={(e) => setEmail(e.target.value)}
                      label="Work Email"
                      id="work-email"
                      variant="standard"
                      helperText="We’ll use this for email notification"
                      inputProps={{ autoComplete: "off", maxLength: 128 }}
                    />
                  </Grid>
                  <Grid xs={12}>
                    <TextField
                      select
                      required
                      value={role}
                      onChange={(e) => setRole(e.target.value)}
                      id="Select-role"
                      label="Role"
                      helperText="select from exiting roles."
                      variant="standard"
                      fullWidth
                    >
                      {roleArray.map((data) => (
                        <MenuItem value={data.row_id} key={data.row_id}>
                          {data.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid xs={12}>
                    <TextField
                      fullWidth
                      required
                      value={jobTitle}
                      onChange={(e) => setJobTitle(e.target.value)}
                      label="Job Title"
                      id="job-title"
                      variant="standard"
                      helperText="As it is  listed in your HR system"
                      inputProps={{ autoComplete: "off", maxLength: 128 }}
                    />
                  </Grid>
                  {/* <Grid xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isBillingContract}
                    onChange={(e) => setIsBillingContract(e.target.checked)}
                  />
                }
                label="Is a Billing Contact"
              />

              <FormHelperText>
                Responsible for everything related to billing in your company.
              </FormHelperText>
            </Grid> */}
                  <Grid xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          required
                          checked={isAuthorisedRepresentative}
                          onChange={(e) =>
                            setIsAuthorisedRepresentative(e.target.checked)
                          }
                        />
                      }
                      label="Is an Authorised Representative"
                    />
                    <FormHelperText>
                      Has the authority to sign legal documents on behalf of
                      your company. Only one allowed per company.
                    </FormHelperText>
                  </Grid>
                </Grid>
              </div>
              <div className="modal_footer">
                <Grid container className="modal_foot_bttn_container">
                  <Button
                    className="theme_bttn fill"
                    type="submit"
                    disabled={loading}
                    startIcon={
                      loading && <CircularProgress size={20} color="inherit" />
                    }
                  >
                    Submit
                  </Button>
                  <Button className="theme_bttn" onClick={props.close}>
                    Cancel
                  </Button>
                </Grid>
              </div>
            </form>
          </Box>
        </Slide>
      </Modal>
    </>
  );
};
