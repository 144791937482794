import "./AddEmployeeFinal.css";
import { React, useState, useEffect } from "react";
import {
  PropTypes,
  Edit,
  Done,
  Box,
  LinearProgress,
  Typography,
  Alert,
  AlertTitle,
  Snackbar,
  CloseIcon,
  Collapse,
  useNavigate,
  Button,
  Grid,
  IconButton,
  color,
  Container,
  Stack,
  Divider,
  updateEmployee,
} from "./AddEmployeeFinalImports";
import { Link, useParams } from "react-router-dom";
import {
  getEmpContract,
  getEmployeeData,
  empInvite,
  getPricingForEmployee,
} from "Services/AddEmployeeAndContractor";
import { HelpButton } from "Components/CommonComponents/Bottom_Help_Button/HelpButton";
import {
  EditIcon,
  FinalPageEmployee_ContractorDetailsDoneIcon,
  PreviousArrowIcon,
} from "Icons/Icons";
import { Backdrop, CircularProgress, Icon } from "@mui/material";
import useNotification from "Components/CommonComponents/AlertNotifications/AlertNotifications";
import { Loader } from "Components/CommonComponents/Loader/Loader";
import { AddEmployeeSummaryUpdate } from "Components/CommonComponents/Modals/AddEmployee Edit Modal/AddEmployeeSummaryUpdate";
import { AddEmployeeContractUpdate } from "Components/CommonComponents/Modals/AddEmployee Edit Modal/AddEmployeeContractUpdate";
import { AddEmployeePricingPlanModal } from "Components/CommonComponents/Modals/AddEmployee Edit Modal/AddEmployeePricingPlanModal";
import { useSnackbar } from "notistack";

export const AddEmployeeFinal = () => {
  const navigate = useNavigate();
  const params = useParams();

  const { enqueueSnackbar } = useSnackbar();
  const [msg, sendNotification] = useNotification();

  const [progress, setProgress] = useState(75);
  const [loading, setLoading] = useState(false);

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [startDate, setStartDate] = useState("");

  const [contractDuration, setContractDuration] = useState("");
  const [termsOfContract, setTermsOfContract] = useState("");

  const [probationPeriod, setProbationPeriod] = useState("");
  const [typeOfEmployee, setTypeOfEmployee] = useState("");
  const [workingHours, setWorkingHours] = useState("");
  const [annualSalary, setAnnualSalary] = useState("");
  const [employeeProvidentFund, setEmployeeProvidentFund] = useState("");
  const [signingBonus, setSigningBonus] = useState("");
  const [otherBonus, setOtherBonus] = useState("");
  const [comission, setComission] = useState("");
  const [equityCompensation, setEquityCompensation] = useState("");
  const [roleDescription, setRoleDescription] = useState("");
  const [superviserName, setSuperviserName] = useState("");
  const [experienceLevel, setExperienceLevel] = useState("");
  const [workAddress, setWorkAddress] = useState("");
  const [nonSolicitationClause, setNonSolicitationClause] = useState("");
  const [benefits, setBenefits] = useState(false);
  const [billingCycle, setBillingCycle] = useState("");
  const [PricingPerEmployee, setPricingPerEmployee] = useState("");
  const [currencyType, setCurrencyType] = useState("");

  const [paidLeaves, setPaidLeaves] = useState("");

  const [signingBonusValue, setSigningBonusValue] = useState("");
  const [otherBonusvalue, setOtherBonusvalue] = useState("");
  const [comissionBonusValue, setComissionBonusValue] = useState("");

  const [summuaryModalOpen, setSummuaryModalOpen] = useState(false);
  const [contractModalOpen, setContractModalOpen] = useState(false);
  const [pricingModalOpen, setPricingModalOpen] = useState(false);

  const showLoading = () => {
    setLoading(true);
  };

  const hideLoading = () => {
    setLoading(false);
  };

  const goBack = () => {
    showLoading();
    var row_id = localStorage.getItem("empRowData");
    var id = params.id;
    if (id || row_id) {
      localStorage.removeItem("Empdata1");
      localStorage.removeItem("Empdata2");
      localStorage.removeItem("Empdata3");
      localStorage.removeItem("empRowData");

      navigate("/onboarding");
      hideLoading();
    } else {
      navigate("/team");
    }
  };

  useEffect(() => {
    getEmployeeData1();
  }, []);

  const getEmployeeData1 = () => {
    if (
      localStorage.getItem("Empdata1") &&
      localStorage.getItem("Empdata2") &&
      localStorage.getItem("Empdata3")
    ) {
      const Summary = JSON.parse(localStorage.getItem("Empdata1"));
      const Contract = JSON.parse(localStorage.getItem("Empdata2"));
      const Pricingplan = JSON.parse(localStorage.getItem("Empdata3"));
      const Masterdata = {
        ...Summary,
        ...Contract,
        ...Pricingplan,
      };

      setFullName(Masterdata.full_name);
      setTermsOfContract(Masterdata.term_of_contract);
      setEmail(Masterdata.email);
      setCountryCode(Masterdata.country_code);
      setContactNumber(Masterdata.phone_number);
      setJobTitle(Masterdata.job_title);
      setStartDate(Masterdata.provisional_start_date);
      setContractDuration(Masterdata.contract_duration);
      setProbationPeriod(Masterdata.probation_period);
      setTypeOfEmployee(Masterdata.type_of_employee);
      setWorkingHours(Masterdata.work_hours);
      setAnnualSalary(Masterdata.annual_gross_salary);
      var empprovidantFund = JSON.parse(
        localStorage.getItem("employee_provident_fund")
      );

      var data = empprovidantFund.filter((value) => {
        return value.row_id === parseInt(Masterdata.employee_provident_fund);
      });

      var value = data.map((data) => data.value);
      setEmployeeProvidentFund(value[0]);
      if (Masterdata.is_signing_bonus == "False") {
        setSigningBonus("No");
      } else {
        setSigningBonus("Yes");
        setSigningBonusValue(Masterdata.signing_bonus);
      }
      if (Masterdata.is_other_bonuses == "False") {
        setOtherBonus("No");
      } else {
        setOtherBonus("Yes");
        setOtherBonusvalue(Masterdata.other_bonuses);
      }
      if (Masterdata.is_commission == "False") {
        setComission("No");
      } else {
        setComission("Yes");
        setComissionBonusValue(Masterdata.commission);
      }
      setEquityCompensation(Masterdata.equity_compensation);
      setRoleDescription(Masterdata.role_description);
      setSuperviserName(Masterdata.supervisor_name);
      if (Masterdata.experience_level) {
        var experienceArray = JSON.parse(
          localStorage.getItem("experience_level")
        );
        var expData = experienceArray.filter((value) => {
          return Masterdata.experience_level == value.row_id;
        });
        var expValue = expData.map((value) => value.value);

        setExperienceLevel(expValue[0]);
      }
      setWorkAddress(Masterdata.work_address);
      setNonSolicitationClause(Masterdata.non_solicitation);
      setBenefits(Masterdata.benefits);

      setPaidLeaves(Masterdata.paid_time_off_days);
      setBillingCycle(Masterdata.type);
      setPricingPerEmployee(Masterdata.price);
      setCurrencyType(Masterdata.currency);
    } else {
      const id = params.id;

      getEmployeeData(id)
        .then(
          (res) => {
            setFullName(res.data.full_name);
            setEmail(res.data.email);
            setJobTitle(res.data.job_title);
            setStartDate(res.data.provisional_start_date);
            setContactNumber(res.data.phone_number);
            setCountryCode(res.data.country_code);
          },
          (err) => {
            if (err.response.status === 403) {
              sendNotification({
                msg: err.response.data.message,
                variant: "error",
              });
              setTimeout(() => {
                navigate("/onboarding");
              }, 500);
            }
          }
        )
        .then(
          getEmpContract(id).then(
            (res) => {
              setContractDuration(res.data.contract_duration);
              setTermsOfContract(res.data.term_of_contract);
              setProbationPeriod(res.data.probation_period);
              setTypeOfEmployee(res.data.type_of_employee);
              setWorkingHours(res.data.work_hours);
              setAnnualSalary(res.data.annual_gross_salary);
              if (res.data.is_signing_bonus == true) {
                setSigningBonus("Yes");
                setSigningBonusValue(res.data.signing_bonus);
              } else {
                setSigningBonus("No");
              }
              if (res.data.is_other_bonuses == true) {
                setOtherBonus("Yes");
                setOtherBonusvalue(res.data.other_bonuses);
              } else {
                setOtherBonus("No");
              }
              if (res.data.is_commission == true) {
                setComission("Yes");
                setComissionBonusValue(res.data.commission);
              } else {
                setComission("No");
              }

              setPaidLeaves(res.data.paid_time_off_days);
              setRoleDescription(res.data.role_description);
              setSuperviserName(res.data.supervisor_name);
              setExperienceLevel(
                res.data.experience_level == null
                  ? ""
                  : res.data.experience_level.value
              );

              if (res.data.non_solicitation == true) {
                setNonSolicitationClause("Yes");
              } else {
                setNonSolicitationClause("No");
              }
              setBenefits(res.data.benefits);
              if (res.data.employee_provident_fund !== null) {
                setEmployeeProvidentFund(
                  res.data.employee_provident_fund.value
                );
              } else {
                setEmployeeProvidentFund("");
              }
            },
            (err) => {
              if (err.response.status === 403) {
                sendNotification({
                  msg: err.response.data.message,
                  variant: "error",
                });
                setTimeout(() => {
                  navigate("/onboarding");
                }, 500);
              }
            }
          )
        )
        .then(
          getPricingForEmployee(id).then(
            (res) => {
              setBillingCycle(res.data.type);
              setPricingPerEmployee(res.data.price);
              setCurrencyType(res.data.currency);
            },
            (err) => {
              if (err.response.status === 403) {
                sendNotification({
                  msg: err.response.data.message,
                  variant: "error",
                });
                setTimeout(() => {
                  navigate("/onboarding");
                }, 500);
              }
            }
          )
        );
    }
  };

  const invitEmployee = () => {
    showLoading();

    var row_id = localStorage.getItem("empRowData");
    var id = params.id;
    const data = {
      is_draft: "False",
    };
    if (id) {
      empInvite(data, id).then(
        (res) => {
          var variant = "success";
          enqueueSnackbar(res.data.message, {
            variant,
          });
          localStorage.removeItem("Empdata1");
          localStorage.removeItem("Empdata2");
          localStorage.removeItem("Empdata3");
          localStorage.removeItem("empRowData");

          setTimeout(() => {
            hideLoading();
            navigate("/onboarding");
          }, 1500);
        },
        (err) => {
          sendNotification({
            msg: err.response.data.message,
            variant: "error",
          });
          setTimeout(() => {
            if (err.response.data.message === "Employee already invited") {
              navigate("/onboarding");
            }
          }, 2000);
          hideLoading();
        }
      );
    } else {
      empInvite(data, row_id).then(
        (res) => {
          var variant = "success";
          enqueueSnackbar(res.data.message, {
            variant,
          });

          localStorage.removeItem("Empdata1");
          localStorage.removeItem("Empdata2");
          localStorage.removeItem("Empdata3");
          localStorage.removeItem("empRowData");

          setTimeout(() => {
            hideLoading();
            navigate("/onboarding");
          }, 1500);
        },
        (err) => {
          sendNotification({
            msg: err.response.data.message,
            variant: "error",
          });
          setTimeout(() => {
            if (err.response.data.message === "Employee already invited") {
              navigate("/onboarding");
            }
          }, 2000);
          hideLoading();
        }
      );
    }
  };

  const saveDraft = () => {
    showLoading();

    var row_id = localStorage.getItem("empRowData");
    var id = params.id;
    const data = {
      is_draft: "True",
    };
    if (id) {
      empInvite(data, id).then(
        (res) => {
          var variant = "success";
          enqueueSnackbar(res.data.message, {
            variant,
          });

          hideLoading();
          localStorage.removeItem("Empdata1");
          localStorage.removeItem("Empdata2");
          localStorage.removeItem("Empdata3");
          localStorage.removeItem("empRowData");

          hideLoading();
          navigate("/onboarding");
        },
        (err) => {
          sendNotification({
            msg: err.response.data.message,
            variant: "error",
          });
          setTimeout(() => {
            if (err.response.data.message === "Employee already invited") {
              navigate("/onboarding");
            }
          }, 2000);
          hideLoading();
        }
      );
    } else {
      empInvite(data, row_id).then(
        (res) => {
          var variant = "success";
          enqueueSnackbar(res.data.message, {
            variant,
          });

          localStorage.removeItem("Empdata1");
          localStorage.removeItem("Empdata2");
          localStorage.removeItem("Empdata3");
          localStorage.removeItem("empRowData");

          hideLoading();
          navigate("/onboarding");
        },
        (err) => {
          sendNotification({
            msg: err.response.data.message,
            variant: "error",
          });
          setTimeout(() => {
            if (err.response.data.message === "Employee already invited") {
              navigate("/onboarding");
              hideLoading();
            }
          }, 2000);
          hideLoading();
        }
      );
    }
  };

  return (
    <>
      <div className="login_cont register_cont">
        <Typography variant="h3">
          {" "}
          Ready to invite {fullName} to complete their onboarding?{" "}
        </Typography>
        <Typography variant="body2">
          If their details look good, we’ll craft their contract and invite them
          to begin the onboarding process. If you’d rather invite them later,
          you can save this draft and invite them from your Onboarding page.
        </Typography>
        <Grid container xs={12} className="login_bttn_wrapper">
          <Button
            onClick={saveDraft}
            className="theme_bttn"
            disabled={loading ? true : false}
          >
            Save Draft
          </Button>

          <Button
            onClick={invitEmployee}
            className="theme_bttn fill"
            disabled={loading ? true : false}
          >
            Invite Now
          </Button>
        </Grid>
        <Grid xs={12} className="all_employe_profile_bx">
          <Box className="employe_profile_bx with_shadow">
            <Grid
              container
              alignItems="center"
              justifyContent={"space-between"}
              className="employe_profile_head"
              spacing={0}
            >
              <Grid item>
                <Typography>Summary</Typography>
              </Grid>
              <Grid item>
                <Grid container alignItems="center">
                  <Button
                    className="theme_bttn transparent"
                    // onClick={summaryNavigate}
                    onClick={() => setSummuaryModalOpen(true)}
                    startIcon={<EditIcon />}
                  >
                    Edit
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <div className="employe_profile_body">
              <Grid
                xs={12}
                container
                alignItems="center"
                flexDirection={{ xs: "row", sm: "row" }}
                className="employe_personal_dtl"
              >
                <Typography variant="body1">Full Name</Typography>
                <Typography variant="body2">{fullName}</Typography>
              </Grid>

              <Grid
                xs={12}
                container
                alignItems="center"
                flexDirection={{ xs: "row", sm: "row" }}
                className="employe_personal_dtl"
              >
                <Typography variant="body1">Personal email</Typography>
                <Typography variant="body2">{email}</Typography>
              </Grid>

              <Grid
                xs={12}
                container
                alignItems="center"
                flexDirection={{ xs: "row", sm: "row" }}
                className="employe_personal_dtl"
              >
                <Typography variant="body1">Job title</Typography>
                <Typography variant="body2">{jobTitle}</Typography>
              </Grid>

              <Grid
                xs={12}
                container
                alignItems="center"
                flexDirection={{ xs: "row", sm: "row" }}
                className="employe_personal_dtl"
              >
                <Typography variant="body1">Phone Number</Typography>
                <Typography variant="body2">
                  {countryCode}-{contactNumber}
                </Typography>
              </Grid>

              <Grid
                xs={12}
                container
                alignItems="center"
                flexDirection={{ xs: "row", sm: "row" }}
                className="employe_personal_dtl"
              >
                <Typography variant="body1">
                  Provisional starting date
                </Typography>
                <Typography variant="body2">{startDate}</Typography>
              </Grid>
            </div>
          </Box>

          <Box className="employe_profile_bx with_shadow">
            <Grid
              container
              alignItems="center"
              justifyContent={"space-between"}
              className="employe_profile_head"
              spacing={0}
            >
              <Grid item>
                <Typography>Contract</Typography>
              </Grid>
              <Grid item>
                <Grid container alignItems="center">
                  <Button
                    className="theme_bttn transparent"
                    onClick={() => setContractModalOpen(true)}
                    startIcon={<EditIcon />}
                  >
                    Edit
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <div className="employe_profile_body">
              <Grid
                xs={12}
                container
                alignItems="center"
                flexDirection={{ xs: "row", sm: "row" }}
                className="employe_personal_dtl"
              >
                <Typography variant="body1">Contract duration</Typography>
                <Typography variant="body2">{contractDuration}</Typography>
              </Grid>
              {contractDuration == "fixed-term" ? (
                <Grid
                  xs={12}
                  container
                  alignItems="center"
                  flexDirection={{ xs: "row", sm: "row" }}
                  className="employe_personal_dtl"
                >
                  <Typography variant="body1">Terms Of Contract</Typography>
                  <Typography variant="body2">{termsOfContract}</Typography>
                </Grid>
              ) : null}

              <Grid
                xs={12}
                container
                alignItems="center"
                flexDirection={{ xs: "row", sm: "row" }}
                className="employe_personal_dtl"
              >
                <Typography variant="body1">
                  Probation period (in months)
                </Typography>
                <Typography variant="body2">{probationPeriod}</Typography>
              </Grid>

              <Grid
                xs={12}
                container
                alignItems="center"
                flexDirection={{ xs: "row", sm: "row" }}
                className="employe_personal_dtl"
              >
                <Typography variant="body1">Type of employee</Typography>
                <Typography variant="body2">{typeOfEmployee}</Typography>
              </Grid>

              <Grid
                xs={12}
                container
                alignItems="center"
                flexDirection={{ xs: "row", sm: "row" }}
                className="employe_personal_dtl"
              >
                <Typography variant="body1">Work hours (per week)</Typography>
                <Typography variant="body2">{workingHours}</Typography>
              </Grid>

              <Grid
                xs={12}
                container
                alignItems="center"
                flexDirection={{ xs: "row", sm: "row" }}
                className="employe_personal_dtl"
              >
                <Typography variant="body1">Annual gross salary</Typography>
                <Typography variant="body2">{annualSalary}</Typography>
              </Grid>

              <Grid
                xs={12}
                container
                alignItems="center"
                flexDirection={{ xs: "row", sm: "row" }}
                className="employe_personal_dtl"
              >
                <Typography variant="body1">Employee Provident Fund</Typography>
                <Typography variant="body2">{employeeProvidentFund}</Typography>
              </Grid>

              <Grid
                xs={12}
                container
                alignItems="center"
                flexDirection={{ xs: "row", sm: "row" }}
                className="employe_personal_dtl"
              >
                <Typography variant="body1">Signing bonus</Typography>
                <Typography variant="body2">{signingBonus}</Typography>
              </Grid>

              {signingBonus == "Yes" ? (
                <Grid
                  xs={12}
                  container
                  alignItems="center"
                  flexDirection={{ xs: "row", sm: "row" }}
                  className="employe_personal_dtl"
                >
                  <Typography variant="body1">Signing bonus Value</Typography>
                  <Typography variant="body2">{signingBonusValue}</Typography>
                </Grid>
              ) : null}

              <Grid
                xs={12}
                container
                alignItems="center"
                flexDirection={{ xs: "row", sm: "row" }}
                className="employe_personal_dtl"
              >
                <Typography variant="body1">Other bonuses</Typography>
                <Typography variant="body2">{otherBonus}</Typography>
              </Grid>
              {otherBonus == "Yes" ? (
                <Grid
                  xs={12}
                  container
                  alignItems="center"
                  flexDirection={{ xs: "row", sm: "row" }}
                  className="employe_personal_dtl"
                >
                  <Typography variant="body1">Other bonus Value</Typography>
                  <Typography variant="body2">{otherBonusvalue}</Typography>
                </Grid>
              ) : null}

              <Grid
                xs={12}
                container
                alignItems="center"
                flexDirection={{ xs: "row", sm: "row" }}
                className="employe_personal_dtl"
              >
                <Typography variant="body1">Commission</Typography>
                <Typography variant="body2">{comission}</Typography>
              </Grid>
              {comission == "Yes" ? (
                <Grid
                  xs={12}
                  container
                  alignItems="center"
                  flexDirection={{ xs: "row", sm: "row" }}
                  className="employe_personal_dtl"
                >
                  <Typography variant="body1">Commision bonus Value</Typography>
                  <Typography variant="body2">{comissionBonusValue}</Typography>
                </Grid>
              ) : null}

              <Grid
                xs={12}
                container
                alignItems="center"
                flexDirection={{ xs: "row", sm: "row" }}
                className="employe_personal_dtl"
              >
                <Typography variant="body1">
                  Number of paid time off days
                </Typography>
                <Typography variant="body2">{paidLeaves}</Typography>
              </Grid>

              <Grid
                xs={12}
                container
                alignItems="center"
                flexDirection={{ xs: "row", sm: "row" }}
                className="employe_personal_dtl"
              >
                <Typography variant="body1">Role description</Typography>
                <Typography variant="body2">{roleDescription}</Typography>
              </Grid>

              <Grid
                xs={12}
                container
                alignItems="center"
                flexDirection={{ xs: "row", sm: "row" }}
                className="employe_personal_dtl"
              >
                <Typography variant="body1">Supervisor name</Typography>
                <Typography variant="body2">{superviserName}</Typography>
              </Grid>

              <Grid
                xs={12}
                container
                alignItems="center"
                flexDirection={{ xs: "row", sm: "row" }}
                className="employe_personal_dtl"
              >
                <Typography variant="body1">Experience level</Typography>
                <Typography variant="body2">{experienceLevel}</Typography>
              </Grid>

              <Grid
                xs={12}
                container
                alignItems="center"
                flexDirection={{ xs: "row", sm: "row" }}
                className="employe_personal_dtl"
              >
                <Typography variant="body1">
                  Apply a non-solicitation clause?
                </Typography>
                <Typography variant="body2">{nonSolicitationClause}</Typography>
              </Grid>

              <Grid
                xs={12}
                container
                alignItems="center"
                flexDirection={{ xs: "row", sm: "row" }}
                className="employe_personal_dtl"
              >
                <Typography variant="body1">Benefits</Typography>
                {benefits && (
                  <Typography variant="body2">
                    Premium (Plum [ICICI Lombard] - Medical Insurance, Dental
                    Insurance, Vision Insurance, Personal Accident Insurance)
                  </Typography>
                )}
              </Grid>
            </div>
          </Box>

          <Box className="employe_profile_bx with_shadow">
            <Grid
              container
              alignItems="center"
              justifyContent={"space-between"}
              className="employe_profile_head"
              spacing={0}
            >
              <Grid item>
                <Typography>Pricing plan</Typography>
              </Grid>
              <Grid item>
                <Grid container alignItems="center">
                  <Button
                    className="theme_bttn transparent"
                    onClick={(e) => setPricingModalOpen(!pricingModalOpen)}
                    startIcon={<EditIcon />}
                  >
                    Edit
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <div className="employe_profile_body">
              <Grid
                xs={12}
                container
                alignItems="center"
                flexDirection={{ xs: "row", sm: "row" }}
                className="employe_personal_dtl"
              >
                <Typography variant="body1">Pricing per employee</Typography>
                <Typography variant="body2">
                  ${PricingPerEmployee}
                  {" " + currencyType}
                </Typography>
              </Grid>

              <Grid
                xs={12}
                container
                alignItems="center"
                flexDirection={{ xs: "row", sm: "row" }}
                className="employe_personal_dtl"
              >
                <Typography variant="body1">Billing cycle</Typography>
                <Typography variant="body2">{billingCycle}</Typography>
              </Grid>

              <Grid
                xs={12}
                container
                alignItems="center"
                flexDirection={{ xs: "row", sm: "row" }}
                className="employe_personal_dtl"
              >
                <Typography variant="body1">Total annual price</Typography>
                <Typography variant="body2">
                  $
                  {billingCycle === "billed-annually"
                    ? PricingPerEmployee
                    : PricingPerEmployee * 12}{" "}
                  {" " + currencyType}
                </Typography>
              </Grid>

              <Grid xs={12} className="employe_personal_dtl_para">
                <Typography variant="body2">
                  In addition to InRole’s management fee, you will also be
                  invoiced for your employee’s salary, benefits, local taxes,
                  and any other local employment fees. Learn more about{" "}
                  <Link to={""}>pricing</Link>, or explore the{" "}
                  <Link to={""}>cost calculator</Link> for your new employee.
                </Typography>
              </Grid>
            </div>
          </Box>
        </Grid>

        <Grid container className="login_bttn_wrapper">
          <Button
            onClick={saveDraft}
            className="theme_bttn"
            disabled={loading ? true : false}
          >
            Save Draft
          </Button>
          <Button
            onClick={invitEmployee}
            className="theme_bttn fill"
            disabled={loading ? true : false}
          >
            Invite Now
          </Button>
        </Grid>
      </div>
      <HelpButton />
      <Loader open={loading} />
      <AddEmployeeSummaryUpdate
        modalOpen={summuaryModalOpen}
        close={() => setSummuaryModalOpen(false)}
        summuryAPI={() => getEmployeeData1()}
        fullName={fullName}
        email={email}
        jobTitle={jobTitle}
        provisionalStartDate={startDate}
        contactNumber={contactNumber}
        countryCode={countryCode}
      />
      <AddEmployeeContractUpdate
        modalOpen={contractModalOpen}
        close={() => setContractModalOpen(false)}
        summuryAPI={() => getEmployeeData1()}
        contractDuration={contractDuration}
        termsOfContract={termsOfContract}
        probationPeriod={probationPeriod}
        typeOfEmployee={typeOfEmployee}
        workingHours={workingHours}
        annualSalary={annualSalary}
        signingBonus={signingBonus}
        signingBonusValue={signingBonusValue}
        otherBonus={otherBonus}
        otherBonusvalue={otherBonusvalue}
        comission={comission}
        comissionBonusValue={comissionBonusValue}
        paidLeaves={paidLeaves}
        roleDescription={roleDescription}
        superviserName={superviserName}
        experienceLevel={experienceLevel}
        nonSolicitationClause={nonSolicitationClause}
        benifits={benefits}
        employeeProvidentFund={employeeProvidentFund}
      />
      <AddEmployeePricingPlanModal
        modalOpen={pricingModalOpen}
        close={(e) => setPricingModalOpen(false)}
        pricingperemployee={PricingPerEmployee}
        billingCycle={billingCycle}
        currencyType={currencyType}
        summuryAPI={() => getEmployeeData1()}
      />
    </>
  );
};
