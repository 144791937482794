import {
  Button,
  Grid,
  Grow,
  IconButton,
  Modal,
  Slide,
  Typography,
  Zoom,
} from "@mui/material";
import "../../../Teams/Teams/Team.css";
import { Box } from "@mui/system";

import React from "react";
import { useNavigate } from "react-router-dom";
import {
  CloseIcon,
  TeamContractorModalIcon,
  TeamEmployeeModalIcon,
} from "Icons/Icons";

export const EmployeeAndContractorAddModal = (props) => {
  const navigate = useNavigate();

  return (
    <Modal
      onBackdropClick={props.close}
      open={props.open}
      onClose={props.close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="add_hire_modal"
    >
      <Slide in={props.open}>
        <Box className="add_hire_modal_bx">
          <Grid>
            <div className="modal_header">
              <Grid container flexDirection={{ xs: "row", sm: "row" }}>
                <Grid item>
                  <Typography>Add New Hire</Typography>
                </Grid>
                <Grid item>
                  <IconButton onClick={props.close} className="icon_close_bttn">
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </div>
            <div className="modal_body">
              <Zoom
                in={props.open}
                style={{ transitionDelay: props.open ? "100ms" : "0ms" }}
              >
                <Button
                  onClick={() => {
                    navigate("/addemployee");
                    localStorage.removeItem("addEmployeeStepper");
                  }}
                  className="modal_employe_type_bx"
                >
                  <Grid container flexDirection={{ xs: "row", sm: "row" }}>
                    <Grid item xs={3} md={2}>
                      <TeamEmployeeModalIcon />
                    </Grid>
                    <Grid item xs={8} md={9}>
                      <Typography variant="h6">Employee</Typography>
                      <Typography>
                        A full-timer or part time worker employed by InRole on
                        your behalf.
                      </Typography>
                    </Grid>
                  </Grid>
                </Button>
              </Zoom>
              <Zoom
                in={props.open}
                style={{ transitionDelay: props.open ? "200ms" : "0ms" }}
              >
                <Button
                  onClick={() => {
                    navigate("/addcontractor");
                    localStorage.removeItem("addContractorStepper");
                  }}
                  className="modal_employe_type_bx"
                >
                  <Grid container flexDirection={{ xs: "row", sm: "row" }}>
                    <Grid item xs={3} md={2}>
                      <TeamContractorModalIcon />
                    </Grid>
                    <Grid item xs={8} md={9}>
                      <Typography variant="h6">Contractor</Typography>
                      <Typography>
                        A person that provides a service for Openwhite Pvt Ltd.
                      </Typography>
                    </Grid>
                  </Grid>
                </Button>
              </Zoom>
            </div>
            <div className="modal_footer">
              <Typography variant="h6">Need more guidance?</Typography>
              <Typography>
                We’re happy to provide you with more information about how
                onboarding works at InRole.
              </Typography>
              <Button className="see_guide_bttn">See Guide</Button>
            </div>
          </Grid>
        </Box>
      </Slide>
    </Modal>
  );
};
