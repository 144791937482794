import React, { useEffect, useState } from "react";

import Grid from "@mui/material/Unstable_Grid2";
import Rectangle from "../../../Assets/Svgs/Rectangle-Login.svg";

import { InRoleSidebarLogo, PreviousArrowIcon } from "Icons/Icons";
import {
  Button,
  IconButton,
  Step,
  StepLabel,
  Stepper,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Register } from "../Register1/Register";
import { Register3 } from "../Register3/Register3";
import { Register4 } from "../Register4/Register4";
import { Register5 } from "../Register5/Register5";
import { Register6 } from "../Register6/Register6";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthSidebar } from "Components/Login/LoginImport";
import { LogoutIcon } from "Components/Dashboard/DashboardImports";
import { useAuth0 } from "@auth0/auth0-react";
import { LinearProgressWithLabel } from "Utils/TabPanel";

export const RegisterSideBar = (props) => {
  return (
    <>
      <Grid container className="login_grid">
        <AuthSidebar logoutBttn>
          <div className="register-sidebar">
            <Typography variant="h3" gutterBottom>
              Sign Up with Inrole
            </Typography>
            <Typography variant="body2" gutterBottom>
              Get Started by creating your account
            </Typography>
            <RegisterStepper />
          </div>
        </AuthSidebar>
        <RegisterHeader
          register1={props.register1}
          register2={props.register2}
          register3={props.register3}
          register4={props.register4}
          register5={props.register5}
        />
      </Grid>
    </>
  );
};

const RegisterStepper = (props) => {
  const location = useLocation();
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    if (location.pathname === "/register/4") {
      setActiveStep(1);
    } else if (location.pathname === "/register/5") {
      setActiveStep(2);
    } else if (location.pathname === "/register/6") {
      setActiveStep(3);
    } else if (location.pathname === "/register/3") {
      setActiveStep(0);
    } else if (location.pathname === "/register") {
      setActiveStep(0);
    }
  }, [location.pathname]);

  const stepsForRegisteration = [
    {
      label: "Create Account",
    },
    {
      label: "Basic Information",
    },
    {
      label: "Company Address",
    },
  ];
  return (
    <Stepper
      activeStep={parseInt(activeStep)}
      orientation={props.orientation ? "horizontal" : "vertical"}
      className={`register-stepper ${
        activeStep == 3 ? "all-steps-completed" : ""
      } ${props.className}`}
    >
      {stepsForRegisteration.map((step, index) => (
        <Step key={step.label}>
          <StepLabel>{props.noLabel ? null : step.label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

const RegisterHeader = (props) => {
  const tabletScreen = useMediaQuery("(max-width:899px)");
  const navigate = useNavigate();
  const location = useLocation();
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (location.pathname === "/register/4") {
      setProgress(33);
    } else if (location.pathname === "/register/5") {
      setProgress(67);
    } else if (location.pathname === "/register/6") {
      setProgress(100);
    } else if (location.pathname === "/register/3") {
      setProgress(0);
    } else if (location.pathname === "/register") {
      setProgress(0);
    }
  }, [location.pathname]);

  const goBack = () => {
    if (location.pathname === "/register/3") {
      navigate("/register");
    } else if (location.pathname === "/register/4") {
      navigate("/register/3");
    } else if (location.pathname === "/register/5") {
      navigate("/register/4");
    } else if (location.pathname === "/register/6") {
      navigate("/register/5");
    }
  };

  return (
    <Grid xs={8} item className="login_content_grid_item">
      {!props.register1 ? (
        <Grid
          container
          alignItems="center"
          flexDirection={{ xs: "row", sm: "row" }}
          className="auth_top_header"
        >
          <Grid item>
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="label"
              onClick={goBack}
            >
              <PreviousArrowIcon />
            </IconButton>
            Previous
          </Grid>
          <Grid item className="auth_top_header_progress">
            <LinearProgressWithLabel value={progress} />
          </Grid>
        </Grid>
      ) : null}
      <div className="login_cont register_cont">
        {props.register1 && (
          <Typography variant="h3">
            Finish Registering Your New Account
          </Typography>
        )}

        {props.register2 && (
          <>
            <Typography variant="h3">How can we help you?</Typography>
          </>
        )}

        {props.register3 && (
          <Typography variant="h3"> Enter company details </Typography>
        )}

        {props.register4 && (
          <Typography variant="h3"> Enter company address </Typography>
        )}

        {props.register5 && (
          <Typography variant="h3">Welcome to Inrole</Typography>
        )}

        {tabletScreen && !props.register5 ? (
          <RegisterStepper
            className="horizontal_Stepper"
            orientation="horizontal"
            noLabel
          />
        ) : null}

        {props.register2 && (
          <Typography variant="body2">
            We are here to help you whatever your requirements maybe. Select
            from one of the options below for us to get to know you better.
          </Typography>
        )}

        {props.register3 && (
          <Typography variant="body2">
            Information needed for invoice and taxation{" "}
          </Typography>
        )}

        {props.register4 && (
          <Typography variant="body2">
            Enter the details of your legally registered entity. If you do not
            have an entity yet, please enter your personal details.
          </Typography>
        )}

        {props.register1 && <Register />}
        {props.register2 && <Register3 />}
        {props.register3 && <Register4 />}
        {props.register4 && <Register5 />}
        {props.register5 && <Register6 />}
      </div>
    </Grid>
  );
};
