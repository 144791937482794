import "./Register3.css";
import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  FormControl,
  Box,
  Radio,
  RadioGroup,
  Grid,
  Button,
  FormControlLabel,
  IconButton,
  Typography,
} from "./Register3Imports";

import { PreviousArrowIcon } from "Icons/Icons";
import useNotification from "Components/CommonComponents/AlertNotifications/AlertNotifications";
import { jwt_decode } from "../Register1/Register1Imports";
import { Skeleton } from "@mui/material";

export const Register3 = () => {
  const navigate = useNavigate();
  const [msg, sendNotification] = useNotification();
  const [loading, setLoading] = useState(true);

  const [progress, setProgress] = useState(0);
  const [requirement, setRequirement] = useState("");
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    getquestion();
    filldata();
    getEmail();
  }, []);

  const showLoading = () => {
    setLoading(true);
  };

  const hideLoading = () => {
    setLoading(false);
  };

  const getEmail = () => {
    if (JSON.parse(localStorage.getItem("JWT")) !== null) {
      const JWT = JSON.parse(localStorage.getItem("JWT"));
      const DecodedJWT = jwt_decode(JWT.access_token);
      const accountType = DecodedJWT.account_type;
      if (accountType === "contractor" || accountType === "employee") {
        navigate("/employeeregister");
      }

      const isUserProfileUpdated = DecodedJWT.user_profile_updated;
      // console.log(isUserProfileUpdated);
      if (isUserProfileUpdated == true) {
        navigate("/register/6");
      }
    }
  };

  const handleChange = (event) => {
    setRequirement(event.target.value);
  };

  const submit = (e) => {
    e.preventDefault();
    const data = {
      requirement_que_id: requirement,
    };

    localStorage.setItem("Registrationdata1", JSON.stringify(data));

    navigate("/register/4");
  };

  const getquestion = () => {
    showLoading();
    if (JSON.parse(localStorage.getItem("requirement")) !== null) {
      setQuestions(JSON.parse(localStorage.getItem("requirement")));
      hideLoading();
    } else {
      setQuestions([]);
      hideLoading();
    }
  };

  const filldata = () => {
    const data = JSON.parse(localStorage.getItem("Registrationdata1"));
    if (data || data != null) {
      setRequirement(data.requirement_que_id);
    }
  };

  return (
    <>
      <form className="register_form">
        <Grid>
          <FormControl>
            <RadioGroup
              required
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              onChange={handleChange}
              className="register_form_card_group"
              value={requirement}
            >
              {!loading
                ? questions.map((questions) => (
                    <Box key={questions.row_id} className="register_form_card">
                      <Grid fullWidth className="register_form_card_grid">
                        <FormControlLabel
                          value={questions.row_id}
                          control={<Radio />}
                          label={questions.value}
                          labelPlacement="start"
                          className="register_form_card_formcontrol"
                        />
                      </Grid>
                    </Box>
                  ))
                : [1, 2, 3, 4].map((questions) => (
                    <Box key={questions} className="register_form_card">
                      <Grid fullWidth className="register_form_card_grid">
                        {" "}
                        <FormControlLabel
                          value={questions}
                          control={
                            <Skeleton
                              animation="wave"
                              variant="circular"
                              height={20}
                              width={20}
                            >
                              <Radio />
                            </Skeleton>
                          }
                          label={
                            <Skeleton animation="wave">
                              <Typography>
                                Onboard contractors, freelancers and employees
                                without a legal entity in India (for foreign
                                companies)
                              </Typography>
                            </Skeleton>
                          }
                          labelPlacement="start"
                          className="register_form_card_formcontrol"
                        />
                      </Grid>
                    </Box>
                  ))}
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid xs={12} className="login_bttn_wrapper">
          {!requirement ? (
            <Button
              disabled
              variant="contained"
              type="submit"
              className="theme_bttn fill"
            >
              Continue
            </Button>
          ) : (
            <Button
              variant="contained"
              type="submit"
              onClick={submit}
              className="theme_bttn fill"
            >
              {" "}
              Continue
            </Button>
          )}
        </Grid>
      </form>
    </>
  );
};
