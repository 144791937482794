import "./AddContractorPricingPlan.css";
import { React, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Grid,
  IconButton,
  Container,
  Stack,
  Divider,
  Rectangle,
  InRolllogo,
  Save14,
  Box,
  Typography,
  Stepper,
  Step,
  StepLabel,
  PersonOutlineOutlinedIcon,
  FiberManualRecordIcon,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Checkbox,
} from "./AddContractorPricingPlanImports";
import { AuthSidebar } from "Components/Login/LoginImport";
import { HelpButton } from "Components/CommonComponents/Bottom_Help_Button/HelpButton";
import { PreviousArrowIcon } from "Icons/Icons";
import { FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import {
  getPricingPlan,
  postContractorPlan,
} from "Services/AddEmployeeAndContractor";
import { useSnackbar } from "notistack";
import useNotification from "Components/CommonComponents/AlertNotifications/AlertNotifications";

export const AddContractorPricingPlan = () => {
  const navigate = useNavigate();
  const params = useParams();

  const { enqueueSnackbar } = useSnackbar();
  const [msg, sendNotification] = useNotification();

  const [progress, setProgress] = useState(75);
  const [billingCycle, setBillingCycle] = useState("Billed-annually");
  const [monthlyPricing, setMonthlyPricing] = useState("");
  const [yearlyPricing, setYearlyPricing] = useState("");
  const [currencyType, setCurrencyType] = useState("");

  useEffect(() => {
    pricingPlan();
    setTimeout(async () => {
      await setProgress(100);
    }, 500);
  }, []);

  const pricingPlan = async () => {
    await getPricingPlan().then(
      (res) => {
        setMonthlyPricing(res.data.monthly_contractor_pricing);
        setYearlyPricing(res.data.yearly_contractor_pricing);
        setCurrencyType(res.data.currency);
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const goBack = () => {
    var id = params.id;
    var rowdata = JSON.parse(localStorage.getItem("ContractorRowData"));
    var path = JSON.parse(localStorage.getItem("ContractorData2"));
    if (path.contract_option_id == 13) {
      if (!id) {
        navigate("/addcontractor/3/" + rowdata);
      } else {
        navigate("/addcontractor/3/" + id);
      }
    } else {
      if (!id) {
        navigate("/addcontractor/2/" + rowdata);
      } else {
        navigate("/addcontractor/2/" + id);
      }
    }
  };

  const submit = () => {
    var id = params.id;
    const companyID = localStorage.getItem("CompanyRowID");
    let price = 0;
    if (billingCycle === "Billed-annually") {
      price = yearlyPricing;
    } else {
      price = monthlyPricing;
    }
    if (!id) {
      var contractorId = localStorage.getItem("ContractorRowData");
      var data = {
        company_id: companyID,
        contractor_id: contractorId,
        type: billingCycle.toLowerCase(),
        price: price,
        currency: currencyType,
      };

      postContractorPlan(data).then(
        (res) => {
          var variant = "success";
          enqueueSnackbar(res.data.message, { variant });
          localStorage.setItem("ContractorData4", JSON.stringify(data));
          navigate("/addcontractorfinal");
        },
        (err) => {
          sendNotification({
            msg: err.response.data.message,
            variant: "error",
          });
        }
      );
    } else {
      var data = {
        company_id: companyID,
        contractor_id: id,
        type: billingCycle.toLowerCase(),
        price: price,
        currency: currencyType,
      };
      postContractorPlan(data).then(
        (res) => {
          var variant = "success";
          enqueueSnackbar(res.data.message, { variant });
          localStorage.setItem("ContractorData4", JSON.stringify(data));
          navigate("/addcontractorfinal");
        },
        (err) => {
          sendNotification({
            msg: err.response.data.message,
            variant: "error",
          });
        }
      );
    }
  };

  return (
    <>
      <form className="register_form">
        <Grid xs={12}>
          <FormLabel id="demo-radio-buttons-group-label">
            Choose your billing cycle
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            value={billingCycle}
            name="radio-buttons-group"
            onChange={(e) => setBillingCycle(e.target.value)}
          >
            <Grid container>
              <FormControlLabel
                value="Billed-annually"
                control={<Radio />}
                label="Billed annually"
              />

              <FormControlLabel
                value="Billed-monthly"
                control={<Radio />}
                label="Billed monthly"
              />
            </Grid>
          </RadioGroup>
        </Grid>
        <Grid xs={12}>
          <div className="pricing_bx">
            <div className="pricing_bx_head">
              <Typography variant="h5">SELECTED</Typography>
            </div>
            <Grid xs={12} className="pricing_bx_profile">
              <Box className="pricing_bx_profile_bx">
                <PersonOutlineOutlinedIcon />
              </Box>
            </Grid>
            <Grid xs={12} className="pricing_bx_cont_wrapper">
              <Box className="pricing_bx_cont">
                <Grid xs={12}>
                  <Typography variant="h4">Single contractor</Typography>
                </Grid>
                {billingCycle === "Billed-annually" ? (
                  <Grid xs={12} className="pricing_bx_save">
                    <Box className="pricing_bx_save_bx">SAVE 14%</Box>
                  </Grid>
                ) : null}
                <Grid container className="pricing_bx_price" spacing={0}>
                  <Typography variant="h4">
                    {/* $ */}
                    {billingCycle === "Billed-annually"
                      ? yearlyPricing
                      : monthlyPricing}
                  </Typography>
                  <Stack>
                    <Typography variant="h5">.00{currencyType}</Typography>
                    <Typography variant="body1">
                      /per
                      {billingCycle === "Billed-annually" ? " year" : " month"}
                    </Typography>
                  </Stack>
                </Grid>
                <Divider className="pricing_bx_Divider" />
                <Grid xs={12} className="pricing_bx_list">
                  <List>
                    <ListItem>International payments in 70+ countries</ListItem>
                    <ListItem>
                      Legally reviewed compliant contracts localised for dozens
                      of countries
                    </ListItem>
                    <ListItem>
                      Put payments on autopilot with recurring invoicing and
                      automatic approvals
                    </ListItem>
                  </List>
                </Grid>
              </Box>
            </Grid>
          </div>
        </Grid>
        <Grid xs={12} className="login_bttn_wrapper">
          <Button type="button" className="theme_bttn fill" onClick={submit}>
            Continue
          </Button>
        </Grid>
      </form>

      <HelpButton />
    </>
  );
};
