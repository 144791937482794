import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Avatar, Button, Fade, Icon, Slide, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { Box } from "@mui/system";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import InRoleLogo from "../../../Assets/Svgs/InRoleLogo2.svg";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import ThumbUpSharpIcon from "@mui/icons-material/ThumbUpSharp";
import { useAuth0 } from "@auth0/auth0-react";

import jwtDecode from "jwt-decode";
import { InRoleGreenLogo } from "Icons/Icons";
import {
  getContractorData,
  getStatus,
  getemployeeProfile,
} from "Services/AddEmployeeAndContractor";

export const EmpWelcome = (props) => {
  const navigate = useNavigate();
  const { logout } = useAuth0();
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(80);
  const [usermail, setEmail] = useState("");
  const [profileStatusArray, setProfileStatusArray] = useState([]);
  const [taskButtons, setTaskButtons] = useState([]);
  const [companyEmployeeStatusArray, setCompanyEmployeeStatusArray] = useState(
    []
  );
  const [totalStatus, setTotalStatus] = useState([]);
  const [totalTasks, setTotalTasks] = useState("");
  useEffect(() => {
    email();
    getProfileStatus();
  }, []);

  const showLoading = () => {
    setLoading(true);
  };

  const HideLoading = () => {
    setLoading(false);
  };

  const email = async () => {
    var jwt = localStorage.getItem("JWT");
    const decodedjwt = jwtDecode(jwt);
    setEmail(decodedjwt.email);
  };

  const getProfileStatus = () => {
    showLoading();
    if (localStorage.getItem("account_type") !== "contractor") {
      getemployeeProfile()
        .then(
          (res) => {
            // console.log(res.data);
            setProfileStatusArray([res.data]);
            setTaskButtons(res.data.company_employee_profile_status);

            setCompanyEmployeeStatusArray(res.data.company_employee_status);
            setTotalStatus(res.data.company_employee_status);
            let filterStatus = res.data.company_employee_status.filter(
              (status) => {
                return status.status_id.sequence == 2;
              }
            );

            var totalStatus = res.data.company_employee_profile_status.length;
            var multiplier = 100 / totalStatus;

            let completedStatus =
              res.data.company_employee_profile_status.filter((data) => {
                return data.completed == false;
              });
            setTotalTasks(completedStatus.length);
            setProgress((totalStatus - completedStatus.length) * multiplier);
            if (filterStatus[0].completed) {
              navigate("/empwelcome");
            }
            HideLoading();
          },
          (err) => {
            console.log(err.message);
            props.unAuthorized(err.response.status);
            HideLoading();
          }
        )
        .then(
          getStatus().then(
            (res) => {
              // console.log(res.data.data);
              // setTotalTasks(res.data.data[1].child_status.length);
            },
            (err) => {
              console.log(err.message);
              props.unAuthorized(err.response.status);
            }
          )
        );
    } else {
      getContractorData()
        .then(
          (res) => {
            setProfileStatusArray([res.data]);
            setTaskButtons(res.data.company_contractor_profile_status);
            setCompanyEmployeeStatusArray(res.data.company_employee_status);
            setTotalStatus(res.data.company_contractor_status);

            let filterStatus = res.data.company_contractor_status.filter(
              (status) => {
                return status.status_id.sequence == 2;
              }
            );

            var totalStatus = res.data.company_contractor_profile_status.length;
            var multiplier = 100 / totalStatus;

            let completedStatus =
              res.data.company_contractor_profile_status.filter((data) => {
                return data.completed == false;
              });
            setTotalTasks(completedStatus.length);
            setProgress((totalStatus - completedStatus.length) * multiplier);

            if (filterStatus[0].completed) {
              navigate("/empwelcome");
            }
            HideLoading();
          },
          (err) => {
            console.log(err.message);
            HideLoading();
          }
        )
        .then(
          getStatus().then(
            (res) => {
              // console.log(res.data);
            },
            (err) => {
              console.log(err.message);
            }
          )
        );
    }
  };

  return (
    <Grid xs={12}>
      <Slide direction="down" in={true} timeout={1000} easing={"ease"}>
        <Box className="user_header">
          <Grid container className="user_header_container">
            <Grid xs={6} className="user_header_logo">
              <InRoleGreenLogo />
            </Grid>
            <Grid xs={6} className="user_header_profile">
              <Grid
                container
                spacing={0}
                className="user_header_profile_container"
              >
                <Avatar className="profile_avatar">
                  {/* {user.nickname.slice(0, 2).toUpperCase()} */}
                </Avatar>
                <Grid item className="profile_cont">
                  <Typography variant="body1">
                    {/* {user.nickname} */}
                    {profileStatusArray.map((data) => data.full_name)}
                  </Typography>
                  <Typography variant="body2">
                    {profileStatusArray.map((data) => data.company_name)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Slide>

      <div className="contractor_onboarding_cont">
        <Box sx={{ overflow: "hidden" }}>
          <Grid xs={12} className="contractor_onboarding_head">
            <Slide direction="down" in={true} timeout={1000} easing={"ease"}>
              <Typography variant="h2">
                Thanks for completing self onboarding! 🎉
              </Typography>
            </Slide>
            <Slide direction="up" in={true} timeout={1000} easing={"ease"}>
              <Typography variant="body2">
                The company will activate you soon
              </Typography>
            </Slide>
          </Grid>
        </Box>
        <Grid className="login_bttn_wrapper">
          <Box sx={{ overflow: "hidden" }}>
            <Fade
              in={true}
              timeout={1000}
              easing={"ease"}
              style={{ transitionDelay: 500 }}
            >
              <Button
                className="theme_bttn fill"
                onClick={() => {
                  logout(
                    {
                      logoutParams: {
                        returnTo: window.location.origin,
                      },
                    },
                    localStorage.clear()
                  );
                }}
              >
                Submit & Finish
              </Button>
            </Fade>
          </Box>
        </Grid>
      </div>
    </Grid>
  );
};
