import "./Dashboard.css";

import { React, useState, useEffect } from "react";
import { Link, useNavigate, useNavigation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Typography,
  Tabs,
  Tab,
  Badge,
  Grid,
  IconButton,
} from "./DashboardImports";

import { HelpButton } from "Components/CommonComponents/Bottom_Help_Button/HelpButton";
import {
  CloseIcon,
  DashboardNoUpdatesIcon,
  OnlineResumePannel,
} from "Icons/Icons";
import {
  getOnboardingdata,
  getOnboardingDataForContractor,
} from "Services/OnboardingService";

import { Grow, Skeleton, Slide } from "@mui/material";
import { TabPanel, a11yProps } from "Utils/TabPanel";

export const Dashboard = (props) => {
  const [loading, setLoading] = useState(true);

  const [value, setValue] = useState(0);
  const [updates, setUpdates] = useState([]);
  const [totalEmployee, setTotalEmployee] = useState(0);
  const [allEmployeeOnboardingData, setAllEmployeeOnboardingData] = useState(
    []
  );
  const [totalContractor, setTotalContractor] = useState(0);
  const [allContractorOnboardingData, setAllContractorOnboardingData] =
    useState([]);

  // const { user } = useAuth0();
  useEffect(() => {
    const abortController = new AbortController();
    props.loginAPI();
    getAllOnboardingDataForEmployee();
    getAllContractorOnboardingData();
    return () => {
      abortController.abort();
    };
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const hideLoading = () => {
    setLoading(false);
  };
  const showLoading = () => {
    setLoading(true);
  };

  const getAllOnboardingDataForEmployee = async (pageNo = 1) => {
    showLoading();

    await getOnboardingdata(pageNo, 9999).then(
      (res) => {
        setTotalEmployee(res.data.total);
        var updateData = [];
        res.data.data.map((value) => {
          value.totalStatus = value.company_employee_status.length;
          let completedArrayList = value.company_employee_status.filter(
            (val) => {
              return val.completed;
            }
          );
          value.totalCompletedStatus = completedArrayList.length;
          if (value.totalStatus - value.totalCompletedStatus == 1) {
            updateData.push(value);
          }
        });
        // console.log(res.status);
        setAllEmployeeOnboardingData(updateData);
        hideLoading();
      },
      (err) => {
        setTotalEmployee(0);
        setAllEmployeeOnboardingData([]);

        props.unAuthorized(err.response.status);
        hideLoading();
      }
    );
  };
  const getAllContractorOnboardingData = async (pageNo = 1) => {
    showLoading();
    await getOnboardingDataForContractor(pageNo, 9999).then(
      (res) => {
        setTotalContractor(res.data.total);
        var updateData = [];
        res.data.data.map((value) => {
          value.totalStatus = value.company_contractor_status.length;
          let completedArrayList = value.company_contractor_status.filter(
            (val) => {
              return val.completed;
            }
          );
          value.totalCompletedStatus = completedArrayList.length;
          if (value.totalStatus - value.totalCompletedStatus == 1) {
            updateData.push(value);
          }
        });

        setAllContractorOnboardingData(updateData);

        hideLoading();
      },
      (err) => {
        setTotalEmployee(0);
        setAllContractorOnboardingData([]);
        props.unAuthorized(err.response.status);
        hideLoading();
      }
    );
  };

  return (
    <Slide in={true} direction="left">
      <Grid item className={props.className}>
        <div className="dash_container">
          <Typography variant="h3" className="page_heading">
            Hello,{localStorage.getItem("accountName")}
          </Typography>

          <div className="dash_tabs">
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs "
            >
              <Tab
                label={
                  <Badge
                    badgeContent={
                      allEmployeeOnboardingData.length +
                      allContractorOnboardingData.length
                    }
                    color="primary"
                  >
                    <Typography>Needs Attention</Typography>
                  </Badge>
                }
                {...a11yProps(0)}
              />

              <Tab label={<Typography>Updates</Typography>} {...a11yProps(1)} />
            </Tabs>
            <TabPanel value={value} index={0} className="tab-pannel">
              {!allEmployeeOnboardingData.length &&
              !allContractorOnboardingData.length ? (
                <div className="need_attention_bx">
                  {!loading ? (
                    <Grid container>
                      {totalEmployee === 0 && totalContractor === 0 ? (
                        <Grid item className="need_attention_grid_cont">
                          <Typography variant="h4">
                            Add your first hire 🎉
                          </Typography>
                          <Typography variant="body2">
                            Ready to build your global team?
                            <br /> Start add new employees or contractors today
                          </Typography>
                          <Link to="/team"> Add my first hire {">"} </Link>
                        </Grid>
                      ) : (
                        <Grid item className="need_attention_grid_cont">
                          <Typography variant="h4">
                            View your team member 🤗
                          </Typography>
                          <Typography variant="body2">
                            Want to meet your global team?
                            <br /> Meet Your new employees or contractors here.
                          </Typography>
                          <Link to="/onboarding">Go to onboarding {">"} </Link>
                        </Grid>
                      )}
                      <Grid item className="need_attention_grid_img">
                        <OnlineResumePannel />
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container>
                      <Grid item className="need_attention_grid_cont">
                        <Skeleton variant="rounded" animation="wave">
                          <Typography variant="h4">
                            Add your first hire 🎉
                          </Typography>
                        </Skeleton>
                        <Skeleton
                          variant="rounded"
                          animation="wave"
                          style={{ marginTop: "10px" }}
                        >
                          <Typography variant="body2">
                            Ready to build your global team?
                            <br /> Start add new employees or contractors today
                          </Typography>
                        </Skeleton>
                        <Skeleton
                          variant="rounded"
                          animation="wave"
                          style={{ marginTop: "10px" }}
                        >
                          <Link to="/team"> Add my first hire {">"} </Link>
                        </Skeleton>
                      </Grid>
                      <Grid item className="need_attention_grid_img">
                        <Skeleton
                          variant="rounded"
                          animation="wave"
                          className="need_attention_img_skelton"
                        >
                          <OnlineResumePannel />
                        </Skeleton>
                      </Grid>
                    </Grid>
                  )}
                </div>
              ) : allEmployeeOnboardingData.length ? (
                allEmployeeOnboardingData.map((data, index) => (
                  <Grow
                    in={true}
                    style={{
                      transitionDelay:
                        index == 0 ? 100 : index == 1 ? 150 : index * 100,
                    }}
                  >
                    <div className="need_attention_bx" key={data.emp_id.row_id}>
                      <Grid container>
                        <Grid item className="need_attention_grid_cont">
                          <Typography variant="h4">
                            {data.emp_id.full_name} has been onboarded 🎉
                          </Typography>
                          <Typography variant="body2">
                            Want to Activate them ? <br />
                            Go To Onboarding page and activate now!
                          </Typography>
                          <Link to="/onboarding"> Go to onboarding {">"} </Link>
                        </Grid>
                        <Grid item className="need_attention_grid_img">
                          <OnlineResumePannel />
                        </Grid>
                      </Grid>
                    </div>
                  </Grow>
                ))
              ) : null}
              {allContractorOnboardingData.length
                ? allContractorOnboardingData.map((data, index) => (
                    <Grow
                      in={true}
                      style={{
                        transitionDelay:
                          index == 0 ? 100 : index == 1 ? 150 : index * 100,
                      }}
                    >
                      <div
                        className="need_attention_bx"
                        key={data.contractor_id.row_id}
                      >
                        <Grid container>
                          <Grid item className="need_attention_grid_cont">
                            <Typography variant="h4">
                              {data.contractor_id.full_name} has been onboarded
                              🎉
                            </Typography>
                            <Typography variant="body2">
                              Want to Activate them ? <br />
                              Go To Onboarding page and activate now!
                            </Typography>
                            <Link to="/onboarding?view=contractor">
                              {" "}
                              Go to onboarding {">"}{" "}
                            </Link>
                          </Grid>
                          <Grid item className="need_attention_grid_img">
                            <OnlineResumePannel />
                          </Grid>
                        </Grid>
                      </div>
                    </Grow>
                  ))
                : null}
            </TabPanel>
            <TabPanel value={value} index={1} className="tab-pannel">
              {updates.length !== 0 ? (
                <div className="need_attention_bx updated_bx">
                  <IconButton className="updated_bx_close_bttn">
                    <CloseIcon />
                  </IconButton>
                  <Typography variant="h4">Add your first hire 🎉</Typography>
                  <Typography variant="body2">
                    {" "}
                    Your first hire was added. You can view or edit information
                    in the teams section.
                  </Typography>
                  <Link to={""}>Add my first hire {">"}</Link>
                </div>
              ) : (
                <>
                  <Box className="no_updates_panel">
                    <DashboardNoUpdatesIcon />
                    <Typography variant="h4">
                      No updates at the moment
                    </Typography>
                    <Typography variant="body2">
                      Come back later for news and updates on employment
                    </Typography>
                  </Box>
                </>
              )}
            </TabPanel>
          </div>
          <HelpButton />
        </div>
      </Grid>
    </Slide>
  );
};
