import {
  Button,
  Grid,
  Grow,
  IconButton,
  Modal,
  Slide,
  Typography,
  Zoom,
} from "@mui/material";
import "../../../Teams/Teams/Team.css";
import { Box } from "@mui/system";

import {
  ModalContractorvector,
  ModalEmployevector,
} from "Components/Teams/Teams/TeamImpoerts";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  CloseIcon,
  OnlineResumePannel,
  TeamContractorModalIcon,
  TeamEmployeeModalIcon,
} from "Icons/Icons";
import { editAgreementContractor } from "Services/AddEmployeeAndContractor";
import { useSnackbar } from "notistack";
import useNotification from "Components/CommonComponents/AlertNotifications/AlertNotifications";
import { Error } from "Utils/Success&Errorfunctions";

export const ContractorEditAgreement = (props) => {
  const [loading, setLoading] = useState(false);
  const [msg, sendNotification] = useNotification();
  const { enqueueSnackbar } = useSnackbar();

  function showLoading() {
    setLoading(true);
  }

  function hideLoading() {
    setLoading(false);
  }

  const editAgreement = async () => {
    showLoading();

    var contractorID = localStorage.getItem("contradactivateIDForDetailView");
    if (contractorID !== null) {
      await editAgreementContractor(contractorID).then(
        (res) => {
          var variant = "success";
          enqueueSnackbar(res.data.message, { variant });
          props.close();
        },
        (err) => {
          var errors = Error(err.response.data.message);

          if (Array.isArray(errors)) {
            errors.forEach((element) => {
              sendNotification({
                msg: `Please enter valid ${element}`,
                variant: "error",
              });
            });
          } else {
            sendNotification({
              msg: err.response.data.message,
              variant: "error",
            });
          }
          hideLoading();
        }
      );
    } else {
      var variant = "error";
      enqueueSnackbar("Sorry, you can't edit the agreement right now.", {
        variant,
      });
    }
    hideLoading();
  };
  return (
    <Modal
      onBackdropClick={props.close}
      open={props.open}
      onClose={props.close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="add_hire_modal"
    >
      <Slide in={props.open}>
        <Box className="add_hire_modal_bx">
          <Grid>
            <div className="modal_header">
              <Grid container flexDirection={{ xs: "row", sm: "row" }}>
                <Grid item>
                  <Typography>Contractor Edit Agreement</Typography>
                </Grid>
                <Grid item>
                  <IconButton onClick={props.close} className="icon_close_bttn">
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </div>
            <div className="modal_body">
              <Zoom
                in={props.open}
                style={{ transitionDelay: props.open ? "100ms" : "0ms" }}
              >
                <Button
                  className="need_attention_bx modal_employe_type_bx"
                  onClick={(e) => editAgreement()}
                >
                  {" "}
                  <Grid container flexDirection={{ xs: "row", sm: "row" }}>
                    <Grid item xs={3} md={2}>
                      <TeamEmployeeModalIcon />
                    </Grid>
                    <Grid item xs={8} md={9}>
                      <Typography variant="h6">
                        For contractor agreement edit
                      </Typography>
                      <Typography>
                        Please reach out to inrole team for editing the
                        agreement for this contractor.
                      </Typography>
                    </Grid>
                  </Grid>
                </Button>
              </Zoom>
            </div>
            <div className="modal_footer">
              <Typography variant="h6">Need more guidance?</Typography>
              <Typography>
                We’re happy to provide you with more information about how
                editing agreement works at InRole.
              </Typography>
              <Button className="see_guide_bttn">See Guide</Button>
            </div>
          </Grid>
        </Box>
      </Slide>
    </Modal>
  );
};
