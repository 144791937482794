import "./AccountDetails.css";
import React, { useEffect, useState } from "react";
import {
  Avatar,
  Divider,
  Icon,
  IconButton,
  Slide,
  Typography,
} from "@mui/material";
import { Grid } from "Utils/muiImport";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import Box from "@mui/material/Box";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import jwtDecode from "jwt-decode";
import { useAuth0 } from "@auth0/auth0-react";
import { HelpButton } from "Components/CommonComponents/Bottom_Help_Button/HelpButton";
import { EditIcon, GeneralInformationIcon } from "Icons/Icons";
import { TabPanel, a11yProps } from "Utils/TabPanel";

export const AccountDetails = (props) => {
  const [value, setValue] = useState(0);
  const [email, setEmail] = useState("");

  useEffect(() => {
    accountInfo();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const accountInfo = () => {
    if (JSON.parse(localStorage.getItem("JWT")) !== null) {
      const jwt = JSON.parse(localStorage.getItem("JWT")).access_token;
      var decoded_jwt = jwtDecode(jwt);
      setEmail(decoded_jwt.email);
    } else {
      const jwt = null;
      setEmail(jwt);
    }
  };

  return (
    <>
      <Slide in={true} direction="left">
        <Grid item className={props.className} data-testid="accountdetails">
          <Grid container className="team_top_heading">
            <Grid item>
              <Typography className="page_heading">
                Account preference
              </Typography>
            </Grid>
          </Grid>
          <div className="dash_tabs">
            <Grid xs={12}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  label={<Typography>Account details</Typography>}
                  {...a11yProps(0)}
                />
              </Tabs>
              <Divider />

              <TabPanel value={value} index={0} className="tab-pannel">
                <Grid container spacing={0} className="company_profile_layer">
                  <Grid item xs={12} lg={8}>
                    <Box className="employe_profile_bx">
                      <Grid
                        container
                        alignItems="center"
                        justifyContent={"space-between"}
                        className="employe_profile_head"
                        spacing={0}
                      >
                        <Grid item>
                          <Grid container alignItems="center">
                            <Icon>
                              <GeneralInformationIcon />
                            </Icon>
                            <Typography>General information</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <div className="employe_profile_body">
                        <Grid
                          xs={12}
                          container
                          alignItems="center"
                          flexDirection={{ xs: "row", sm: "row" }}
                          className="employe_personal_dtl"
                        >
                          <Typography variant="body1">Email</Typography>

                          <Typography variant="body2" className="ellipsis">
                            {email}
                          </Typography>

                          <IconButton>
                            <EditIcon />
                          </IconButton>
                        </Grid>
                        <Grid
                          xs={12}
                          container
                          alignItems="center"
                          flexDirection={{ xs: "row", sm: "row" }}
                          className="employe_personal_dtl"
                        >
                          <Typography variant="body1"> Password</Typography>
                          <Typography variant="body2">*************</Typography>
                          <IconButton>
                            <EditIcon />
                          </IconButton>
                        </Grid>
                      </div>
                    </Box>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    lg={4}
                    className="company_info_sidebar_grid"
                  >
                    <Box className="company_info_sidebar">
                      <Grid container className="avatar_container">
                        <Box className="img_bx">
                          <Avatar>
                            {localStorage.getItem("accountName") !== null &&
                            localStorage.getItem("accountName").length !== 0
                              ? localStorage.getItem("accountName").length >= 2
                                ? localStorage
                                    .getItem("accountName")
                                    .slice(0, 2)
                                    .toUpperCase()
                                : localStorage

                                    .getItem("accountName")
                                    .slice(0, 1)
                                    .toUpperCase()
                              : ""}
                          </Avatar>
                          <IconButton>
                            <CreateOutlinedIcon />
                          </IconButton>
                        </Box>
                        <Typography>
                          {localStorage.getItem("accountName")}
                        </Typography>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </TabPanel>
            </Grid>
          </div>
        </Grid>
      </Slide>
      <HelpButton />
    </>
  );
};
