import "./AddContractor2.css";
import { React, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Box,
  Typography,
  
} from "./AddContractor2Imports";
import { updateContractor } from "Services/AddEmployeeAndContractor";

import { HelpButton } from "Components/CommonComponents/Bottom_Help_Button/HelpButton";
import { PreviousArrowIcon } from "Icons/Icons";
import useNotification from "Components/CommonComponents/AlertNotifications/AlertNotifications";
import { useSnackbar } from "notistack";
import { Error } from "Utils/Success&Errorfunctions";

export const AddContractor2 = () => {
  const navigate = useNavigate();
  const params = useParams();

  const { enqueueSnackbar } = useSnackbar();
  const [msg, sendNotification] = useNotification();

  const [progress, setProgress] = useState(25);
  const [contractOption, setContractOption] = useState("");
  const [contractOptionArray, setContractOptionArray] = useState([]);

  useEffect(() => {
    getContracts();
    setTimeout(async () => {
      await setProgress(50);
    }, 1000);
  }, []);

  const getContracts = () => {
    var contractOption = JSON.parse(localStorage.getItem("Contract_options"));
    if (contractOption !== null) {
      setContractOptionArray(contractOption);
    } else {
      setContractOptionArray([]);
    }
  };

  const goBack = () => {
    var id = params.id;
    var rowdata = JSON.parse(localStorage.getItem("ContractorRowData"));
    if (!id) {
      navigate("/addcontractor/" + rowdata);
    } else {
      navigate("/addcontractor/" + id);
    }
  };

  const submit = (e) => {
    var id = params.id;
    e.preventDefault();
    const data = {
      contract_option_id: parseInt(contractOption),
      is_draft: "True",
    };
    if (!id) {
      if (contractOption == 13) {
        localStorage.setItem("ContractorData2", JSON.stringify(data));
        var rowdata = JSON.parse(localStorage.getItem("ContractorRowData"));

        var contractordata = JSON.parse(
          localStorage.getItem("ContractorData1")
        );
        var finaldata = {
          ...contractordata,
          ...data,
        };
        updateContractor(finaldata, rowdata).then(
          (res) => {
            var variant = "success";
            enqueueSnackbar(res.data.message, { variant });
            navigate("/addcontractor/3");
          },
          (err) => {
            var variant = "error";
            enqueueSnackbar(err.response.data.message, { variant });
          }
        );
      } else {
        localStorage.setItem("ContractorData2", JSON.stringify(data));
        var rowdata = JSON.parse(localStorage.getItem("ContractorRowData"));

        var contractordata = JSON.parse(
          localStorage.getItem("ContractorData1")
        );
        var finaldata = {
          ...contractordata,
          ...data,
          is_draft: true,
        };
        updateContractor(finaldata, rowdata).then(
          (res) => {
            // console.log(finaldata);
            var variant = "success";
            enqueueSnackbar(res.data.message, { variant });

            navigate("/addcontractorpricingplan");
          },
          (err) => {
            sendNotification({
              msg: err.response.data.message,
              variant: "error",
            });
          }
        );
      }
    } else {
      if (contractOption == 13) {
        localStorage.setItem("ContractorData2", JSON.stringify(data));
        var rowdata = JSON.parse(localStorage.getItem("ContractorRowData"));

        var contractordata = JSON.parse(
          localStorage.getItem("ContractorData1")
        );
        var finaldata = {
          ...contractordata,
          ...data,
        };

        updateContractor(finaldata, id).then(
          (res) => {
            var variant = "success";
            enqueueSnackbar(res.data.message, { variant });
            navigate("/addcontractor/3/" + id);
          },
          (err) => {
            sendNotification({
              msg: err.response.data.message,
              variant: "error",
            });
          }
        );
      } else {
        localStorage.setItem("ContractorData2", JSON.stringify(data));
        var rowdata = JSON.parse(localStorage.getItem("ContractorRowData"));

        var contractordata = JSON.parse(
          localStorage.getItem("ContractorData1")
        );
        localStorage.removeItem("ContractorData3");
        var finaldata = {
          ...data,
          is_draft: true,
        };

        updateContractor(finaldata, id).then(
          (res) => {
            var variant = "success";
            enqueueSnackbar(res.data.message, { variant });

            navigate("/addcontractorpricingplan/" + id);
          },
          (err) => {
            sendNotification({
              msg: err.response.data.message,
              variant: "error",
            });
          }
        );
      }
    }
  };

  return (
    <>
     
      <form className="register_form">
        <Grid xs={12}>
          <RadioGroup
            required
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            onChange={(e) => {
              setContractOption(e.target.value);
            }}
            className="register_form_card_group"
          >
            {contractOptionArray.map((data) => (
              <Box
                className="register_form_card"
                key={data.row_id}
                alignItems="center"
              >
                <Grid
                  fullWidth
                  container
                  className="register_form_card_grid"
                  spacing={0}
                  alignItems="center"
                  justifyContent={"space-between"}
                >
                  <Grid item xs={10}>
                    <Typography variant="h4">{data.value}</Typography>
                    <Typography variant="body2">{data.description}</Typography>
                  </Grid>
                  <Radio value={data.row_id} />
                </Grid>
              </Box>
            ))}
          </RadioGroup>
        </Grid>
        <Grid xs={12} className="login_bttn_wrapper">
          {!contractOption ? (
            <Button
              disabled
              variant="contained"
              type="submit"
              color="primary"
              className="theme_bttn fill"
            >
              Continue
            </Button>
          ) : (
            <Button
              variant="contained"
              type="submit"
              color="primary"
              onClick={submit}
              className="theme_bttn fill"
            >
              Continue
            </Button>
          )}
        </Grid>
      </form>
     
      <HelpButton />
    </>
  );
};
