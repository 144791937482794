import App from "./App";
import React from "react";
import ReactDOM from "react-dom/client";

import { Auth0Provider } from "@auth0/auth0-react";
import { BrowserRouter } from "react-router-dom";
import { SnackbarProvider, closeSnackbar } from "notistack";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";

import TaskAltIcon from "@mui/icons-material/TaskAlt";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { IconButton, ThemeProvider, createTheme } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

// CUSTOM CSS
import "./Styles/fonts.css";
import "./Styles/App.css";
import "./Styles/Animation.css";
import "./Styles/AppResponsive.css";

const root = ReactDOM.createRoot(document.getElementById("root"));

const domainForAuth0 = process.env.REACT_APP_DOMAIN_FOR_AUTH_0;
const clientIDForAuth0 = process.env.REACT_APP_CLIENT_ID_FOR_AUTH_0;
const redirectURIForAuth0 = process.env.REACT_APP_FALLBACK_URL_FOR_AUTH_0;
const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
const scopeOptions = process.env.REACT_APP_AUTH0_SCOPE;
const customURL = process.env.REACT_APP_AUTH0_CUSTOM_URL;

const anchorOrigin = {
  vertical: "top",
  horizontal: "right",
};

const muiTheme = createTheme({
  palette: {
    primary: {
      light: "#597B5C",
      main: "#39603D",
      dark: "#2E4D31",
      contrastText: "#fff",
    },
    dark: {
      main: "#000000",
    },
    light: {
      main: "#FFFFFF",
    },
  },
});

root.render(
  <Auth0Provider
    domain={customURL}
    clientId={clientIDForAuth0}
    authorizationParams={{
      redirect_uri: redirectURIForAuth0,
      audience: audience,
      scope: scopeOptions,
    }}
  >
    <BrowserRouter>
      {/* <React.StrictMode> */}
      <ThemeProvider theme={muiTheme}>
        <SnackbarProvider
          preventDuplicate
          action={(snackbarId) => (
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => closeSnackbar(snackbarId)}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          )}
          iconVariant={{
            success: <TaskAltIcon sx={{ marginRight: "15px" }} />,
            error: <ErrorOutlineIcon sx={{ marginRight: "15px" }} />,
            warning: <WarningAmberIcon sx={{ marginRight: "15px" }} />,
            info: <InfoOutlinedIcon sx={{ marginRight: "15px" }} />,
          }}
          maxSnack={1}
          anchorOrigin={anchorOrigin}
          autoHideDuration={5000}
        >
          <App auth0domain={domainForAuth0} />
        </SnackbarProvider>
      </ThemeProvider>
      {/* </React.StrictMode> */}
    </BrowserRouter>
  </Auth0Provider>
);
serviceWorkerRegistration.unregister();

reportWebVitals();
