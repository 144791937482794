import React from "react";
import { Box, Button, Fade, IconButton, Slide } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import LogoutIcon from "@mui/icons-material/Logout";
import { useAuth0 } from "@auth0/auth0-react";

export const HelpButton = () => {
  return (
    <Slide direction="up" in={true} timeout={1000} easing={"ease"}>
      <Button className="theme_bttn help_bttn" startIcon={<HelpOutlineIcon />}>
        Help
      </Button>
    </Slide>
  );
};

export const LogOutButton = () => {
  const { logout } = useAuth0();
  return (
    <Slide direction="up" in={true} timeout={1000} easing={"ease"}>
      <Button
        className="theme_bttn empregister_logout_bttn"
        startIcon={<LogoutIcon />}
        onClick={() => {
          logout(
            {
              logoutParams: {
                returnTo: window.location.origin,
              },
            },
            localStorage.clear()
          );
        }}
      >
        LogOut
        {/* <LogoutIcon /> */}
      </Button>
    </Slide>
  );
};
