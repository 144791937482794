import "./Login.css";
import { React, useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Link, useHref, useNavigate } from "react-router-dom";

import { Grid, Button, Typography, AuthSidebar } from "./LoginImport";
import { login } from "../../Services/Login&Registrationservice";
import { getCommonSettings } from "Services/AddEmployeeAndContractor";
import jwtDecode from "jwt-decode";
import PropTypes from "prop-types";
import {
  AppBar,
  Checkbox,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  Grow,
  Icon,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Slide,
  TextField,
  Toolbar,
  Zoom,
} from "@mui/material";
import { MenuIcon } from "Components/CommonComponents/SideBar/SideBarImports";
import { Box, Container } from "@mui/system";
import Glob from "../../Assets/Svgs/Connected world-amico 1.svg";
import { InRoleGreenLogo, InRoleSidebarLogo, LoginGlob } from "Icons/Icons";
import inrollogopng from "../../Assets/Logo/InroleLogoPNG.png";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Loader } from "Components/CommonComponents/Loader/Loader";
import { useSnackbar } from "notistack";
import useNotification from "Components/CommonComponents/AlertNotifications/AlertNotifications";

export const Login = (props) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [loginSidebarItems, setLoginSidebarItems] = useState([
    {
      menuName: "Home",
      redirectionRoute: "https://www.inrole.io/",
    },
    {
      menuName: "Service",
      redirectionRoute: "https://www.inrole.io/services",
    },
    {
      menuName: "About us",
      redirectionRoute: "https://www.inrole.io/about",
    },
    {
      menuName: "Contact us",
      redirectionRoute: "https://form.typeform.com/to/QBunW9zL",
    },
  ]);

  const { loginWithRedirect, isAuthenticated, getAccessTokenSilently, user } =
    useAuth0();

  useEffect(() => {
    props.loginAPI();
  }, [isAuthenticated]);

  const drawerWidth = 240;

  const { windows } = props;

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} className="appbar_drawer_bx">
      <Grid xs={12} className="appbar_drawer_logo">
        <InRoleSidebarLogo />
      </Grid>
      <Grid xs={12} className="appbar_drawer_menu">
        <List>
          {loginSidebarItems.map((Data) => (
            <ListItem key={Data.menuName} disablePadding>
              <ListItemButton
                onClick={() => window.location.replace(Data.redirectionRoute)}
              >
                <ListItemText primary={Data.menuName} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Grid>
    </Box>
  );

  const container =
    windows !== undefined ? () => windows().document.body : undefined;

  return (
    <>
      {!props.loading ? (
        <div className="login_page">
          <Grid className="login_sidebar_container">
            <Slide direction="down" in={true} timeout={1000} easing={"ease"}>
              <AppBar component="nav" className="nav_appbar">
                <Container>
                  <Toolbar>
                    <Grid sx={{ flexGrow: 1 }}>
                      <img
                        src={inrollogopng}
                        alt=""
                        className="logo-img"
                        width={"92"}
                      />
                    </Grid>
                    <IconButton
                      color="black"
                      aria-label="open drawer"
                      edge="start"
                      onClick={handleDrawerToggle}
                      sx={{ display: { xs: "inline-flex", md: "none" } }}
                      className="appbar_drawer_toggler"
                    >
                      <MenuIcon />
                    </IconButton>
                    <Box
                      sx={{ display: { xs: "none", md: "block" } }}
                      className="appbar_menu"
                    >
                      {loginSidebarItems.map((item) => (
                        <Button
                          key={item.menuName}
                          disableRipple
                          onClick={() =>
                            window.location.replace(item.redirectionRoute)
                          }
                        >
                          {item.menuName}
                        </Button>
                      ))}
                    </Box>
                  </Toolbar>
                </Container>
              </AppBar>
            </Slide>

            <Box component="nav">
              <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                  keepMounted: true,
                }}
                sx={{
                  display: { xs: "block", md: "none" },
                  "& .MuiDrawer-paper": {
                    boxSizing: "border-box",
                    width: drawerWidth,
                  },
                }}
                className="appbar_drawer"
              >
                {drawer}
              </Drawer>
            </Box>
          </Grid>

          <Container className="login_grid">
            <Grid container xs={12} className="login_content_grid_item">
              <Grid xs={12} md={6} item>
                <div className="login_cont text_left">
                  <Box sx={{ overflow: "hidden" }}>
                    <Slide
                      direction="up"
                      in={true}
                      timeout={1000}
                      easing={"ease"}
                    >
                      <Typography variant="h3" gutterBottom>
                        Welcome to InRole
                      </Typography>
                    </Slide>
                  </Box>
                  <Box sx={{ overflow: "hidden" }}>
                    <Slide
                      direction="up"
                      in={true}
                      timeout={1000}
                      easing={"ease"}
                    >
                      <Typography variant="body2" gutterBottom>
                        your all-in-one solution for cross-border company
                        backend operations. Our chat-first platform streamlines
                        everything from remote hiring to accounting, taxation,
                        payroll, and compliance - leaving you more time to focus
                        on your core business! Our team of expert professionals
                        ensures that you stay fully compliant with local
                        regulations, and our chat-first approach makes it easy
                        for you to communicate with us in real-time.
                      </Typography>
                    </Slide>
                  </Box>
                  <Box sx={{ overflow: "hidden" }}>
                    <Slide
                      direction="up"
                      in={true}
                      timeout={1000}
                      easing={"ease"}
                    >
                      <Grid container xs={12} spacing={3} mt={4}>
                        <Grid item>
                          <Button
                            variant="contained"
                            type="button"
                            onClick={async () => {
                              await loginWithRedirect(
                                {
                                  authorizationParams: { screen_hint: "login" },
                                },
                                { timeoutInSeconds: 3600 }
                              );
                              // props.loginAPI();
                            }}
                            className="theme_bttn fill"
                          >
                            Log In
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            variant="contained"
                            type="button"
                            onClick={async () => {
                              await loginWithRedirect(
                                {
                                  authorizationParams: {
                                    screen_hint: "signup",
                                  },
                                },
                                { timeoutInSeconds: 3600 }
                              );
                            }}
                            className="theme_bttn"
                          >
                            Sign Up
                          </Button>
                        </Grid>
                      </Grid>
                    </Slide>
                  </Box>
                  <Grid
                    container
                    xs={12}
                    spacing={0}
                    mt={4}
                    sx={{ overflow: "hidden" }}
                  >
                    <Slide
                      direction="up"
                      in={true}
                      timeout={1000}
                      easing={"ease"}
                    >
                      <Typography variant="body1">
                        You can also{" "}
                        <Link
                          onClick={async () =>
                            await loginWithRedirect(
                              {
                                authorizationParams: { screen_hint: "signup" },
                              },
                              { timeoutInSeconds: 3600 }
                            )
                          }
                        >
                          log in with SSO
                        </Link>
                      </Typography>
                    </Slide>
                  </Grid>
                </div>
              </Grid>
              <Grid xs={12} md={6} item className="login_page_img">
                {/* <LoginGlob /> */}
                <Grow in={true} timeout={1000} easing={"ease"}>
                  <img src={Glob} alt="" />
                </Grow>
              </Grid>
            </Grid>
          </Container>
        </div>
      ) : (
        <Loader open={props.loading} />
      )}
      {/* <>
        <Grid container className="login_grid">
          <AuthSidebar backgroundImage></AuthSidebar>
          <Grid xs={8} item className="login_content_grid_item">
            <div className="login_cont register_cont">
              <Typography variant="h3">Log in to InRole</Typography>
              <Typography variant="body2">
                Ready to build up your Inrole team?<Link>Sign Up</Link>
              </Typography>
              <Grid xs={12}>
                <form className="register_form">
                  <Grid xs={12}>
                    <TextField
                      required
                      fullWidth
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      type="email"
                      label="Email address"
                      variant="standard"
                      id="Email_address"
                    />
                  </Grid>
                  <Grid xs={12}>
                    <FormControl variant="standard" fullWidth>
                      <InputLabel htmlFor="standard-adornment-password">
                        Password
                      </InputLabel>
                      <Input
                        id="standard-adornment-password"
                        type={showPassword ? "text" : "password"}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword((show) => !show)}
                              onMouseDown={(e) => e.preventDefault()}
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    container
                    spacing={0}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item>
                      <FormControlLabel
                        required
                        control={<Checkbox />}
                        label="Keep me logged in"
                      />
                    </Grid>
                    <Grid item>
                      {" "}
                      <Typography variant="body2">
                        <Link>Forgot password?</Link>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container className="login_bttn_wrapper">
                    <Button
                      variant="contained"
                      type="submit"
                      className="theme_bttn fill"
                    >
                      Log In
                    </Button>
                  </Grid>
                  <Grid container className="login_bttn_wrapper">
                    <Typography variant="body2">
                      You can also<Link>log in with SSO</Link>
                    </Typography>
                  </Grid>
                </form>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </> */}
    </>
  );
};
