import "./SideBar.css";
import { React, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Dashboard,
  Teams,
  TeamEmployeeDetails,
  Onboarding,
  Incentives,
  Box,
  List,
  Typography,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Avatar,
  DrawerHeader,
  Drawer,
} from "./SideBarImports";
import { Requests } from "Components/Requests/Requests";
import { Billing } from "Components/Billing/Billing";
import { CompanySettings } from "Components/CompanySettings/CompanySettings";
import jwtDecode from "jwt-decode";
import { EmployeeAndContractorDocument } from "Components/Documents/Employee And Contractor Documents/EmployeeAndContractorDocument";
import { TeamContractorDetails } from "Components/Teams/TeamContractorDetails/TeamContractorDetails";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import LogoutIcon from "@mui/icons-material/Logout";
import { Button, Collapse, Icon, Menu, MenuItem } from "@mui/material";
import { AccountDetails } from "Components/Account Details/AccountDetails";
import {
  RequestIcon,
  HomeIcon,
  IncentiveIcon,
  OnboardingIcon,
  TeamIcon,
  SearchIcon,
  BillingIcon,
  SettingSidebarIcon,
  DocumentSidebarIcon,
  InRoleSidebarLogo,
  SidebarCloseIcon,
  HamburgerMenu,
} from "Icons/Icons";

import { getCompanyData } from "Services/CompanyServices";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

import { CompanyDocuuments } from "Components/Documents/Company Documents/CompanyDocuuments";

export const Sidebar = (props) => {
  const navigate = useNavigate();

  const { logout } = useAuth0();
  const [open, setOpen] = useState(true);
  const [documenttabopen, setDocumenttabopen] = useState(false);
  const [name, setName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    let JWT = JSON.parse(localStorage.getItem("JWT"));
    if (JWT !== null) {
      let decodedToken = jwtDecode(JWT.access_token);
      getCompanyData(decodedToken.row_id).then(
        (res) => {
          if (
            res.data.current_user.full_name !== null &&
            res.data.company_name !== null
          ) {
            setName(res.data.current_user.full_name);
            localStorage.setItem(
              "accountName",
              res.data.current_user.full_name
            );
            setCompanyName(res.data.company_name);
            localStorage.setItem("NameOfCompany", res.data.company_name);
          } else {
            setName("inrole");
            setCompanyName("Inrole");
          }
        },
        (err) => {
          console.log(err.message);
          props.unAuthorized(err.response.status);
        }
      );
    }
  }, []);

  const open1 = Boolean(anchorEl);
  const id = open1 ? "simple-popover" : undefined;

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const responsiveSidebarToggle = () => {
    let windowWidth = window.innerWidth;
    if (windowWidth < 900) {
      handleDrawerToggle();
    }
  };

  return (
    <Grid container spacing={2} xs={12} className="sidebar_layout">
      <Grid
        item
        xs={3}
        className={`sidebar_layout_drawer ${
          !open ? "sidebar_menu_toggle" : ""
        }`}
      >
        <Box sx={{ display: "flex" }}>
          <Drawer variant="permanent" open={open}>
            <DrawerHeader className="drawer_header">
              <Grid
                fullWidth
                container
                alignItems="center"
                flexDirection={{ xs: "row", sm: "row" }}
                spacing={0}
              >
                <InRoleSidebarLogo className={"drawer_header_logo"} />
                <Grid
                  item
                  className="grid_item_avatar"
                  sx={{ display: { xs: "block", md: "none" } }}
                >
                  <Avatar onClick={() => navigate("/accountpreference")}>
                    {name !== null
                      ? name.length != 0
                        ? name.length >= 2
                          ? name.slice(0, 2).toUpperCase()
                          : name.slice(0, 1).toUpperCase()
                        : "IN"
                      : "IN"}
                  </Avatar>
                </Grid>
                <IconButton onClick={handleDrawerToggle} className="icon_bttn">
                  <SidebarCloseIcon className="icon_hamburger_sidebar" />
                  <HamburgerMenu className="icon_hamburger_menu" />
                </IconButton>
              </Grid>
            </DrawerHeader>

            <Button
              disableRipple
              onClick={handleDrawerToggle}
              className="drawer_body_close"
            ></Button>
            <Grid xs={12} className="drawer_body">
              <List>
                {[
                  {
                    menuname: "Quick Search",
                    iconname: <SearchIcon />,
                    isActive: props.Home && false,
                    route: "/home",
                  },
                  {
                    menuname: "Home",
                    iconname: <HomeIcon />,
                    isActive: props.Home && true,
                    route: "/home",
                  },
                ].map((text, index) => (
                  <ListItem
                    key={text.menuname.toLowerCase()}
                    disablePadding
                    sx={{ display: "block" }}
                    className={text.isActive ? "active" : null}
                  >
                    <ListItemButton
                      onClick={(e) => {
                        e.preventDefault();
                        navigate(`${text.route}`);
                        responsiveSidebarToggle();
                      }}
                    >
                      <ListItemIcon>
                        <Icon>{text.iconname}</Icon>
                      </ListItemIcon>
                      <ListItemText primary={text.menuname} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
              <Typography className="list_item_heading">People</Typography>
              <List>
                {[
                  {
                    menuname: "Team",
                    iconname: <TeamIcon />,
                    isActive:
                      (props.Team ||
                        props.employeedetails ||
                        props.contractordetails) &&
                      true,
                    route: "/team",
                  },
                  {
                    menuname: "Onboarding",
                    iconname: <OnboardingIcon />,
                    isActive: props.onboarding && true,
                    route: "/onboarding",
                  },
                  {
                    menuname: "Incentives",
                    iconname: <IncentiveIcon />,
                    isActive: props.incentives && true,
                    route: "/incentives",
                  },
                  {
                    menuname: "Requests",
                    iconname: <RequestIcon />,
                    isActive: props.requests && true,
                    route: "/requests",
                  },
                ].map((text, index) => (
                  <ListItem
                    key={text.menuname.toLowerCase()}
                    disablePadding
                    sx={{ display: "block" }}
                    className={text.isActive ? "active" : null}
                  >
                    <ListItemButton
                      onClick={(e) => {
                        e.preventDefault();
                        navigate(`${text.route}`);
                        responsiveSidebarToggle();
                      }}
                    >
                      <ListItemIcon>
                        <Icon>{text.iconname}</Icon>
                      </ListItemIcon>
                      <ListItemText primary={text.menuname} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
              <Typography className="list_item_heading">Payment</Typography>
              <List>
                {[
                  {
                    menuname: "Billing",
                    iconname: <BillingIcon />,
                    isActive: props.billing && true,
                    route: "/billing",
                  },
                ].map((text, index) => (
                  <ListItem
                    key={text.menuname.toLowerCase()}
                    disablePadding
                    sx={{ display: "block" }}
                    className={text.isActive ? "active" : null}
                  >
                    <ListItemButton
                      onClick={(e) => {
                        e.preventDefault();
                        navigate(`${text.route}`);
                        responsiveSidebarToggle();
                      }}
                    >
                      <ListItemIcon>
                        <Icon>{text.iconname}</Icon>
                      </ListItemIcon>
                      <ListItemText primary={text.menuname} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
              <Typography className="list_item_heading">Company</Typography>
              <List>
                {[
                  {
                    menuname: "Company Settings",
                    iconname: <SettingSidebarIcon />,
                    isActive: props.companysettings && true,
                    route: "/companysettings",
                  },
                  {
                    menuname: "Documents",
                    iconname: <DocumentSidebarIcon />,
                    isActive:
                      (props.document || props.companyDocuments) && true,
                    item: [
                      {
                        title: "Employee/contractor ",
                        iconname: <TeamIcon />,
                        route: "/employeedocuments",
                        isActive: props.document && true,
                      },
                      {
                        title: "Company ",
                        iconname: <OnboardingIcon />,
                        route: "/companydocuments",
                        isActive: props.companyDocuments && true,
                      },
                    ],
                  },
                ].map((text, index) =>
                  !text.item ? (
                    <ListItem
                      key={text.title}
                      disablePadding
                      sx={{ display: "block" }}
                      className={text.isActive ? "active" : null}
                    >
                      <ListItemButton
                        onClick={(e) => {
                          e.preventDefault();
                          navigate(`${text.route}`);
                          responsiveSidebarToggle();
                        }}
                      >
                        <ListItemIcon>
                          <Icon>{text.iconname}</Icon>
                        </ListItemIcon>
                        <ListItemText primary={text.menuname} />
                      </ListItemButton>
                    </ListItem>
                  ) : (
                    <>
                      <ListItem
                        key={text.menuname.toLowerCase()}
                        disablePadding
                        sx={{ display: "block" }}
                      >
                        <ListItemButton
                          onClick={() => setDocumenttabopen(!documenttabopen)}
                        >
                          <ListItemIcon>
                            <Icon>{text.iconname}</Icon>
                          </ListItemIcon>
                          <ListItemText primary={text.menuname} />
                          <ListItemIcon>
                            <Icon>
                              {documenttabopen ? (
                                <ExpandLess />
                              ) : (
                                <ExpandMore />
                              )}
                            </Icon>
                          </ListItemIcon>
                        </ListItemButton>
                        <Collapse
                          in={documenttabopen}
                          timeout="auto"
                          unmountOnExit
                        >
                          <List>
                            {text.item.map((child) => (
                              <ListItem
                                key={child.title.toLowerCase()}
                                disablePadding
                                sx={{ display: "block" }}
                              >
                                <ListItemButton
                                  onClick={(e) => {
                                    e.preventDefault();
                                    navigate(`${child.route}`);
                                    responsiveSidebarToggle();
                                  }}
                                >
                                  <ListItemIcon>
                                    <Icon>{child.iconname}</Icon>
                                  </ListItemIcon>
                                  <ListItemText primary={child.title} />
                                </ListItemButton>
                              </ListItem>
                            ))}
                          </List>
                        </Collapse>
                      </ListItem>
                    </>
                  )
                )}
                <Typography
                  sx={{ display: { xs: "block", md: "none" } }}
                  className="list_item_heading"
                >
                  Account
                </Typography>
                <ListItem
                  key={"LogOut"}
                  disablePadding
                  sx={{ display: { xs: "block", md: "none" } }}
                  className="logout_list_item"
                >
                  <ListItemButton
                    onClick={() => {
                      logout(
                        {
                          logoutParams: {
                            returnTo: window.location.origin,
                          },
                        },
                        localStorage.clear()
                      );
                    }}
                  >
                    <ListItemIcon>
                      <Icon>
                        <LogoutIcon />
                      </Icon>
                    </ListItemIcon>
                    <ListItemText primary={"Logout"} />
                  </ListItemButton>
                </ListItem>
              </List>
            </Grid>

            <Grid xs={12} className="drawer_footer">
              <Grid
                container
                alignItems="center"
                flexDirection={{ xs: "row", sm: "row" }}
                style={{ cursor: "pointer" }}
              >
                <Grid
                  item
                  onClick={() => navigate("/accountpreference")}
                  className="grid_item_avatar"
                >
                  <Avatar>
                    {name !== null
                      ? name.length != 0
                        ? name.length >= 2
                          ? name.slice(0, 2).toUpperCase()
                          : name.slice(0, 1).toUpperCase()
                        : "IN"
                      : "IN"}
                  </Avatar>
                  <Grid className="avatar_typography_group">
                    <Typography variant="h6">{name}</Typography>
                    <Typography variant="body2"> {companyName} </Typography>
                  </Grid>
                </Grid>
                <Grid item>
                  <IconButton onClick={handleClick}>
                    <MoreHorizIcon style={{ color: "white" }} />
                  </IconButton>
                  <Menu
                    id={id}
                    open={open1}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                  >
                    <MenuItem
                      variant="contained"
                      onClick={() => {
                        logout(
                          {
                            logoutParams: {
                              returnTo: window.location.origin,
                            },
                          },
                          localStorage.clear()
                        );
                      }}
                    >
                      <ListItemIcon>
                        <LogoutIcon />
                      </ListItemIcon>
                      <ListItemText>Log Out</ListItemText>
                    </MenuItem>
                  </Menu>
                </Grid>
              </Grid>
            </Grid>
          </Drawer>
        </Box>
      </Grid>
      {props.Home && (
        <Dashboard
          className="sidebar_layout_page"
          unAuthorized={props.unAuthorized}
          loginAPI={(e) => props.loginAPI()}
        />
      )}
      {props.Team && (
        <Teams
          className="sidebar_layout_page"
          unAuthorized={props.unAuthorized}
        />
      )}
      {props.employeedetails && (
        <TeamEmployeeDetails
          className="sidebar_layout_page"
          unAuthorized={props.unAuthorized}
        />
      )}
      {props.onboarding && (
        <Onboarding
          className="sidebar_layout_page"
          unAuthorized={props.unAuthorized}
        />
      )}
      {props.incentives && (
        <Incentives
          className="sidebar_layout_page"
          unAuthorized={props.unAuthorized}
        />
      )}
      {props.requests && (
        <Requests
          className="sidebar_layout_page"
          unAuthorized={props.unAuthorized}
        />
      )}
      {props.billing && (
        <Billing
          className="sidebar_layout_page"
          unAuthorized={props.unAuthorized}
        />
      )}
      {props.companysettings && (
        <CompanySettings
          className="sidebar_layout_page"
          unAuthorized={props.unAuthorized}
        />
      )}
      {props.document && (
        <EmployeeAndContractorDocument
          className="sidebar_layout_page"
          unAuthorized={props.unAuthorized}
        />
      )}
      {props.companyDocuments && (
        <CompanyDocuuments
          className="sidebar_layout_page"
          unAuthorized={props.unAuthorized}
        />
      )}
      {props.contractordetails && (
        <TeamContractorDetails
          className="sidebar_layout_page"
          unAuthorized={props.unAuthorized}
        />
      )}
      {props.accountpreference && (
        <AccountDetails
          className="sidebar_layout_page"
          unAuthorized={props.unAuthorized}
        />
      )}
    </Grid>
  );
};
