import "./EmpRegister2.css";
import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  IconButton,
  Box,
  Typography,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  MenuItem,
  Autocomplete,
  moment,
  DesktopDatePicker,
  LocalizationProvider,
  AdapterDayjs,
  Button,
} from "./EmpRegister2Imports";
import { getCountries } from "Services/Login&Registrationservice";
import {
  contractorOnboarding,
  emponboarding,
  getContractorData,
  getemployeeProfile,
} from "Services/AddEmployeeAndContractor";
import jwtDecode from "jwt-decode";
import country from "../../../Assets/JSONs/Countries.json";
import { FormHelperText, InputLabel, Select, Slide } from "@mui/material";
import { PreviousArrowIcon } from "Icons/Icons";
import useNotification from "Components/CommonComponents/AlertNotifications/AlertNotifications";
import { DatePicker } from "@mui/x-date-pickers";
import { useSnackbar } from "notistack";
import { Error } from "Utils/Success&Errorfunctions";
import { LinearProgressWithLabel } from "Utils/TabPanel";

export const EmpRegister2 = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [msg, sendNotification] = useNotification();

  const navigate = useNavigate();
  const [value, setValue] = useState();
  const [progress, setProgress] = useState(33);
  const [countries, setCountries] = useState([]);
  const [fullName, setFullName] = useState("");
  const [firstAndMiddleName, setFirstAndMiddleName] = useState("");
  const [lastName, setLastName] = useState("");

  const [sex, setSex] = useState("female");

  const [nationality, setNationality] = useState("");
  const [startDate, setStartDate] = useState(moment(Date.now()));

  const [indexOfCountryCode, setIndexOfCountryCode] = useState(92);
  const [countryCode, setCountryCode] = useState("+91");
  const [contactNo, setContactNo] = useState("");

  useEffect(() => {
    getcountry();
    getRow();
  }, []);

  const goBack = () => {
    navigate("/employeeregister");
  };

  const getcountry = () => {
    getCountries().then(
      (res) => {
        setCountries(res.data);
      },
      (err) => {
        console.log(err.message);
      }
    );
  };

  const getRow = () => {
    if (JSON.parse(localStorage.getItem("JWT")) !== null) {
      var jwt = JSON.parse(localStorage.getItem("JWT")).access_token;

      var decodedJwt = jwtDecode(jwt);
      const accountType = decodedJwt.account_type;
      localStorage.setItem("account_type", accountType);
      if (accountType !== "contractor") {
        getemployeeProfile().then(
          (res) => {
            localStorage.setItem("EmpOnboardingRowId", res.data.row_id);
          },
          (err) => {
            console.log(err.message);
          }
        );
      } else {
        getContractorData().then(
          (res) => {
            localStorage.setItem("ContractorOnboardingRowId", res.data.row_id);
          },
          (err) => {
            console.log(err.message);
          }
        );
      }
    }
  };

  const submitProfileData = (e) => {
    e.preventDefault();
    getRow();
    var name = firstAndMiddleName.trim();
    var firstname = name.split(" ")[0];
    var middlename = name.split(" ")[1];
    var formatedDate = moment(new Date(startDate)).format("YYYY-MM-DD");

    var eighteenYearsAgo = moment().subtract("years", 18);
    var birthday = moment(formatedDate);

    if (middlename) {
      var emprofiledata = {
        full_name: fullName.trim(),
        first_name: firstname.trim(),
        middle_name: middlename,
        last_name: lastName.trim(),
        gender: sex,
        nationality: nationality,
        birthdate: formatedDate,
        country_code: countryCode,
        phone_number: contactNo,
      };
    } else {
      var emprofiledata = {
        full_name: fullName.trim(),
        first_name: firstname.trim(),
        last_name: lastName.trim(),
        gender: sex,
        nationality: nationality,
        birthdate: formatedDate,
        country_code: countryCode,
        phone_number: contactNo,
      };
    }

    const accountType = localStorage.getItem("account_type");
    if (startDate !== null) {
      if (
        fullName.trim() != "" &&
        firstAndMiddleName.trim() != "" &&
        lastName.trim() != "" &&
        formatedDate !== "Invalid date" &&
        eighteenYearsAgo.isAfter(birthday)
      ) {
        if (accountType !== "contractor") {
          var row_id = localStorage.getItem("EmpOnboardingRowId");
          emponboarding(emprofiledata, row_id).then(
            (res) => {
              localStorage.removeItem("EmpOnboardingRowId");
              var variant = "success";
              enqueueSnackbar(res.data.message, { variant });
              navigate("/employeeregister");
            },
            (err) => {
              var errors = Error(err.response.data.message);

              if (Array.isArray(errors)) {
                errors.forEach((element) => {
                  sendNotification({
                    msg: `Please enter valid ${element}`,
                    variant: "error",
                  });
                });
              } else {
                sendNotification({
                  msg: err.response.data.message,
                  variant: "error",
                });
              }
            }
          );
        } else {
          var row_id = localStorage.getItem("ContractorOnboardingRowId");
          contractorOnboarding(emprofiledata, row_id).then(
            (res) => {
              var variant = "success";
              enqueueSnackbar(res.data.message, { variant });
              localStorage.removeItem("ContractorOnboardingRowId");
              navigate("/employeeregister");
            },
            (err) => {
              var errors = Error(err.response.data.message);

              if (Array.isArray(errors)) {
                errors.forEach((element) => {
                  sendNotification({
                    msg: `Please enter valid ${element}`,
                    variant: "error",
                  });
                });
              } else {
                sendNotification({
                  msg: err.response.data.message,
                  variant: "error",
                });
              }
            }
          );
        }
      } else {
        if (fullName.trim() == "") {
          sendNotification({
            msg: "Please enter full name",
            variant: "error",
          });
        } else if (firstAndMiddleName.trim() == "") {
          sendNotification({
            msg: "Please enter first and middle name",
            variant: "error",
          });
        } else if (lastName.trim() == "") {
          sendNotification({
            msg: "Please enter last name",
            variant: "error",
          });
        } else if (formatedDate === "Invalid date") {
          sendNotification({
            msg: "Please Enter Valid Date",
            variant: "error",
          });
        } else if (eighteenYearsAgo.isAfter(birthday) === false) {
          sendNotification({
            msg: "You should be older than 18 years to be onboarded as an employee!",
            variant: "error",
          });
        }
      }
    } else {
      sendNotification({
        msg: "Please Enter Valid Date!",
        variant: "error",
      });
    }
  };

  function restrictAlphabets(e) {
    var x = e.which || e.keycode;
    if (x >= 48 && x <= 57) {
      return true;
    } else {
      e.preventDefault();
    }
  }

  return (
    <>
      <Slide direction="left" in={true}>
        <Grid xs={8} item className="login_content_grid_item">
          <Grid
            container
            alignItems="center"
            flexDirection={{ xs: "row", sm: "row" }}
            className="auth_top_header"
          >
            <Grid item>
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="label"
                onClick={goBack}
              >
                <PreviousArrowIcon />
              </IconButton>
              Back
            </Grid>
            <Grid item className="auth_top_header_progress">
              <LinearProgressWithLabel value={progress} />
            </Grid>
          </Grid>
          <div className="login_cont register_cont">
            <Typography variant="h3">Personal profile</Typography>
            <Typography variant="body2">Lets start with the basics</Typography>
            <form onSubmit={submitProfileData} className="register_form">
              <Grid xs={12}>
                <TextField
                  required
                  id="Fullname"
                  label="Full legal name"
                  variant="standard"
                  helperText="This will be used for processing payroll, make sure it is the same as your Government ID."
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  inputProps={{ maxLength: 128, autoComplete: "off" }}
                />
              </Grid>
              <Grid xs={12}>
                <TextField
                  required
                  id="First and middle name"
                  label="First and middle name"
                  variant="standard"
                  helperText="This will be used for processing payroll, make sure it is the same as your Government ID."
                  value={firstAndMiddleName}
                  onChange={(e) => setFirstAndMiddleName(e.target.value)}
                  inputProps={{ maxLength: 128, autoComplete: "off" }}
                />
              </Grid>
              <Grid xs={12}>
                <TextField
                  required
                  id="Last name or surname"
                  label="Last name or surname"
                  variant="standard"
                  helperText="This will be used for processing payroll, make sure it is the same as your Government ID."
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  inputProps={{ maxLength: 128, autoComplete: "off" }}
                />
              </Grid>

              <Grid xs={12}>
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label">
                    <FormLabel>What is your sex?</FormLabel>
                    <FormHelperText>
                      We know sex is non-binary but for insurance and payroll
                      purposes, we need to ask for the information
                    </FormHelperText>
                  </FormLabel>
                  <RadioGroup
                    value={sex}
                    onChange={(e) => setSex(e.target.value)}
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                  >
                    <FormControlLabel
                      value="female"
                      control={<Radio />}
                      label="Female"
                    />
                    <FormControlLabel
                      value="male"
                      control={<Radio />}
                      label="Male"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid xs={12}>
                <InputLabel id="demo-simple-select-helper-label">
                  Nationality
                </InputLabel>
                <Select
                  required
                  fullWidth
                  variant="standard"
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={nationality}
                  label="Country"
                  onChange={(e) => setNationality(e.target.value)}
                >
                  {countries.map((data) => (
                    <MenuItem value={data.name} key={data.row_id}>
                      {data.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              <Grid xs={12} className="date_picker_employee">
                <FormControl>
                  <FormLabel>
                    <Typography>Birthdate</Typography>
                  </FormLabel>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      required
                      varient="standard"
                      inputFormat="YYYY/MM/DD"
                      value={startDate}
                      onChange={(newValue) => {
                        setStartDate(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>

              <Grid
                container
                alignItems="center"
                justifyContent="center"
                flexDirection={{ xs: "row", sm: "row" }}
                className="phone_input_group"
              >
                <Grid item xs={12}>
                  <FormLabel className="MuiInputLabel-root">
                    Phone Number
                  </FormLabel>
                </Grid>
                <Grid item xs={3}>
                  <Autocomplete
                    id="country-code"
                    defaultValue={country[indexOfCountryCode]}
                    options={country}
                    autoHighlight
                    onChange={(event, newValue) => {
                      setValue(newValue.phone);
                    }}
                    inputValue={countryCode}
                    onInputChange={(event, newInputValue) => {
                      setCountryCode(newInputValue);
                    }}
                    getOptionLabel={(option) => " +" + option.phone}
                    filterOptions={(options, { inputValue }) =>
                      options.filter((option) =>
                        (option.label + " +" + option.phone)
                          .toLowerCase()
                          .includes(inputValue.toLowerCase())
                      )
                    }
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        <img
                          loading="lazy"
                          width="20"
                          src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                          srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                          alt=""
                        />
                        {option.label} +{option.phone}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        label={false}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "off", // disable autocomplete and autofill
                        }}
                        variant="standard"
                      />
                    )}
                    className="autocomplete"
                  />
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    required
                    fullWidth
                    type="phonenumber"
                    placeholder="9999999999"
                    id="fullWidth"
                    variant="standard"
                    value={contactNo}
                    onKeyPress={(e) => restrictAlphabets(e)}
                    onChange={(e) => setContactNo(e.target.value)}
                    inputProps={{
                      required: "required",
                      maxLength: 10,
                      max: "10",
                      autoComplete: "off",
                      pattern: "[0-9]{10}",
                    }}
                  />
                </Grid>
              </Grid>
              <Grid className="login_bttn_wrapper">
                <Button type="submit" className="theme_bttn fill">
                  Continue
                </Button>
              </Grid>
            </form>
          </div>
        </Grid>
      </Slide>
    </>
  );
};
