import { useState, useEffect } from "react";
import { RouterConfig } from "Navigation/RouterConfig";
// Handle ConsoleLogs
// import "./Utils/dropConsole.js";

function App(props) {
  // useEffect(() => {
  //   const handleVisibilityChange = () => {
  //     document.title = document.hidden ? "InRole" : "InRole";
  //   };
  //   document.addEventListener("visibilitychange", handleVisibilityChange);
  //   return () => {
  //     document.removeEventListener("visibilitychange", handleVisibilityChange);
  //   };
  // }, []);

  const [isLogin, setisLogin] = useState(
    localStorage.getItem("Login_Status") ? true : false
  );

  return <RouterConfig isLogin={isLogin} auth0domain={props.auth0domain} />;
}

export default App;
