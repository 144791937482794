import "./AddContractor.css";
import { React, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import countries from "../../../Assets/JSONs/Countries.json";
import {
  Button,
  Grid,
  TextField,
  Box,
  InputLabel,
  MenuItem,
  FormHelperText,
  Select,
  getCountries,
} from "./AddContractorImports.js";
import {
  addContractor,
  contractorSummary,
} from "Services/AddEmployeeAndContractor";
import { Autocomplete, FormLabel } from "@mui/material";

import { HelpButton } from "Components/CommonComponents/Bottom_Help_Button/HelpButton";

import useNotification from "Components/CommonComponents/AlertNotifications/AlertNotifications";

import { useSnackbar } from "notistack";
import { Error } from "Utils/Success&Errorfunctions";

export const AddContractor = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [msg, sendNotification] = useNotification();

  const [loading, setLoading] = useState(false);
  const [indexOfCountryCode, setIndexOfCountryCode] = useState(92);
  const [value, setValue] = useState();

  const [progress, setProgress] = useState(0);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [countryCodeArray, setCountryCodeArray] = useState();
  const [contactNumber, setContactNumber] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [Country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [countryArray, setCountryArray] = useState([]);
  const [isParams, setIsParams] = useState(false);

  useEffect(() => {
    getCountry();
    fillform();
    setTimeout(() => {
      setProgress(25);
    }, 1000);
  }, []);

  const getCountry = () => {
    getCountries().then(
      (res) => {
        setCountryArray(res.data);
      },
      (err) => {
        throw err;
      }
    );
  };

  const showLoading = () => {
    setLoading(true);
  };
  const hideLoading = () => {
    setLoading(false);
  };

  const submitContractorDatail = (e) => {
    e.preventDefault();
    var id = params.id;
    showLoading();
    if (fullName.trim() !== "" && jobTitle.trim() !== "" && state.trim()) {
      if (!id) {
        postContractor();
      } else {
        putContractor(id);
      }
    } else {
      var variant = "error";
      if (fullName.trim() == "") {
        sendNotification({
          msg: "Please fill valid Name.",
          variant: variant,
        });

        hideLoading();
      } else if (jobTitle.trim() == "") {
        sendNotification({
          msg: "Please fill valid job title.",
          variant: variant,
        });

        hideLoading();
      } else if (state.trim() == "") {
        sendNotification({
          msg: "Please fill valid state.",
          variant: variant,
        });

        hideLoading();
      }
    }
  };

  const postContractor = () => {
    const contractorData = {
      full_name: fullName.trim(),
      email: email.trim().toLowerCase(),
      country_code: countryCode,
      phone_number: contactNumber,
      job_title: jobTitle.trim(),
      country_id: Country,
      states: state.trim(),
    };
    addContractor(contractorData).then(
      (res) => {
        enqueueSnackbar(res.data.message, { variant: "success" });
        var rowdata = res.data.row_id;

        localStorage.setItem("ContractorRowData", rowdata);
        localStorage.setItem("ContractorData1", JSON.stringify(contractorData));

        navigate("/addcontractor/2");
        hideLoading();
      },
      (err) => {
        var errors = Error(err.response.data.message);

        if (Array.isArray(errors)) {
          errors.forEach((element) => {
            sendNotification({
              msg: `Please enter valid ${element}`,
              variant: "error",
            });
          });
          hideLoading();
        } else {
          sendNotification({
            msg: err.response.data.message,
            variant: "error",
          });
          hideLoading();
        }
      }
    );
  };

  const putContractor = (id) => {
    const contractorData = {
      full_name: fullName.trim(),
      country_code: countryCode,
      phone_number: contactNumber,
      job_title: jobTitle.trim(),
      country_id: Country,
      states: state.trim(),
    };
    contractorSummary(contractorData, id).then(
      (res) => {
        enqueueSnackbar(res.data.message, { variant: "success" });

        const contractorData1 = {
          full_name: fullName,
          email: email.trim().toLowerCase(),
          country_code: countryCode,
          phone_number: contactNumber,
          job_title: jobTitle,
          country_id: Country,
          states: state,
        };

        localStorage.setItem(
          "ContractorData1",
          JSON.stringify(contractorData1)
        );

        navigate("/addcontractor/2/" + id);
        hideLoading();
      },
      (err) => {
        var errors = Error(err.response.data.message);
        if (Array.isArray(errors)) {
          errors.forEach((element) => {
            sendNotification({
              msg: `Please enter valid ${element}`,
              variant: "error",
            });
          });
          hideLoading();
        } else {
          sendNotification({
            msg: err.response.data.message,
            variant: "error",
          });
          hideLoading();
        }
      }
    );
  };

  const fillform = () => {
    var id = params.id;
    if (id) {
      setIsParams(true);
    }
    const contractordata1 = JSON.parse(localStorage.getItem("ContractorData1"));
    if (contractordata1 || contractordata1 !== null) {
      setFullName(contractordata1.full_name);
      setEmail(contractordata1.email);
      var code = contractordata1.country_code.split("+")[1];
      var indexOfCountryCode = countries.indexOf(code);
      // console.log(indexOfCountryCode);
      let array = countries.filter((val) => {
        return val.phone == code;
      });
      // console.log(array.map((data) => data.label));
      // setValue(array.map((data) => data.label));

      setCountryCodeArray(array[0]);
      setTimeout(() => {
        // console.log(countryCodeArray);
      }, 10);
      // console.log(countries[0]);
      setContactNumber(contractordata1.phone_number);

      setState(contractordata1.states);
      setJobTitle(contractordata1.job_title);
      setCountry(contractordata1.country_id);
    }
  };

  function restrictAlphabets(e) {
    var x = e.which || e.keycode;
    if (x >= 48 && x <= 57) {
      return true;
    } else {
      e.preventDefault();
    }
  }

  return (
    <>
      <form onSubmit={submitContractorDatail} className="register_form">
        <Grid xs={12}>
          <TextField
            required
            fullWidth
            label="Full Name"
            variant="standard"
            helperText="Full employee name as it appears on identification document"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            id="fullname"
            inputProps={{ maxLength: 128, autoComplete: "off" }}
          />
        </Grid>
        <Grid xs={12}>
          <TextField
            disabled={!isParams ? false : true}
            required
            fullWidth
            type="email"
            label="Personal email"
            variant="standard"
            helperText="E.g. jane@gmail.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            id="email"
            inputProps={{ maxLength: 128, autoComplete: "off" }}
          />
        </Grid>
        <Grid xs={12}>
          <TextField
            required
            fullWidth
            label="Job title"
            variant="standard"
            helperText="E.g. product designer"
            value={jobTitle}
            onChange={(e) => setJobTitle(e.target.value)}
            id="email"
            inputProps={{ maxLength: 128, autoComplete: "off" }}
          />
        </Grid>
        <Grid container className="phone_input_group">
          <Grid item xs={12}>
            <FormLabel className="MuiInputLabel-root">Phone Number</FormLabel>
          </Grid>
          {/* {countryCodeArray} */}
          <Grid item xs={3}>
            <Autocomplete
              id="country-code"
              // value={countryCode}
              defaultValue={countries[indexOfCountryCode]}
              options={countries}
              autoHighlight
              onChange={(event, newValue) => {
                setValue(newValue.phone);
              }}
              inputValue={countryCode}
              onInputChange={(event, newInputValue) => {
                setCountryCode(newInputValue);
              }}
              getOptionLabel={(option) => " +" + option.phone}
              filterOptions={(options, { inputValue }) =>
                options.filter((option) =>
                  (option.label + " +" + option.phone)
                    .toLowerCase()
                    .includes(inputValue.toLowerCase())
                )
              }
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  <img
                    loading="lazy"
                    width="20"
                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                    alt=""
                  />
                  {option.label} +{option.phone}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  label={false}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "off",
                  }}
                  variant="standard"
                />
              )}
              className="autocomplete"
            />
          </Grid>
          <Grid item xs={9}>
            <TextField
              required
              fullWidth
              type="text"
              placeholder="Phone Number"
              id="fullWidth"
              variant="standard"
              value={contactNumber}
              onKeyPress={(e) => restrictAlphabets(e)}
              onChange={(e) => setContactNumber(e.target.value)}
              inputProps={{
                required: "required",
                maxLength: 10,
                max: "10",
                autoComplete: "off",
                pattern: "[0-9]{10}",
              }}
            />
          </Grid>
        </Grid>

        <Grid xs={12}>
          <InputLabel id="demo-simple-select-helper-label">Country</InputLabel>
          <Select
            required
            fullWidth
            variant="standard"
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={Country}
            label="Country"
            onChange={(e) => setCountry(e.target.value)}
          >
            {countryArray.map((data) => (
              <MenuItem value={data.row_id} key={data.row_id}>
                {data.name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>Where does your new contractor live?</FormHelperText>
        </Grid>
        <Grid xs={12}>
          <TextField
            required
            fullWidth
            label="State"
            variant="standard"
            helperText="Which state your new contractor live?"
            value={state}
            onChange={(e) => setState(e.target.value)}
            id="state"
            inputProps={{ maxLength: 128, autoComplete: "off" }}
          />
        </Grid>
        <Grid className="login_bttn_wrapper">
          <Button
            type="submit"
            className="theme_bttn fill"
            disabled={loading ? true : false}
          >
            Continue
          </Button>
        </Grid>
      </form>

      <HelpButton />
    </>
  );
};
