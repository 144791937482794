import { Box, IconButton, Modal, Slide, Typography } from "@mui/material";
import { CloseIcon } from "Icons/Icons";

import { Grid } from "Utils/muiImport";

export const CurrencyConverter = (props) => {
  return (
    <Modal
      open={props.modalOpen}
      onClose={props.close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="add_hire_modal converted_currencies_modal"
    >
      <Slide in={props.modalOpen}>
        <Box className="add_hire_modal_bx">
          <div className="modal_header">
            <Grid container flexDirection={{ xs: "row", sm: "row" }}>
              <Grid item>
                <Typography>Converted Currencies</Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={props.close} className="icon_close_bttn">
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </div>
          <div className="modal_body">
            {props.currencyData.map((data) => (
              <>
                <Grid
                  container
                  className="converted_currencies_list"
                  key={data.code}
                >
                  <Grid xs={6}>
                    <Typography variant="body1">{data.currency}</Typography>
                  </Grid>

                  <Grid xs={1}>
                    <Typography>=</Typography>
                  </Grid>

                  <Grid xs={5}>
                    <Typography variant="body2">
                      {data.value.toFixed(2)}
                      {data.code}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            ))}
          </div>
        </Box>
      </Slide>
    </Modal>
  );
};
