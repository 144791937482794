import {
  Avatar,
  Fade,
  IconButton,
  Slide,
  Step,
  StepLabel,
  Stepper,
  useMediaQuery,
} from "@mui/material";
import { AuthSidebar, Grid, Typography } from "Components/Login/LoginImport";
import React, { useEffect, useState } from "react";
import { AddEmployee } from "../AddEmployee1/AddEmployee";
import { AddEmployee2 } from "../AddEmployee2/AddEmployee2";
import { AddEmployeePricingPlan } from "../AddEmployeePricingPlan/AddEmployeePricingPlan";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import {
  FinalPageEmployee_ContractorDetailsDoneIcon,
  PreviousArrowIcon,
} from "Icons/Icons";
import { AddEmployeeFinal } from "../AddEmployeeFinal/AddEmployeeFinal";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import { LinearProgressWithLabel } from "Utils/TabPanel";

export const AddEmployeeStepper = (props) => {
  const location = useLocation();
  const params = useParams();
  const [activeStep, setActiveStep] = useState(0);
  useEffect(() => {
    if (
      location.pathname === "/addemployee" ||
      location.pathname === "/addemployee/" + params.id
    ) {
      setActiveStep(0);
    } else if (
      location.pathname === "/addemployee/2" ||
      location.pathname === "/addemployee/2/" + params.id
    ) {
      setActiveStep(1);
    } else if (
      location.pathname === "/addemployeepricingplan" ||
      location.pathname === "/addemployeepricingplan/" + params.id
    ) {
      setActiveStep(2);
    } else if (
      location.pathname === "/addemployeefinal" ||
      location.pathname === "/addemployeefinal/" + params.id
    ) {
      setActiveStep(3);
    }
  }, [location.pathname]);

  const steps = [
    {
      label: "Basic Information",
    },
    {
      label: "Contract details",
    },
    {
      label: "Pricing plan",
    },
  ];

  return (
    <>
      <Stepper
        activeStep={parseInt(activeStep)}
        orientation={props.orientation ? "horizontal" : "vertical"}
        className={`register-stepper ${
          activeStep == 3 ? "all-steps-completed" : ""
        } ${props.className}`}
      >
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel>{props.noLabel ? null : step.label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </>
  );
};

export const AddEmployeeSideBar = (props) => {
  const location = useLocation();
  const params = useParams();
  const [id, setID] = useState(params.id);
  const [name, setName] = useState("");
  const [jobtitle, setJobtitle] = useState("");

  useEffect(() => {
    var employeeName = JSON.parse(localStorage.getItem("Empdata1"));
    if (employeeName !== null) {
      setName(employeeName.full_name);
      setJobtitle(employeeName.job_title);
    } else {
      setName("");
    }
  }, [location.pathname]);

  return (
    <>
      <Slide in={true} direction="right">
        <Grid container className="login_grid show_sidebar_content">
          {location.pathname !== "/addemployeefinal" &&
          location.pathname !== `/addemployeefinal/${id}` &&
          location.pathname !== `/addemployeefinal/` ? (
            <AuthSidebar>
              <div className="register-sidebar addemployee-sidebar">
                <Grid
                  container
                  className="register-sidebar-addteam"
                  spacing={3}
                  flexWrap="nowrap"
                >
                  <Grid item className="avatar-item">
                    <Avatar>
                      {name === "" ? (
                        <GroupAddOutlinedIcon />
                      ) : name.length >= 2 ? (
                        name.slice(0, 2).toUpperCase()
                      ) : (
                        name
                      )}
                    </Avatar>
                  </Grid>
                  <Grid item>
                    <Typography variant="h3" gutterBottom>
                      Add {name === "" ? "a new employee" : name}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      {!jobtitle
                        ? "It’s simple and fast"
                        : `You’re adding ${name} as a ${jobtitle}. `}
                    </Typography>
                  </Grid>
                </Grid>
                <AddEmployeeStepper />
              </div>
            </AuthSidebar>
          ) : null}

          <EmployeeHeader
            addemployee1={props.addemployee1 ? true : false}
            addemployee2={props.addemployee2 ? true : false}
            addemployee3={props.addemployee3 ? true : false}
            addemployee4={props.addemployee4 ? true : false}
          />
        </Grid>
      </Slide>
    </>
  );
};

export const EmployeeHeader = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const tabletScreen = useMediaQuery("(max-width:899px)");
  const [progress, setProgress] = useState(0);

  const goBack = () => {
    var id = params.id;
    if (
      location.pathname === "/addemployee" ||
      location.pathname === "/addemployee/" + params.id
    ) {
      if (!id) {
        localStorage.removeItem("Empdata1");
        localStorage.removeItem("empRowData");
        localStorage.removeItem("Empdata2");
        localStorage.removeItem("Empdata3");

        navigate("/team");
      } else {
        var data = JSON.parse(localStorage.getItem("Empdata2"));
        if (data) {
          navigate("/addemployeefinal/" + id);
        } else {
          localStorage.removeItem("Empdata1");
          localStorage.removeItem("empRowData");
          localStorage.removeItem("Empdata2");
          localStorage.removeItem("Empdata3");

          navigate("/onboarding");
        }
      }
    } else if (
      location.pathname === "/addemployee/2" ||
      location.pathname === "/addemployee/2/" + params.id
    ) {
      if (!id) {
        var rowdata = localStorage.getItem("empRowData");
        navigate("/addemployee/" + rowdata);
      } else {
        navigate("/addemployee/" + id);
      }
    } else if (
      location.pathname === "/addemployeepricingplan" ||
      location.pathname === "/addemployeepricingplan/" + params.id
    ) {
      if (!id) {
        navigate("/addemployee/2");
      } else {
        navigate("/addemployee/2/" + id);
      }
    } else if (
      location.pathname === "/addemployeefinal" ||
      location.pathname === "/addemployeefinal/" + params.id ||
      location.pathname === "/addemployeefinal/" + undefined
    ) {
      var row_id = localStorage.getItem("empRowData");

      if (id || row_id) {
        localStorage.removeItem("Empdata1");
        localStorage.removeItem("Empdata2");
        localStorage.removeItem("Empdata3");
        localStorage.removeItem("empRowData");

        navigate("/onboarding");
      } else {
        navigate("/team");
      }
    } else {
      navigate("/onboarding");
    }
  };

  useEffect(() => {
    if (
      location.pathname === "/addemployee" ||
      location.pathname === "/addemployee/" + params.id
    ) {
      setProgress(25);
    } else if (
      location.pathname === "/addemployee/2" ||
      location.pathname === "/addemployee/2/" + params.id
    ) {
      setProgress(50);
    } else if (
      location.pathname === "/addemployeepricingplan" ||
      location.pathname === "/addemployeepricingplan/" + params.id
    ) {
      setProgress(75);
    } else if (
      location.pathname === "/addemployeefinal" ||
      location.pathname === "/addemployeefinal/" + params.id
    ) {
      setProgress(100);
    }
  }, [location.pathname]);

  return (
    <>
      <Grid
        xs={8}
        item
        className={`login_content_grid_item ${
          props.addemployee4 ? "add_employe_final_cont" : null
        }`}
      >
        <Grid
          container
          alignItems="center"
          flexDirection={{ xs: "row", sm: "row" }}
          className="auth_top_header"
        >
          <Grid item>
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="label"
              onClick={goBack}
            >
              <PreviousArrowIcon />
            </IconButton>
            {props.addemployee1 ? "Previous" : "Back"}
          </Grid>
          <Grid item className="auth_top_header_progress">
            <LinearProgressWithLabel value={progress} />
          </Grid>
        </Grid>
        <div className="login_cont register_cont">
          {props.addemployee1 && (
            <>
              <Typography variant="h3">Basic information</Typography>
              <Typography variant="body2">
                Please make sure this information is accurate. We’ll craft the
                contract with the details you provide.
              </Typography>
            </>
          )}
          {props.addemployee2 && (
            <>
              <Typography variant="h3">Contract details</Typography>
              <Typography variant="body2">
                Please make sure this information is accurate. We’ll craft the
                contract with the details you provide.
              </Typography>
            </>
          )}
          {props.addemployee3 && (
            <>
              <Typography variant="h3">Pricing Plan</Typography>
            </>
          )}
          {tabletScreen ? (
            <AddEmployeeStepper
              className="horizontal_Stepper"
              orientation="horizontal"
              noLabel
            />
          ) : location.pathname === "/addemployeefinal" ||
            location.pathname === "/addemployeefinal/" + params.id ? (
            <Grid xs={12} className="check_img_svg">
              <FinalPageEmployee_ContractorDetailsDoneIcon />
            </Grid>
          ) : null}

          {props.addemployee3 && (
            <>
              <Typography variant="body2">
                No hidden fees, deposit requirements, or exclusivity clauses.
                Pay a flat fee per employee based on the country where they’ll
                be working.
              </Typography>
            </>
          )}
          {props.addemployee1 && <AddEmployee />}
          {props.addemployee2 && <AddEmployee2 />}
          {props.addemployee3 && <AddEmployeePricingPlan />}
          {props.addemployee4 && <AddEmployeeFinal />}
        </div>
      </Grid>
    </>
  );
};
