import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Modal,
  Slide,
  TextField,
  Typography,
} from "@mui/material";
import { CloseIcon } from "Icons/Icons";
import useNotification from "Components/CommonComponents/AlertNotifications/AlertNotifications";
import { updateBankDetails } from "Services/CompanyServices";
import { Grid } from "Utils/muiImport";
import { Error } from "Utils/Success&Errorfunctions";

export const BankDetailsModal = (props) => {
  const [msg, sendNotification] = useNotification();

  const [accountHolderName, setAccountHolderName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [ifscNumber, setIfscNumber] = useState("");
  const [bankName, setBankName] = useState("");
  const [isSWIFT, setIsSWIFT] = useState(false);
  const [swift, setSwift] = useState("");

  const [error, setError] = useState(false);

  useEffect(() => {
    setAccountHolderName(props.accountHolderName);
    setAccountNumber(props.accountNumber);
    setIfscNumber(props.ifsccode);
    setBankName(props.bankname);
    setSwift(props.swift);
  }, [props.modalOpen]);

  let regex = new RegExp("[0-9]");
  useEffect(() => {
    if (regex.test(accountNumber) == false) {
      setError(true);
    } else {
      setError(false);
    }
  }, [accountNumber]);

  const updateBank = (e) => {
    e.preventDefault();
    var companyID = localStorage.getItem("CompanyRowID");
    var bankdata = {
      account_holder_name: accountHolderName.trim(),
      account_number: accountNumber.trim(),
      ifsc_code: ifscNumber.trim(),
      full_bank_name: bankName.trim(),
      swift: swift,
    };

    if (
      accountHolderName.trim() != "" &&
      accountNumber.trim() != "" &&
      ifscNumber.trim() != "" &&
      bankName.trim() != ""
    ) {
      updateBankDetails(bankdata, companyID).then(
        (res) => {
          sendNotification({
            msg: res.data.message,
            variant: "success",
          });
          setAccountHolderName("");
          setAccountNumber("");
          setIfscNumber("");
          setBankName("");
          setIsSWIFT(false);
          props.close();
        },
        (err) => {
          var errors = Error(err.response.data.message);

          if (Array.isArray(errors)) {
            errors.forEach((element) => {
              sendNotification({
                msg: `Please enter valid ${element}`,
                variant: "error",
              });
            });
          } else {
            sendNotification({
              msg: err.response.data.message,
              variant: "error",
            });
          }
        }
      );
    } else {
      if (accountHolderName.trim() == "") {
        sendNotification({
          msg: "Please Enter Valid Name",
          variant: "error",
        });
      } else if (accountNumber.trim() == "") {
        sendNotification({
          msg: "Please Enter Valid Account Number",
          variant: "error",
        });
      } else if (ifscNumber.trim() == "") {
        sendNotification({
          msg: "Please Enter Valid IFSC Code",
          variant: "error",
        });
      } else if (bankName.trim() == "") {
        sendNotification({
          msg: "Please Enter Valid Bank Name",
          variant: "error",
        });
      }
    }
  };

  return (
    <>
      <Modal
        onBackdropClick={props.close}
        open={props.modalOpen}
        onClose={props.close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="add_hire_modal new_request_modal"
      >
        <Slide in={props.modalOpen}>
          <Box className="add_hire_modal_bx">
            <div className="modal_header">
              <Grid container>
                <Grid item>
                  <Typography id="modal-modal-title" variant="h6">
                    Update Bank Details
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton onClick={props.close} className="icon_close_bttn">
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </div>
            <form onSubmit={(e) => updateBank(e)}>
              <div className="modal_body">
                <div className="register_form">
                  <Grid className="modal_select_employe_dropdown">
                    <TextField
                      required
                      fullWidth
                      value={accountHolderName}
                      onChange={(e) => setAccountHolderName(e.target.value)}
                      id="account-holder-name"
                      variant="standard"
                      label="Account Holder Name"
                      inputProps={{ autoComplete: "off", maxLength: 256 }}
                    />
                  </Grid>
                  <Grid>
                    <TextField
                      required
                      fullWidth
                      value={accountNumber}
                      onChange={(e) => setAccountNumber(e.target.value)}
                      id="account-number"
                      label="Account Number"
                      variant="standard"
                      inputProps={{
                        autoComplete: "off",
                        maxLength: 20,
                      }}
                    />
                  </Grid>
                  <Grid>
                    <TextField
                      required
                      fullWidth
                      value={bankName}
                      onChange={(e) => setBankName(e.target.value)}
                      id="bank-Name"
                      label="Bank Name"
                      variant="standard"
                      inputProps={{ autoComplete: "off", maxLength: 128 }}
                    />
                  </Grid>
                  <Grid>
                    <TextField
                      required
                      fullWidth
                      value={ifscNumber}
                      onChange={(e) => setIfscNumber(e.target.value)}
                      id="IFSC"
                      label="IFSC Number"
                      variant="standard"
                      inputProps={{ autoComplete: "off", maxLength: 11 }}
                    />
                  </Grid>

                  <Grid>
                    <TextField
                      fullWidth
                      value={swift}
                      onChange={(e) => setSwift(e.target.value)}
                      id="SWIFT-Number"
                      label="SWIFT Number"
                      variant="standard"
                      inputProps={{ autoComplete: "off", maxLength: 11 }}
                    />
                  </Grid>
                </div>
              </div>
              <div className="modal_footer">
                <Grid container className="modal_foot_bttn_container">
                  <Button className="save_bttn" type="submit">
                    Update Details
                  </Button>
                  <Button className="cancel_bttn" onClick={props.close}>
                    Cancel
                  </Button>
                </Grid>
              </div>
            </form>
          </Box>
        </Slide>
      </Modal>
    </>
  );
};
