import "./AddEmployeePricingPlan.css";
import { React, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Rectangle,
  InRolllogo,
  Save14,
  PersonOutlineOutlinedIcon,
  FiberManualRecordIcon,
  Box,
  LinearProgress,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Container,
  Stack,
  Button,
  Grid,
  IconButton,
  Divider,
} from "./AddEmployeePricingPlanImports";
import {
  createPricingPlanForEmployee,
  getPricingPlan,
} from "Services/AddEmployeeAndContractor";
import { AuthSidebar } from "Components/Login/LoginImport";
import { List, ListItem, ListItemText } from "@mui/material";
import { HelpButton } from "Components/CommonComponents/Bottom_Help_Button/HelpButton";
import { PreviousArrowIcon } from "Icons/Icons";
import { async } from "q";
import { useSnackbar } from "notistack";
import { Error } from "Utils/Success&Errorfunctions";
import useNotification from "Components/CommonComponents/AlertNotifications/AlertNotifications";
import { AddEmployeeStepper } from "../AddEmployeeSideBar/AddEmployeeSideBar";
import useMediaQuery from "@mui/material/useMediaQuery";

export const AddEmployeePricingPlan = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const tabletScreen = useMediaQuery("(max-width:899px)");

  const { enqueueSnackbar } = useSnackbar();
  const [msg, sendNotification] = useNotification();

  const [activeStep, setActiveStep] = useState(2);
  const [progress, setProgress] = useState(50);
  const [billingCycle, setBillingCycle] = useState("Billed-annually");
  const [monthlyPricing, setMonthlyPricing] = useState("");
  const [yearlyPricing, setYearlyPricing] = useState("");
  const [currencyType, setCurrencyType] = useState("");

  useEffect(() => {
    fillform();
    pricingPlan();
  }, []);

  const goBack = () => {
    var id = params.id;
    if (!id) {
      navigate("/addemployee/2");
    } else {
      navigate("/addemployee/2/" + id);
    }
  };

  const pricingPlan = async () => {
    await getPricingPlan().then(
      (res) => {
        setMonthlyPricing(res.data.monthly_employee_pricing);
        setYearlyPricing(res.data.yearly_employee_pricing);
        setCurrencyType(res.data.currency);
      },
      (err) => {
        console.log(err.message);
      }
    );
  };

  const finalsubmit = () => {
    const id = params.id;
    if (!id) {
      var emp_id = localStorage.getItem("empRowData");
      var bill = billingCycle.toLowerCase();
      let price = 0;
      if (billingCycle === "Billed-annually") {
        price = yearlyPricing;
      } else {
        price = monthlyPricing;
      }
      const companyID = localStorage.getItem("CompanyRowID");
      const pricing_plan = {
        company_id: companyID,
        emp_id: emp_id,
        type: bill,
        price: price,
        currency: currencyType,
      };

      createPricingPlanForEmployee(pricing_plan).then(
        (res) => {
          var variant = "success";
          enqueueSnackbar(res.data.message, { variant });

          localStorage.setItem("Empdata3", JSON.stringify(pricing_plan));
          navigate("/addemployeefinal");
        },
        (err) => {
          var errors = Error(err.response.data.message);

          if (Array.isArray(errors)) {
            errors.forEach((element) => {
              sendNotification({
                msg: `Please enter valid ${element}`,
                variant: "error",
              });
            });
          } else {
            sendNotification({
              msg: err.response.data.message,
              variant: "error",
            });
          }
        }
      );
    } else {
      var bill = billingCycle.toLowerCase();
      let price = 0;
      if (billingCycle === "Billed-annually") {
        price = yearlyPricing;
      } else {
        price = monthlyPricing;
      }
      const companyID = localStorage.getItem("CompanyRowID");
      const pricing_plan = {
        company_id: companyID,
        emp_id: id,
        type: bill,
        price: price,
        currency: currencyType,
      };

      createPricingPlanForEmployee(pricing_plan).then(
        (res) => {
          var variant = "success";
          enqueueSnackbar(res.data.message, { variant });

          localStorage.setItem("Empdata3", JSON.stringify(pricing_plan));
          navigate("/addemployeefinal");
        },
        (err) => {
          var errors = Error(err.response.data.message);

          if (Array.isArray(errors)) {
            errors.forEach((element) => {
              sendNotification({
                msg: `Please enter valid ${element}`,
                variant: "error",
              });
            });
          } else {
            sendNotification({
              msg: err.response.data.message,
              variant: "error",
            });
          }
        }
      );
    }
  };

  const fillform = () => {
    var id = params.id;
    if (!id) {
      const empdata = JSON.parse(localStorage.getItem("Empdata3"));
      if (empdata || empdata !== null) {
        if (empdata.type == "billed-monthly") {
          setBillingCycle("Billed-monthly");
        } else {
          setBillingCycle("Billed-annually");
        }
      }
    }
  };

  return (
    <>
      <Typography variant="body2">
        <Link>View FAQs about pricing</Link>
      </Typography>
      <form className="register_form">
        <Grid xs={12}>
          <FormLabel id="demo-radio-buttons-group-label">
            Choose your billing cycle
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            value={billingCycle}
            name="radio-buttons-group"
            onChange={(e) => setBillingCycle(e.target.value)}
          >
            <Grid container>
              <FormControlLabel
                value="Billed-annually"
                control={<Radio />}
                label="Billed annually"
              />

              <FormControlLabel
                value="Billed-monthly"
                control={<Radio />}
                label="Billed monthly"
              />
            </Grid>
          </RadioGroup>
        </Grid>
        <Grid xs={12}>
          <div className="pricing_bx">
            <div className="pricing_bx_head">
              <Typography variant="h5">SELECTED</Typography>
            </div>
            <Grid xs={12} className="pricing_bx_profile">
              <Box className="pricing_bx_profile_bx">
                <PersonOutlineOutlinedIcon />
              </Box>
            </Grid>
            <Grid xs={12} className="pricing_bx_cont_wrapper">
              <Box className="pricing_bx_cont">
                <Grid xs={12}>
                  <Typography variant="h4">Single Employee</Typography>
                </Grid>

                {billingCycle === "Billed-annually" ? (
                  <Grid xs={12} className="pricing_bx_save">
                    <Box className="pricing_bx_save_bx">SAVE 14%</Box>
                  </Grid>
                ) : null}
                <Grid container className="pricing_bx_price" spacing={0}>
                  <Typography variant="h4">
                    {billingCycle === "Billed-annually"
                      ? yearlyPricing
                      : monthlyPricing}
                  </Typography>
                  <Stack>
                    <Typography variant="h5">.00{currencyType}</Typography>
                    <Typography variant="body1">
                      /per{" "}
                      {billingCycle === "Billed-annually" ? " year" : " month"}
                    </Typography>
                  </Stack>
                </Grid>
                <Divider className="pricing_bx_Divider"></Divider>
                <Grid xs={12} className="pricing_bx_list">
                  <List>
                    <ListItem>
                      Hire International employees and let us handle local
                      payrolls, benefits, taxes, and compliance
                    </ListItem>
                    <ListItem>
                      Competetive benefits packages tailored to each country
                    </ListItem>
                    <ListItem>
                      Get support from local HR and legal experts, no
                      third-party delays
                    </ListItem>
                    <ListItem>
                      Ensure your IP and invention rights are fully protected
                    </ListItem>
                  </List>
                </Grid>
              </Box>
            </Grid>
          </div>
        </Grid>
        <Grid xs={12} className="login_bttn_wrapper">
          {/* <a
              href="https://api.inrole.dev/media/pqr.jpeg"
              download="https://api.inrole.dev/media/pqr.jpeg"
            >
              download
            </a> */}
          <Button
            type="button"
            onClick={finalsubmit}
            className="theme_bttn fill"
          >
            Continue
          </Button>
        </Grid>
      </form>
      <HelpButton />
    </>
  );
};
