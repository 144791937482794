import {
  Autocomplete,
  Box,
  Button,
  FormHelperText,
  FormLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Slide,
  TextField,
  Typography,
} from "@mui/material";

import useNotification from "Components/CommonComponents/AlertNotifications/AlertNotifications";
import { CloseIcon } from "Icons/Icons";
import { contractorSummary } from "Services/AddEmployeeAndContractor";
import { Grid } from "Utils/muiImport";
import countries from "../../../../Assets/JSONs/Countries.json";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getCountries } from "Services/Login&Registrationservice";
import { Error } from "Utils/Success&Errorfunctions";
import { useSnackbar } from "notistack";

export const AddContractorSummuryUpdate = (props) => {
  const navigate = useNavigate();
  const params = useParams();

  const [msg, sendNotification] = useNotification();

  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState();
  const [index, setIndex] = useState(0);

  const [countryArray, setCountryArray] = useState([]);

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [Country, setCountry] = useState("");
  const [state, setState] = useState("");

  useEffect(() => {
    getCountry();

    var selectedCountry = countryArray.filter((value) => {
      return props.country === value.name;
    });
    var country = selectedCountry.map((value) => value.row_id);

    setFullName(props.fullname);
    setEmail(props.email);

    setPhoneNumber(`${props.phonenumber}`);
    setState(props.state);
    setJobTitle(props.jobtitle);
    setCountry(country[0]);
    const Index = countries.findIndex(
      (country) => country.phone === props.countryCode.split("+")[1]
    );

    setIndex(Index);
  }, [props.modalOpen, props.close]);

  const getCountry = () => {
    getCountries().then(
      (res) => {
        setCountryArray(res.data);
      },
      (err) => {
        throw err;
      }
    );
  };

  const putContractor = (e, id) => {
    e.preventDefault();
    var id = params.id;
    var row_id = localStorage.getItem("ContractorRowData");
    // console.log(phoneNumber.length);
    if (
      fullName.trim() !== "" &&
      state.trim() !== "" &&
      jobTitle.trim() !== "" &&
      phoneNumber.length === 10
    ) {
      if (id) {
        const contractorData = {
          full_name: fullName.trim(),
          country_code: countryCode,
          phone_number: phoneNumber,
          job_title: jobTitle.trim(),
          country_id: Country,
          states: state.trim(),
        };
        contractorSummary(contractorData, id).then(
          (res) => {
            sendNotification({
              msg: res.data.message,
              variant: "success",
            });
            props.close();
          },
          (err) => {
            var errors = Error(err.response.data.message);

            if (Array.isArray(errors)) {
              errors.forEach((element) => {
                sendNotification({
                  msg: `Please enter valid ${element}`,
                  variant: "error",
                });
              });
            } else {
              sendNotification({
                msg: err.response.data.message,
                variant: "error",
              });
            }
            props.close();
            if (err.response.data.message == "Contractor already invited") {
              setTimeout(() => {
                navigate("/onboarding?view=contractor");
              }, 1000);
            }
          }
        );
      } else {
        const contractorData = {
          full_name: fullName.trim(),
          country_code: countryCode,
          phone_number: phoneNumber,
          job_title: jobTitle.trim(),
          country_id: Country,
          states: state.trim(),
        };
        contractorSummary(contractorData, row_id).then(
          (res) => {
            const contractorData = {
              full_name: fullName.trim(),
              email: email,
              country_code: countryCode,
              phone_number: phoneNumber,
              job_title: jobTitle.trim(),
              country_id: Country,
              states: state.trim(),
            };

            localStorage.setItem(
              "ContractorData1",
              JSON.stringify(contractorData)
            );
            sendNotification({
              msg: res.data.message,
              variant: "success",
            });
            props.close();
          },
          (err) => {
            var errors = Error(err.response.data.message);

            if (Array.isArray(errors)) {
              errors.forEach((element) => {
                sendNotification({
                  msg: `Please enter valid ${element}`,
                  variant: "error",
                });
              });
            } else {
              sendNotification({
                msg: err.response.data.message,
                variant: "error",
              });
            }
            props.close();
            if (err.response.data.message == "Contractor already invited") {
              setTimeout(() => {
                navigate("/onboarding?view=contractor");
              }, 1000);
            }
          }
        );
      }
    } else {
      if (fullName.trim() === "") {
        sendNotification({
          msg: "Please Enter Valid Name!",
          variant: "error",
        });
      } else if (jobTitle.trim() === "") {
        sendNotification({
          msg: "Please Enter Valid Job Title!",
          variant: "error",
        });
      } else if (state.trim() === "") {
        sendNotification({
          msg: "Please Valid State Name!",
          variant: "error",
        });
      } else if (phoneNumber.length !== 10) {
        sendNotification({
          msg: "Please select valid phone number.",
          variant: "error",
        });
      }
    }
  };

  function restrictAlphabets(e) {
    var x = e.which || e.keycode;
    if (x >= 48 && x <= 57) {
      return true;
    } else {
      e.preventDefault();
    }
  }
  return (
    <>
      <Modal
        onBackdropClick={props.close}
        open={props.modalOpen}
        onClose={props.close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="add_hire_modal new_request_modal"
      >
        <Slide in={props.modalOpen}>
          <Box className="add_hire_modal_bx">
            <div className="modal_header">
              <Grid container>
                <Grid item>
                  <Typography id="modal-modal-title" variant="h6">
                    Update Contractor Summary.
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton onClick={props.close} className="icon_close_bttn">
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </div>
            <form onSubmit={(e) => putContractor(e)}>
              <div className="modal_body">
                <div className="register_form">
                  <Grid>
                    <TextField
                      required
                      fullWidth
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                      id="fullName"
                      label="Full Name"
                      helperText="Full employee name as it appears on identification document"
                      variant="standard"
                      inputProps={{ autoComplete: "off", maxLength: 128 }}
                    />
                  </Grid>
                  <Grid>
                    <TextField
                      fullWidth
                      disabled
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      id="email"
                      type="email"
                      label="Email"
                      helperText="E.g. jane@gmail.com"
                      variant="standard"
                      inputProps={{ autoComplete: "off", maxLength: 128 }}
                    />
                  </Grid>
                  <Grid
                    container
                    flexDirection={{ xs: "row", sm: "row" }}
                    className="phone_input_group"
                  >
                    <Grid item xs={12}>
                      <FormLabel required>Phone Number</FormLabel>
                    </Grid>
                    <Grid item xs={3}>
                      <Autocomplete
                        id="country-code"
                        options={countries}
                        defaultValue={countries[index]}
                        autoHighlight
                        onChange={(event, newValue) => {
                          setValue(newValue.phone);
                        }}
                        inputValue={countryCode}
                        onInputChange={(event, newInputValue) => {
                          setCountryCode(newInputValue);
                        }}
                        getOptionLabel={(option) => " +" + option.phone}
                        filterOptions={(options, { inputValue }) =>
                          options.filter((option) =>
                            (option.label + " +" + option.phone)
                              .toLowerCase()
                              .includes(inputValue.toLowerCase())
                          )
                        }
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            <img
                              loading="lazy"
                              width="20"
                              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                              alt=""
                            />
                            {option.label} +{option.phone}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            required
                            {...params}
                            label={false}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "off",
                            }}
                            variant="standard"
                          />
                        )}
                        className="autocomplete"
                      />
                    </Grid>
                    <Grid item xs={9}>
                      <TextField
                        required
                        fullWidth
                        type="text"
                        placeholder="9999999999"
                        variant="standard"
                        value={phoneNumber}
                        onKeyPress={(e) => restrictAlphabets(e)}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        inputProps={{
                          maxvalue: 9999999999,
                          required: "required",
                          maxLength: 10,
                          max: "9999999999",
                          autoComplete: "off",
                          pattern: "[0-9]{10}",
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid xs={12}>
                    <TextField
                      required
                      fullWidth
                      label="Job title"
                      variant="standard"
                      helperText="E.g. product designer"
                      value={jobTitle}
                      onChange={(e) => setJobTitle(e.target.value)}
                      id="email"
                      inputProps={{ maxLength: 128, autoComplete: "off" }}
                    />
                  </Grid>
                  <Grid xs={12}>
                    <InputLabel id="demo-simple-select-helper-label">
                      Country
                    </InputLabel>
                    <Select
                      required
                      fullWidth
                      variant="standard"
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={Country}
                      label="Country"
                      onChange={(e) => setCountry(e.target.value)}
                    >
                      {countryArray.map((data) => (
                        <MenuItem key={data.row_id} value={data.row_id}>
                          {data.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      Where does your new contractor live?
                    </FormHelperText>
                  </Grid>
                  <Grid xs={12}>
                    <TextField
                      required
                      fullWidth
                      label="State"
                      variant="standard"
                      helperText="Which state your new contractor live?"
                      value={state}
                      onChange={(e) => setState(e.target.value)}
                      id="state"
                      inputProps={{ maxLength: 128, autoComplete: "off" }}
                    />
                  </Grid>
                </div>
              </div>

              <div className="modal_footer">
                {/* <a href="https://api.inrole.dev/media/pqr.jpeg" _download>
            Download File.
          </a> */}
                <Grid container className="modal_foot_bttn_container">
                  <Button className="save_bttn" type="submit">
                    Update Details
                  </Button>
                  <Button className="cancel_bttn" onClick={props.close}>
                    Cancel
                  </Button>
                </Grid>
              </div>
            </form>
          </Box>
        </Slide>
      </Modal>
    </>
  );
};
