import { postApiWithFormData, getApi, postApi, putApi } from "./commonservice";

export function getCommonSettings() {
  return getApi("common-settings-list");
}

export function postEmployee(data) {
  return postApi("employee", data);
}

export function updateEmployee(data, id) {
  return putApi("employee/" + id, data);
}

export function getExperiences() {
  return getApi("common-settings-list");
}

export function getStates() {
  return getApi("states");
}

export function getCountries() {
  return getApi("countries");
}

export function getbenifit() {
  return getApi("benefits");
}

export function createPricingPlanForEmployee(data) {
  return postApi("employee-pricing", data);
}

export function getCurrencies(data) {
  if (!data) {
    return getApi("currency_conversion?value=" + 0);
  } else {
    return getApi("currency_conversion?value=" + data);
  }
}

export function empGetById(data) {
  return getApi("employee/" + data);
}

export function emponboarding(data, rowdata) {
  return putApi("employee-onboarding/" + rowdata, data);
}

export function getEmployeeData(id) {
  return getApi("employee/" + id);
}

export function submitAddress(data) {
  return postApi("employee-addresses", data);
}

export function emergencyContact(data) {
  return postApi("employee-emergency-contact", data);
}

export function employeedocs(data) {
  return postApiWithFormData("employee-document", data);
}

export function getAllEmployee(
  page = 1,
  rowPerPage = 5,
  searchData = "",
  status = "active",
  employeeStatus = "employee_status"
) {
  if (status !== "all") {
    return getApi(
      `teams?page=${page}&per_page=${rowPerPage}&search=${searchData}&filter_by=${employeeStatus}&filter_val=${status}`
    );
  } else {
    return getApi(
      `teams?page=${page}&per_page=${rowPerPage}&search=${searchData}`
    );
  }
}

export function addContractor(data) {
  return postApi("contractor", data);
}
export function contractorSummary(data, id) {
  return putApi("contractor-summary/" + id, data);
}
export function getContractOption() {
  return getApi("common-settings-list");
}

export function updateContractor(data, id) {
  return putApi("contractor/" + id, data);
}

export function getAllContractor(
  page = 1,
  rowPerPage = 5,
  searchData = "",
  status = "active",
  contractorStatus = "contractor_status"
) {
  if (status !== "all") {
    return getApi(
      `teams-contractor?page=${page}&per_page=${rowPerPage}&search=${searchData}&filter_by=${contractorStatus}&filter_val=${status}`
    );
  } else {
    return getApi(
      `teams-contractor?page=${page}&per_page=${rowPerPage}&search=${searchData}`
    );
  }
}

export function getContractorByIdData(id) {
  return getApi("contractor/" + id);
}

export function putEmployeeData(empdata, id) {
  return putApi("employee-summary/" + id, empdata);
}

export function empInvite(data, id) {
  return putApi("employee-invite/" + id, data);
}

export function isDraftContractor(data, id) {
  return putApi("contractor-invite/" + id, data);
}

export function getEmpContract(id) {
  return getApi("employee-contract/" + id);
}

export function activateEmployee(id) {
  return putApi("employee-activate/" + id);
}

export function deactivateEmployee(id) {
  return putApi("employee-deactivate/" + id);
}

export function getEmployeeAddress(id) {
  return getApi("employee-addresses/" + id);
}

export function getEmployeeEmergencyContact(id) {
  return getApi("employee-emergency-contact/" + id);
}

export function getemployeeProfile() {
  return getApi("employee-profile");
}

export function getContractor(id) {
  return getApi("contractor/" + id);
}
export function activateContractor(id) {
  return putApi("contractor-activate/" + id);
}
export function deactivateContractor(id) {
  return putApi("contractor-deactivate/" + id);
}

export function getContractorData(id) {
  return getApi("contractor-profile");
}

export function contractorOnboarding(data, id) {
  return putApi("contractor-onboarding/" + id, data);
}

export function addContractorAddress(data) {
  return postApi("contractor-address", data);
}

export function addContractorEmergencyContact(data) {
  return postApi("contractor-emergency-contact", data);
}

export function contractorDocument(data) {
  return postApiWithFormData("contractor-document", data);
}

export function getContractorAddress(id) {
  return getApi("contractor-addresses/" + id);
}

export function getContractorEmergencyContact(id) {
  return getApi("contractor-emergency-contact/" + id);
}

export function getStatus() {
  return getApi("emp-status");
}
export function employeeSelfEnrollment() {
  return putApi("employee-self-enrollment");
}
export function contractorSelfEnrollment() {
  return putApi("contractor-self-enrollment");
}

export function getPricingPlan() {
  var companyId = localStorage.getItem("CompanyRowID");
  return getApi("pricing_plan/" + companyId);
}

export function getPricingForEmployee(id) {
  var companyId = localStorage.getItem("CompanyRowID");
  return getApi(`employee-pricing?company_id=${companyId}&emp_id=${id}`);
}

export function putPricingForEmployee(data, id) {
  var companyId = localStorage.getItem("CompanyRowID");
  return putApi(`employee-pricing?company_id=${companyId}&emp_id=${id}`, data);
}

export function postContractorPlan(data) {
  return postApi("contractor-pricing", data);
}

export function getPricingForContractor(contractorId) {
  var companyId = localStorage.getItem("CompanyRowID");
  return getApi(
    `contractor-pricing?company_id=${companyId}&contractor_id=${contractorId}`
  );
}

export function putPricingForContractor(data, contractorId) {
  var companyId = localStorage.getItem("CompanyRowID");
  return putApi(
    `contractor-pricing?company_id=${companyId}&contractor_id=${contractorId}`,
    data
  );
}

export function editAgreementEmployee(employeeID) {
  return postApi(`emp-edit-agreement/${employeeID}`);
}

export function editAgreementContractor(contractorID) {
  return postApi(`contractor-edit-agreement/${contractorID}`);
}
