import { LinearProgress, Typography } from "@mui/material";
import { Button, Grid, IconButton } from "Utils/muiImport";
import { Box } from "@mui/system";
import { useNavigate } from "Components/AddEmployee/AddEmployeeFinal/AddEmployeeFinalImports";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  getContractor,
  getPricingForContractor,
  isDraftContractor,
} from "Services/AddEmployeeAndContractor";
import { getCountries } from "Services/Login&Registrationservice";

import { HelpButton } from "Components/CommonComponents/Bottom_Help_Button/HelpButton";
import {
  EditIcon,
  FinalPageEmployee_ContractorDetailsDoneIcon,
  PreviousArrowIcon,
} from "Icons/Icons";
import { Loader } from "Components/CommonComponents/Loader/Loader";
import useNotification from "Components/CommonComponents/AlertNotifications/AlertNotifications";
import { AddContractorSummuryUpdate } from "Components/CommonComponents/Modals/AddContractor Edit Modal/AddContractorSummuryUpdate";
import { AddContractorContractUpdate } from "Components/CommonComponents/Modals/AddContractor Edit Modal/AddContractorContractUpdate";
import { AddContractorSelectContract } from "Components/CommonComponents/Modals/AddContractor Edit Modal/AddContractorSelectContract";
import { AddContractorPricingUpdateModal } from "Components/CommonComponents/Modals/AddContractor Edit Modal/AddContractorPricingUpdateModal";
import { useSnackbar } from "notistack";

export const AddContractorFinal = () => {
  const navigate = useNavigate();
  const params = useParams();

  const { enqueueSnackbar } = useSnackbar();
  const [msg, sendNotification] = useNotification();

  const [progress, setProgress] = useState(100);
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [jobtitle, setJobtitle] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [contactNumber, setContactNumber] = useState("");

  const [agreementDate, setAgreementDate] = useState("");
  const [dateOfJoining, setDateOfJoining] = useState("");
  const [contractType, setContractType] = useState("");
  const [legalJurisdiction, setLegalJurisdiction] = useState("");
  const [state, setState] = useState("");
  const [periodOfContract, setPeriodOfContract] = useState("");
  const [roleDescription, setRoleDescription] = useState("");
  const [monthlyContractFees, setMonthlyContractFees] = useState("");

  const [CountryID, setCountryID] = useState("");
  const [legalJurisdictionCountryId, setLegalJurisdictionCountryId] =
    useState("");
  const [legalJuridictionStateID, setLegalJuridictionStateID] = useState("");
  const [contractTypeID, setContractTypeID] = useState("");

  const [summaryModalOpen, setSummaryModalOpen] = useState(false);
  const [contractModalOpen, setcontractModalOpen] = useState(false);
  const [contractSelectModal, setContractSelectModal] = useState(false);
  const [pricingModalOpen, setPricingModalOpen] = useState(false);

  const [billingCycle, setBillingCycle] = useState("");
  const [pricingPerEmployee, setPricingPerEmployee] = useState(0);
  const [currency, setCurrency] = useState("");

  const showLoading = () => {
    setLoading(true);
  };

  const hideLoading = () => {
    setLoading(false);
  };

  const goBack = () => {
    showLoading();
    var row_id = localStorage.getItem("ContractorRowData");
    var id = params.id;
    if (id || row_id) {
      localStorage.removeItem("ContractorData1");
      localStorage.removeItem("ContractorData2");
      localStorage.removeItem("ContractorData3");
      localStorage.removeItem("ContractorRowData");

      localStorage.removeItem("summuaryData");
      hideLoading();
      navigate("/onboarding?view=contractor");
    } else {
      navigate("/team");
    }
  };

  useEffect(() => {
    filldata();
  }, []);

  const filldata = () => {
    const contractordata1 = JSON.parse(localStorage.getItem("ContractorData1"));
    const contractordata2 = JSON.parse(localStorage.getItem("ContractorData2"));
    const contractordata3 = JSON.parse(localStorage.getItem("ContractorData3"));
    const contractordata4 = JSON.parse(localStorage.getItem("ContractorData4"));
    const Contract_options = JSON.parse(
      localStorage.getItem("Contract_options")
    );
    const id = params.id;
    if (!id) {
      if (contractordata1 != null) {
        setName(contractordata1.full_name);
        setEmail(contractordata1.email);
        setJobtitle(contractordata1.job_title);
        setCountryCode(contractordata1.country_code);
        setContactNumber(contractordata1.phone_number);

        setState(contractordata1.states);

        getCountries().then(
          (res) => {
            const country = res.data.filter((data) => {
              return data.row_id == contractordata1.country_id;
            });
            setLegalJurisdiction(country.length ? country[0].name : "");
            setCountryID(contractordata1.country_id);
          },
          (err) => {
            console.log(err.message);
          }
        );
      }
      if (contractordata2 != null) {
        var contractOption = JSON.parse(
          localStorage.getItem("Contract_options")
        );

        var option = contractOption.filter((value) => {
          return value.row_id === contractordata2.contract_option_id;
        });
        setContractType(option[0].value);
        setContractTypeID(contractordata2.contract_option_id);
      }

      if (contractordata3 != null) {
        setPeriodOfContract(contractordata3.period_of_contract);
        setMonthlyContractFees(contractordata3.monthly_contractor_fees);

        let contractType = Contract_options.filter((val) => {
          return val.row_id == contractordata2.contract_option_id;
        });
        setRoleDescription(contractordata3.role_description);
        setAgreementDate(contractordata3.agreement_date);
        setDateOfJoining(contractordata3.date_of_joining);
        setContractType(contractType.map((Data) => Data.value));
      }
      if (contractordata4 !== null) {
        setBillingCycle(contractordata4.type);
        setPricingPerEmployee(contractordata4.price);
        setCurrency(contractordata4.currency);
      }
    } else {
      getContractor(id)
        .then(
          (res) => {
            localStorage.removeItem("ContractorData1");
            localStorage.removeItem("ContractorData2");
            localStorage.removeItem("ContractorData3");
            localStorage.removeItem("ContractorRowData");

            localStorage.removeItem("summuaryData");
            const data = res.data;
            setName(data.full_name);
            setEmail(data.email);
            setJobtitle(data.job_title);
            setCountryCode(data.country_code);
            setContactNumber(data.phone_number);
            if (data.period_of_contract != null) {
              setPeriodOfContract(data.period_of_contract);
            } else {
              setPeriodOfContract("");
            }
            if (data.monthly_contractor_fees != null) {
              setMonthlyContractFees(data.monthly_contractor_fees);
            } else {
              setMonthlyContractFees("");
            }
            if (data.role_description != null) {
              setRoleDescription(data.role_description);
            } else {
              setRoleDescription("");
            }
            setCountryID(data.country_id.row_id);
            setLegalJurisdictionCountryId(data.country_id.name);
            setLegalJuridictionStateID(data.legal_jurisdiction_state);
            setLegalJurisdiction(data.country_id.name);
            setState(data.states);

            if (data.contract_option_id !== null) {
              setContractType(data.contract_option_id.Contract_options.value);
              setContractTypeID(
                data.contract_option_id.Contract_options.row_id
              );
            } else {
              setContractTypeID(null);
              setContractType("");
            }

            if (data.agreement_date != null) {
              setAgreementDate(data.agreement_date);
            } else {
              setAgreementDate("");
            }
            if (data.date_of_joining != null) {
              setDateOfJoining(data.date_of_joining);
            } else {
              setDateOfJoining("");
            }
            var contractordata2 = {
              contract_option_id:
                data.contract_option_id.Contract_options.row_id,
            };

            localStorage.setItem(
              "ContractorData2",
              JSON.stringify(contractordata2)
            );
          },
          (err) => {
            if (err.response.status === 403) {
              sendNotification({
                msg: err.response.data.message,
                variant: "error",
              });
              setTimeout(() => {
                navigate("/onboarding");
              }, 500);
            }
          }
        )
        .then(
          getPricingForContractor(id).then(
            (res) => {
              setBillingCycle(res.data.type);
              setPricingPerEmployee(res.data.price);
              setCurrency(res.data.currency);
            },
            (err) => {
              if (err.response.status === 403) {
                sendNotification({
                  msg: err.response.data.message,
                  variant: "error",
                });
                setTimeout(() => {
                  navigate("/onboarding");
                }, 500);
              }
            }
          )
        );
    }
  };

  const summaryNavigate = () => {
    const contractorData = {
      full_name: name,
      email: email.trim().toLowerCase(),
      country_code: countryCode,
      phone_number: contactNumber,
      job_title: jobtitle,
      country_id: CountryID,
      states: state,
    };
    localStorage.setItem("summuaryData", JSON.stringify(contractorData));
    setSummaryModalOpen(true);
  };

  const invite = () => {
    showLoading();
    var id = params.id;
    if (!id) {
      var data = JSON.parse(localStorage.getItem("ContractorData3"));
      var finalData = {
        ...data,
        is_draft: "False",
      };
      var rowdata = localStorage.getItem("ContractorRowData");
      isDraftContractor(finalData, rowdata).then(
        (res) => {
          var variant = "success";
          enqueueSnackbar(res.data.message, {
            variant,
          });
          localStorage.removeItem("ContractorData1");
          localStorage.removeItem("ContractorData2");
          localStorage.removeItem("ContractorData3");
          localStorage.removeItem("ContractorRowData");

          hideLoading();
          navigate("/onboarding?view=contractor");
        },
        (err) => {
          sendNotification({
            msg: err.response.data.message,
            variant: "error",
          });

          hideLoading();
        }
      );
    } else {
      var finalData = {
        is_draft: "False",
      };
      isDraftContractor(finalData, id).then(
        (res) => {
          var variant = "success";
          enqueueSnackbar(res.data.message, {
            variant,
          });
          localStorage.removeItem("ContractorData1");
          localStorage.removeItem("ContractorData2");
          localStorage.removeItem("ContractorData3");
          localStorage.removeItem("ContractorRowData");

          hideLoading();
          navigate("/onboarding?view=contractor");
        },
        (err) => {
          sendNotification({
            msg: err.response.data.message,
            variant: "error",
          });
          hideLoading();
        }
      );
    }
  };

  const saveDraft = () => {
    showLoading();
    var id = params.id;
    if (!id) {
      var data = JSON.parse(localStorage.getItem("ContractorData3"));
      var rowdata = localStorage.getItem("ContractorRowData");
      if (data == null) {
        var data = {
          is_draft: "True",
        };
        isDraftContractor(data, rowdata).then(
          (res) => {
            var variant = "success";
            enqueueSnackbar(res.data.message, {
              variant,
            });
            localStorage.removeItem("ContractorData1");
            localStorage.removeItem("ContractorData2");
            localStorage.removeItem("ContractorData3");
            localStorage.removeItem("ContractorRowData");

            navigate("/onboarding?view=contractor");
            hideLoading();
          },
          (err) => {
            sendNotification({
              msg: err.response.data.message,
              variant: "error",
            });
            hideLoading();
          }
        );
      } else {
        isDraftContractor(data, rowdata).then(
          (res) => {
            var variant = "success";
            enqueueSnackbar(res.data.message, {
              variant,
            });
            localStorage.removeItem("ContractorData1");
            localStorage.removeItem("ContractorData2");
            localStorage.removeItem("ContractorData3");
            localStorage.removeItem("ContractorRowData");

            navigate("/onboarding?view=contractor");
            hideLoading();
          },
          (err) => {
            sendNotification({
              msg: err.response.data.message,
              variant: "error",
            });
            hideLoading();
          }
        );
      }
    } else {
      var data = {
        is_draft: "True",
      };
      isDraftContractor(data, id).then(
        (res) => {
          var variant = "success";
          enqueueSnackbar(res.data.message, {
            variant,
          });
          localStorage.removeItem("ContractorData1");
          localStorage.removeItem("ContractorData2");
          localStorage.removeItem("ContractorData3");
          localStorage.removeItem("ContractorRowData");
          setTimeout(async () => {
            await navigate("/onboarding?view=contractor");
            hideLoading();
          }, 1500);
        },
        (err) => {
          sendNotification({
            msg: err.response.data.message,
            variant: "error",
          });
          hideLoading();
        }
      );
    }
  };

  return (
    <>
        <div className="login_cont register_cont">
          <Typography variant="h3">
            Ready to invite {name} to complete their onboarding?
          </Typography>
          <Typography variant="body2">
            If their details look good, we’ll craft their contract and invite
            them to begin the onboarding process. If you’d rather invite them
            later, you can save this draft and invite them from your Onboarding
            page.
          </Typography>
          <Grid container xs={12} className="login_bttn_wrapper">
            <Button
              className="theme_bttn"
              onClick={saveDraft}
              disabled={loading ? true : false}
            >
              Save Draft
            </Button>
            <Button
              className="theme_bttn fill"
              onClick={invite}
              disabled={loading ? true : false}
            >
              Invite Now
            </Button>
          </Grid>
          <Grid xs={12} className="all_employe_profile_bx">
            <Box className="employe_profile_bx with_shadow">
              <Grid
                container
                alignItems="center"
                justifyContent={"space-between"}
                className="employe_profile_head"
              >
                <Grid item>
                  <Typography>Summary</Typography>
                </Grid>
                <Grid item>
                  <Button
                    className="theme_bttn transparent"
                    onClick={summaryNavigate}
                    // onClick={() => setSummaryModalOpen(true)}
                  >
                    <EditIcon />
                    Edit
                  </Button>
                </Grid>
              </Grid>
              <div className="employe_profile_body">
                <Grid
                  xs={12}
                  container
                  alignItems="center"
                  flexDirection={{ xs: "row", sm: "row" }}
                  className="employe_personal_dtl"
                >
                  <Typography variant="body1">Full Name</Typography>
                  <Typography variant="body2">{name}</Typography>
                </Grid>
                <Grid
                  xs={12}
                  container
                  alignItems="center"
                  flexDirection={{ xs: "row", sm: "row" }}
                  className="employe_personal_dtl"
                >
                  <Typography variant="body1">Personal email</Typography>
                  <Typography variant="body2">{email}</Typography>
                </Grid>

                <Grid
                  xs={12}
                  container
                  alignItems="center"
                  flexDirection={{ xs: "row", sm: "row" }}
                  className="employe_personal_dtl"
                >
                  <Typography variant="body1">Job title</Typography>
                  <Typography variant="body2">{jobtitle}</Typography>
                </Grid>

                <Grid
                  xs={12}
                  container
                  alignItems="center"
                  flexDirection={{ xs: "row", sm: "row" }}
                  className="employe_personal_dtl"
                >
                  <Typography variant="body1">Contact Info</Typography>
                  <Typography variant="body2">
                    {countryCode}-{contactNumber}
                  </Typography>
                </Grid>
                <Grid
                  xs={12}
                  container
                  alignItems="center"
                  flexDirection={{ xs: "row", sm: "row" }}
                  className="employe_personal_dtl"
                >
                  <Typography variant="body1">State</Typography>
                  <Typography variant="body2">{state}</Typography>
                </Grid>

                <Grid
                  xs={12}
                  container
                  alignItems="center"
                  flexDirection={{ xs: "row", sm: "row" }}
                  className="employe_personal_dtl"
                >
                  <Typography variant="body1">Country</Typography>
                  <Typography variant="body2">{legalJurisdiction}</Typography>
                </Grid>
              </div>
            </Box>

            <Box className="employe_profile_bx with_shadow">
              <Grid
                container
                alignItems="center"
                justifyContent={"space-between"}
                className="employe_profile_head"
              >
                <Grid item>
                  <Typography>Contract Details</Typography>
                </Grid>
                <Grid item>
                  <Button
                    className="theme_bttn transparent"
                    // onClick={contractnavigate}
                    onClick={() =>
                      contractTypeID !== null
                        ? setcontractModalOpen(true)
                        : setContractSelectModal(true)
                    }
                    disabled={
                      contractTypeID == 13 || contractTypeID == null
                        ? false
                        : true
                    }
                  >
                    <EditIcon />
                    Edit
                  </Button>
                </Grid>
              </Grid>
              <div className="employe_profile_body">
                <Grid
                  xs={12}
                  container
                  alignItems="center"
                  flexDirection={{ xs: "row", sm: "row" }}
                  className="employe_personal_dtl"
                >
                  <Typography variant="body1">Period Of Contract</Typography>
                  <Typography variant="body2">
                    {periodOfContract ? periodOfContract + "  months" : "N/A"}
                  </Typography>
                </Grid>

                <Grid
                  xs={12}
                  container
                  alignItems="center"
                  flexDirection={{ xs: "row", sm: "row" }}
                  className="employe_personal_dtl"
                >
                  <Typography variant="body1">Monthly Contract fees</Typography>
                  <Typography variant="body2">
                    {monthlyContractFees ? "$ " + monthlyContractFees : "N/A"}
                  </Typography>
                </Grid>

                <Grid
                  xs={12}
                  container
                  alignItems="center"
                  flexDirection={{ xs: "row", sm: "row" }}
                  className="employe_personal_dtl"
                >
                  <Typography variant="body1">Contract Type</Typography>
                  <Typography variant="body2">
                    {contractType ? contractType : "N/A"}
                  </Typography>
                </Grid>

                <Grid
                  xs={12}
                  container
                  alignItems="center"
                  flexDirection={{ xs: "row", sm: "row" }}
                  className="employe_personal_dtl"
                >
                  <Typography variant="body1">Agreement Date</Typography>
                  <Typography variant="body2">
                    {agreementDate ? agreementDate : "N/A"}
                  </Typography>
                </Grid>

                <Grid
                  xs={12}
                  container
                  alignItems="center"
                  flexDirection={{ xs: "row", sm: "row" }}
                  className="employe_personal_dtl"
                >
                  <Typography variant="body1">Joining Date</Typography>
                  <Typography variant="body2">
                    {dateOfJoining ? dateOfJoining : "N/A"}
                  </Typography>
                </Grid>

                <Grid
                  xs={12}
                  container
                  alignItems="center"
                  flexDirection={{ xs: "row", sm: "row" }}
                  className="employe_personal_dtl"
                >
                  <Typography variant="body1">Role Type</Typography>
                  <Typography variant="body2">
                    {roleDescription ? roleDescription : "N/A"}
                  </Typography>
                </Grid>
              </div>
            </Box>

            <Box className="employe_profile_bx with_shadow">
              <Grid
                container
                alignItems="center"
                justifyContent={"space-between"}
                className="employe_profile_head"
              >
                <Grid item>
                  <Typography>Pricing plan</Typography>
                </Grid>
                <Grid item>
                  <Button
                    className="theme_bttn transparent"
                    onClick={() => setPricingModalOpen(true)}
                  >
                    <EditIcon />
                    Edit
                  </Button>
                </Grid>
              </Grid>
              <div className="employe_profile_body">
                <Grid
                  xs={12}
                  container
                  alignItems="center"
                  flexDirection={{ xs: "row", sm: "row" }}
                  className="employe_personal_dtl"
                >
                  <Typography variant="body1">Pricing per employee</Typography>
                  <Typography variant="body2">
                    ${pricingPerEmployee} {currency}
                  </Typography>
                </Grid>

                <Grid
                  xs={12}
                  container
                  alignItems="center"
                  flexDirection={{ xs: "row", sm: "row" }}
                  className="employe_personal_dtl"
                >
                  <Typography variant="body1">Billing cycle</Typography>
                  <Typography variant="body2">{billingCycle}</Typography>
                </Grid>

                <Grid
                  xs={12}
                  container
                  alignItems="center"
                  flexDirection={{ xs: "row", sm: "row" }}
                  className="employe_personal_dtl"
                >
                  <Typography variant="body1">Total annual price</Typography>
                  <Typography variant="body2">
                    $
                    {billingCycle === "billed-annually"
                      ? pricingPerEmployee
                      : pricingPerEmployee * 12}{" "}
                    {" " + currency}
                  </Typography>
                </Grid>

                <Grid xs={12} className="employe_personal_dtl_para">
                  <Typography variant="body2">
                    In addition to InRole’s management fee, you will also be
                    invoiced for your employee’s salary, benefits, local taxes,
                    and any other local employment fees. Learn more about
                    pricing, or explore the cost calculator for your new
                    employee.
                  </Typography>
                </Grid>
              </div>
            </Box>
          </Grid>
          <Grid container className="login_bttn_wrapper">
            <Button
              className="theme_bttn"
              onClick={saveDraft}
              disabled={loading ? true : false}
            >
              Save Draft
            </Button>
            <Button
              className="theme_bttn fill"
              onClick={invite}
              disabled={loading ? true : false}
            >
              Invite Now
            </Button>
          </Grid>
        </div>

      <HelpButton />
      <AddContractorSummuryUpdate
        modalOpen={summaryModalOpen}
        close={() => {
          setSummaryModalOpen(false);
          localStorage.removeItem("summuaryData");
          filldata();
        }}
        fullname={name}
        email={email}
        jobtitle={jobtitle}
        countryCode={countryCode}
        phonenumber={contactNumber}
        state={state}
        country={legalJurisdiction}
      />
      <AddContractorContractUpdate
        modalOpen={contractModalOpen}
        close={() => {
          setcontractModalOpen(false);
          // localStorage.removeItem("contractData");
          filldata();
        }}
        dateOfJoining={dateOfJoining}
        contractType={contractType}
        periodOfContract={periodOfContract}
        monthlyContractFees={monthlyContractFees}
        roleDescription={roleDescription}
        agreementDate={agreementDate}
      />
      <AddContractorSelectContract
        modalOpen={contractSelectModal}
        close={() => {
          setContractSelectModal(false);
          filldata();
        }}
      />
      <AddContractorPricingUpdateModal
        modalOpen={pricingModalOpen}
        billingCycle={billingCycle}
        close={() => setPricingModalOpen(false)}
        currencyType={currency}
        summuryAPI={() => filldata()}
      />
      <Loader open={loading} />
    </>
  );
};
