import { getApi, postApi, putApi } from "./commonservice";

export function getCompanyData(id) {
  return getApi("company/" + id);
}

export function getCompanyStates() {
  return getApi("company-stats/" + localStorage.getItem("CompanyRowID"));
}

export function getCompanyAddress(id) {
  return getApi("company-addresses/" + id);
}

export function getBankdetails(id) {
  return getApi("company-bank-details/" + id);
}

export function updateBankDetails(data, id) {
  return putApi("company-bank-details/" + id, data);
}

export function updateAddressDetails(data, id) {
  return putApi("company-addresses/" + id, data);
}

export function companyUser(data) {
  return postApi("company-user", data);
}

export function companyUserListing(
  id,
  page = 1,
  rowPerPage = 5,
  searchData = ""
) {
  return getApi(
    `company-users/${id}?page=${page}&per_page=${rowPerPage}&search=${searchData}`
  );
}

export function Roles() {
  return getApi("roles?type=company");
}

export function getDocumentTypes() {
  return getApi("company-doc-types");
}
