import React, { useState } from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { NotGuarded, Guarded } from "./PrivateRoute";
import NotFound from "./NotFound";

import { Login } from "Components/Login/Login";

import { RegisterSideBar } from "Components/Register/RegisterSideBar/RegisterSideBar";
import { Register2 } from "Components/Register/Register2/Register2";

import { RegisterEmailVerified } from "Components/Register/Register Email Verified/RegisterEmailVerified";

import { Sidebar } from "Components/CommonComponents/SideBar/Sidebar";

import { EmpRegister1 } from "Components/Employee-Contractor Login&Registration/Employee-Contractor Registration 1/EmpRegister1";
import { EmpRegister2 } from "Components/Employee-Contractor Login&Registration/Employee-Contractor Registration 2/EmpRegister2";
import { EmpRegister3 } from "Components/Employee-Contractor Login&Registration/Employee-Contractor Registration 3/EmpRegister3";
import { EmpRegister4 } from "Components/Employee-Contractor Login&Registration/Employee-Contractor Registration 4/EmpRegister4";
import { EmpRegister5 } from "Components/Employee-Contractor Login&Registration/Employee-Contractor Registration 5/EmpRegister5";
import { EmpWelcome } from "Components/Employee-Contractor Login&Registration/Employee-Contractor Registration Welcome/EmpWelcome";

import { Loader } from "Components/CommonComponents/Loader/Loader";

import { login } from "Services/Login&Registrationservice";
import { getCompanyData } from "Services/CompanyServices";
import { getCommonSettings } from "Services/AddEmployeeAndContractor";

import jwtDecode from "jwt-decode";
import { AddEmployeeSideBar } from "Components/AddEmployee/AddEmployeeSideBar/AddEmployeeSideBar";
import { AddContractorSideBar } from "Components/AddContractor/AddContractorSideBar/AddContractorSIdeBar";

export const RouterConfig = (props) => {
  var audience = process.env.REACT_APP_AUTH0_AUDIENCE;
  var customURL = process.env.REACT_APP_AUTH0_CUSTOM_URL;

  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [auth0domain, setAuth0domain] = useState("");

  const { user, isAuthenticated, isLoading, logout, getAccessTokenSilently } =
    useAuth0();

  const showLoading = () => {
    setLoading(true);
  };

  const hideLoading = () => {
    setLoading(false);
  };

  if (isLoading) {
    return (
      <>
        <Loader open={isLoading} />
      </>
    );
  }

  const getUserMetadata = async () => {
    try {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: audience,
          scope: "read:current_user",
        },
      });
      const userDetailsByIdUrl = `https://${customURL}/api/v2/users/${user.sub}`;
      const metadataResponse = await fetch(userDetailsByIdUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return metadataResponse;
    } catch (e) {
      console.log(e.message);
      hideLoading();
    }
  };

  const loginAPI = async () => {
    showLoading();

    setAuth0domain(props.auth0domain);
    try {
      if (isAuthenticated) {
        var data = await getUserMetadata();
        var metaData = await data.json();
        var email = { email: metaData.email };

        await login(email)
          .then(
            (res) => {
              localStorage.setItem(
                "JWT",
                JSON.stringify({ access_token: res.data.access_token })
              );
              var decodedToken = jwtDecode(res.data.access_token);
              const accountType = decodedToken.account_type;
              const isupdated = res.data.user_profile_updated;
              localStorage.setItem("Account_Type", accountType);
              localStorage.setItem("IsEmailVerified", metaData.email_verified);
              localStorage.setItem("Login_Status", isAuthenticated);
              localStorage.setItem("isAuth", true);

              if (accountType === "company") {
                getCompanyData(decodedToken.row_id).then(
                  (res) => {
                    // console.log(res);
                    localStorage.setItem("CompanyRowID", res.data.row_id);
                    localStorage.setItem(
                      "accountName",
                      res.data.current_user.full_name
                    );
                    localStorage.setItem(
                      "NameOfCompany",
                      res.data.company_name
                    );
                  },
                  (err) => {
                    console.log(err.message);
                  }
                );
                localStorage.setItem(
                  "IsEmailVerified",
                  metaData.email_verified
                );
                if (res.data.user_profile_updated && metaData.email_verified) {
                  hideLoading();
                  navigate("/home");
                } else if (!metaData.email_verified) {
                  hideLoading();
                  navigate("/emailverification");
                } else {
                  hideLoading();
                  navigate("/register");
                }
              } else if (
                (!isupdated && accountType === "employee") ||
                (!isupdated && accountType === "contractor")
              ) {
                if (!metaData.email_verified) {
                  hideLoading();
                  navigate("/emailverification");
                } else {
                  hideLoading();
                  navigate("/employeeregister");
                }
              }
            },
            (err) => {
              hideLoading();
              console.log(err.message);
            }
          )
          .then(
            getCommonSettings().then(
              (res) => {
                res.data.map((obj) => {
                  let keys = Object.keys(obj)[0];
                  localStorage.setItem(keys, JSON.stringify(obj[keys]));
                  return { keys };
                });
              },
              (err) => {
                console.log(err.message);
              }
            )
          );
      } else {
        navigate("/login");
        hideLoading();
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  const unAuthorized = (Status) => {
    if (Status === 401) {
      logout(
        {
          logoutParams: {
            returnTo: window.location.origin,
          },
        },
        localStorage.clear()
      );
    }
  };

  return (
    <>
      {document.body.classList.remove("no-scroll")}
      <Routes>
        {/* Login Routes */}

        <>
          (
          <>
            <Route
              path="/login"
              element={
                <NotGuarded isAuthenticated={isAuthenticated}>
                  <Login
                    loading={loading}
                    loginAPI={(e) => loginAPI()}
                    isAuthenticated={isAuthenticated}
                  />
                </NotGuarded>
              }
            />
            <Route
              path="/"
              element={
                <NotGuarded isAuthenticated={isAuthenticated}>
                  <Login
                    loginAPI={(e) => loginAPI()}
                    isAuthenticated={isAuthenticated}
                  />
                </NotGuarded>
              }
            />
          </>
          )
        </>

        {/* Register Routes */}
        {isAuthenticated && (
          <>
            <Route
              path="/emailverification"
              element={
                <Guarded isAuthenticated={isAuthenticated}>
                  <Register2 unAuthorized={(status) => unAuthorized(status)} />
                </Guarded>
              }
            />
            <Route
              path="/emailverified"
              element={
                <Guarded
                  isAuthenticated={isAuthenticated}
                  auth0domain={auth0domain}
                  loginAPI={(e) => loginAPI()}
                >
                  <RegisterEmailVerified
                    auth0domain={auth0domain}
                    loginAPI={(e) => loginAPI()}
                    unAuthorized={(status) => unAuthorized(status)}
                  />
                </Guarded>
              }
            />
            <Route
              exact
              path="/register"
              element={
                <Guarded isAuthenticated={isAuthenticated}>
                  <RegisterSideBar
                    register1={"active"}
                    unAuthorized={(status) => unAuthorized(status)}
                  />
                </Guarded>
              }
            />
            <Route
              path="/register/3"
              element={
                <Guarded isAuthenticated={isAuthenticated}>
                  <RegisterSideBar
                    register2={"active"}
                    unAuthorized={(status) => unAuthorized(status)}
                  />
                </Guarded>
              }
            />
            <Route
              path="/register/4"
              element={
                <Guarded isAuthenticated={isAuthenticated}>
                  <RegisterSideBar
                    register3={"active"}
                    unAuthorized={(status) => unAuthorized(status)}
                  />
                </Guarded>
              }
            />
            <Route
              path="/register/5"
              element={
                <Guarded isAuthenticated={isAuthenticated}>
                  <RegisterSideBar
                    loginAPI={() => loginAPI()}
                    register4={"active"}
                    unAuthorized={(status) => unAuthorized(status)}
                  />
                </Guarded>
              }
            />
            <Route
              path="/register/6"
              element={
                <Guarded isAuthenticated={isAuthenticated}>
                  <RegisterSideBar
                    register5={"active"}
                    unAuthorized={(status) => unAuthorized(status)}
                  />
                </Guarded>
              }
            />
            {/* Dashboard Routes */}
            <Route
              path="/home"
              element={
                <Guarded isAuthenticated={isAuthenticated}>
                  <Sidebar
                    Home={"active"}
                    unAuthorized={(status) => unAuthorized(status)}
                    loginAPI={(e) => loginAPI()}
                  />
                </Guarded>
              }
            />
            {/* Team Routes */}
            <Route
              exact
              path="/team"
              element={
                <Guarded isAuthenticated={isAuthenticated}>
                  <Sidebar
                    loginAPI={(e) => loginAPI()}
                    Team={"active"}
                    unAuthorized={(status) => unAuthorized(status)}
                  />
                </Guarded>
              }
            />
            <Route
              path={"/team/employeeid"}
              element={
                <Guarded isAuthenticated={isAuthenticated}>
                  <Sidebar
                    loginAPI={(e) => loginAPI()}
                    employeedetails={"active"}
                    unAuthorized={(status) => unAuthorized(status)}
                  />
                </Guarded>
              }
            />
            {/* Onboarding Routes */}
            <Route
              path="/onboarding"
              element={
                <Guarded isAuthenticated={isAuthenticated}>
                  <Sidebar
                    loginAPI={(e) => loginAPI()}
                    onboarding={"active"}
                    unAuthorized={(status) => unAuthorized(status)}
                  />
                </Guarded>
              }
            />
            {/* Employee Add Routes */}
            <Route
              exact
              path="/addemployee"
              element={
                <Guarded isAuthenticated={isAuthenticated}>
                  <AddEmployeeSideBar
                    addemployee1={"active"}
                    unAuthorized={(status) => unAuthorized(status)}
                  />
                </Guarded>
              }
            />
            <Route
              path="/addemployee/:id"
              element={
                <Guarded isAuthenticated={isAuthenticated}>
                  <AddEmployeeSideBar
                    addemployee1={"active"}
                    unAuthorized={(status) => unAuthorized(status)}
                  />
                </Guarded>
              }
            />
            <Route
              exact
              path="/addemployee/2"
              element={
                <Guarded isAuthenticated={isAuthenticated}>
                  <AddEmployeeSideBar
                    addemployee2={"active"}
                    unAuthorized={(status) => unAuthorized(status)}
                  />
                </Guarded>
              }
            />
            <Route
              path="/addemployee/2/:id"
              element={
                <Guarded isAuthenticated={isAuthenticated}>
                  <AddEmployeeSideBar
                    addemployee2={"active"}
                    unAuthorized={(status) => unAuthorized(status)}
                  />
                </Guarded>
              }
            />

            <Route
              path="/addemployeepricingplan"
              element={
                <Guarded isAuthenticated={isAuthenticated}>
                  <AddEmployeeSideBar
                    addemployee3={"active"}
                    unAuthorized={(status) => unAuthorized(status)}
                  />
                </Guarded>
              }
            />
            <Route
              path="/addemployeepricingplan/:id"
              element={
                <Guarded isAuthenticated={isAuthenticated}>
                  <AddEmployeeSideBar
                    addemployee3={"active"}
                    unAuthorized={(status) => unAuthorized(status)}
                  />
                </Guarded>
              }
            />
            <Route
              exact
              path="/addemployeefinal"
              element={
                <Guarded isAuthenticated={isAuthenticated}>
                  <AddEmployeeSideBar
                    addemployee4={"active"}
                    unAuthorized={(status) => unAuthorized(status)}
                  />
                </Guarded>
              }
            />
            <Route
              path="/addemployeefinal/:id"
              element={
                <Guarded isAuthenticated={isAuthenticated}>
                  <AddEmployeeSideBar
                    addemployee4={"active"}
                    unAuthorized={(status) => unAuthorized(status)}
                  />
                </Guarded>
              }
            />
            {/* Contractor Add Routes */}
            <Route
              path="/addcontractor"
              element={
                <Guarded isAuthenticated={isAuthenticated}>
                  <AddContractorSideBar
                    addContractor1
                    unAuthorized={(status) => unAuthorized(status)}
                  />
                </Guarded>
              }
            />
            <Route
              path="/addcontractor/:id"
              element={
                <Guarded isAuthenticated={isAuthenticated}>
                  <AddContractorSideBar
                    addContractor1
                    unAuthorized={(status) => unAuthorized(status)}
                  />
                </Guarded>
              }
            />
            <Route
              path="/addcontractor/2"
              element={
                <Guarded isAuthenticated={isAuthenticated}>
                  <AddContractorSideBar
                    addContractor2
                    unAuthorized={(status) => unAuthorized(status)}
                  />
                </Guarded>
              }
            />
            <Route
              path="/addcontractor/2/:id"
              element={
                <Guarded isAuthenticated={isAuthenticated}>
                  <AddContractorSideBar
                    addContractor2
                    unAuthorized={(status) => unAuthorized(status)}
                  />
                </Guarded>
              }
            />
            <Route
              path="/addcontractor/3"
              element={
                <Guarded isAuthenticated={isAuthenticated}>
                  <AddContractorSideBar
                    addContractor3
                    unAuthorized={(status) => unAuthorized(status)}
                  />
                </Guarded>
              }
            />
            <Route
              path="/addcontractor/3/:id"
              element={
                <Guarded isAuthenticated={isAuthenticated}>
                  <AddContractorSideBar
                    addContractor3
                    unAuthorized={(status) => unAuthorized(status)}
                  />
                </Guarded>
              }
            />
            <Route
              path="/addcontractorpricingplan"
              element={
                <Guarded isAuthenticated={isAuthenticated}>
                  <AddContractorSideBar
                    addContractor4
                    unAuthorized={(status) => unAuthorized(status)}
                  />
                </Guarded>
              }
            />
            <Route
              path="/addcontractorpricingplan/:id"
              element={
                <Guarded isAuthenticated={isAuthenticated}>
                  <AddContractorSideBar
                    addContractor4
                    unAuthorized={(status) => unAuthorized(status)}
                  />
                </Guarded>
              }
            />
            <Route
              path={"/team/contractorid"}
              element={
                <Guarded isAuthenticated={isAuthenticated}>
                  <Sidebar
                    loginAPI={(e) => loginAPI()}
                    contractordetails={"active"}
                    unAuthorized={(status) => unAuthorized(status)}
                  />
                </Guarded>
              }
            />
            <Route
              exact
              path="/addcontractorfinal"
              element={
                <Guarded isAuthenticated={isAuthenticated}>
                  <AddContractorSideBar
                    addContractor5
                    unAuthorized={(status) => unAuthorized(status)}
                  />
                </Guarded>
              }
            />
            <Route
              exact
              path="/addcontractorfinal/:id"
              element={
                <Guarded isAuthenticated={isAuthenticated}>
                  <AddContractorSideBar
                    addContractor5
                    unAuthorized={(status) => unAuthorized(status)}
                  />
                </Guarded>
              }
            />
            {/* Employee Registration Route */}
            <Route
              path="/employeeregister"
              element={
                <Guarded isAuthenticated={isAuthenticated}>
                  <EmpRegister1
                    unAuthorized={(status) => unAuthorized(status)}
                  />
                </Guarded>
              }
            />
            <Route
              path="/emppersonalprofile"
              element={
                <Guarded isAuthenticated={isAuthenticated}>
                  <EmpRegister2
                    unAuthorized={(status) => unAuthorized(status)}
                  />
                </Guarded>
              }
            />
            <Route
              path="/emphomeaddress"
              element={
                <Guarded isAuthenticated={isAuthenticated}>
                  <EmpRegister3
                    unAuthorized={(status) => unAuthorized(status)}
                  />
                </Guarded>
              }
            />
            <Route
              path="/empemergencycontact"
              element={
                <Guarded isAuthenticated={isAuthenticated}>
                  <EmpRegister4
                    unAuthorized={(status) => unAuthorized(status)}
                  />
                </Guarded>
              }
            />
            <Route
              path="/empidentityverification"
              element={
                <Guarded isAuthenticated={isAuthenticated}>
                  <EmpRegister5
                    unAuthorized={(status) => unAuthorized(status)}
                  />
                </Guarded>
              }
            />
            <Route
              path="/empwelcome"
              element={
                <Guarded isAuthenticated={isAuthenticated}>
                  <EmpWelcome unAuthorized={(status) => unAuthorized(status)} />
                </Guarded>
              }
            />
            {/* Incentives Routes */}
            <Route
              path="/incentives"
              element={
                <Guarded isAuthenticated={isAuthenticated}>
                  <Sidebar
                    loginAPI={(e) => loginAPI()}
                    incentives={"active"}
                    unAuthorized={(status) => unAuthorized(status)}
                  />
                </Guarded>
              }
            />
            {/*Requests Routes*/}
            <Route
              path="/requests"
              element={
                <Guarded isAuthenticated={isAuthenticated}>
                  <Sidebar
                    loginAPI={(e) => loginAPI()}
                    requests={"active"}
                    unAuthorized={(status) => unAuthorized(status)}
                  />
                </Guarded>
              }
            />
            {/* Billing Routes */}
            <Route
              path="billing"
              element={
                <Guarded isAuthenticated={isAuthenticated}>
                  <Sidebar
                    loginAPI={(e) => loginAPI()}
                    billing={"active"}
                    unAuthorized={(status) => unAuthorized(status)}
                  />
                </Guarded>
              }
            />
            {/* companysettings Routes */}
            <Route
              path="/companysettings"
              element={
                <Guarded isAuthenticated={isAuthenticated}>
                  <Sidebar
                    loginAPI={(e) => loginAPI()}
                    companysettings={"active"}
                    unAuthorized={(status) => unAuthorized(status)}
                  />
                </Guarded>
              }
            />
            {/* Documents Route */}

            <Route
              path="/employeedocuments"
              element={
                <Guarded isAuthenticated={isAuthenticated}>
                  <Sidebar
                    loginAPI={(e) => loginAPI()}
                    document={"active"}
                    unAuthorized={(status) => unAuthorized(status)}
                  />
                </Guarded>
              }
            />
            <Route
              path="/companydocuments"
              element={
                <Guarded isAuthenticated={isAuthenticated}>
                  <Sidebar
                    loginAPI={(e) => loginAPI()}
                    companyDocuments={"active"}
                    unAuthorized={(status) => unAuthorized(status)}
                  />
                </Guarded>
              }
            />
            <Route
              path="/accountpreference"
              element={
                <Guarded isAuthenticated={isAuthenticated}>
                  <Sidebar
                    loginAPI={(e) => loginAPI()}
                    accountpreference={"active"}
                    unAuthorized={(status) => unAuthorized(status)}
                  />
                </Guarded>
              }
            />

            {/* Not Found page */}
          </>
        )}
        <Route
          path="*"
          element={
            <NotFound
              isAuthenticated={isAuthenticated}
              unAuthorized={(status) => unAuthorized(status)}
            />
          }
        />
      </Routes>
      <Loader open={isLoading} />
      <Loader open={location.path === "login" ? loading : false} />
    </>
  );
};

export default withAuthenticationRequired(
  RouterConfig,

  {
    onRedirecting: () => <Loader />,

    returnTo: () => process.env.REACT_APP_FALLBACK_URL_FOR_AUTH_0,
  }
);
