import {
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  Fade,
  FormControl,
  FormHelperText,
  FormLabel,
  Grow,
  IconButton,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Slide,
  TextField,
  Typography,
  Zoom,
} from "@mui/material";
import { Box } from "@mui/system";
import useNotification from "Components/CommonComponents/AlertNotifications/AlertNotifications";

import { CloseIcon } from "Icons/Icons";
import { useSnackbar } from "notistack";
import { React, useEffect, useState } from "react";
import { getAllEmployee } from "Services/AddEmployeeAndContractor";
import { postInsentive } from "Services/Incentive";
import { Grid } from "Utils/muiImport";
import { Error } from "Utils/Success&Errorfunctions";

export default function IncentiveModal(props) {
  const [msg, sendNotification] = useNotification();

  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(true);
  const [employee, setEmployee] = useState("");
  const [allEmployeeArray, setAllEmployeeArray] = useState([]);
  const [incentiveType, setIncentiveType] = useState("");
  const [note, setNote] = useState("");
  const [grossOrNet, setGrossOrNet] = useState("gross");
  const [paymentFrequency, setPaymentFrequency] = useState("one time");

  const hideLoading = () => {
    setLoading(false);
  };
  const showLoading = () => {
    setLoading(true);
  };

  useEffect(() => {
    getEmployee();
    setEmployee("");
    setIncentiveType("");
    setNote("");
  }, [props.open]);

  const saveIncentive = (e) => {
    e.preventDefault();
    showLoading();
    var incentiveData = {
      incentive_type: incentiveType,
      note: note.trim(),
      amount_type: grossOrNet,
      frequency: paymentFrequency,
      emp_id: employee,
    };
    showLoading();
    if (note.trim() != "" && employee != "" && incentiveType != "") {
      postInsentive(incentiveData).then(
        (res) => {
          sendNotification({
            msg: res.data.message,
            variant: "success",
          });
          setModalOpen(false);
          setEmployee("");
          setIncentiveType("");
          setNote("");
          setGrossOrNet("");
          setPaymentFrequency("");
          props.saveSuccessfully();
          props.close();
          hideLoading();
        },
        (err) => {
          var errors = Error(err.response.data.message);

          if (Array.isArray(errors)) {
            errors.forEach((element) => {
              sendNotification({
                msg: `Please enter valid ${element}`,
                variant: "error",
              });
            });
          } else {
            sendNotification({
              msg: err.response.data.message,
              variant: "error",
            });
          }
          props.close();
          console.log(err.message);
          hideLoading();
        }
      );
    } else {
      if (employee == "") {
        sendNotification({
          msg: "Please select employee!",
          variant: "error",
        });
        hideLoading();
      } else if (incentiveType == "") {
        sendNotification({
          msg: "Please select incentive type!",
          variant: "error",
        });
        hideLoading();
      } else if (note.trim() == "") {
        sendNotification({
          msg: "Please enter valid Note!",
          variant: "error",
        });
        hideLoading();
      }
    }
  };

  const getEmployee = () => {
    showLoading();
    getAllEmployee(1, 9999).then(
      (res) => {
        setAllEmployeeArray(res.data.data);
        hideLoading();
      },
      (err) => {
        console.log(err.message);
        hideLoading();
      }
    );
  };

  return (
    <>
      <>
        <div>
          <Modal
            open={props.open}
            onClose={props.close}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="add_hire_modal"
          >
            <Slide in={props.open}>
              <div>
                <Box className="add_hire_modal_bx">
                  <div className="modal_header">
                    <Grid container>
                      <Grid item>
                        <Typography id="modal-modal-title" variant="h6">
                          Add Incentive Pay
                        </Typography>
                      </Grid>
                      <Grid item>
                        <IconButton
                          onClick={props.close}
                          className="icon_close_bttn"
                        >
                          <CloseIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </div>
                  <form onSubmit={(e) => saveIncentive(e)}>
                    <div className="modal_body">
                      <div className="register_form">
                        <Grid xs={12}>
                          <TextField
                            required
                            variant="standard"
                            select
                            label="Employee"
                            fullWidth
                            value={employee}
                            onChange={(e) => setEmployee(e.target.value)}
                          >
                            {allEmployeeArray.length ? (
                              allEmployeeArray.map((data) => (
                                <MenuItem
                                  value={data.emp_id.row_id}
                                  key={data.emp_id.row_id}
                                  className="request-modal-employe-listitem"
                                >
                                  <span>
                                    {data.emp_id.full_name}
                                  </span>
                                </MenuItem>
                              ))
                            ) : (
                              <MenuItem disabled>
                                There Are No Active Employees
                              </MenuItem>
                            )}
                          </TextField>
                        </Grid>
                        <Grid xs={12}>
                          <TextField
                            required
                            id="Select-incentive-type"
                            select
                            label="Select incentive type"
                            variant="standard"
                            fullWidth
                            value={incentiveType}
                            onChange={(e) => setIncentiveType(e.target.value)}
                          >
                            <MenuItem value="signing bonus">
                              Signing bonus
                            </MenuItem>
                            <MenuItem value="bonus">Bonus (Other)</MenuItem>
                            <MenuItem value="commission">Commissions</MenuItem>
                            <MenuItem value="allowance">Allowance</MenuItem>
                            <MenuItem value="stipend">Stipend</MenuItem>
                            <MenuItem value="other">
                              Other (please leave a note)
                            </MenuItem>
                          </TextField>
                        </Grid>
                        <Grid xs={12}>
                          <TextField
                            fullWidth
                            required
                            id="standard-multiline-static"
                            label="Note"
                            variant="standard"
                            helperText="Your employee will see this note"
                            value={note}
                            onChange={(e) => setNote(e.target.value)}
                            inputProps={{ autoComplete: "off", maxLength: 250 }}
                          />
                        </Grid>
                        <Grid xs={12}>
                          <FormControl>
                            <FormLabel id="demo-radio-buttons-group-label">
                              Is this amount a gross or net value?
                            </FormLabel>
                            <RadioGroup
                              aria-labelledby="demo-radio-buttons-group-label"
                              defaultValue="gross"
                              name="radio-buttons-group"
                              value={grossOrNet}
                              onChange={(e) => setGrossOrNet(e.target.value)}
                            >
                              <Grid container>
                                <Grid item>
                                  <Radio value="gross" />
                                </Grid>
                                <Grid
                                  item
                                  style={{ marginLeft: "10px", flex: "1 0 0" }}
                                >
                                  <FormLabel>Gross</FormLabel>
                                  <FormHelperText>
                                    This is the amount to be paid before taxes
                                    are subtracted.
                                  </FormHelperText>
                                </Grid>
                              </Grid>
                              <Grid container>
                                <Grid item>
                                  <Radio value="net" />
                                </Grid>
                                <Grid
                                  item
                                  style={{ marginLeft: "10px", flex: "1 0 0" }}
                                >
                                  <FormLabel>Net</FormLabel>
                                  <FormHelperText>
                                    This is the amount which will be paid to the
                                    employee after taxes. Inrole will gross this
                                    up to ensure the taxes are included and
                                    employee receives the amount requested
                                    without further reduction.
                                  </FormHelperText>
                                </Grid>
                              </Grid>
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                        <Grid xs={12}>
                          <TextField
                            required
                            id="Select-payment-frequency"
                            select
                            label="Select payment frequency"
                            helperText="Payment will be added to the first payroll run after this date."
                            variant="standard"
                            fullWidth
                            value={paymentFrequency}
                            onChange={(e) =>
                              setPaymentFrequency(e.target.value)
                            }
                          >
                            <MenuItem value="one time">One time</MenuItem>
                            <MenuItem value="monthly">Monthly</MenuItem>
                          </TextField>
                        </Grid>
                      </div>
                    </div>
                    <div className="modal_footer">
                      <Grid container className="modal_foot_bttn_container">
                        <Button
                          disabled={loading}
                          className="theme_bttn fill"
                          type="submit"
                        >
                          Save
                        </Button>
                        <Button className="theme_bttn" onClick={props.close}>
                          Cancel
                        </Button>
                      </Grid>
                    </div>
                  </form>
                </Box>
              </div>
            </Slide>
          </Modal>
        </div>
      </>
    </>
  );
}
