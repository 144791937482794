import { useAuth0 } from "@auth0/auth0-react";
import { RegisterEmailVerified } from "Components/Register/Register Email Verified/RegisterEmailVerified";
import { Register2 } from "Components/Register/Register2/Register2";
import { Navigate, useLocation } from "react-router-dom";

export function Guarded({ children, auth0domain, loginAPI }) {
  const { isAuthenticated } = useAuth0();
  var location = useLocation();
  var items = JSON.parse(localStorage.getItem("JWT"));
  var isEmailVerified = localStorage.getItem("IsEmailVerified");
  if (!isAuthenticated || !items) {
    localStorage.clear();
    return <Navigate to="/login" replace />;
  }

  let params = new URLSearchParams(location.search);
  var result = params.get("success");

  if (isEmailVerified !== "true") {
    if (result !== "true" || result === null) {
      return <Register2 />;
    } else if (location.pathname === "/emailverified") {
      localStorage.setItem("IsEmailVerified", true);
      return (
        <RegisterEmailVerified auth0domain={auth0domain} loginAPI={loginAPI} />
      );
    } else {
      return <Register2 />;
    }
  }

  return children;
}

export function NotGuarded({ children, isAuthenticated }) {
  const items = JSON.parse(localStorage.getItem("JWT"));
  const accountType = localStorage.getItem("Account_Type");
  if (items && isAuthenticated && accountType === "company") {
    return <Navigate to="/home" replace />;
  }
  if (
    items &&
    isAuthenticated &&
    (accountType === "employee" || accountType === "contractor")
  ) {
    return <Navigate to="/employeeregister" replace />;
  }
  return children;
}
