import "./EmpRegister1Imports";
import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  InRoleLogo,
  CheckCircleRoundedIcon,
  ThumbUpSharpIcon,
  Box,
  Grid,
  Avatar,
  Icon,
  Typography,
} from "./EmpRegister1Imports";

import jwtDecode from "jwt-decode";
import {
  contractorSelfEnrollment,
  employeeSelfEnrollment,
  getContractorData,
  getemployeeProfile,
  getStatus,
} from "Services/AddEmployeeAndContractor";
import {
  HelpButton,
  LogOutButton,
} from "Components/CommonComponents/Bottom_Help_Button/HelpButton";
import { InRoleGreenLogo } from "Icons/Icons";
import useNotification, {
  WarningAlertNotification,
} from "Components/CommonComponents/AlertNotifications/AlertNotifications";
import { Button, Fade, Grow, Skeleton, Slide } from "@mui/material";
import { useSnackbar } from "notistack";
import { LinearProgressWithLabel } from "Utils/TabPanel";

export const EmpRegister1 = (props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const { enqueueSnackbar } = useSnackbar();
  const [msg, sendNotification] = useNotification();

  const [progress, setProgress] = useState(0);
  const [usermail, setEmail] = useState("");
  const [profileStatusArray, setProfileStatusArray] = useState([]);
  const [companyEmployeeStatusArray, setCompanyEmployeeStatusArray] = useState(
    []
  );
  const [totalTasks, setTotalTasks] = useState("");
  const [totalStatus, setTotalStatus] = useState([]);
  const [taskButtons, setTaskButtons] = useState([]);

  const [response, setResponse] = useState("");

  useEffect(() => {
    email();
    getProfileStatus();
  }, []);

  const showLoading = () => {
    setLoading(true);
  };

  const HideLoading = () => {
    setLoading(false);
  };

  const email = async () => {
    var jwt = localStorage.getItem("JWT");
    const decodedjwt = jwtDecode(jwt);
    localStorage.setItem("account_type", decodedjwt.account_type);
    setEmail(decodedjwt.email);
  };

  const navigateToWelcome = () => {
    if (localStorage.getItem("account_type") !== "contractor") {
      employeeSelfEnrollment().then(
        (res) => {
          var variant = "success";
          enqueueSnackbar(res.data.message, { variant });
          navigate("/empwelcome");
        },
        (err) => {
          props.unAuthorized(err.response.status);
          console.log(err.message);
        }
      );
    } else {
      contractorSelfEnrollment().then(
        (res) => {
          var variant = "success";
          enqueueSnackbar(res.data.message, { variant });
          navigate("/empwelcome");
        },
        (err) => {
          props.unAuthorized(err.response.status);
          console.log(err.message);
        }
      );
    }
  };

  const getProfileStatus = () => {
    showLoading();
    if (localStorage.getItem("account_type") !== "contractor") {
      getemployeeProfile()
        .then(
          (res) => {
            // console.log(res.data);
            setProfileStatusArray([res.data]);
            setTaskButtons(res.data.company_employee_profile_status);

            setCompanyEmployeeStatusArray(res.data.company_employee_status);
            setTotalStatus(res.data.company_employee_status);
            let filterStatus = res.data.company_employee_status.filter(
              (status) => {
                return status.status_id.sequence == 2;
              }
            );

            var totalStatus = res.data.company_employee_profile_status.length;
            var multiplier = 100 / totalStatus;

            let completedStatus =
              res.data.company_employee_profile_status.filter((data) => {
                return data.completed == false;
              });
            setTotalTasks(completedStatus.length);
            setProgress((totalStatus - completedStatus.length) * multiplier);
            if (filterStatus[0].completed) {
              navigate("/empwelcome");
            }
            HideLoading();
          },
          (err) => {
            console.log(err.message);
            props.unAuthorized(err.response.status);
            HideLoading();
          }
        )
        .then(
          getStatus().then(
            (res) => {
              // console.log(res.data.data);
              // setTotalTasks(res.data.data[1].child_status.length);
            },
            (err) => {
              console.log(err.message);
              props.unAuthorized(err.response.status);
            }
          )
        );
    } else {
      getContractorData()
        .then(
          (res) => {
            setProfileStatusArray([res.data]);
            setTaskButtons(res.data.company_contractor_profile_status);
            setCompanyEmployeeStatusArray(res.data.company_employee_status);
            setTotalStatus(res.data.company_contractor_status);

            let filterStatus = res.data.company_contractor_status.filter(
              (status) => {
                return status.status_id.sequence == 2;
              }
            );

            var totalStatus = res.data.company_contractor_profile_status.length;
            var multiplier = 100 / totalStatus;

            let completedStatus =
              res.data.company_contractor_profile_status.filter((data) => {
                return data.completed == false;
              });
            setTotalTasks(completedStatus.length);
            setProgress((totalStatus - completedStatus.length) * multiplier);

            if (filterStatus[0].completed) {
              navigate("/empwelcome");
            }
            HideLoading();
          },
          (err) => {
            console.log(err.message);
            HideLoading();
          }
        )
        .then(
          getStatus().then(
            (res) => {
              // console.log(res.data);
            },
            (err) => {
              console.log(err.message);
            }
          )
        );
    }
  };

  const completeStatusNotification = () => {
    sendNotification({
      msg: "This task has been completed!",
      variant: "warning",
    });
  };

  return (
    <>
      <Grid xs={12}>
        <Slide direction="down" in={true} timeout={1000} easing={"ease"}>
          <Box className="user_header">
            <Grid container className="user_header_container">
              <Grid xs={6} className="user_header_logo">
                <InRoleGreenLogo />
              </Grid>
              <Grid xs={6} className="user_header_profile">
                {!loading ? (
                  <Grid
                    container
                    spacing={0}
                    className="user_header_profile_container"
                  >
                    <Avatar className="profile_avatar">
                      {/* {user.nickname.slice(0, 2).toUpperCase()} */}
                    </Avatar>
                    <Grid item className="profile_cont">
                      <Typography variant="body1">
                        {/* {user.nickname} */}
                        {profileStatusArray.map((data) => data.full_name)}
                      </Typography>
                      <Typography variant="body2">
                        {profileStatusArray.map((data) => data.company_name)}
                      </Typography>
                    </Grid>
                  </Grid>
                ) : (
                  <>
                    <Grid
                      container
                      spacing={0}
                      className="user_header_profile_container"
                    >
                      <Skeleton animation="wave" variant="circular">
                        <Avatar className="profile_avatar">
                          {/* {user.nickname.slice(0, 2).toUpperCase()} */}
                        </Avatar>
                      </Skeleton>
                      <Grid item className="profile_cont">
                        <Skeleton animation="wave">
                          {" "}
                          <Typography variant="body1">
                            {/* {user.nickname} */}
                            InRole
                          </Typography>
                        </Skeleton>
                        <Skeleton animation="wave" variant="rounded">
                          <Typography variant="body2">
                            Inrole Company
                          </Typography>
                        </Skeleton>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Box>
        </Slide>

        <div className="contractor_onboarding_cont">
          <Grid xs={12} className="contractor_onboarding_head">
            <Box sx={{ overflow: "hidden" }}>
              <Slide direction="up" in={true} timeout={1000} easing={"ease"}>
                <Typography variant="h2">
                  The Finish line is in sight! 🎉
                </Typography>
              </Slide>
            </Box>
            <Box sx={{ overflow: "hidden" }}>
              <Slide direction="up" in={true} timeout={1000} easing={"ease"}>
                <Typography variant="body2">
                  Check out all the details we require from you
                </Typography>
              </Slide>
            </Box>
          </Grid>
          <Box sx={{ overflow: "hidden" }}>
            <Grid
              container
              spacing={0}
              className="contractor_onboarding_progress"
            >
              <Slide direction="right" in={true} timeout={1000} easing={"ease"}>
                <Grid item>
                  {!loading ? (
                    totalTasks != 0 ? (
                      <Typography>{totalTasks} Task remaining</Typography>
                    ) : (
                      <Typography color="primary" variant="body2">
                        Completed
                      </Typography>
                    )
                  ) : (
                    <Skeleton animation="wave" variant="rounded">
                      <Typography color="primary" variant="body2">
                        Completed
                      </Typography>
                    </Skeleton>
                  )}
                </Grid>
              </Slide>
              <Slide direction="left" in={true} timeout={1000} easing={"ease"}>
                <Grid item>
                  {!loading ? (
                    <Grid item className="auth_top_header_progress">
                      <LinearProgressWithLabel value={progress} />
                    </Grid>
                  ) : (
                    <Skeleton animation="wave" variant="rounded">
                      <Grid item className="auth_top_header_progress">
                        <LinearProgressWithLabel />
                      </Grid>
                    </Skeleton>
                  )}
                </Grid>
              </Slide>
            </Grid>
          </Box>
          <Grid xs={12} spacing={0} className="contractor_onboarding_blocks">
            {!loading
              ? taskButtons.map((data, index) =>
                  data.completed ? (
                    <Box
                      onClick={completeStatusNotification}
                      className="contractor_onboarding_block iscompleted"
                    >
                      <Box sx={{ overflow: "hidden" }}>
                        <Grid container spacing={0}>
                          <Slide
                            direction="right"
                            in={!loading}
                            timeout={1000}
                            easing={"ease"}
                          >
                            <Typography>{data.status_id.code}</Typography>
                          </Slide>
                          <Slide
                            direction="left"
                            in={!loading}
                            timeout={1000}
                            easing={"ease"}
                          >
                            <Icon>
                              <CheckCircleRoundedIcon />
                            </Icon>
                          </Slide>
                        </Grid>
                      </Box>
                    </Box>
                  ) : (
                    <Box
                      onClick={() => navigate(`/${data.status_id.status_code}`)}
                      className="contractor_onboarding_block inprogress"
                    >
                      <Box sx={{ overflow: "hidden" }}>
                        <Grid container spacing={0}>
                          <Slide
                            direction="right"
                            in={!loading}
                            timeout={1000}
                            easing={"ease"}
                          >
                            <Typography>{data.status_id.code}</Typography>
                          </Slide>
                          <Slide
                            direction="left"
                            in={!loading}
                            timeout={1000}
                            easing={"ease"}
                          >
                            <Icon>
                              <ThumbUpSharpIcon />
                            </Icon>
                          </Slide>
                        </Grid>
                      </Box>
                    </Box>
                  )
                )
              : [1, 2, 3, 4].map((data, index) => (
                  <Box className="contractor_onboarding_block inprogress">
                    <Grid container spacing={0} flex-wrap="nowrap">
                      <Skeleton animation="wave" variant="rounded">
                        <Typography>
                          This is Skeleton For Loading.....
                        </Typography>
                      </Skeleton>
                      <Skeleton animation="wave" variant="circular" width={30}>
                        <Icon>
                          <ThumbUpSharpIcon />
                        </Icon>
                      </Skeleton>
                    </Grid>
                  </Box>
                ))}
          </Grid>
          {!loading ? (
            totalTasks == 0 ? (
              <Box sx={{ overflow: "hidden" }}>
                <Slide in={totalTasks == 0} direction="up">
                  <Grid className="login_bttn_wrapper">
                    <Button
                      type="button"
                      className="theme_bttn fill"
                      onClick={navigateToWelcome}
                    >
                      Save & Continue
                    </Button>
                  </Grid>
                </Slide>
              </Box>
            ) : null
          ) : null}
        </div>
      </Grid>
      <Box sx={{ overflow: "hidden" }}>
        <LogOutButton />
      </Box>
      <Box sx={{ overflow: "hidden" }}>
        <HelpButton />
      </Box>
    </>
  );
};
