import "./AddEmployee2.css";
import { React, useState, useEffect } from "react";
import FormHelperText from "@mui/material/FormHelperText";
import OutlinedInput from "@mui/material/OutlinedInput";
import {
  PropTypes,
  Rectangle,
  InRolllogo,
  LanguageIcon,
  FavoriteBorderIcon,
  TextField,
  Box,
  LinearProgress,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  MenuItem,
  InputAdornment,
  Input,
  NativeSelect,
  Checkbox,
  Container,
  Stack,
  useNavigate,
  Icon,
  Button,
  Grid,
  IconButton,
  getExperiences,
  getStates,
  updateEmployee,
  FilledInput,
  InputLabel,
  Divider,
} from "./AddEmployee2Imports";

import { Link, useParams } from "react-router-dom";
import Modal from "@mui/material/Modal";
import {
  getbenifit,
  getCurrencies,
  getEmpContract,
} from "Services/AddEmployeeAndContractor";
import { AuthSidebar } from "Components/Login/LoginImport";
import { CloseIcon, PreviousArrowIcon } from "Icons/Icons";
import useNotification from "Components/CommonComponents/AlertNotifications/AlertNotifications";
import { CurrencyConverter } from "Components/CommonComponents/Modals/CurrencyModal/CurrencyConverter";
import { useSnackbar } from "notistack";
import { Error } from "Utils/Success&Errorfunctions";
import { AddEmployeeStepper } from "../AddEmployeeSideBar/AddEmployeeSideBar";
import useMediaQuery from "@mui/material/useMediaQuery";

export const AddEmployee2 = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const tabletScreen = useMediaQuery("(max-width:899px)");

  const { enqueueSnackbar } = useSnackbar();
  const [msg, sendNotification] = useNotification();

  const [loading, setLoading] = useState(false);

  const [total, setTotal] = useState(0);
  const [progress, setProgress] = useState(25);
  const [contractDuration, setContractDuration] = useState("indefinite");
  const [termOfContract, setTermOfContract] = useState("");
  const [probationPeriod, setProbationPeriod] = useState("");
  const [typeOfEmployee, setTypeOfEmployee] = useState("full-time");
  const [workingHours, setWorkingHours] = useState("");
  const [annualSalary, setAnnualSalary] = useState("");
  const [employeeProvidentFund, setEmployeeProvidentFund] = useState("18");
  const [signingBonus, setSigningBonus] = useState("False");
  const [signingBonusText, setSigningBonusText] = useState("");
  const [otherBonus, setOtherBonus] = useState("False");
  const [otherBonusText, setOtherBonusText] = useState("");
  const [comission, setComission] = useState("False");
  const [comissionText, setComissionText] = useState("");
  const [equityCompensation, setEquityCompensation] = useState("");
  const [roleDescription, setRoleDescription] = useState("");
  const [superviserName, setSuperviserName] = useState("");
  const [experienceLevel, setExperienceLevel] = useState("4");

  const [nonSolicitationClause, setNonSolicitationClause] = useState("No");
  const [benefits, setBenefits] = useState(false);
  const [paidLeaves, setPaidLeaves] = useState("");
  const [Experience, setExperience] = useState([]);

  const [empprovidantFund, setEmpprovidantFund] = useState([]);
  const [allBenifits, setAllBenifits] = useState([]);
  const [currency, setCurrency] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  const [errorOpen, setErrorOpen] = useState(false);

  useEffect(() => {
    fillform();
    getExperience();

    getbenifits();
  }, []);

  const getExperience = () => {
    if (JSON.parse(localStorage.getItem("experience_level")) !== null) {
      setExperience(JSON.parse(localStorage.getItem("experience_level")));
    } else {
      setExperience([]);
    }

    if (JSON.parse(localStorage.getItem("employee_provident_fund")) !== null) {
      setEmpprovidantFund(
        JSON.parse(localStorage.getItem("employee_provident_fund"))
      );
    } else {
      setEmployeeProvidentFund([]);
    }
  };

  const showLoading = () => {
    setLoading(true);
  };

  const hideLoading = () => {
    setLoading(false);
  };

  const getbenifits = () => {
    getbenifit().then(
      (res) => {
        let tempTotal = 0;
        res.data.map((value) => {
          if (value.mandatory) tempTotal += value.price;
          value.checked = value.mandatory;
        });
        setAllBenifits(res.data);
        setTotal(tempTotal);
      },
      (err) => {
        console.log(err.message);
      }
    );
  };

  const getCurrenctConversion = (data) => {
    getCurrencies(data).then(
      (res) => {
        setCurrency(res.data);
      },
      (err) => {
        console.log(err.message);
      }
    );
  };

  const onBenifitsChecked = (e, index) => {
    allBenifits[index].checked = e.target.checked;
    setAllBenifits(allBenifits);
    if (e.target.checked) {
      let tempTotal = total;
      setTotal(tempTotal + allBenifits[index].price);
    } else {
      let tempTotal = total;
      setTotal(tempTotal - allBenifits[index].price);
    }
  };

  const goBack = () => {
    var id = params.id;
    if (!id) {
      var rowdata = localStorage.getItem("empRowData");
      navigate("/addemployee/" + rowdata);
    } else {
      navigate("/addemployee/" + id);
    }
  };

  const changetext = () => {
    if (signingBonus == "False") {
      setSigningBonusText("");
    }
    if (otherBonus == "False") {
      setOtherBonusText("");
    }
    if (comission == "False") {
      setComissionText("");
    }
  };

  const submtnewemployee = (e) => {
    e.preventDefault();
    showLoading();

    var id = params.id;
    var row_id = localStorage.getItem("empRowData");

    setErrorOpen(false);

    let otherArray = [];
    allBenifits.map((val) => {
      if (val.checked) {
        otherArray.push({ benefit_id: val.row_id });
      }
    });

    if (contractDuration === "fixed-term" && termOfContract <= 0) {
      setErrorOpen(true);
      sendNotification({
        msg: "Terms of Contract cant't be 0",
        variant: "error",
      });
      hideLoading();
    } else if (probationPeriod <= 0) {
      setErrorOpen(true);
      sendNotification({
        msg: "Probation Period Can't Be Less Than 0",
        variant: "error",
      });
      hideLoading();
    } else if (workingHours <= 0) {
      setErrorOpen(true);
      sendNotification({
        msg: "Working Hours Can't Be Less Than 0",
        variant: "error",
      });
      hideLoading();
    } else if (annualSalary <= 0) {
      setErrorOpen(true);
      sendNotification({
        msg: "Annual Salary Can't Be Less Than 0",
        variant: "error",
      });
      hideLoading();
    } else if (signingBonus == "True" && signingBonusText <= 0) {
      setErrorOpen(true);
      sendNotification({
        msg: "Signing Bonus Can't Be Less Than 0",
        variant: "error",
      });
      hideLoading();
    } else if (otherBonus == "True" && otherBonusText <= 0) {
      setErrorOpen(true);
      sendNotification({
        msg: "Other Bonus Can't Be Less Than 0",
        variant: "error",
      });
      hideLoading();
    } else if (comission == "True" && comissionText <= 0) {
      setErrorOpen(true);
      sendNotification({
        msg: "Comission Bonus Can't Be Less Than 0",
        variant: "error",
      });
      hideLoading();
    } else if (paidLeaves < 27 || paidLeaves > 365) {
      setErrorOpen(true);
      sendNotification({
        msg: `Paid Leaves Can't Be Less Than 27 or greater than 365`,
        variant: "error",
      });
      hideLoading();
    } else if (roleDescription.trim() === "") {
      setErrorOpen(true);
      sendNotification({
        msg: "Please enter valid role description!",
        variant: "error",
      });
      hideLoading();
    } else if (superviserName.trim() === "") {
      setErrorOpen(true);
      sendNotification({
        msg: "Please enter valid supervisor name!",
        variant: "error",
      });

      hideLoading();
    } else if (errorOpen == false) {
      const empData = {
        contract_duration: contractDuration,
        term_of_contract: parseInt(termOfContract),
        probation_period: probationPeriod,
        type_of_employee: typeOfEmployee,
        work_hours: workingHours,
        annual_gross_salary: annualSalary,
        employee_provident_fund: employeeProvidentFund,
        is_signing_bonus: signingBonus,
        signing_bonus: signingBonusText,
        is_other_bonuses: otherBonus,
        other_bonuses: otherBonusText,
        is_commission: comission,
        commission: comissionText,

        role_description: roleDescription.trim(),
        supervisor_name: superviserName.trim(),
        experience_level: experienceLevel,

        non_solicitation: nonSolicitationClause,
        benefits: benefits,
        paid_time_off_days: parseInt(paidLeaves),
        employee_benefits: otherArray,
      };

      const Summary = JSON.parse(localStorage.getItem("Empdata1"));
      const Masterdata = {
        ...Summary,
        ...empData,
        is_draft: "True",
      };

      if (row_id !== null) {
        postEmployee(Masterdata, row_id, empData);
      } else {
        putEmloyee(Masterdata, id, empData);
      }
    } else {
      const empData = {
        contract_duration: contractDuration,
        term_of_contract: parseInt(termOfContract),
        probation_period: probationPeriod,
        type_of_employee: typeOfEmployee,
        work_hours: workingHours,
        annual_gross_salary: annualSalary,
        employee_provident_fund: employeeProvidentFund,
        is_signing_bonus: signingBonus,
        signing_bonus: signingBonusText,
        is_other_bonuses: otherBonus,
        other_bonuses: otherBonusText,
        is_commission: comission,
        commission: comissionText,

        role_description: roleDescription.trim(),
        supervisor_name: superviserName.trim(),
        experience_level: experienceLevel,

        non_solicitation: nonSolicitationClause,
        benefits: benefits,
        paid_time_off_days: parseInt(paidLeaves),
        employee_benefits: otherArray,
      };

      const Summary = JSON.parse(localStorage.getItem("Empdata1"));
      const Masterdata = {
        ...Summary,
        ...empData,
        is_draft: "True",
      };

      if (row_id !== null) {
        postEmployee(Masterdata, row_id, empData);
      } else {
        putEmloyee(Masterdata, id, empData);
      }
    }
  };

  const postEmployee = (Masterdata, row_id, empData) => {
    showLoading();
    updateEmployee(Masterdata, row_id).then(
      (res) => {
        var variant = "success";
        enqueueSnackbar(res.data.message, { variant });
        localStorage.setItem("Empdata2", JSON.stringify(empData));

        navigate("/addemployeepricingplan");
        hideLoading();
      },
      (err) => {
        var errors = Error(err.response.data.message);

        if (Array.isArray(errors)) {
          errors.forEach((element) => {
            sendNotification({
              msg: `Please enter valid ${element}`,
              variant: "error",
            });
          });
          hideLoading();
        } else {
          sendNotification({
            msg: err.response.data.message,
            variant: "error",
          });
          hideLoading();
        }
        hideLoading();
      }
    );
    hideLoading();
  };

  const putEmloyee = (Masterdata, id, empData) => {
    showLoading();
    updateEmployee(Masterdata, id).then(
      (res) => {
        var variant = "success";
        enqueueSnackbar(res.data.message, { variant });
        localStorage.setItem("Empdata2", JSON.stringify(empData));

        navigate("/addemployeepricingplan/" + id);
        hideLoading();
      },
      (err) => {
        var errors = Error(err.response.data.message);
        if (Array.isArray(errors)) {
          errors.forEach((element) => {
            sendNotification({
              msg: `Please enter valid ${element}`,
              variant: "error",
            });
          });
          hideLoading();
        } else {
          sendNotification({
            msg: err.response.data.message,
            variant: "error",
          });
          hideLoading();
        }
        setTimeout(() => {
          if (err.response.data.message === "Employee already invited") {
            navigate("/onboarding");
          }
        }, 2000);
        hideLoading();
      }
    );
    hideLoading();
  };

  const fillform = () => {
    var id = params.id;

    if (!id) {
      const empdata = JSON.parse(localStorage.getItem("Empdata2"));

      if (empdata || empdata !== null) {
        setContractDuration(empdata.contract_duration);

        setTermOfContract(empdata.term_of_contract);
        setProbationPeriod(empdata.probation_period);
        setTypeOfEmployee(empdata.type_of_employee);
        setWorkingHours(empdata.work_hours);
        setAnnualSalary(empdata.annual_gross_salary);
        setEmployeeProvidentFund(empdata.employee_provident_fund);
        setSigningBonus(empdata.is_signing_bonus);
        setSigningBonusText(empdata.signing_bonus);
        setOtherBonus(empdata.is_other_bonuses);
        setOtherBonusText(empdata.other_bonuses);
        setComission(empdata.is_commission);
        setComissionText(empdata.commission);
        setEquityCompensation(empdata.equity_compensation);
        setRoleDescription(empdata.role_description);
        setSuperviserName(empdata.supervisor_name);
        setExperienceLevel(empdata.experience_level);
        // setWorkAddress(empdata.work_address);
        setNonSolicitationClause(empdata.non_solicitation);
        setBenefits(empdata.benefits);
        setPaidLeaves(empdata.paid_time_off_days);
        // setAllBenifits(empdata.employee_benefits);
      }
    } else {
      getEmpContract(id).then(
        (res) => {
          const val = res.data;
          if (val.contract_duration !== null) {
            setContractDuration(val.contract_duration);
          } else {
            setContractDuration("indefinite");
          }
          setProbationPeriod(val.probation_period);
          if (val.type_of_employee !== null) {
            setTypeOfEmployee(val.type_of_employee);
          } else {
            setTypeOfEmployee("full-time");
          }
          setTermOfContract(val.term_of_contract);
          setWorkingHours(val.work_hours);
          setAnnualSalary(val.annual_gross_salary);

          if (val.employee_provident_fund.value !== null) {
            var employeefund = JSON.parse(
              localStorage.getItem("employee_provident_fund")
            );
            var data = employeefund.filter((data) => {
              return data.value === val.employee_provident_fund.value;
            });

            var row = data.map((data) => {
              return data.row_id;
            });
            if (row[0] != undefined) {
              setEmployeeProvidentFund(`${row[0]}`);
            } else {
              setEmployeeProvidentFund("");
            }
          } else {
            setEmployeeProvidentFund("");
          }

          if (val.is_signing_bonus == true) {
            setSigningBonus("True");
            setSigningBonusText(val.signing_bonus);
          } else {
            setSigningBonus("False");
          }
          if (val.is_other_bonuses == true) {
            setOtherBonus("True");
            setOtherBonusText(val.other_bonuses);
          } else {
            setOtherBonus("False");
          }
          if (val.is_commission == true) {
            setComission("True");
            setComissionText(val.commission);
          } else {
            setComission("False");
          }

          setPaidLeaves(val.paid_time_off_days);
          setRoleDescription(val.role_description);
          setSuperviserName(val.supervisor_name);

          var experienceData = JSON.parse(
            localStorage.getItem("experience_level")
          );

          var data = experienceData.filter((value) => {
            return value.value == val.experience_level.value;
          });
          setExperienceLevel(data[0].row_id);

          if (res.data.non_solicitation == true) {
            setNonSolicitationClause("Yes");
          } else {
            setNonSolicitationClause("No");
          }
          setBenefits(res.data.benefits);
        },
        (err) => {
          console.log(err.message);
        }
      );
    }
  };

  const changeTermsOfContract = () => {
    if (contractDuration === "indefinite") {
      setTermOfContract("");
    }
  };

  return (
    <>
      <Box className="warning_info_box">
        <Grid container>
          <Grid item className="warning_box_icon">
            <Box className="icon_bx">
              <Icon>
                <LanguageIcon />
              </Icon>
            </Box>
          </Grid>
          <Grid item className="warning_box_cont">
            <Typography variant="h3">Would you like some help?</Typography>
            <Typography variant="body2">
              Here is our guide to employing people in India
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <form
        onSubmit={submtnewemployee}
        className="register_form add_employe_form"
      >
        <Grid xs={12}>
          <FormLabel
            id="demo-radio-buttons-group-label"
            className="lable_color_theme"
          >
            Contract duration
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            value={contractDuration}
            name="radio-buttons-group"
            onChange={(e) => {
              setContractDuration(e.target.value);
              changeTermsOfContract();
            }}
          >
            <Grid container spacing={0} className="justify_content_start">
              <FormControlLabel
                value="indefinite"
                control={<Radio />}
                label="Indefinite"
                spacing={0}
              />
              <FormControlLabel
                value="fixed-term"
                control={<Radio />}
                label="Fixed Term"
                spacing={0}
              />
            </Grid>
          </RadioGroup>
          {contractDuration === "fixed-term" && (
            <Grid xs={12}>
              <TextField
                required
                fullWidth
                type="number"
                placeholder="Term Of Contract.(monthly)"
                variant="standard"
                value={termOfContract}
                onChange={(e) => setTermOfContract(e.target.value)}
                id="probationperiod"
                inputProps={{
                  max: 99,
                  autoComplete: "off",
                  min: 1,
                  maxLength: 2,
                  pattern: "/^d*$/",
                }}
                className="lable_color_theme"
              />
            </Grid>
          )}
          <FormHelperText className="helper_color_theme">
            Learn more about contract duration
          </FormHelperText>
        </Grid>
        <Grid xs={12}>
          <FormLabel
            id="demo-radio-buttons-group-label"
            className="lable_color_theme"
          >
            Probation period
          </FormLabel>
          <TextField
            required
            fullWidth
            type="number"
            placeholder="Probation period (in months)"
            variant="standard"
            helperText="How would you describe their status in their selected country?"
            value={probationPeriod}
            onChange={(e) => setProbationPeriod(e.target.value)}
            id="probationperiod"
            inputProps={{
              max: 99,
              autoComplete: "off",
              min: 1,
              maxLength: 2,
              pattern: "/^d*$/",
            }}
            className="lable_color_theme"
          />
          <FormHelperText className="helper_color_theme">
            Learn more about probation period
          </FormHelperText>
        </Grid>
        <Grid xs={12}>
          <FormLabel
            id="demo-radio-buttons-group-label"
            className="lable_color_theme"
          >
            Type of employee
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            value={typeOfEmployee}
            name="radio-buttons-group"
            onChange={(e) => setTypeOfEmployee(e.target.value)}
          >
            <Grid container>
              <FormControlLabel
                value="full-time"
                control={<Radio />}
                label="Full-time"
              />
              <FormControlLabel
                value="part-time"
                control={<Radio />}
                label="Part-time"
              />
            </Grid>
          </RadioGroup>
        </Grid>
        <Grid xs={12}>
          <FormLabel
            id="demo-radio-buttons-group-label"
            className="lable_color_theme"
          >
            Work Hours
          </FormLabel>
          <TextField
            required
            type="number"
            fullWidth
            placeholder="Hours per week"
            variant="standard"
            value={workingHours}
            onChange={(e) => setWorkingHours(e.target.value)}
            id="workingHours"
            inputProps={{
              max: 999,
              autoComplete: "off",
              min: 1,
              maxLength: 10,
              pattern: "/^d*$/",
            }}
            className="lable_color_theme"
          />
        </Grid>
        <Grid xs={12} className="accual_gross_salary">
          <FormLabel>Annual gross salary</FormLabel>
          <Grid xs={12}>
            <Input
              required
              type="number"
              value={annualSalary}
              onChange={(e) => setAnnualSalary(e.target.value)}
              placeholder="Annual gross salary"
              id="standard-adornment-amount"
              inputProps={{
                max: 99999999999999,
                autoComplete: "off",
                min: 1,
                maxLength: 10,
                pattern: "/^d*$/",
              }}
              startAdornment={
                <InputAdornment position="start">INR</InputAdornment>
              }
            />
          </Grid>
          <FormHelperText
            id="standard-weight-helper-text"
            className="helper_color_theme"
          >
            See Conversion
            <Link
              className="modalLink"
              onClick={() => {
                setModalOpen(true);
                getCurrenctConversion(annualSalary);
              }}
            >
              see here
            </Link>
          </FormHelperText>
        </Grid>
        <Grid xs={12}>
          <FormLabel>Employee Provident Fund</FormLabel>

          <TextField
            onChange={(e) => {
              setEmployeeProvidentFund(e.target.value);
            }}
            required
            value={employeeProvidentFund}
            fullWidth
            id="standard-select-currency-native"
            select
            defaultValue="EUR"
            variant="standard"
          >
            {empprovidantFund.map((option) => (
              <MenuItem key={option.value} value={option.row_id}>
                {option.value}
              </MenuItem>
            ))}
          </TextField>
          <FormHelperText>
            For the Employee Provident Fund, will the employee make a 12%
            contribution calculated against their basic salary or India’s base
            minimum salary? An employee’s basic salary is calculated as 50% of
            their annual salary, while the base minimum salary is the country’s
            minimum wage.
          </FormHelperText>
        </Grid>
        <Grid xs={12}>
          <FormLabel
            id="demo-radio-buttons-group-label"
            className="lable_color_theme"
          >
            Signing bonus
          </FormLabel>
          <FormHelperText>
            Do you want to include a one-time signing bonus?
          </FormHelperText>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            value={signingBonus}
            name="radio-buttons-group"
            onChange={(e) => setSigningBonus(e.target.value, changetext())}
          >
            <Grid container>
              <FormControlLabel value="True" control={<Radio />} label="Yes" />
              <FormControlLabel value="False" control={<Radio />} label="No" />
            </Grid>
          </RadioGroup>
        </Grid>

        {signingBonus == "True" ? (
          <Grid xs={12}>
            <Input
              type="number"
              value={signingBonusText}
              onChange={(e) => setSigningBonusText(e.target.value)}
              id="standard-adornment-weight"
              startAdornment={
                <InputAdornment position="end">INR</InputAdornment>
              }
              aria-describedby="standard-weight-helper-text"
              inputProps={{
                max: 99999,
                autoComplete: "off",
                min: 1,
                maxLength: 10,
                pattern: "/^d*$/",
              }}
            />
            <FormHelperText id="standard-weight-helper-text">
              See Conversion
              <Link
                className="modalLink"
                onClick={() => {
                  setModalOpen(true);
                  getCurrenctConversion(signingBonusText);
                }}
              >
                see here
              </Link>
            </FormHelperText>
          </Grid>
        ) : null}
        <Grid xs={12}>
          <FormLabel
            id="demo-radio-buttons-group-label"
            className="lable_color_theme"
          >
            Other bonuses
          </FormLabel>
          <FormHelperText>
            Do you want to include any other type of bonuses, such as
            performance-related bonuses?
          </FormHelperText>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            value={otherBonus}
            name="radio-buttons-group"
            onChange={(e) => setOtherBonus(e.target.value, changetext())}
          >
            <Grid container>
              <FormControlLabel value="True" control={<Radio />} label="Yes" />
              <FormControlLabel value="False" control={<Radio />} label="No" />
            </Grid>
          </RadioGroup>
        </Grid>

        {otherBonus == "True" ? (
          <Grid xs={12}>
            <Input
              value={otherBonusText}
              type="number"
              onChange={(e) => setOtherBonusText(e.target.value)}
              id="standard-adornment-other"
              startAdornment={
                <InputAdornment position="end">INR</InputAdornment>
              }
              aria-describedby="standard-weight-helper-text"
              inputProps={{
                max: 99999,
                autoComplete: "off",
                min: 1,
                maxLength: 10,
                pattern: "/^d*$/",
              }}
            />
            <FormHelperText id="standard-weight-helper-text">
              See Conversion{" "}
              <Link
                className="modalLink"
                onClick={() => {
                  setModalOpen(true);
                  getCurrenctConversion(otherBonusText);
                }}
              >
                see here
              </Link>
            </FormHelperText>
          </Grid>
        ) : null}
        <Grid xs={12}>
          <FormLabel
            id="demo-radio-buttons-group-label"
            className="lable_color_theme"
          >
            Commission
          </FormLabel>
          <FormHelperText>
            Do you want to include any commission?
          </FormHelperText>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            value={comission}
            name="radio-buttons-group"
            onChange={(e) => setComission(e.target.value, changetext())}
          >
            <Grid container>
              <FormControlLabel value="True" control={<Radio />} label="Yes" />
              <FormControlLabel value="False" control={<Radio />} label="No" />
            </Grid>
          </RadioGroup>
        </Grid>
        {comission == "True" ? (
          <Grid xs={12}>
            <Input
              value={comissionText}
              type="number"
              onChange={(e) => setComissionText(e.target.value)}
              id="standard-adornment-comissionText"
              startAdornment={
                <InputAdornment position="end">INR</InputAdornment>
              }
              aria-describedby="standard-weight-helper-text"
              inputProps={{
                max: 99999,
                autoComplete: "off",
                min: 1,
                maxLength: 10,
                pattern: "/^d*$/",
              }}
            />
            <FormHelperText id="standard-weight-helper-text">
              See Conversion{" "}
              <Link
                className="modalLink"
                onClick={() => {
                  setModalOpen(true);
                  getCurrenctConversion(comissionText);
                }}
              >
                see here
              </Link>
            </FormHelperText>
          </Grid>
        ) : null}

        <Grid xs={12}>
          <TextField
            required
            fullWidth
            label="   Number of paid time off days"
            helperText="According to Employment laws in India we need to offer a minimum
                  of 27 PTOs per year: ( 15 days Privilege Leave , 6 days Casual
                  Leave, 6 days Sick Leave). In addition to the PTOs, there are
                  Public holidays that are mandatory to be given."
            variant="standard"
            type="number"
            value={paidLeaves}
            onChange={(e) => setPaidLeaves(e.target.value)}
            id="paidleaves"
            inputProps={{
              max: 999,
              min: 27,
              max: 365,
              maxLength: 3,

              pattern: "/^d*$/",
            }}
          />
        </Grid>
        <Grid xs={12}>
          <FormHelperText>Role description</FormHelperText>
          <TextField
            required
            fullWidth
            id="outlined-textarea"
            helperText="Please add 3 tasks minimum (at least 100 characters in total). You can copy this from the job description."
            rows={3}
            multiline
            value={roleDescription}
            className="roledesctextfield"
            onChange={(e) => setRoleDescription(e.target.value)}
            inputProps={{ maxLength: 10000, required: true }}
          />

          <Grid xsOffset={11}>
            {roleDescription && roleDescription.length < 9500 ? (
              <Typography className="counter">
                {" "}
                {roleDescription.length}/10000
              </Typography>
            ) : (
              <Typography className="counter danger">
                {" "}
                {roleDescription ? roleDescription.length : 0}/10000
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid xs={12}>
          <TextField
            required
            fullWidth
            label="Supervisor name"
            variant="standard"
            helperText="Name of the direct supervisor or line manager"
            value={superviserName}
            onChange={(e) => setSuperviserName(e.target.value)}
            id="probationperiod"
            inputProps={{ maxLength: 128, autoComplete: "off" }}
          />
        </Grid>
        <Grid xs={12}>
          <FormLabel
            id="demo-radio-buttons-group-label"
            className="lable_color_theme"
          >
            Experience Level
          </FormLabel>
          <FormHelperText>
            Please select the experience level that aligns with this role based
            on the job description (not the employees overall experience)
          </FormHelperText>

          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            value={experienceLevel}
            name="radio-buttons-group"
            onChange={(e) => setExperienceLevel(e.target.value)}
          >
            {Experience.map((data, index) => (
              <div key={index}>
                <FormControlLabel
                  value={data.row_id}
                  control={<Radio />}
                  label={data.value}
                />

                <FormHelperText>{data.description}</FormHelperText>
              </div>
            ))}
          </RadioGroup>
        </Grid>

        <Grid xs={12}>
          <FormLabel
            id="demo-radio-buttons-group-label"
            className="lable_color_theme"
          >
            Apply a non-solicitation clause?
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            value={nonSolicitationClause}
            name="radio-buttons-group"
            onChange={(e) => setNonSolicitationClause(e.target.value)}
          >
            <div>
              <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
              <FormHelperText className="addEmployee-radiohelper">
                This will prevent the employee from reaching out to your
                company’s clients or customers for their own benefit, after
                their employment with you is done.
              </FormHelperText>
            </div>
            <div>
              <FormControlLabel value="No" control={<Radio />} label="No" />
            </div>
          </RadioGroup>
        </Grid>
        <Grid xs={12}>
          <FormLabel className="lable_color_theme">Benefits</FormLabel>
          <FormHelperText>
            We offer employee [only] supplemental, comprehensive coverage with
            Inrole Health, provided in partnership with Plum. The insurer is
            ICICI Lombard and coverage is for employee and dependents (spouse
            and children only). This also includes an employee only personal
            accident coverage.
          </FormHelperText>
        </Grid>
        <Grid xs={12} className="BoxGrid">
          <Grid xs={12}>
            <Box className="warning_info_box benefit">
              <Grid container>
                <Grid item className="warning_box_icon">
                  <Box className="icon_bx">
                    <Icon>
                      <FavoriteBorderIcon />
                    </Icon>
                  </Box>
                </Grid>
                <Grid item className="warning_box_cont">
                  <Typography variant="h3">
                    Check the benefits in India
                  </Typography>
                  <Grid>
                    <Typography variant="body2">
                      What InRole can offer your employees in India
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Grid xs={12} className="BoxGrid">
          <Box className="BenifitsBox-2">
            {allBenifits.map((data, index) => (
              <div key={index}>
                <Grid
                  container
                  alignItems="center"
                  flexDirection={{ xs: "row", sm: "row" }}
                >
                  <Grid xs={6}>
                    <Typography className="BenifitType">{data.name}</Typography>
                  </Grid>
                  <Grid xs={6}>
                    {data.mandatory ? (
                      <FormControlLabel
                        className="BenifitCheckbox"
                        control={<Checkbox defaultChecked disabled />}
                        label={
                          <Typography className="BenifitCheckbox">
                            {data.price} $
                          </Typography>
                        }
                      />
                    ) : (
                      <FormControlLabel
                        className="BenifitCheckbox"
                        onChange={(e) => {
                          onBenifitsChecked(e, index);
                        }}
                        control={<Checkbox />}
                        label={
                          <Typography className="BenifitCheckbox">
                            {data.price} $
                          </Typography>
                        }
                      />
                    )}
                  </Grid>
                </Grid>
                <Divider />
              </div>
            ))}
            <Grid
              container
              alignItems="center"
              flexDirection={{ xs: "row", sm: "row" }}
            >
              <Grid xs={6}>
                <FormLabel className="lable_color_theme">Grand Total</FormLabel>
              </Grid>
              <Grid xs={6}>
                <Typography className="BenifitCheckbox">{total}$</Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid xs={12}>
          <FormHelperText className="BenifitDesc">
            Prices listed in USD, local currency equivalent will be determined
            based on exchange rate at the time of invoicing. Please note that
            all local payroll deductions for required coverages are included in
            the TCE. Pricing listed is subject to change based on age of the
            employee (age banding) and/or plan changes/renewals. Any pricing
            changes will be communicated in advance of updated billing.
          </FormHelperText>
        </Grid>
        <Grid xs={12}>
          <FormControlLabel
            control={<Checkbox checked={benefits} />}
            onChange={(e) => setBenefits(e.target.checked)}
            label="Benefits"
            className="lable_color_theme"
          />
          <FormHelperText className="addEmployee-radiohelper">
            I acknowledge that the benefits shown above will be included in this
            employee's profile.
          </FormHelperText>
        </Grid>
        <Grid xs={12} className="login_bttn_wrapper">
          <Button
            type="submit"
            className="theme_bttn fill"
            disabled={loading ? true : false}
          >
            Continue
          </Button>
        </Grid>
      </form>

      <CurrencyConverter
        modalOpen={modalOpen}
        close={() => setModalOpen(false)}
        currencyData={currency}
      />
    </>
  );
};
